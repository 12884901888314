import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { CountdownTimerStyle } from './CountdownTimerWidget';
import { CountdownTimerContext } from '@/state/CountdownTimerContext';
import { leftFillNum } from '@/utils/helpers';

function CountdownTimer({ timerStyle, timerId, setTimeBoxWidth }) {
	const { t } = useTranslation('checkout');
	const { timeLeft } = useContext(CountdownTimerContext);
	const { days, hours, minutes, seconds } = timeLeft;
	const shouldHideDays = days === 0;
	const shouldHideHours = shouldHideDays && hours === 0;
	const shouldHideMinutes = shouldHideHours && minutes === 0;
	const secondsLabelId = `seconds-label-id-${timerId}`;
	const secondsDigitsId = `seconds-digits-id-${timerId}`;

	const showColons = timerStyle
		? timerStyle === CountdownTimerStyle.STANDARD || timerStyle === CountdownTimerStyle.DIGITAL
		: true;

	const isCircleOrSquare =
		timerStyle === CountdownTimerStyle.SQUARE || timerStyle === CountdownTimerStyle.CIRCLE;

	const digitClassName = isCircleOrSquare ? 'timer border' : 'timer';

	const debounceRef = useRef(null);

	const debounce = (func, delay) => {
		return (...args) => {
			if (debounceRef.current) {
				clearTimeout(debounceRef.current);
			}
			debounceRef.current = setTimeout(() => {
				func(...args);
			}, delay);
		};
	};

	useEffect(() => {
		const handleResize = () => {
			const labelElementWidth = document
				.getElementById(secondsLabelId)
				?.getBoundingClientRect().width;
			const digitElementWidth = document
				.getElementById(secondsDigitsId)
				?.getBoundingClientRect().width;
			if (labelElementWidth !== undefined && digitElementWidth) {
				setTimeBoxWidth(
					labelElementWidth > digitElementWidth ? labelElementWidth : digitElementWidth,
				);
			}
		};

		// Call it initially to set the timeBoxWidth
		handleResize();
		const debouncedResize = debounce(handleResize, 100);

		window.addEventListener('resize', debouncedResize);
		return () => {
			window.removeEventListener('resize', debouncedResize);
		};
	}, [timerStyle, secondsDigitsId, secondsLabelId, setTimeBoxWidth]);

	return (
		<div className="container">
			{!shouldHideDays && (
				<>
					<div className="time-descriptor-box">
						<Typography className={digitClassName}>{leftFillNum(days, 2)}</Typography>
						<Typography className="label">{t('countdown-timer.days')}</Typography>
					</div>
					{showColons && <Typography className={digitClassName}>:</Typography>}
				</>
			)}
			{!shouldHideHours && (
				<>
					<div className="time-descriptor-box">
						<Typography className={digitClassName}>{leftFillNum(hours, 2)}</Typography>
						<Typography className="label">{t('countdown-timer.hours')}</Typography>
					</div>
					{showColons && <Typography className={digitClassName}>:</Typography>}
				</>
			)}
			{!shouldHideMinutes && (
				<>
					<div className="time-descriptor-box">
						<Typography className={digitClassName}>
							{leftFillNum(minutes, 2)}
						</Typography>
						<Typography className="label">{t('countdown-timer.minutes')}</Typography>
					</div>
					{showColons && <Typography className={digitClassName}>:</Typography>}
				</>
			)}
			<div className="time-descriptor-box">
				<Typography className={digitClassName} id={secondsDigitsId}>
					{leftFillNum(seconds, 2)}
				</Typography>
				<Typography className="label" id={secondsLabelId}>
					{t('countdown-timer.seconds')}
				</Typography>
			</div>
		</div>
	);
}

CountdownTimer.propTypes = {
	timerStyle: PropTypes.string,
	timerId: PropTypes.string,
	setTimeBoxWidth: PropTypes.func,
};

export default CountdownTimer;
