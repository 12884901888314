import { css } from '@emotion/react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

import Cart from '@/components/cart/Cart';
import SubmitButton from '@/components/common/SubmitButton';
import CustomerInfoBlock from '@/components/formBlocks/CustomerInfoBlock';
import ShippingAddress from '@/components/formBlocks/ShippingAddress';
import { getZoneStyles } from '@/components/mainContent/contentStyle';
import Payment from '@/components/payment/Payment';
import { useStore } from '@/state/stores';
import { cbNeutral, defaultPanelBorderColor, defaultPanelBoxShadow } from '@/theme';

const style = (template) => {
	const { backgroundColor, boxShadow, borderSize, borderColor, borderRadius, body } =
		getZoneStyles(template, 'cart') || {};

	const connectedBoxShadow = boxShadow || defaultPanelBoxShadow;
	const isBorderTransparent = borderColor?.length > 7 && borderColor?.slice(-2) === '00';
	const hideBorder = !borderColor || isBorderTransparent;
	const borderWidth = borderSize || '1px';
	const borderColorValue = borderColor || defaultPanelBorderColor;
	const border = hideBorder ? 'none' : `${borderWidth} solid ${borderColorValue}`;

	return css`
		.panel-wrapper {
			background-color: ${backgroundColor || cbNeutral[1000]};
			box-shadow: ${connectedBoxShadow};
			border: ${border};
			border-radius: ${borderRadius ? borderRadius : '0px'};
			overflow: hidden;
		}

		.mobile-submit-button-container {
			margin: 0 1.5rem 40px;
			border-top: 1px solid ${body?.dividerColor || cbNeutral[800]};
		}
	`;
};

const MobileLayout = ({ bumpList, shouldDisplayBumps }) => {
	const isShippable = useStore((state) => state.isShippable);
	const template = useStore((state) => state.template);
	return (
		<Grid css={style(template)} container className="main-zone-background">
			<Grid container item className="panel-wrapper CB-form" id="CB-mobile-form-wrapper">
				<Grid container direction="column">
					<Cart bumpList={bumpList} shouldDisplayBumps={shouldDisplayBumps} />
				</Grid>
				<Grid container direction="column">
					<CustomerInfoBlock />
					<Payment />
					{isShippable && <ShippingAddress />}
					<div className="mobile-submit-button-container">
						<SubmitButton />
					</div>
				</Grid>
			</Grid>
		</Grid>
	);
};

MobileLayout.propTypes = {
	bumpList: PropTypes.array,
	shouldDisplayBumps: PropTypes.bool,
};

export default MobileLayout;
