import { css } from '@emotion/react';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useManualQuery } from 'graphql-hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { PRODUCTS, UPSELL_FLOW_RECEIPTS } from '@/graphql/queries';
import { useStore } from '@/state/stores';
import { cbNeutral } from '@/theme';
import { buildReceiptPageProducts } from '@/utils/products';

const styles = css`
	.MuiDialogTitle-root {
		color: ${cbNeutral[100]};
		font-size: 1.125rem;
	}

	.MuiDialogContent-root {
		font-size: 1rem;
	}

	.MuiDialogActions-root {
		justify-content: space-between;
		padding: 0 24px 24px;

		> :not(:first-of-type) {
			margin-left: 0;
		}

		@media (max-width: 700px) {
			flex-direction: column-reverse;

			button {
				&:last-of-type {
					margin-bottom: 24px;
				}
			}
		}
	}

	.alert-container {
		padding: 0 24px 24px;
	}
`;

const UpsellPaymentDeclineModal = () => {
	const { t } = useTranslation(['checkout']);
	const history = useHistory();
	const {
		urlVars,
		setReceiptPageData,
		selectedLanguage,
		setSelectedCurrency,
		setDisplayCurrency,
		setSessionToken,
		setReceiptPageProducts,
		setOrderTotals,
		showUpsellPaymentDeclinedModal,
		setShowUpsellPaymentDeclinedModal,
	} = useStore(
		useShallow((state) => ({
			urlVars: state.urlVars,
			setReceiptPageData: state.setReceiptPageData,
			selectedLanguage: state.selectedLanguage,
			setSelectedCurrency: state.setSelectedCurrency,
			setDisplayCurrency: state.setDisplayCurrency,
			setSessionToken: state.setSessionToken,
			setReceiptPageProducts: state.setReceiptPageProducts,
			setOrderTotals: state.setOrderTotals,
			showUpsellPaymentDeclinedModal: state.showUpsellPaymentDeclinedModal,
			setShowUpsellPaymentDeclinedModal: state.setShowUpsellPaymentDeclinedModal,
		})),
	);

	const [fetchUpsells] = useManualQuery(UPSELL_FLOW_RECEIPTS, {
		variables: {
			upsellFlowSessionId: urlVars.cbf,
		},
	});
	const [fetchProducts] = useManualQuery(PRODUCTS);

	const [upsellError, setUpsellError] = useState(null);

	const getProducts = async (purchasedProducts, vendorId) => {
		try {
			const skus = purchasedProducts.map((sku) => {
				// removes the vendorId from the product id
				return sku.productId.substring(sku.productId.indexOf('-') + 1);
			});
			const products = await fetchProducts({
				variables: {
					vendorId,
					skus,
				},
			});
			return products;
		} catch (error) {
			// we are not handling GQL errors for the PRODUCTS query in the UI for the receipt page,
			// but if we get any they will be sent to RUM so we know if they are happening
			console.error(
				'Error fetching products for one of the receipts in an upsell flow',
				JSON.stringify(error),
			);
		}
	};

	const handleContinueToReceipt = async () => {
		try {
			const receiptPageDataArray = [];
			const receiptPageProductsArray = [];
			const orderTotalsArray = [];
			const upsellReceipts = await fetchUpsells();

			const { upsellFlowReceipts } = upsellReceipts.data || {};

			if (upsellReceipts.error) {
				console.error(
					'Error fetching upsell receipts after payment was declined',
					upsellReceipts.error,
				);
				setUpsellError(true);
			}

			if (upsellFlowReceipts?.length) {
				const lastIndex = upsellFlowReceipts?.length - 1;

				const productsPromises = upsellFlowReceipts.map(({ purchasedProducts, vendorId }) =>
					getProducts(purchasedProducts, vendorId),
				);

				const products = await Promise.all(productsPromises);

				const productsArr = products.map((product) => product.data.products);

				upsellFlowReceipts.forEach(
					(
						{
							receipt,
							purchasedProducts,
							timestamp,
							softDescriptor,
							calculatedCart,
							paymentMethod,
							email,
							shippingAddress,
							editUrl,
							last4cc,
							fullName,
							cardHolderName,
						},
						index,
					) => {
						const hasPhysicalProduct = Boolean(
							productsArr[index].find((item) => item.type.includes('PHYSICAL')),
						);
						const receiptPageData = {
							fullName: fullName,
							cardHolderName,
							email: email,
							address: shippingAddress,
							softDescriptor: softDescriptor,
							timestamp: timestamp,
							editUrl: editUrl,
							last4cc: last4cc,
							paymentMethod: paymentMethod,
							orderNumber: receipt,
							hasPhysicalProduct: hasPhysicalProduct,
						};
						receiptPageDataArray.push(receiptPageData);

						const receiptPageProducts = buildReceiptPageProducts(
							productsArr[index],
							purchasedProducts,
							calculatedCart.lineItems,
							selectedLanguage,
						);

						receiptPageProductsArray.push(receiptPageProducts);
						orderTotalsArray.push(calculatedCart);
					},
				);

				setReceiptPageData(receiptPageDataArray);
				setReceiptPageProducts(receiptPageProductsArray);
				setOrderTotals(orderTotalsArray);
				const currencyUsed = upsellFlowReceipts[lastIndex].currencyId;
				setSelectedCurrency(currencyUsed);
				setDisplayCurrency(currencyUsed);
				history.push(`/order-received?upsell-key=${urlVars.cbf}`);
				setSessionToken(upsellFlowReceipts[lastIndex].sessionToken);
			}
		} catch (error) {
			console.error('Error fetching upsell receipts after payment was declined', error);
		}
	};

	return (
		<Dialog open={showUpsellPaymentDeclinedModal} css={styles}>
			<DialogTitle>{t('declned-payment-modal.title')}</DialogTitle>
			<DialogContent>{t('declned-payment-modal.body')}</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					onClick={() => setShowUpsellPaymentDeclinedModal(false)}
				>
					{t('declned-payment-modal.update-payment-method')}
				</Button>
				<Button onClick={handleContinueToReceipt}>
					{t('declned-payment-modal.continue-to-receipt')}
				</Button>
			</DialogActions>
			{upsellError && (
				<div className="alert-container">
					<Alert severity="error">
						<b>{t('declned-payment-modal.there-was-an-error')}</b>{' '}
						{t('declned-payment-modal.try-again')}{' '}
						<a href="https://clkbank.com/">{t('server-errors.contact-support')}.</a>
					</Alert>
				</div>
			)}
		</Dialog>
	);
};

export default UpsellPaymentDeclineModal;
