import { create } from 'zustand';

import { defaultTemplate } from '@/components/layoutFactory/layoutFactoryConstants';
import { decodeURLVars } from '@/utils/urlVars';

const hideSpeed = 250;
const revealSpeed = 500;
const overlayRevealDelay = hideSpeed + revealSpeed + 100;

const initialUrlVars = decodeURLVars();
export const defaultPazeData = {
	currencyData: {
		currencyData: null,
		selectedCurrency: '',
		defaultCountryCode: '',
	},
	acceptedCCs: [],
	supportedCountries: [],
	consumerPresent: null, // Initial value is null so we can determine if it has been updated or not - should only be a boolean after first update
	checkoutResponse: null,
	existingCompleteResponse: null,
	existingCompleteResponseExp: null,
	uuid: null,
};

const hasUpsellPaymentDeclined = 'cbpd' in initialUrlVars;

const useStore = create((set, get) => ({
	template: defaultTemplate,
	setTemplate: (arg) => {
		set((prev) => ({
			template: typeof arg === 'function' ? arg(prev.template) : arg,
		}));
	},
	hasTemplateQueryFailed: false,
	hasExitOfferQueryFailed: false,
	setHasExitOfferQueryFailed: (hasFailed) => set({ hasExitOfferQueryFailed: hasFailed }),
	hasTemplateQueryData: false,
	featureFlags: {},
	hasIsFeatureEnabledQueryFailed: false,
	hasIsFeatureEnabledQueryData: false,
	exitOffer: null,
	setExitOffer: (exitOffer) => set({ exitOffer }),
	urlVars: initialUrlVars,
	allProducts: undefined,
	setAllProducts: (products) => set({ allProducts: products }),
	selectedLanguage: 'en',
	setSelectedLanguage: (selectedLanguage) => set({ selectedLanguage }),
	affiliate: null,
	siteSettings: {},
	tokenExConfig: null,
	setTokenExConfig: (config) => set({ tokenExConfig: config }),
	tokenExStatus: null,
	setTokenExStatus: (status) => set({ tokenExStatus: status }),
	lastFour: null,
	setLastFour: (lastFour) => set({ lastFour }),
	firstSix: null,
	setFirstSix: (firstSix) => set({ firstSix }),
	refreshTokenEx: false,
	triggerRefreshTokenEx: () => set((state) => ({ refreshTokenEx: !state.refreshTokenEx })),
	tokenExIframe: null,
	setTokenExIframe: (iframe) => set({ tokenExIframe: iframe }),
	receiptPageData: [],
	setReceiptPageData: (data) => set({ receiptPageData: data }),
	receiptPageProducts: [],
	setReceiptPageProducts: (products) => set({ receiptPageProducts: products }),
	kountSessionId: null,
	setKountSessionId: (id) => set({ kountSessionId: id }),
	submitOverlayVisible: false,
	setSubmitOverlayVisible: (isVisible) => set({ submitOverlayVisible: isVisible }),
	isUpsellLoading: false,
	setIsUpsellLoading: (isLoading) => set({ isUpsellLoading: isLoading }),
	errorData: null,
	setErrorData: (errorData) => set({ errorData }),
	showErrorModal: false,
	setShowErrorModal: (show) => set({ showErrorModal: show }),
	modalErrorMessage: null,
	setModalErrorMessage: (message) => set({ modalErrorMessage: message }),
	token: null,
	setToken: (token) => set({ token }),
	tokenHash: null,
	setTokenHash: (tokenHash) => set({ tokenHash }),
	defaultCountry: null,
	setDefaultCountry: (country) => set({ defaultCountry: country }),
	selectedCountryData: {
		billing: null,
		shipping: null,
	},
	setSelectedCountryData: (data, accessor) =>
		set((state) => ({
			selectedCountryData: { ...state.selectedCountryData, [accessor]: data },
		})),
	countryOptions: null,
	setCountries: (countries) => set({ countryOptions: countries }),
	regulations: [],
	setRegulations: (arg) =>
		set((state) => ({ regulations: typeof arg === 'function' ? arg(state.regulations) : arg })),
	cardsAccepted: [],
	setCardsAccepted: (cardsAccepted) => set({ cardsAccepted }),
	forceGdpr: true,
	setForceGdpr: (forceGdpr) => set({ forceGdpr }),
	currencyData: [],
	setCurrencyData: (currencyData) => set({ currencyData }),
	payPalEnabled: false,
	setPayPalEnabled: (bool) => set({ payPalEnabled: bool }),
	payPalCommerceEnabled: false,
	setPayPalCommerceEnabled: (bool) => set({ payPalCommerceEnabled: bool }),
	stateOptions: {
		billing: null,
		shipping: null,
	},
	isPayPalDirectEnabled: false,
	setIsPayPalDirectEnabled: (bool) => set({ isPayPalDirectEnabled: bool }),
	setStates: (states, accessor) =>
		set((state) => ({ stateOptions: { ...state.stateOptions, [accessor]: states } })),
	cityOptions: {
		billing: null,
		shipping: null,
	},
	setCities: (cities, accessor) =>
		set((state) => ({ cityOptions: { ...state.cityOptions, [accessor]: cities } })),
	calculateCartAddressVars: {},
	setCalculateCartAddressVars: (vars) => set({ calculateCartAddressVars: vars }),
	calculateCartLineItems: {},
	setCalculateCartLineItems: (items) => set({ calculateCartLineItems: items }),
	submitOrderLineItems: {},
	setSubmitOrderLineItems: (items) => set({ submitOrderLineItems: items }),
	availableItems: null,
	setAvailableItems: (items) => set({ availableItems: items }),
	allSKUs: [],
	setAllSKUs: (skus) => set({ allSKUs: skus }),
	isInvalidProductInCart: false,
	isSiteActivated: true,
	isShippable: null,
	setIsShippable: (bool) => set({ isShippable: bool }),
	refundDaysLimit: null,
	cartStatus: [],
	setCartStatus: (arg) =>
		set((prevState) => ({
			cartStatus: typeof arg === 'function' ? arg(prevState.cartStatus) : arg,
		})),
	isCartUpdating: true,
	setIsCartUpdating: (isUpdating) => set({ isCartUpdating: isUpdating }),
	cardType: '',
	setCardType: (cardType) => set({ cardType }),
	paymentMethod: 'credit',
	setPaymentMethod: (paymentMethod) => set({ paymentMethod }),
	couponCode: '',
	setCouponCode: (couponCode) => set({ couponCode }),
	validatedCoupon: null,
	setValidatedCoupon: (validatedCoupon) => set({ validatedCoupon }),
	couponErrorMessageOverride: null,
	setCouponErrorMessageOverride: (message) => set({ couponErrorMessageOverride: message }),
	triggerCalculateCart: false,
	setTriggerCalculateCart: (triggerCalculateCart) => set({ triggerCalculateCart }),
	couponSuccessMessage: null,
	setCouponSuccessMessage: (couponSuccessMessage) => set({ couponSuccessMessage }),
	selectedCurrency: 'USD',
	setSelectedCurrency: (selectedCurrency) => set({ selectedCurrency }),
	displayCurrency: 'USD',
	setDisplayCurrency: (displayCurrency) => set({ displayCurrency }),
	orderTotals: null,
	setOrderTotals: (orderTotals) => set({ orderTotals }),
	trackingPixels: null,
	setTrackingPixels: (trackingPixels) => set({ trackingPixels }),
	formServerErrorInfo: hasUpsellPaymentDeclined ? { key: 'auth-fail' } : null,
	setFormServerErrorInfo: (errorInfo) => set({ formServerErrorInfo: errorInfo }),
	sessionToken: null,
	setSessionToken: (sessionToken) => set({ sessionToken }),
	currencyCodes: [],
	setCurrencyCodes: (currencyCodes) => set({ currencyCodes }),
	rebuildCartFromSku: null,
	setRebuildCartFromSku: (rebuildCartFromSku) => set({ rebuildCartFromSku }),
	cartInitialized: false,
	animationProps: {
		timeout: { enter: revealSpeed, exit: hideSpeed },
		mountOnEnter: true,
		unmountOnExit: true,
	},
	currentInput: null,
	setCurrentInput: (input) => set({ currentInput: input }),
	refocusCurrentInput: () => {
		const currentInput = get().currentInput;
		currentInput && currentInput.focus();
	},
	showPhone: false,
	showCoupon: false,
	hideSpeed,
	revealSpeed,
	overlayRevealDelay,
	mergeInitialState: (incomingData) => set(incomingData),
	initialGQLData: null,
	setInitialGQLData: (arg) => {
		if (typeof arg === 'function') {
			set((state) => ({ initialGQLData: arg(state.initialGQLData) }));
		} else {
			set({ initialGQLData: arg });
		}
	},
	locationLoading: {
		billing: false,
		shipping: false,
	},
	setLocationLoading: (isLoading, accessor) =>
		set((state) => ({ locationLoading: { ...state.locationLoading, [accessor]: isLoading } })),
	payPalRebuildHandler: null,
	setPayPalRebuildHandler: (handler) => set({ payPalRebuildHandler: handler }),
	getGlobalState: () => get(),
	orderFormHasUnloaded: false,
	setOrderFormHasUnloaded: (bool) => set({ orderFormHasUnloaded: bool }),
	applePayData: {
		acceptedCCs: [],
		supportedCountries: [],
		showApplePayButton: false,
		defaultToApplePay: false,
		currencyData: {
			currencyData: null,
			selectedCurrency: '',
			defaultCountryCode: '',
		},
	},
	setApplePayData: (data) => set({ applePayData: data }),
	pazeData: defaultPazeData,
	setPazeData: (arg) => {
		set((prev) => ({
			pazeData: typeof arg === 'function' ? arg(prev.pazeData) : arg,
		}));
		return get().pazeData;
	},
	isShippingSameAsBilling: true,
	setIsShippingSameAsBilling: (bool) => set({ isShippingSameAsBilling: bool }),
	affiliateBlockedOrInactive: false,
	exitOfferActive: false,
	setExitOfferActive: (bool) => set({ exitOfferActive: bool }),
	displayPurchaseComplete: false,
	setDisplayPurchaseComplete: (bool) => set({ displayPurchaseComplete: bool }),
	showTokenExPlaceholder: false,
	setShowTokenExPlaceholder: (bool) => set({ showTokenExPlaceholder: bool }),
	showUpsellPaymentDeclinedModal: hasUpsellPaymentDeclined,
	setShowUpsellPaymentDeclinedModal: (bool) => set({ showUpsellPaymentDeclinedModal: bool }),
	userHasAcceptedDuplicate: false,
	getUserHasAcceptedDuplicate: () => get().userHasAcceptedDuplicate,
	setUserHasAcceptedDuplicate: (bool) => set({ userHasAcceptedDuplicate: bool }),
	impressionVars: null,
	autocompleteAddressSelected: false,
	setAutocompleteAddressSelected: (bool) => set({ autocompleteAddressSelected: bool }),
	isLargeProductImage: false,
	countryCodeDerivedFromURLPhone: null,
	addressSearchId: null,
	countdownTimerHasExpired: false,
	setCountdownTimerHasExpired: (bool) => set({ countdownTimerHasExpired: bool }),
	isSmsConfirmationEnabled: false,
	setIsisSmsConfirmationEnabled: (bool) => set({ isSmsConfirmationEnabled: bool }),
}));

export default useStore;
