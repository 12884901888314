import { css } from '@emotion/react';
import LockIcon from '@mui/icons-material/Lock';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { CBTextField } from '..';
import { getZoneStyles } from '@/components/mainContent/contentStyle';
import { useFormStore, useStore } from '@/state/stores';
import {
	cbNegative,
	defaultBodyFontColor,
	defaultInputBackgroundColor,
	defaultInputValueFontWeight,
} from '@/theme';
import { deviceType } from '@/utils/deviceType';
import { isiOS } from '@/utils/helpers';

// detects if user is on desktop or mobile
const isDesktop = !deviceType();
// id of span where the iframe is to be injected
const target = 'cardNumber';

const tokenExStyles = (showTokenExPlaceholder, body) => {
	return css`
		${showTokenExPlaceholder ? 'display: none;' : ''}
		.internal-container {
			height: inherit;
			position: relative;
			width: 100%;

			.lock-icon-container {
				margin-right: 0.5rem;
				position: absolute;
				right: 0;
				top: 0;
				height: 100%;
				display: flex;
				align-items: center;

				.lock-icon {
					color: ${body?.fontColor || defaultBodyFontColor};
				}
			}
		}
	`;
};

const TokenExInput = () => {
	const {
		tokenExConfig,
		setCardType,
		setToken,
		setTokenHash,
		tokenExStatus,
		setTokenExStatus,
		setLastFour,
		setFirstSix,
		cardsAccepted,
		triggerRefreshTokenEx,
		template,
		tokenExIframe,
		setTokenExIframe,
		isCartUpdating,
		cartInitialized,
		setCurrentInput,
		isCurrentInput,
		token,
		tokenHash,
		showTokenExPlaceholder,
		setShowTokenExPlaceholder,
	} = useStore(
		useShallow((state) => ({
			tokenExConfig: state.tokenExConfig,
			setCardType: state.setCardType,
			setToken: state.setToken,
			setTokenHash: state.setTokenHash,
			tokenExStatus: state.tokenExStatus,
			setTokenExStatus: state.setTokenExStatus,
			setLastFour: state.setLastFour,
			setFirstSix: state.setFirstSix,
			cardsAccepted: state.cardsAccepted,
			triggerRefreshTokenEx: state.triggerRefreshTokenEx,
			template: state.template,
			tokenExIframe: state.tokenExIframe,
			setTokenExIframe: state.setTokenExIframe,
			isCartUpdating: state.isCartUpdating,
			cartInitialized: state.cartInitialized,
			isCurrentInput: state.currentInput?.name === 'cardToken',
			setCurrentInput: state.setCurrentInput,
			token: state.token,
			tokenHash: state.tokenHash,
			showTokenExPlaceholder: state.showTokenExPlaceholder,
			setShowTokenExPlaceholder: state.setShowTokenExPlaceholder,
		})),
	);

	const { setFormValue, formFieldIsValid, formFieldOnChangeHandlers } = useFormStore(
		useShallow((state) => ({
			setFormValue: state.setFormValue,
			formFieldIsValid: state.formFieldIsValid,
			formFieldOnChangeHandlers: state.formFieldOnChangeHandlers,
		})),
	);
	const [isFocused, setIsFocused] = useState(false);
	const [cardNumberChanged, setCardNumberChanged] = useState(false);
	const [cardsAcceptedChanged, setCardsAcceptedChanged] = useState(false);
	const currentAuthKey = useRef(null);
	const isTokenizingRef = useRef(false);

	const paymentStyles = getZoneStyles(template, 'payment');
	const { body } = paymentStyles || {};
	const iframeColor = body?.fontColor || '#3F3D5C';
	const iframeFontWeight = body?.fontWeight || defaultInputValueFontWeight;
	const iframeFontFamily = body?.fontFamily
		? `${body.fontFamily} ,sans-serif, helvetica, arial`
		: 'proxima-nova, sans-serif, helvetica, arial';
	const iframeBorderRadius = body?.inputBorderRadius ? body.inputBorderRadius : '';

	// the font weight has !important on it because the iframe has a style that overrides it when a google font is used
	const baseConfigStyles = `
		width: 100%;
		border-style: none;
		padding: 0;
		font-weight: ${iframeFontWeight} !important; 
		font-size: 1rem;
		font-family: ${iframeFontFamily};
		background: ${body?.inputBackgroundColor || defaultInputBackgroundColor};
		border-radius: 4px;
		color: ${iframeColor};
		height: 3rem;
		padding-left: 12px;
		border-radius: ${iframeBorderRadius};
		box-sizing: border-box;
		padding-top: 14px;
	`;

	const customizerGoogleFonts = [
		'Lato',
		'Noto Sans',
		'Noto Serif',
		'Nunito',
		'Open Sans',
		'Poppins',
		'Raleway',
		'Roboto',
		'Source Sans Pro',
		'Titillium Web',
	];

	const isGoogleFont = body?.fontFamily && customizerGoogleFonts.includes(body.fontFamily);

	// config settings
	const config = useMemo(() => JSON.parse(tokenExConfig), [tokenExConfig]);
	if (config) {
		if (isDesktop) config.inputType = 'text';
		config.debug = false;
		config.enableAutoComplete = true;
		config.styles = {
			base: baseConfigStyles,
			error: `color: ${cbNegative[280]};`,
			focus: 'outline: none;',
		};
		if (isGoogleFont) {
			// config.font is only for google fonts and does not work properly if a non-google font is sent
			// If "font" is included in the config, it will override the font-family in baseConfigStyles
			config.font = body.fontFamily;
		}
	}

	const toggleChangedOff = useCallback(() => {
		setCardNumberChanged(false);
		setCardsAcceptedChanged(false);
	}, [setCardNumberChanged, setCardsAcceptedChanged]);

	const insertTokenFrame = useCallback(async () => {
		if (!config) {
			return;
		}

		// this will show the placeholder if we have a token in state and the iframe is refreshed
		if (token && !tokenExIframe) {
			setShowTokenExPlaceholder(true);
			return;
		}

		try {
			// store the current auth key so we know when get get a new one in order to refresh the iframe before it expires
			currentAuthKey.current = config.authenticationKey;
			const globalTokenEx = window.TokenEx;
			const theIframe = new globalTokenEx.Iframe(target, config);
			// Add it to the DOM
			await theIframe.load();
			theIframe.on('load', () => {
				setTokenExIframe(theIframe);
			});
		} catch (err) {
			console.error('TokenEx input failed to insert');
		}
	}, [config, token, tokenExIframe, setShowTokenExPlaceholder, setTokenExIframe]);

	/**
	 * replaces the iframe with a new before the auth key expires
	 */
	const refreshIframe = useCallback(() => {
		if (
			currentAuthKey.current &&
			currentAuthKey.current !== config?.authenticationKey &&
			tokenExIframe
		) {
			// iframe has to be removed before a new one can be generated
			tokenExIframe.remove();
			insertTokenFrame();
		}
	}, [config?.authenticationKey, insertTokenFrame, tokenExIframe]);

	// fix for iOS 12 bug that prevents validate in the iframe from triggering
	const removeFocus = () => {
		document.activeElement.blur();
	};

	// Ref that will always reflect the state of token, so current value
	// can be accessed within cleanup below without it being stale
	const tokenRef = useRef(token);
	useEffect(() => {
		tokenRef.current = token;
	}, [token]);

	useEffect(() => {
		// show placeholder if there is a token when TokenExInput unmounts
		return () => {
			if (tokenRef.current) {
				setShowTokenExPlaceholder(true);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// adds event listener so we can force tokenEx to validate on iOS 11 & 12
	useEffect(() => {
		if (isiOS() && isFocused) {
			document.body.addEventListener('touchstart', removeFocus);
		}

		if (isiOS() && !isFocused) {
			document.body.removeEventListener('touchstart', removeFocus);
		}
	}, [isFocused]);

	useEffect(() => {
		// Wait for TokenX iframe to exist and add title attribute for accessibility
		const tokenXObserver = new MutationObserver(() => {
			if (document.contains(document.getElementById('tx_iframe_cardNumber'))) {
				document
					.getElementById('tx_iframe_cardNumber')
					.setAttribute('title', 'TokenX card number iframe');
				tokenXObserver.disconnect();
			}
		});

		tokenXObserver.observe(document, {
			attributes: false,
			childList: true,
			characterData: false,
			subtree: true,
		});
	}, []);

	const { t } = useTranslation('checkout');

	const updateIframe = useCallback(() => {
		if (tokenExIframe) {
			tokenExIframe.on('change', () => {
				formFieldOnChangeHandlers.forEach((cb) => cb('cardToken'));
				setCardNumberChanged(true);
			});

			tokenExIframe.on('validate', (data) => {
				const acceptedCard =
					cardsAccepted.includes(data.cardType) || data.cardType === 'unknown';
				setIsFocused(false);

				if (!data.isValid) {
					// data.validator returns 'format' or 'required'
					setTokenExStatus(data.validator);
					toggleChangedOff();
					token && setToken(null);
					tokenHash && setTokenHash(null);
				} else {
					if (!acceptedCard) {
						// throw invalid card error message
						setTokenExStatus('format');
					} else {
						const shouldTokenize = cardNumberChanged || cardsAcceptedChanged;
						if (shouldTokenize) {
							isTokenizingRef.current = true;
							tokenExIframe.tokenize();
						}
						setTokenExStatus('ok');
						setCardType(data.cardType);
						setLastFour(data.lastFour);
						setFirstSix(data.firstSix);
					}
				}
			});

			tokenExIframe.on('cardTypeChange', (data) => {
				setIsFocused(true);
				setCardType(data.possibleCardType);
			});

			tokenExIframe.on('focus', () => {
				setIsFocused(true);
				setCurrentInput(document.querySelector('#data[name="cardNumber"]'));
			});

			tokenExIframe.on('tokenize', (data) => {
				setToken(data.token);
				setTokenHash(data.tokenHMAC);
				toggleChangedOff();
			});

			tokenExIframe.on('error', (data) => {
				// for RUM
				console.error('TokenEx error:', JSON.stringify(data));
				//Can be caused by expired timestamp, possibly on mobile when browser is backgrounded then foregrounded.
				if (
					typeof data.error === 'string' &&
					data.error.toLowerCase().includes('expired')
				) {
					triggerRefreshTokenEx();
				}
			});
		}
	}, [
		tokenExIframe,
		cardsAccepted,
		setTokenExStatus,
		toggleChangedOff,
		token,
		setToken,
		tokenHash,
		setTokenHash,
		cardNumberChanged,
		cardsAcceptedChanged,
		setCardType,
		setLastFour,
		setFirstSix,
		setCurrentInput,
		triggerRefreshTokenEx,
		formFieldOnChangeHandlers,
	]);

	useEffect(() => {
		if (token && tokenHash) {
			isTokenizingRef.current = false;
		}
	}, [token, tokenHash]);

	useEffect(() => {
		const noTokenData = token === null && tokenHash === null;
		const tokenExMismatch = tokenExStatus === 'ok' && noTokenData && !isTokenizingRef.current;
		if (tokenExMismatch && tokenExIframe) {
			// tokenExStatus was flipped in validation but due to async error tokenize was not invoked.
			isTokenizingRef.current = true;
			tokenExIframe.tokenize();
		}
	}, [tokenExStatus, tokenExIframe, token, tokenHash]);

	useEffect(() => {
		// tokenExStatus is null until the tokenEx validation has been triggered.
		// Once validation has run, set the value of the hidden cardNumber input
		// to the validation status, which triggers the yup validation and displays
		// the proper error message. If tokenExStatus was set to 'ok', no error is displayed.
		if (tokenExStatus) {
			const clearAndValidate = async () => {
				setFormValue('cardToken', tokenExStatus);
				await formFieldIsValid('cardToken', tokenExStatus);
			};
			clearAndValidate().catch((err) => {
				// This will occur if validation is attempted before schema has finished
				// regenerating (e.g. on payment method change)
				const validationIsAbsent = err.message.includes('cardToken');
				if (!validationIsAbsent) {
					throw err;
				}
			});
		}
	}, [tokenExStatus, formFieldIsValid, setFormValue]);

	useEffect(() => {
		if (!cartInitialized) {
			return;
		}
		if (config?.authenticationKey && cardsAccepted.length && !currentAuthKey.current) {
			insertTokenFrame();
		} else {
			refreshIframe();
		}
	}, [config, cardsAccepted, insertTokenFrame, refreshIframe, cartInitialized]);

	useEffect(() => {
		if (tokenExIframe && cardsAccepted.length > 0) {
			updateIframe();
		}
	}, [cardsAccepted, tokenExIframe, cardNumberChanged, cardsAcceptedChanged, updateIframe]);

	useEffect(() => {
		cardsAccepted.length > 0 && setCardsAcceptedChanged(true);
	}, [cardsAccepted]);

	useEffect(() => {
		cardNumberChanged && tokenExIframe && tokenExIframe.validate();
	}, [cardNumberChanged, tokenExIframe]);

	return (
		<div css={tokenExStyles(showTokenExPlaceholder, body)}>
			<CBTextField
				name="cardToken"
				label={t('field.card-number.label')}
				required={true}
				type="hidden"
				focused={isFocused}
				isCurrentInput={isCurrentInput}
				disabled={!cartInitialized || isCartUpdating}
				showPlaceholder={!cartInitialized}
				inputLabelProps={{
					className:
						!isFocused && (tokenExStatus === 'required' || tokenExStatus === null)
							? 'tokenex-label-large'
							: `tokenex-label-small ${isFocused ? 'Mui-focused' : ''}`,
				}}
				prependHtml={
					<div className="internal-container">
						{/* TokenEx iframe is injected in the span via insertTokenFrame() */}
						<span id="cardNumber" className="no-mouseflow"></span>
						<div className="lock-icon-container">
							<LockIcon className="lock-icon" />
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default TokenExInput;
