import { ClientContext, useManualQuery, useMutation } from 'graphql-hooks';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import DuplicateOrderModal from './DuplicateOrderModal';
import SuggestedAddressDialog from '@/components/cart/SuggestedAddressDialog';
import {
	APPLE_PAY_CREATE_SESSION,
	REQUEST_DIRECT_PAYPAL_ORDER,
	SUBMIT_DIGITAL_PAYMENT,
	SUBMIT_ORDER,
} from '@/graphql';
import { CALCULATE_CART, VERIFY_ADDRESS_QUERY } from '@/graphql/queries';
import { BumpSelectionContext } from '@/state/BumpSelectionContext';
import { useFormStore, useStore } from '@/state/stores';
import {
	countryIsNotSupported,
	formatApplePayerPhone,
	getMissingRequiredShippingFields,
	getRequiredShippingFields,
	getShippingAddressErrors,
	processApplePay,
} from '@/utils/applePayHelpers';
import { PaymentMethods } from '@/utils/enums';
import { errorHandler } from '@/utils/errorHandler';
import {
	cartIncludesPhysical,
	closeOverlayAndSetErrorData,
	isPreview,
	logSplunkRumError,
	scrollUserToFormError,
} from '@/utils/helpers';
import { pazeSubmit } from '@/utils/pazeHelpers';
import { buildReceiptPageProducts } from '@/utils/products';

// stores number of times any endpoint retries.
// third retry triggers error message if needed, then that endpoint's count is reset
const retryCount = {};

export const getConsent = (regulations) => {
	// CCPA is opt-out, so false === consenting
	let givesConsent = false;
	const GDPRResponse = regulations.find((regulation) => regulation.value === 'GDPR');
	const CCPAResponse = regulations.find((regulation) => regulation.value === 'CCPA');
	if (GDPRResponse && CCPAResponse) {
		// We need GDPRConsent && CCPAConsent
		// If both are no consent -> no consent
		// If either is no consent -> no consent
		// Only if both are consenting should vendor consent be true.
		givesConsent = GDPRResponse.checked && !CCPAResponse.checked;
	} else if (GDPRResponse) {
		givesConsent = GDPRResponse.checked;
	} else if (CCPAResponse) {
		givesConsent = !CCPAResponse.checked;
	} else {
		return null;
	}
	return {
		vendorConsent: givesConsent,
		clickBankConsent: false,
	};
};

const CheckoutForm = (props) => {
	const {
		setPaymentMethod,
		setPazeData,
		pazeData,
		applePayData,
		urlVars,
		selectedCurrency,
		orderTotals,
		setOrderTotals,
		token,
		tokenHash,
		submitOrderLineItems,
		paymentMethod,
		setSubmitOverlayVisible,
		setErrorData,
		overlayRevealDelay,
		setSessionToken,
		selectedLanguage,
		allProducts,
		setReceiptPageData,
		kountSessionId,
		couponCode,
		validatedCoupon,
		regulations,
		setCurrentInput,
		setReceiptPageProducts,
		isCartUpdating,
		showPhone,
		calculateCartAddressVars,
		isShippingSameAsBilling,
		isShippable,
		isPayPalDirectEnabled,
		tokenExIframe,
		setDisplayPurchaseComplete,
		setShowUpsellPaymentDeclinedModal,
		calculateCartLineItems,
		availableItems,
		siteSettings,
		couponSuccessMessage,
		cartStatus,
		setFormServerErrorInfo,
		getUserHasAcceptedDuplicate,
		autocompleteAddressSelected,
		isSmsConfirmationEnabled,
	} = useStore(
		useShallow((state) => ({
			setPaymentMethod: state.setPaymentMethod,
			setPazeData: state.setPazeData,
			pazeData: state.pazeData,
			applePayData: state.applePayData,
			urlVars: state.urlVars,
			selectedCurrency: state.selectedCurrency,
			orderTotals: state.orderTotals,
			setOrderTotals: state.setOrderTotals,
			token: state.token,
			tokenHash: state.tokenHash,
			submitOrderLineItems: state.submitOrderLineItems,
			paymentMethod: state.paymentMethod,
			setSubmitOverlayVisible: state.setSubmitOverlayVisible,
			setErrorData: state.setErrorData,
			overlayRevealDelay: state.overlayRevealDelay,
			setSessionToken: state.setSessionToken,
			selectedLanguage: state.selectedLanguage,
			allProducts: state.allProducts,
			setReceiptPageData: state.setReceiptPageData,
			kountSessionId: state.kountSessionId,
			couponCode: state.couponCode,
			validatedCoupon: state.validatedCoupon,
			regulations: state.regulations,
			setCurrentInput: state.setCurrentInput,
			setReceiptPageProducts: state.setReceiptPageProducts,
			isCartUpdating: state.isCartUpdating,
			showPhone: state.showPhone,
			calculateCartAddressVars: state.calculateCartAddressVars,
			isShippingSameAsBilling: state.isShippingSameAsBilling,
			isShippable: state.isShippable,
			isPayPalDirectEnabled: state.isPayPalDirectEnabled,
			tokenExIframe: state.tokenExIframe,
			setDisplayPurchaseComplete: state.setDisplayPurchaseComplete,
			setShowUpsellPaymentDeclinedModal: state.setShowUpsellPaymentDeclinedModal,
			calculateCartLineItems: state.calculateCartLineItems,
			availableItems: state.availableItems,
			siteSettings: state.siteSettings,
			couponSuccessMessage: state.couponSuccessMessage,
			cartStatus: state.cartStatus,
			setFormServerErrorInfo: state.setFormServerErrorInfo,
			getUserHasAcceptedDuplicate: state.getUserHasAcceptedDuplicate,
			autocompleteAddressSelected: state.autocompleteAddressSelected,
			isSmsConfirmationEnabled: state.isSmsConfirmationEnabled,
		})),
	);

	const {
		formIsValid,
		formFieldIsValid,
		formErrors,
		mergeFormData,
		getFormData,
		validationSchemaCalculating,
	} = useFormStore(
		useShallow((state) => ({
			mergeFormData: state.mergeFormData,
			formFieldIsValid: state.formFieldIsValid,
			formIsValid: state.formIsValid,
			formErrors: state.formErrors,
			getFormData: state.getFormData,
			validationSchemaCalculating: state.validationSchemaCalculating,
		})),
	);

	const hiddenInputRef = useRef(null);
	const [isDuplicateOrderModalOpen, setIsDuplicateOrderModalOpen] = useState(false);
	const hasDuplicateOrderModalOpened = useRef(false);
	const [submitterId, setSubmitterId] = useState(null);
	const { t } = useTranslation('checkout');
	const { triggeringBumpsQueue } = useContext(BumpSelectionContext);

	const isDuplicateOrder = !!orderTotals?.[0].duplicate;

	const forceNameForPayPal = isPayPalDirectEnabled && paymentMethod === 'payPal';

	const client = useContext(ClientContext);

	const [checkoutForm, setCheckoutForm] = useState();
	const [isSuggestedAddressDialogOpen, setIsSuggestedAddressDialogOpen] = useState(false);
	const [verifiedAddress, setVerifiedAddress] = useState(null);
	const [submitOrder] = useMutation(SUBMIT_ORDER);
	const [submitPayPal] = useMutation(REQUEST_DIRECT_PAYPAL_ORDER);
	const [createApplePaySession] = useMutation(APPLE_PAY_CREATE_SESSION);
	const [submitDigitalPayment] = useMutation(SUBMIT_DIGITAL_PAYMENT);
	const [verifyAddressQuery] = useManualQuery(VERIFY_ADDRESS_QUERY);
	const [calculateCart] = useManualQuery(CALCULATE_CART);
	const history = useHistory();
	// Consent is always false for Apple Pay form, as checkbox is not displayed
	const consent =
		paymentMethod === PaymentMethods.APPLE_PAY
			? {
					vendorConsent: false,
					clickBankConsent: false,
				}
			: getConsent(regulations);
	const tokenExMismatchErrorHasTriggered = useRef(false);
	const cartIncludesShippableProduct = cartIncludesPhysical(calculateCartLineItems, allProducts);

	const initializeApplePaySession = async (validationURL) => {
		const { data, error } = await createApplePaySession({
			variables: { validationURL },
		});

		if (error) {
			closeOverlayAndSetErrorData(
				errorHandler({ error }, 'Apple'),
				setSubmitOverlayVisible,
				setErrorData,
			);
			return;
		}
		if (data?.createPaymentSession) {
			return data.createPaymentSession;
		}
	};

	const processApplePayResponse = async (response, incomingVars) => {
		const { billingContact, shippingContact } = response.details;
		const fullName = `${shippingContact.givenName} ${shippingContact.familyName}`;
		const payerName = `${billingContact.givenName} ${billingContact.familyName}`;

		const billingAddress = {
			countryCode: billingContact.countryCode,
			zip: billingContact.postalCode,
			city: billingContact.locality.toUpperCase(),
			state: billingContact.administrativeArea,
		};

		let shippingAddress = {};
		if (response.shippingAddress) {
			const { country, postalCode, city, region, addressLine } = response.shippingAddress;

			shippingAddress = {
				countryCode: country,
				zip: postalCode,
				city: city,
				state: region,
				address1: addressLine[0],
				// if there is no address2 in the response then addressLine length will be 1,
				// so we are setting address2 as '' for consistency
				address2: addressLine[1] || '',
			};
		}

		// Attempt to format telephone number
		let phoneNumber = response.payerPhone || null;
		if (phoneNumber) {
			try {
				phoneNumber = await formatApplePayerPhone(
					phoneNumber,
					response.details,
					hiddenInputRef,
				);
			} catch (error) {
				logSplunkRumError(
					`Attempt to format Apple Phone Number failed with error: ${JSON.stringify(
						error,
					)}`,
				);
			}
		}

		const appleSubmitVars = {
			...incomingVars,
			...(cartIncludesShippableProduct && { fullName, shippingAddress }),
			address: billingAddress,
			email: response.payerEmail,
			phone: phoneNumber,
		};

		return await submitDigitalPayment({
			variables: {
				appleToken: response.details.token,
				cardHolderName: payerName,
				...appleSubmitVars,
			},
		});
	};

	const calculateCartOnApplePayChange = async (changeEvent) => {
		const shippingAddress = changeEvent.target.shippingAddress;
		const billingAddress = changeEvent.methodDetails?.billingContact;
		let address;

		if (cartIncludesShippableProduct) {
			if (!shippingAddress) {
				return;
			}

			if (countryIsNotSupported(shippingAddress.country, applePayData.supportedCountries)) {
				return {
					shippingAddressErrors: {
						country: t('apple-misc.invalid-shipping-country'),
					},
				};
			}

			const requiredShippingFields = getRequiredShippingFields(shippingAddress);
			const missingRequiredFields = getMissingRequiredShippingFields(
				requiredShippingFields,
				shippingAddress,
			);

			if (missingRequiredFields.length) {
				return {
					shippingAddressErrors: getShippingAddressErrors(missingRequiredFields),
				};
			}

			address = {
				countryCode: shippingAddress.country,
				zip: shippingAddress.postalCode,
				city: shippingAddress.city,
				state: shippingAddress.region,
			};
		}

		if (!cartIncludesShippableProduct) {
			if (!billingAddress) {
				return;
			}
			address = {
				countryCode: billingAddress?.countryCode,
				zip: billingAddress.postalCode,
				city: billingAddress.locality,
				state: billingAddress.administrativeArea,
			};
		}

		const response = await calculateCart({
			variables: {
				lineItems: calculateCartLineItems,
				vendorId: urlVars.vvvv,
				currencyId: selectedCurrency,
				couponCode: validatedCoupon && !couponCode ? validatedCoupon : couponCode,
				address,
				availableItems: availableItems,
				urlParams: window.location.search,
			},
		});

		if (response.error) {
			setErrorData(errorHandler({ error: response.error }, 'Apple'));
		}

		const discount = response?.data?.calculateCart?.discountTotal;
		const showDiscount = !isNaN(parseInt(discount, 10)) && couponSuccessMessage;

		const displayItems = [
			...(showDiscount
				? [
						{
							label: couponSuccessMessage,
							amount: {
								value: `-${discount}`,
								currency: selectedCurrency,
							},
						},
					]
				: []),
			...(cartIncludesShippableProduct
				? [
						{
							label: t('apple-misc.shipping'),
							amount: {
								value: response.data.calculateCart.shippingAmount || '0.00',
								currency: selectedCurrency,
							},
						},
					]
				: []),
			{
				label: t('apple-misc.tax'),
				amount: {
					value: response.data.calculateCart.tax || '0.00',
					currency: selectedCurrency,
				},
			},
		];

		const returnValue = {
			displayItems,
			total: {
				label: siteSettings.vendorBrand
					? t('apple-misc.brand-name-via-clickbank', { brand: siteSettings.vendorBrand })
					: 'ClickBank',
				amount: {
					value: response.data.calculateCart.total,
					currency: selectedCurrency,
				},
			},
		};

		return returnValue;
	};

	const removeClientHintHeaders = () => {
		client.removeHeader('Arch');
		client.removeHeader('Model');
		client.removeHeader('Platform-Version');
		client.removeHeader('Full-Version-List');
		client.removeHeader('Bitness');
	};

	const addClientHintHeaders = async () => {
		try {
			const { architecture, model, platformVersion, fullVersionList, bitness } =
				await navigator.userAgentData.getHighEntropyValues([
					'architecture',
					'model',
					'platformVersion',
					'fullVersionList',
					'bitness',
				]);

			architecture && client.setHeader('Arch', architecture);
			model && client.setHeader('Model', model);
			platformVersion && client.setHeader('Platform-Version', platformVersion);
			fullVersionList &&
				client.setHeader('Full-Version-List', JSON.stringify(fullVersionList));
			bitness && client.setHeader('Bitness', bitness);
		} catch (error) {
			//Do nothing if error. Not all browsers support user agent headers
		}
	};

	const handlePayPalResponse = (response) => {
		const url = response.requestDirectPayPalOrder?.url;
		if (url) {
			window.location.href = url;
		} else {
			setErrorData(errorHandler('NO_ID', 'REQUEST_DIRECT_PAYPAL_ORDER'));
			setSubmitOverlayVisible(false);
		}
	};

	const handleCreditResponse = (responseData) => {
		const {
			receipt,
			purchasedProducts,
			sessionToken,
			timestamp,
			softDescriptor,
			calculatedCart,
			paymentMethod,
			email,
			shippingAddress,
			editUrl,
			last4cc,
			fullName,
			cardHolderName,
			domain,
			tokenProvider,
		} = responseData || { receipt: null };

		// TODO a flag is going to be added to the initial product in the returned data so we can make sure we are redirecting to the correct URL
		// for the time being it will use the first redirect = true
		const redirect = purchasedProducts?.find((product) => product.redirect === true);

		if (receipt && !redirect) {
			// get products for the receipt
			const products = [];
			purchasedProducts.forEach((product) => {
				allProducts.forEach((item) => {
					if (product.productId === item.id) {
						products.push(item);
					}
				});
			});

			const hasPhysicalProduct = !!products.find((item) => item.physical === true);
			const isTestPurchase = paymentMethod.toLowerCase() === PaymentMethods.TEST;
			const receiptPageProducts = [
				buildReceiptPageProducts(
					products,
					purchasedProducts,
					calculatedCart.lineItems,
					selectedLanguage,
				),
			];
			const receiptPageData = [
				{
					...(fullName && {
						fullName: fullName,
					}),
					cardHolderName,
					email: email,
					address: shippingAddress,
					softDescriptor: softDescriptor,
					timestamp: timestamp,
					editUrl: editUrl,
					last4cc: last4cc,
					paymentMethod: paymentMethod,
					orderNumber: receipt,
					hasPhysicalProduct: hasPhysicalProduct,
					isTestPurchase: isTestPurchase,
					domain: domain,
					tokenProvider: tokenProvider,
				},
			];
			setReceiptPageData(receiptPageData);
			setReceiptPageProducts(receiptPageProducts);
			setOrderTotals([calculatedCart]);
			setSessionToken(sessionToken);
			setSubmitOverlayVisible(false);
			history.push('/order-received');
		} else {
			redirect?.link && window.location.replace(redirect.link);
			setTimeout(() => setDisplayPurchaseComplete(true), 2000);
		}
	};

	const sendThenProcess = async (params) => {
		const formValues = getFormData();
		const { email, securityCode, expirationDate, phone, cardHolderName, billing, shipping } =
			formValues;
		const billingFindLocationActive = ['US', 'CA'].includes(billing.countryCode);
		const shippingFindLocationActive =
			!isShippingSameAsBilling && ['US', 'CA'].includes(shipping.countryCode);
		if (billingFindLocationActive && isShippingSameAsBilling) {
			const keysToTest = ['zip', 'state', 'countryCode'];
			if (billing.countryCode === 'US') {
				keysToTest.push('city');
			}
			const billingKeysMatch = keysToTest.every((field) => {
				return billing[field] === calculateCartAddressVars[field];
			});
			if (!billingKeysMatch) {
				// Find location in progress - do not allow submit
				setSubmitOverlayVisible(false);
				return;
			}
		}
		if (shippingFindLocationActive && !isShippingSameAsBilling) {
			const keysToTest = ['zip', 'state', 'countryCode'];
			if (shipping.countryCode === 'US') {
				keysToTest.push('city');
			}
			const shippingKeysMatch = keysToTest.every((field) => {
				return shipping[field] === calculateCartAddressVars[field];
			});
			if (!shippingKeysMatch) {
				// Find location in progress - do not allow submit
				setSubmitOverlayVisible(false);
				return;
			}
		}

		const { targetMutation, submitFunction, suggestedAddress } = params;
		const mutationType =
			targetMutation === 'SUBMIT_ORDER' ? PaymentMethods.CREDIT : PaymentMethods.PAYPAL;

		if (
			mutationType === PaymentMethods.CREDIT &&
			(!token || !tokenHash) &&
			!tokenExMismatchErrorHasTriggered.current
		) {
			tokenExMismatchErrorHasTriggered.current = true;
			// If token or hash are null, clear input entirely and trigger error for reentry
			// Only execute one time, allow error if it reoccurs.
			if (tokenExIframe) {
				tokenExIframe.reset();
				tokenExIframe.validate();
			}
			const errors = await formIsValid(true);
			scrollUserToFormError(errors);
			setSubmitOverlayVisible(false);
			return;
		}
		const { fullName } = isShippingSameAsBilling ? billing : shipping;

		//removes the slash from the expiration date
		const expiration = expirationDate?.replace('/', '');

		if (isShippable && !verifiedAddress && !suggestedAddress && !autocompleteAddressSelected) {
			const { data } = await verifyAddressQuery({
				variables: {
					addressVerifyRequest: {
						orderIdentifier: kountSessionId,
						address: {
							address1: billing.address1,
							address2: billing.address2,
							city: billing.city,
							state: billing.state,
							zip: billing.zip,
							countryCode: billing.countryCode,
						},
					},
				},
			});
			if (data?.addressVerify) {
				const { verifiedSuggestion, reason, editedFields, result } = data.addressVerify;

				if (verifiedSuggestion || reason || result === false) {
					setVerifiedAddress({
						result,
						reason,
						editedFields,
						suggestion: verifiedSuggestion,
					});
					setIsSuggestedAddressDialogOpen(true);
					return;
				}
			}
		}

		let addressBody = {
			countryCode: billing.countryCode,
			zip: billing.zip,
			city: billing.city,
			state: billing.state,
			address1: billing.address1,
			address2: billing.address2,
		};

		if (suggestedAddress) {
			addressBody = {
				countryCode: suggestedAddress.countryCode,
				zip: suggestedAddress.zip,
				city: suggestedAddress.city,
				state: suggestedAddress.state,
				address1: suggestedAddress.address1,
				address2: suggestedAddress.address2,
			};
			const { data } = await calculateCart({
				variables: {
					lineItems: calculateCartLineItems,
					vendorId: urlVars.vvvv,
					currencyId: selectedCurrency,
					couponCode: validatedCoupon && !couponCode ? validatedCoupon : couponCode,
					address: addressBody,
					availableItems: availableItems,
					urlParams: window.location.search,
				},
			});

			if (!data.calculateCart) {
				setSubmitOverlayVisible(false);
				setErrorData({ message: 'connection-fail', messageType: 'cart' });
				return;
			}

			const currentCartTotal = orderTotals[0]?.total;
			const cartTotalFromSuggestedAddress = data.calculateCart.total;

			if (currentCartTotal !== cartTotalFromSuggestedAddress) {
				setSubmitOverlayVisible(false);
				mergeFormData({ billing: addressBody }, true);
				setIsSuggestedAddressDialogOpen(false);
				return;
			}
		}

		const body = {
			gdpr: consent,
			kountSid: kountSessionId,
			address: addressBody,
			...(!isShippingSameAsBilling
				? {
						shippingAddress: {
							countryCode: shipping.countryCode,
							zip: shipping.zip,
							city: shipping.city,
							state: shipping.state,
							address1: shipping.address1,
							address2: shipping.address2,
						},
					}
				: {}),
			lineItems: submitOrderLineItems,
			...((isShippable || forceNameForPayPal) && {
				fullName: fullName,
			}),
			cardHolderName,
			email: email,
			phone: showPhone ? phone : null,
			vendorId: urlVars.vvvv,
			couponCode: validatedCoupon || couponCode,
			currencyId: selectedCurrency,
			cvv: securityCode,
			cardTokenHash: tokenHash,
			cardToken: token,
			cartTotal: orderTotals[0]?.total,
			expirationDate: expiration,
			locale: selectedLanguage,
			urlParams: window.location.search,
			acceptDuplicate: getUserHasAcceptedDuplicate(),
			smsConfirmationEnabled: isSmsConfirmationEnabled,
		};

		const submitVariables = urlVars && orderTotals && body;
		if (!submitVariables) {
			// setting message as connection-fail just to reuse the copy
			setSubmitOverlayVisible(false);
			setErrorData({ message: 'connection-fail', messageType: 'cart' });
			return;
		}

		const gqlMutation = await submitFunction({ variables: submitVariables });

		if (gqlMutation.error) {
			if (
				'cbpd' in urlVars &&
				gqlMutation.error?.graphQLErrors?.[0]?.extensions?.status === 401
			) {
				setShowUpsellPaymentDeclinedModal(true);
				setSubmitOverlayVisible(false);
			}

			// retry as needed
			const { targetMutation } = params;
			const { error } = gqlMutation;
			const retry = error?.graphQLErrors?.[0]?.extensions?.status === 503;

			// increment the endpoint's retry count, then retry or handle the failure
			retryCount[targetMutation] = !retryCount?.[targetMutation]
				? 1
				: retryCount[targetMutation] + 1;

			if (retry && retryCount?.[targetMutation] <= 2) {
				// for RUM
				console.error(
					'Had to retry a mutation (max retries is 2):',
					JSON.stringify({
						retryCount: retryCount[targetMutation],
						mutation: targetMutation,
					}),
				);
				console.warn('Trying: ' + targetMutation + ' again...');
				const waitTime = retryCount[targetMutation] === 1 ? 0 : 2000;
				setTimeout(() => {
					params.retry = true;
					sendThenProcess(params);
				}, waitTime);
			} else {
				setErrorData(errorHandler({ error }, targetMutation));
				retryCount[targetMutation] = undefined;
				setTimeout(() => {
					setSubmitOverlayVisible(false);
				}, overlayRevealDelay);
			}
			return;
		}
		retryCount[targetMutation] = undefined; // Reset retry object if no error
		if (gqlMutation.data) {
			mutationType === PaymentMethods.CREDIT &&
				handleCreditResponse(gqlMutation.data.submitOrder);
			mutationType === PaymentMethods.PAYPAL && handlePayPalResponse(gqlMutation.data);
		}
	};

	useEffect(() => {
		// currentInput and checkoutForm are used to add focus to
		// whatever input had focus when they are disabled for an API call.
		// The highlight is added back by comparing currentInput.name to the
		// input's name in the various select or input fields.
		if (checkoutForm) {
			const setFocusedInput = (event) => {
				const input = event.target;
				setCurrentInput(input);
			};
			checkoutForm.addEventListener('focus', setFocusedInput, true);
			return () => {
				checkoutForm.removeEventListener('focus', setFocusedInput, true);
			};
		}
	}, [checkoutForm, setCurrentInput]);

	const onSubmit = async (ev, id, suggestedAddress) => {
		const { phone } = getFormData();
		setSubmitterId(ev.nativeEvent.submitter?.id);
		const isAppleSubmission =
			ev.nativeEvent.submitter?.id === 'apple-pay-submit-button' ||
			id === 'apple-pay-submit-button';

		ev.preventDefault();
		if (
			isPreview ||
			isCartUpdating ||
			validationSchemaCalculating ||
			!orderTotals ||
			triggeringBumpsQueue.current.length !== 0
		) {
			return;
		}

		if (!isAppleSubmission) {
			const validForm = await formIsValid(true);
			if (!validForm) {
				scrollUserToFormError(formErrors);
				return;
			}
		}
		if (isDuplicateOrder && !hasDuplicateOrderModalOpened.current) {
			setIsDuplicateOrderModalOpen(true);
			hasDuplicateOrderModalOpened.current = true;
			return;
		}

		if (isAppleSubmission) {
			const validForm = await formFieldIsValid('subscriptionTerms');
			if (!validForm) {
				document.getElementById('subscriptionTerms').scrollIntoView({
					behavior: 'smooth',
				});
				return;
			}
		}

		setSubmitOverlayVisible(true);
		setErrorData(null);
		setFormServerErrorInfo(null);

		await addClientHintHeaders();

		if (paymentMethod === PaymentMethods.CREDIT) {
			sendThenProcess({
				targetMutation: 'SUBMIT_ORDER',
				submitFunction: submitOrder,
				suggestedAddress,
			});
		} else if (paymentMethod === PaymentMethods.PAYPAL) {
			sendThenProcess({
				targetMutation: 'REQUEST_DIRECT_PAYPAL_ORDER',
				submitFunction: submitPayPal,
				suggestedAddress,
			});
		} else if (paymentMethod === PaymentMethods.PAZE) {
			const pazeSubmitVars = urlVars &&
				orderTotals && {
					cartTotal: orderTotals[0].total,
					gdpr: consent,
					kountSid: kountSessionId,
					lineItems: submitOrderLineItems,
					vendorId: urlVars.vvvv,
					couponCode: validatedCoupon || couponCode,
					currencyId: selectedCurrency,
					locale: selectedLanguage,
					urlParams: window.location.search,
					phone: showPhone ? phone : null,
					acceptDuplicate: getUserHasAcceptedDuplicate(),
				};

			if (!pazeSubmitVars) {
				// setting message as connection-fail just to reuse the copy
				setSubmitOverlayVisible(false);
				setErrorData({ message: 'connection-fail', messageType: 'cart' });
				return;
			}

			await pazeSubmit(
				orderTotals,
				selectedCurrency,
				submitDigitalPayment,
				pazeSubmitVars,
				pazeData,
				setPazeData,
				handleCreditResponse,
				setSubmitOverlayVisible,
				setErrorData,
				setPaymentMethod,
				isShippable,
				getFormData,
				calculateCartAddressVars,
			);
		} else if (paymentMethod === PaymentMethods.APPLE_PAY) {
			// if the user makes changes to the SOF that updates the shipping/tax data for the products
			// we need to be sure that APF does not use that info and defaults to the unitPrice
			const productsWithoutCalculateCartInfluence = allProducts.map((product) => {
				return {
					...product,
					priceModel: {
						...product.priceModel,
						initialPayment: product.priceModel.unitPrice,
						subsequentPayments: product.priceModel.recurringUnitPrice,
					},
				};
			});

			processApplePay(
				setSubmitOverlayVisible,
				setErrorData,
				getFormData,
				urlVars,
				orderTotals,
				kountSessionId,
				submitOrderLineItems,
				validatedCoupon,
				couponCode,
				selectedCurrency,
				selectedLanguage,
				showPhone,
				applePayData,
				handleCreditResponse,
				initializeApplePaySession,
				processApplePayResponse,
				cartStatus,
				productsWithoutCalculateCartInfluence,
				calculateCartOnApplePayChange,
				cartIncludesShippableProduct,
				siteSettings.vendorBrand,
				t,
			);
		}

		removeClientHintHeaders();
	};

	return (
		<form id="checkoutForm" noValidate onSubmit={onSubmit} ref={setCheckoutForm}>
			{props.children}
			{verifiedAddress ? (
				<SuggestedAddressDialog
					setVerifiedAddress={setVerifiedAddress}
					verifiedAddress={verifiedAddress}
					isDialogOpen={isSuggestedAddressDialogOpen}
					setIsDialogOpen={setIsSuggestedAddressDialogOpen}
					handleSubmit={onSubmit}
					submitterId={submitterId}
				/>
			) : null}
			<DuplicateOrderModal
				isOpen={isDuplicateOrderModalOpen}
				setIsOpen={setIsDuplicateOrderModalOpen}
				handleSubmit={onSubmit}
				submitterId={submitterId}
			/>
			<input ref={hiddenInputRef} type="hidden"></input>
		</form>
	);
};

export default CheckoutForm;

CheckoutForm.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};
