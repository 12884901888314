import PropTypes from 'prop-types';
import { createContext, useEffect, useMemo, useState } from 'react';

export const SecurityLogosContext = createContext({});

const loadScripts = process.env.REACT_APP_TRUSTED_SITE_ENABLED === 'true';

export const SecurityLogosContextProvider = ({ children }) => {
	const [trustedSiteMarkup, setTrustedSiteMarkup] = useState('');
	const [digicertMarkup, setDigicertMarkup] = useState('');
	// Digicert will be blocked by loading images, so wait to load BBB until
	// it is set, or it fails to set
	const [loadBBB, setLoadBBB] = useState(false);
	const [showBBB, setShowBBB] = useState(false);

	useEffect(() => {
		if (!loadScripts) {
			return;
		}
		// Create DOM nodes to avoid race condition with client rendering
		const trustedSiteNode = document.createElement('div');
		trustedSiteNode.className = 'mfes-trustmark logo trusted-site-logo hidden-security-logo';
		trustedSiteNode.setAttribute('data-type', '212');
		trustedSiteNode.setAttribute('data-width', '90');
		trustedSiteNode.setAttribute('data-height', '50');
		trustedSiteNode.setAttribute('data-ext', 'svg');
		document.body.appendChild(trustedSiteNode);

		const digicertNode = document.createElement('div');
		digicertNode.id = 'DigiCertClickID_CthxTUrH';
		digicertNode.className = 'hidden-security-logo';
		document.body.appendChild(digicertNode);

		// Trusted Site
		const trustedSiteScript = document.createElement('script');
		trustedSiteScript.type = 'text/javascript';
		trustedSiteScript.async = true;
		trustedSiteScript.src = 'https://cdn.ywxi.net/js/1.js';
		let trustedSiteDebounce = null;
		const trustedSiteObserver = new window.MutationObserver(() => {
			window.clearTimeout(trustedSiteDebounce);
			trustedSiteDebounce = window.setTimeout(() => {
				let html = trustedSiteNode.outerHTML;
				html = html.replace(/class=["'][^"']*["'] ?/, '');
				const clickOriginalNode = "document.querySelector('.trusted-site-logo').click();";
				const handlerText = `onclick="${clickOriginalNode}" onkeypress="if(event.key === 'Enter'){${clickOriginalNode}}" `;
				html = html.replace('<div ', `<div ${handlerText}`);
				setTrustedSiteMarkup(html);
			}, 200);
		});
		trustedSiteObserver.observe(trustedSiteNode, {
			childList: true,
			subtree: true,
			attributes: true,
		});
		document.body.appendChild(trustedSiteScript);

		// Digicert
		window.__dcid = window.__dcid || [];
		window.__dcid.push({ cid: 'DigiCertClickID_CthxTUrH', tag: 'CthxTUrH' });
		const cid = document.createElement('script');
		cid.async = true;
		cid.src = '//seal.digicert.com/seals/cascade/seal.min.js';
		cid.onerror = () => {
			setLoadBBB(true);
		};
		let digicertDebounce = null;
		const digicertObserver = new window.MutationObserver(() => {
			window.clearTimeout(digicertDebounce);
			digicertDebounce = window.setTimeout(() => {
				let html = digicertNode.outerHTML;
				html = html.replace(/class=["'][^"']*["'] ?/, '');
				html = html.replace(/id=["'][^"']*["'] ?/, '');
				const clickOriginalImg = `document.querySelector('#${digicertNode.id} img').click();`;
				const handlerText = `onclick="${clickOriginalImg}" onkeypress="if(event.key === 'Enter'){${clickOriginalImg}}" `;
				html = html.replace('<img ', `<img ${handlerText}`);
				setDigicertMarkup(html);
				setLoadBBB(true);
			}, 200);
		});
		digicertObserver.observe(digicertNode, {
			childList: true,
			subtree: true,
			attributes: true,
		});
		const s = document.getElementsByTagName('script');
		const ls = s[s.length - 1];
		ls.parentNode.insertBefore(cid, ls.nextSibling);
	}, []);

	const ctx = useMemo(
		() => ({ trustedSiteMarkup, digicertMarkup, showBBB, setShowBBB, loadBBB }),
		[trustedSiteMarkup, digicertMarkup, showBBB, setShowBBB, loadBBB],
	);

	return <SecurityLogosContext.Provider value={ctx}>{children}</SecurityLogosContext.Provider>;
};

SecurityLogosContextProvider.propTypes = {
	children: PropTypes.node,
};
