import { css } from '@emotion/react';
import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

import { useStore } from '@/state/stores';
import { cbNeutral } from '@/theme';

const UpdateOverlay = ({ visible, transparentBackground = false }) => {
	const submitOverlayVisible = useStore((state) => state.submitOverlayVisible);

	const overlayCSS = (transparentBackground) => css`
		position: ${submitOverlayVisible ? 'fixed' : 'absolute'};
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		/* rgba won't work with a var in Emotion. '4c is 30% in hex */
		background-color: ${transparentBackground ? 'transparent' : '#0e0d264c'};
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 1;

		.dark-spinner {
			color: ${cbNeutral[100]};
		}
	`;

	if (!visible) {
		return null;
	}

	return (
		<div css={overlayCSS(transparentBackground)}>
			<CircularProgress className="dark-spinner" size={88} thickness={4} />
		</div>
	);
};

UpdateOverlay.propTypes = {
	visible: PropTypes.bool.isRequired,
	transparentBackground: PropTypes.bool,
};

UpdateOverlay.defaultProps = {
	transparentBackground: false,
};

export default UpdateOverlay;
