import { css } from '@emotion/react';

import DividerWidget from './DividerWidget';
import ImageWidget from './ImageWidget';
import TextWidget from './TextWidget';
import Cart from '@/components/cart/Cart';
import { breakpointMap, v2BreakPointMap } from '@/theme';
import { Layouts } from '@/utils/enums';

export const COMPONENT_LIST = {
	Cart,
	ImageWidget,
	TextWidget,
	DividerWidget,
};

export const WidgetNameMap = {
	SEPARATOR: 'DividerWidget',
	IMAGE: 'ImageWidget',
	TEXT_IMAGE: 'ImageCaptionWidget',
	IMAGE_GROUP: 'ImageGroupWidget',
	ORDER_BUMPS: 'OrderBumpsWidget',
	TEXT: 'TextWidget',
	COUNTDOWN_TIMER: 'CountdownTimerWidget',
};

export const defaultTemplate = {
	layout: Layouts.STANDARD,
	zones: [
		{
			id: 'clickBankHeader',
		},
		{
			id: 'header',
		},
		{
			id: 'leftColumnTop',
		},
		{
			id: 'leftColumnBottom',
		},
		{
			id: 'rightColumnTop',
		},
		{
			id: 'rightColumnBottom',
		},
		{
			id: 'footer',
		},
		{
			id: 'termsGuarantee',
		},
		{
			id: 'copyright',
		},
		{
			id: 'cart',
		},
		{
			id: 'shipping',
		},
		{
			id: 'payment',
		},
	],
};

export const getBreakPointMap = (visibility) => {
	return visibility.length && visibility[0].includes('_2') ? v2BreakPointMap : breakpointMap;
};

/**
 *
 * @param {[]} visibility
 * @returns  {{styles: string}} An object with CSS that determines if a widget is visible or not at different screen sizes
 */
export const getVisibilityStyle = (visibility) => {
	let cssInput = '';
	if (!Array.isArray(visibility)) {
		console.error('Visibility prop must be an array');
		return css(cssInput);
	}

	const activeBreakPointMap = getBreakPointMap(visibility);
	const screenSizes = Object.keys(activeBreakPointMap);
	screenSizes.forEach((size) => {
		if (!visibility.includes(size)) {
			cssInput += `${activeBreakPointMap[size]} {
				display: none;
			}`;
		}
	});
	return css(cssInput);
};
