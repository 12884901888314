import { ClientContext, GraphQLClient } from 'graphql-hooks';
import PropTypes from 'prop-types';

import { logErrors } from '@/utils/errorHandler';
import { isDevEnv, isPreview } from '@/utils/helpers';

const url = process.env.REACT_APP_GRAPHQL_URL
	? process.env.REACT_APP_GRAPHQL_URL
	: process.env.REACT_APP_BASE_PATH.concat('/graphql');

const client = new GraphQLClient({
	url,
	onError: logErrors,
	logErrors: false,
});

export const GraphQLProvider = (props) => {
	const { children } = props;
	const hasExitOfferInUrl = window.location.search.includes('exitoffer');

	if ((isDevEnv || isPreview) && hasExitOfferInUrl) {
		// Add the JWt requited for fetching unapproved exit offers
		props.dataFetchPromise
			.then((data) => {
				const token = data.fetchHeaders.Authorization;
				client.setHeader('authorization', token);
			})
			.catch((err) => {
				console.error(err);
			});
	}
	return <ClientContext.Provider value={client}>{children}</ClientContext.Provider>;
};

GraphQLProvider.propTypes = {
	children: PropTypes.object,
	dataFetchPromise: PropTypes.object,
};
