import { fetchUrl, fetchWithRetry, getQuantity } from './fetchHelpers';
import { CALCULATE_CART } from '@/graphql/queries';
import { i18nInstance } from '@/i18n';
import { stringIsEqualCaseInsensitive } from '@/utils/helpers';
import { getProductsWithPricing } from '@/utils/products';

const getCalculateCartQuery =
	({ urlVars, fetchHeaders, initialCoupon }) =>
	async (incomingData) => {
		try {
			const { state: prevState } = incomingData;
			const {
				availableItems,
				calculateCartLineItems,
				selectedCurrency,
				calculateCartAddressVars,
				allProducts,
				cartStatus,
			} = prevState;

			// If coupon is not valid, we do not want to send it, as that will cause the request
			// to return a 400. In this case, the invalid coupon will be persisted onto the
			// form for validation, but discarded for calculate cart.
			const applyCoupon = initialCoupon && /^[a-zA-Z\d_-]{5,20}$/.test(initialCoupon);

			const requestVars = {
				lineItems: calculateCartLineItems,
				vendorId: urlVars.vvvv,
				currencyId: selectedCurrency,
				couponCode: applyCoupon ? initialCoupon : '',
				address: calculateCartAddressVars,
				availableItems,
				urlParams: window.location.search,
			};

			const body = await fetchWithRetry('CALCULATE_CART', fetchUrl, {
				method: 'POST',
				headers: fetchHeaders,
				body: JSON.stringify({
					query: CALCULATE_CART,
					variables: requestVars,
				}),
			});
			if (body.error) {
				throw body;
			}
			const { data } = body;
			const allProductsWithPricingInjected = getProductsWithPricing(allProducts, data);

			const submitOrderLineItems = cartStatus
				.filter((product) => product.isActive)
				.map((product) => {
					const currentProduct = allProductsWithPricingInjected.find((item) =>
						stringIsEqualCaseInsensitive(item.sku, product.sku),
					);
					return {
						productId: urlVars.vvvv + '-' + product.sku,
						quantity: getQuantity(product),
						unitPrice: currentProduct.priceModel.unitPrice,
						bump: product.isBump,
					};
				});

			const state = {
				allProducts: allProductsWithPricingInjected,
				submitOrderLineItems,
				submitOverlayVisible: false,
				isCartUpdating: false,
				cartInitialized: true,
				orderTotals: [data.calculateCart],
				couponCode: '',
				impressionVars: requestVars,
			};

			if (applyCoupon) {
				const t = await i18nInstance;
				const hasDiscount = Number(data.calculateCart.discountTotal || 0) !== 0;

				if (hasDiscount) {
					state.validatedCoupon = initialCoupon;
					state.couponSuccessMessage = t('checkout:field.coupon-code.success', {
						code: initialCoupon,
						discount: Math.floor(data.calculateCart.discountRate * 100),
					});
				} else {
					// Error
					state.couponErrorMessageOverride = t(
						'checkout:field.coupon-code.error.invalid-submitted',
						{
							code: initialCoupon,
						},
					);
				}
			}

			return {
				...incomingData,
				graphql: {
					...incomingData.graphql,
					CALCULATE_CART: data,
				},
				state: {
					...prevState,
					...state,
				},
			};
		} catch (body) {
			return Promise.reject({ errors: { CALCULATE_CART: body } });
		}
	};

export default getCalculateCartQuery;
