import { css } from '@emotion/react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Autocomplete, ClickAwayListener, Typography } from '@mui/material';
import { useManualQuery } from 'graphql-hooks';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import CBTextField from '@/components/common/CBTextField';
import { ADDRESS_SEARCH_QUERY } from '@/graphql/queries';
import { useFormStore, useStore } from '@/state/stores';
import { cbPrimary, fontWeightBold } from '@/theme';

const autoCompleteStyles = css`
	.more-addresses {
		color: ${cbPrimary[500]};
		display: flex;
		padding-left: 8px;
		font-weight: ${fontWeightBold};
		align-items: center;
	}
`;

const AddressAutoComplete = () => {
	const { t } = useTranslation('checkout');
	const accessor = 'billing';
	const {
		cartInitialized,
		addressSearchId,
		selectedCountryData,
		setAutocompleteAddressSelected,
	} = useStore(
		useShallow((state) => ({
			cartInitialized: state.cartInitialized,
			addressSearchId: state.addressSearchId,
			selectedCountryData: state.selectedCountryData[accessor],
			setAutocompleteAddressSelected: state.setAutocompleteAddressSelected,
		})),
	);

	const { setFormValue, address1, formIsValid } = useFormStore(
		useShallow((state) => ({
			address1: state.formData.billing.address1,
			setFormValue: state.setFormValue,
			formIsValid: state.formIsValid,
		})),
	);

	const [autoCompleteQuery] = useManualQuery(ADDRESS_SEARCH_QUERY);

	const [isAutoCompleteOpen, setIsAutoCompleteOpen] = useState(false);
	const [addressOptions, setAddressOptions] = useState([]);
	const { autocomplete, countryCode } = selectedCountryData;
	const autoCompleteDebounceRef = useRef(null);

	// This boolean is used to prevent the autocomplete dropdown from opening when autofill is used
	const [isAddressInputFocused, setIsAddressInputFocused] = useState(false);

	const setFormValues = (address) => {
		const { address1, address2, city, state, zip } = address;
		setFormValue(`${accessor}.address1`, address1 || '');
		setFormValue(`${accessor}.address2`, address2 || '');
		setFormValue(`${accessor}.city`, city || '');
		setFormValue(`${accessor}.state`, state || '');
		setFormValue(`${accessor}.zip`, zip || '');
		formIsValid(true);
	};

	// run when the user is typing in the input and adds data to the dropdown
	const handleInputChange = async (event, _, reason) => {
		// return early if the event was from choosing an address from the dropdown or if the user is not focused on the input
		if (reason !== 'input' || !isAddressInputFocused) {
			return;
		}
		setAutocompleteAddressSelected(false);
		const { value } = event.target;
		if (autocomplete) {
			if (value.length > 3) {
				window.clearTimeout(autoCompleteDebounceRef.current);
				autoCompleteDebounceRef.current = window.setTimeout(async () => {
					const response = await autoCompleteQuery({
						variables: {
							addressSearchRequest: {
								orderIdentifier: addressSearchId,
								search: value,
								searchCountry: countryCode,
							},
						},
					});

					const addresses = response?.data?.addressSearch;
					if (addresses?.length > 0) {
						// iterate over addresses and turn the objects into strings
						const addressOptions = addresses.map((address) => {
							const { addressText, address1, address2, city, state, zip } =
								address || {};
							if (addressText) {
								return {
									label: addressText,
									value: addressText,
									address: { ...address, countryCode },
								};
							}
							return {
								address,
								label: `${address1} ${address2 || ''}, ${city} ${state}, ${zip}`,
								value: `${address1}`,
							};
						});
						setIsAutoCompleteOpen(true);
						setAddressOptions(addressOptions);
					}
				}, 200);
			}
		}
	};

	// Runs when the user selects an address from the dropdown
	const handleSelection = async (_, value) => {
		const { address1, address2, city, state, zip, countryCode, addressId, entries } =
			value.address || {};
		setAutocompleteAddressSelected(true);
		setIsAutoCompleteOpen(false);
		const hasMultipleAddresses = entries > 1;
		if (hasMultipleAddresses) {
			// this is where it calls for secondary addresses
			const response = await autoCompleteQuery({
				variables: {
					addressSearchRequest: {
						orderIdentifier: addressSearchId,
						selected: {
							addressId,
							address1,
							address2,
							zip,
							state,
							city,
							countryCode,
						},
						entries,
					},
				},
			});

			const addresses = response?.data?.addressSearch;

			if (addresses?.length > 0) {
				// iterate over addresses and turn the objects into strings
				const addressOptions = addresses.map((address) => {
					const { addressText, address1, address2, city, state, zip } = address || {};
					const isInternational = Boolean(addressText);
					return isInternational
						? {
								label: addressText,
								value: addressText,
								address: { ...address, countryCode },
							}
						: {
								address,
								label: `${address1}${` ${
									address2 || ''
								}`}, ${city} ${state}, ${zip}`,
								value: `${address1}`,
							};
				});
				setAddressOptions(addressOptions);
				setIsAutoCompleteOpen(true);
			}
		} else {
			let intlAddress;
			const USAddress = value.address;
			const isInternational = value.address.countryCode !== 'US';
			if (isInternational) {
				// fetch the international city state and zip
				const response = await autoCompleteQuery({
					variables: {
						addressSearchRequest: {
							orderIdentifier: addressSearchId,
							selected: {
								addressId,
								countryCode,
							},
						},
					},
				});

				intlAddress = response?.data?.addressSearch[0] || {};
			}

			setFormValues(intlAddress || USAddress);
		}
	};

	return (
		<ClickAwayListener onClickAway={() => setIsAutoCompleteOpen(false)}>
			<div css={autoCompleteStyles}>
				<Autocomplete
					value={address1}
					inputValue={address1}
					open={isAutoCompleteOpen}
					options={addressOptions}
					isOptionEqualToValue={(option, value) => {
						return option.value === value;
					}}
					required={true}
					onChange={(_, value) => handleSelection(_, value)}
					onInputChange={handleInputChange}
					freeSolo
					disableClearable
					disablePortal
					className="autocomplete"
					id={`${accessor}.address1`}
					onFocus={() => setIsAddressInputFocused(true)}
					onBlur={() => setIsAddressInputFocused(false)}
					renderOption={(MUIprops, option) => {
						const {
							entries,
							countryCode,
							addressText,
							address1,
							address2,
							city,
							state,
							zip,
						} = option.address || {};
						const isInternational = countryCode !== 'US';
						return isInternational ? (
							<li
								{...MUIprops}
								key={`${addressText}-${MUIprops['data-option-index']}`}
							>
								<Typography variant="body2">{addressText}</Typography>
								{entries > 1 && (
									<div className="more-addresses">
										<Typography sx={{ fontSize: `${12 / 16}rem` }} noWrap>
											{`+${entries} Addresses`}
										</Typography>
										<ChevronRightIcon />
									</div>
								)}
							</li>
						) : (
							<li {...MUIprops} key={`${address1}-${MUIprops['data-option-index']}`}>
								<Typography variant="body2">{`${address1}${
									address2 ? ` ${address2}` : ''
								} ${city} ${state} ${zip}`}</Typography>
								{entries > 1 && (
									<div className="more-addresses">
										<Typography sx={{ fontSize: `${12 / 16}rem` }} noWrap>
											{`+${entries} Addresses`}
										</Typography>
										<ChevronRightIcon />
									</div>
								)}
							</li>
						);
					}}
					getOptionLabel={(option) => {
						return typeof option === 'string' ? option : option.value;
					}}
					sx={{ padding: '0' }}
					renderInput={(params) => (
						<CBTextField
							{...params}
							name={`${accessor}.address1`}
							label={t('field.address1.label')}
							required={true}
							addressAccessor={accessor}
							showPlaceholder={!cartInitialized}
						/>
					)}
				/>
			</div>
		</ClickAwayListener>
	);
};

export default AddressAutoComplete;
