import { css } from '@emotion/react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';

import { cbPrimary } from './theme';
import { isPreview } from './utils/helpers';

const dialogActions = css`
	.MuiDialog-container {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
		width: 100vw;
	}

	.MuiPaper-root {
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		padding: 40px;
		padding-bottom: 32px;
		width: 656px;
	}

	.MuiDialogContent-root {
		padding: 0;
	}

	.MuiDialogActions-root.dialog-actions {
		display: flex;
		justify-content: center;
		margin-top: auto;
	}

	.go-to-manager {
		background-color: ${cbPrimary['primary-950']};
		&:hover {
			background-color: ${cbPrimary['primary-900']};
		}
	}
`;

const ErrorFallback = ({ resetErrorBoundary }) => {
	const history = useHistory();
	const location = useLocation();
	const { t } = useTranslation(['common']);
	const urlParams = window.location.search;
	const isOnReceiptPage = location.pathname === '/order-received';

	const returnToCart = () => {
		resetErrorBoundary();
		history.push(`/${urlParams}`);
	};

	const errorModalMessage = isOnReceiptPage ? (
		<>
			<strong>{t('error-fallback.an-error-occurred')}</strong>{' '}
			{t('error-fallback.an-error-occurred-message')}
			<a href="https://clkbank.com"> www.clkbank.com</a>.
		</>
	) : (
		<>
			<strong>{t('error-fallback.something-went-wrong')}</strong>{' '}
			{t('error-fallback.something-went-wrong-message')}
		</>
	);

	const previewErrorMessage = (
		<>
			<strong>{t('error-fallback.preview-error')}</strong>{' '}
			{t('error-fallback.preview-error-message')}
		</>
	);

	const escapeButton = isOnReceiptPage ? (
		<Button color="primary" href="https://clkbank.com" onClick={resetErrorBoundary}>
			{t('error-fallback.look-up-order-info')}
		</Button>
	) : urlParams ? (
		<Button color="primary" onClick={returnToCart}>
			{t('error-fallback.return-to-cart')}
		</Button>
	) : null;

	const previewEscapeButton = (
		<Button color="primary" onClick={() => window.location.reload()}>
			{t('error-fallback.refresh-page')}
		</Button>
	);

	return (
		<Dialog
			css={dialogActions}
			maxWidth="md"
			open={true}
			onClose={resetErrorBoundary}
			disablePortal
		>
			<DialogContent>
				<Typography variant="subtitle1" gutterBottom>
					{isPreview ? previewErrorMessage : errorModalMessage}
				</Typography>
			</DialogContent>
			<DialogActions className="dialog-actions">
				{isPreview ? previewEscapeButton : escapeButton}
			</DialogActions>
		</Dialog>
	);
};

ErrorFallback.propTypes = {
	resetErrorBoundary: PropTypes.func,
};

export default ErrorFallback;
