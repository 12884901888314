import { css } from '@emotion/react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Modal } from '..';
import { gearRewind } from '@/assets';

const loadErrorModalStyles = css`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.error-icon {
		display: block;
		margin-bottom: 2rem;
	}
`;

const LoadErrorModal = (props) => {
	const { modalOpen, closeModal, errorMessage } = props;
	const { t } = useTranslation(['checkout']);

	const dialogTitle = t('error-modal.oops');

	const upsellDialogue = (
		<Typography variant="subtitle1">
			{t('server-errors.upsell-problem')}{' '}
			<a href="https://www.clkbank.com/" target="_blank" rel="noopener noreferrer">
				CLKBank.com
			</a>{' '}
			{t('server-errors.upsell-help')}
		</Typography>
	);

	const standardDialogue =
		errorMessage === 'generic-error' ? (
			<Typography variant="subtitle1">
				{t(`server-errors.generic-error-1`)}{' '}
				<a href="https://www.clkbank.com/" target="_blank" rel="noopener noreferrer">
					{t(`server-errors.contact-support`)}
				</a>{' '}
				{t(`server-errors.generic-error-2`)}
			</Typography>
		) : (
			<Typography variant="subtitle1">{t(`server-errors.${errorMessage}`)}</Typography>
		);

	const dialogContent = (
		<div css={loadErrorModalStyles}>
			<img src={gearRewind} alt={t('alt-tags.load-error')} className="error-icon" />
			{errorMessage === 'upsell' ? upsellDialogue : standardDialogue}
		</div>
	);

	return (
		<Modal
			modalOpen={modalOpen}
			closeModal={closeModal}
			dialogTitle={dialogTitle}
			dialogContent={dialogContent}
		/>
	);
};

LoadErrorModal.propTypes = {
	modalOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	errorMessage: PropTypes.string,
};

export default LoadErrorModal;
