import { css } from '@emotion/react';
import DownloadIcon from '@mui/icons-material/Download';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const productTypeStyles = css`
	display: flex;
	align-items: center;
	svg {
		margin-right: 3px;
		margin-left: -3px;
	}
`;

export const productTypeShouldDisplay = (type) =>
	[
		'STANDARD_DIGITAL',
		'RECURRING_DIGITAL',
		'STANDARD_PHYSICAL_RECURRING_DIGITAL',
		'STANDARD_DIGITAL_STANDARD_PHYSICAL',
	].includes(type);

const ProductType = (props) => {
	const { type, useTypography, ...rest } = props;
	const { t } = useTranslation('common');
	const location = useLocation();
	const isNotOnReceiptPage = location.pathname !== '/order-received';

	if (productTypeShouldDisplay(type)) {
		return useTypography ? (
			<Typography variant="body2" css={productTypeStyles} {...rest}>
				{isNotOnReceiptPage && <DownloadIcon fontSize="small" />}{' '}
				{t('product-type.digital')}
			</Typography>
		) : (
			<span css={productTypeStyles}>
				{isNotOnReceiptPage && <DownloadIcon fontSize="small" />}{' '}
				{t('product-type.digital')}
			</span>
		);
	}
	return null;
};

ProductType.propTypes = {
	type: PropTypes.oneOf([
		'STANDARD_DIGITAL',
		'STANDARD_PHYSICAL',
		'RECURRING_DIGITAL',
		'RECURRING_PHYSICAL',
		'STANDARD_PHYSICAL_RECURRING_DIGITAL',
		'STANDARD_DIGITAL_STANDARD_PHYSICAL',
	]).isRequired,
	useTypography: PropTypes.bool,
};

export default ProductType;
