import { useManualQuery, useMutation } from 'graphql-hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { ReceiptPageSkeleton } from '@/components';
import { CANCEL_DIRECT_PAYPAL_ORDER, UPDATE_DIRECT_PAYPAL_ORDER } from '@/graphql';
import { PRODUCTS } from '@/graphql/queries';
import { useStore } from '@/state/stores';
import { errorHandler } from '@/utils/errorHandler';
import { parse } from '@/utils/helpers';
import { buildReceiptPageProducts } from '@/utils/products';
import { decodeURLVars } from '@/utils/urlVars';

const PayPalResponse = () => {
	const { t } = useTranslation(['checkout']);
	const [noGQLCallsHaveBeenMade, setNoGQLCallsHaveBeenMade] = useState(true);
	const [updatePayPalOrder] = useMutation(UPDATE_DIRECT_PAYPAL_ORDER);
	const [cancelPayPalOrder] = useMutation(CANCEL_DIRECT_PAYPAL_ORDER);
	const [fetchProducts] = useManualQuery(PRODUCTS);
	const {
		setSubmitOverlayVisible,
		setSessionToken,
		selectedLanguage,
		setOrderTotals,
		setReceiptPageData,
		setReceiptPageProducts,
		setCouponSuccessMessage,
		setErrorData,
		setPaymentMethod,
		setDisplayCurrency,
		payPalRebuildHandler,
	} = useStore(
		useShallow((state) => ({
			setSubmitOverlayVisible: state.setSubmitOverlayVisible,
			setRebuildCartFromSku: state.setRebuildCartFromSku,
			setSessionToken: state.setSessionToken,
			selectedLanguage: state.selectedLanguage,
			setOrderTotals: state.setOrderTotals,
			setReceiptPageData: state.setReceiptPageData,
			setReceiptPageProducts: state.setReceiptPageProducts,
			setCouponSuccessMessage: state.setCouponSuccessMessage,
			setErrorData: state.setErrorData,
			setPaymentMethod: state.setPaymentMethod,
			setDisplayCurrency: state.setDisplayCurrency,
			payPalRebuildHandler: state.payPalRebuildHandler,
		})),
	);

	const history = useHistory();
	const skus = [];
	const urlVars = decodeURLVars();
	const { order, token, PayerID, cancel } = urlVars;

	const variables = {
		order: order,
		token: token,
		payerId: PayerID,
	};

	const redirectToFormAndRebuildCart = (payload) => {
		// in the event we don't have the paylink, they are sent to the form with no url params and the modal will appear.
		if (!payload?.metadata?.payLink) return history.replace('/');

		const urlParams = payload.metadata.payLink.split('?')[1];
		const skuAndQuantity = [];

		// save skus and quantity in store so the previous state of the cart can be created
		payload.lineItems.forEach((item) => {
			const sku = item.productId.substring(item.productId.indexOf('-') + 1);
			const lineItemtemData = {
				sku: sku,
				quantity: item.quantity,
			};
			skuAndQuantity.push(lineItemtemData);
		});
		const rebuildData = {
			rebuildCartFromSku: skuAndQuantity,
			rebuildFormData: {
				fullName: payload.fullName,
				email: payload.email,
				phone: payload.phoneNumber,
				address1: payload.address.address1,
				address2: payload.address.address2,
				city: payload.address.city,
				state: payload.address.state,
				zip: payload.address.zip,
				countryCode: payload.address.countryCode,
				couponCode: payload.couponCode,
			},
		};
		const rebuildURLVars = decodeURLVars(`?${urlParams}`);
		payPalRebuildHandler(rebuildData, rebuildURLVars);
		history.push(`/?${urlParams}`);
	};

	const updatePayPal = async () => {
		const submitPayPal = await updatePayPalOrder({ variables: variables });
		if (submitPayPal.data?.updateDirectPayPalOrder) {
			const {
				receipt,
				purchasedProducts,
				sessionToken,
				timestamp,
				softDescriptor,
				calculatedCart,
				paymentMethod,
				tokenProvider,
				email,
				shippingAddress,
				editUrl,
				last4cc,
				fullName,
				cardHolderName,
				vendorId,
				currencyId,
				domain,
			} = submitPayPal.data.updateDirectPayPalOrder || { receipt: null };

			// redirect to the first redirect = true
			const redirect = purchasedProducts.find((product) => product.redirect === true);

			if (!redirect && calculatedCart.lineItems) {
				// if a coupon has been used save it in store so it shows up in orderTotals on the receipt
				const couponMessage = calculatedCart.discountTotal
					? t('field.coupon-code.success', {
							// Note: This translation for "Discount" is not happening for credit card orders
							// because we are able use the name of the coupon in that case.  If/when we are able
							// get the coupon code returned for paypal purchases, we can change to using that for the code.
							code: t('field.coupon-code.discount'),
							discount: calculatedCart.discountRate * 100,
						})
					: null;
				couponMessage && setCouponSuccessMessage(couponMessage);

				// save skus in state for fetchProducts
				purchasedProducts.forEach((sku) => {
					// removes the vendorId from the product id
					skus.push(sku.productId.substring(sku.productId.indexOf('-') + 1));
				});
				// get product data for the receipt
				const products =
					vendorId && skus
						? (
								await fetchProducts({
									variables: {
										vendorId: vendorId,
										skus: skus,
									},
								})
							).data.products
						: null;

				const hasPhysicalProduct = !!products.find((item) => {
					return item.type.includes('PHYSICAL');
				});

				const receiptPageProducts = [
					buildReceiptPageProducts(
						products,
						purchasedProducts,
						calculatedCart.lineItems,
						selectedLanguage,
					),
				];

				const receiptPageData = [
					{
						fullName: fullName,
						cardHolderName,
						email: email,
						address: shippingAddress,
						softDescriptor: softDescriptor,
						timestamp: timestamp,
						editUrl: editUrl,
						last4cc: last4cc,
						paymentMethod: paymentMethod,
						tokenProvider: tokenProvider,
						orderNumber: receipt,
						hasPhysicalProduct: hasPhysicalProduct,
						domain: domain,
					},
				];

				setReceiptPageData(receiptPageData);
				setReceiptPageProducts(receiptPageProducts);
				setOrderTotals([calculatedCart]);
				setSubmitOverlayVisible(false);
				setSessionToken(sessionToken);
				setDisplayCurrency(currencyId);
				history.replace('/order-received');
			} else {
				redirect.link && window.location.replace(redirect.link);
			}
		}

		if (submitPayPal.error) {
			const payload = parse(submitPayPal.error.graphQLErrors?.[0].extensions.payload) || null;
			setErrorData(errorHandler({ error: submitPayPal.error }, 'UPDATE_DIRECT_PAYPAL_ORDER'));
			setPaymentMethod('payPal');
			redirectToFormAndRebuildCart(payload);
		}
	};

	const cancelPayPal = async () => {
		const cancelPayPal = await cancelPayPalOrder({ variables: variables });
		const payload = parse(cancelPayPal?.data?.cancelDirectPayPalOrder?.payload) || null;
		redirectToFormAndRebuildCart(payload);
	};

	if (noGQLCallsHaveBeenMade) {
		setSubmitOverlayVisible(true);
		if (cancel) {
			cancelPayPal();
		} else {
			updatePayPal();
		}
		setNoGQLCallsHaveBeenMade(false);
	}

	return <ReceiptPageSkeleton />;
};

export default PayPalResponse;
