import { css } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Modal from '@mui/material/Modal';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import componentList from '@/components/layoutFactory/componentList';
import { WidgetNameMap } from '@/components/layoutFactory/layoutFactoryConstants';
import { useStore } from '@/state/stores';
import { cbNeutral, cbPrimary, checkoutTheme } from '@/theme';
import { exitOfferWidth } from '@/theme/checkoutTheme';
import { defaultBodyFontColor, defaultExitOfferBoxShadow } from '@/theme/palette';
import { isPreview } from '@/utils/helpers';

const validPrimaryButtonTranslationKeys = [
	'buy-now',
	'order-now',
	'purchase-now',
	'click-to-buy',
	'take-offer',
	'accept-offer',
	'yes-im-in',
].reduce((a, b) => {
	a[b] = true;
	return a;
}, {});

const styles = (exitOfferStyles) => {
	const {
		primaryButtonColor,
		primaryButtonTextColor,
		closeIconXColor,
		backgroundColor,
		buttonBorderRadius,
		buttonBorderWidth,
		buttonBorderColor,
		buttonFontFamily,
		borderRadius,
		borderSize,
		borderColor,
		boxShadow,
	} = exitOfferStyles;

	const btnBackgroundColor = primaryButtonColor || cbPrimary[500];
	const btnColors = checkoutTheme.palette.augmentColor({
		color: {
			main: btnBackgroundColor,
		},
	});

	return css`
		display: flex;
		justify-content: center;
		align-items: start;
		overflow-y: scroll;
		.container {
			position: relative;
			margin-top: 40px;
			width: ${exitOfferWidth};
			max-width: 816px;
			background-color: ${backgroundColor || cbNeutral[1000]};
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			border-radius: ${borderRadius || '8px'};
			border: ${borderSize || '1px'} solid ${borderColor || cbNeutral[900]};
			box-shadow: ${boxShadow || defaultExitOfferBoxShadow};
			max-height: min(560px, 90vh);
			${checkoutTheme.breakpoints.up('sm')} {
				max-height: min(860px, 90vh);
			}
			${checkoutTheme.breakpoints.up('lg')} {
				max-height: min(1002px, 90vh);
			}
			:focus-visible {
				outline: none;
			}
		}
		.close-icon-button {
			position: absolute;
			top: 5px;
			right: 5px;
			background-color: transparent;
		}
		.content-container {
			padding-top: 58px;
		}
		.button-container {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 24px 0;
		}
		.primary-btn {
			color: ${primaryButtonTextColor || cbNeutral[1000]};
			background-color: ${btnBackgroundColor};
			font-family: ${buttonFontFamily || 'inherit'};
			border-radius: ${buttonBorderRadius || 0};
			border-width: ${buttonBorderWidth || '4px'};
			border-color: ${buttonBorderColor || 'inherit'};
			border-style: solid;
			height: auto;
			white-space: normal;
			p {
				margin: 0;
			}
			:hover {
				background-color: ${btnColors.dark};
			}
		}

		.close-icon {
			color: ${closeIconXColor || defaultBodyFontColor};
		}
	`;
};

/**
 * This should only be used for custom CSS passed in from the exitOffer
 * @param { string } customCSS
 * @returns
 */
const customCss = (customCSS) => {
	return css`
		${customCSS}
	`;
};

/*
	ExitOffer is rendered if data for exit offer is available, and has several triggers:
		1. Mouse leaves the bounds of the window, such and Y coordinate in client is <= 0
		2. Window is scrolled up and down several times
		3. Visibility of tab changes to hidden
		4. If address bar on mobile expands after being minimized
*/
const ExitOffer = () => {
	const { t } = useTranslation(['checkout']);
	const { exitOffer, exitOfferActive, setExitOfferActive } = useStore(
		useShallow((state) => ({
			exitOffer: state.exitOffer,
			exitOfferActive: state.exitOfferActive,
			setExitOfferActive: state.setExitOfferActive,
		})),
	);

	// Exit offer should only ever trigger one time
	const exitOfferHasTriggered = useRef(false);

	const triggerExitOffer = useCallback(() => {
		exitOfferHasTriggered.current = true;
		setExitOfferActive(true);
	}, [setExitOfferActive]);

	// Effect to trigger exit offer when mouse leaves the bounds of the window, such and Y coordinate in client is <= 0
	useEffect(() => {
		if (isPreview) {
			triggerExitOffer();
		}
		const triggerExitOfferCheckOnMouseOut = (ev) => {
			if (exitOfferHasTriggered.current || ev.clientY >= 0) {
				return;
			}
			triggerExitOffer();
		};
		window.addEventListener('mouseout', triggerExitOfferCheckOnMouseOut);
		return () => {
			window.removeEventListener('mouseout', triggerExitOfferCheckOnMouseOut);
		};
	}, [triggerExitOffer]);

	// Effect to trigger exit offer when window is scrolled up and down several times
	// useEffect(() => {
	// 	let prevLocation = window.scrollY;
	// 	let scrollCount = 0;
	// 	// This value determines whether a downward or upward scroll should increment the above count.
	// 	// It is initialized as null to allow the initial scroll to be in either direction.
	// 	let shouldIncrementOnDownwardScroll = null;
	// 	const triggerExitOfferCheckOnScroll = () => {
	// 		if (exitOfferHasTriggered.current) {
	// 			return;
	// 		}
	// 		const currentLocation = window.scrollY;
	// 		if (shouldIncrementOnDownwardScroll === null) {
	// 			// Don't initialize directional logic until difference between starting location and current location is > 100px
	// 			if (Math.abs(prevLocation - currentLocation) >= 100) {
	// 				// Determine which direction the first scroll happened in, and set boolean accordingly
	// 				shouldIncrementOnDownwardScroll = currentLocation < prevLocation;
	// 				prevLocation = currentLocation;
	// 			}
	// 			return;
	// 		}
	// 		let eligibleScroll = false;
	// 		const hasTraveledDownward = currentLocation > prevLocation;
	// 		// If user continues traveling in the direction they've been going, update prevLocation
	// 		// to match their direction as they travel. Otherwise, check the difference between prev
	// 		// and current, and if > 100, increment counter and toggle directional boolean
	// 		if (shouldIncrementOnDownwardScroll) {
	// 			if (hasTraveledDownward) {
	// 				eligibleScroll = currentLocation > prevLocation + 100;
	// 			} else {
	// 				prevLocation = currentLocation;
	// 			}
	// 		} else {
	// 			if (hasTraveledDownward) {
	// 				prevLocation = currentLocation;
	// 			} else {
	// 				eligibleScroll = currentLocation < prevLocation - 100;
	// 			}
	// 		}
	// 		// Depending on if we are detecting upward or downward scroll, currentLocation must be either
	// 		// less than or greater than prevLocation, respectively. In either instance, the scroll must have
	// 		// traveled at least 100px to be counted.
	// 		if (eligibleScroll) {
	// 			// Increment scroll count, set prevLocation to current, and flip boolean
	// 			scrollCount++;
	// 			prevLocation = currentLocation;
	// 			shouldIncrementOnDownwardScroll = !shouldIncrementOnDownwardScroll;
	// 		}
	// 		// Require scrollCount to reach five before triggering
	// 		if (scrollCount >= 5) {
	// 			triggerExitOffer();
	// 		}
	// 	};
	// 	window.addEventListener('scroll', triggerExitOfferCheckOnScroll);
	// 	return () => {
	// 		window.removeEventListener('scroll', triggerExitOfferCheckOnScroll);
	// 	};
	// }, [triggerExitOffer]);

	// Effect to trigger exit offer when visibility of tab changes to hidden
	// useEffect(() => {
	// 	const triggerExitOfferCheckOnVisibilityChange = () => {
	// 		if (exitOfferHasTriggered.current) {
	// 			return;
	// 		}
	// 		if (document.visibilityState === 'hidden') {
	// 			triggerExitOffer();
	// 		}
	// 	};
	// 	document.addEventListener('visibilitychange', triggerExitOfferCheckOnVisibilityChange);
	// 	return () => {
	// 		document.removeEventListener(
	// 			'visibilitychange',
	// 			triggerExitOfferCheckOnVisibilityChange
	// 		);
	// 	};
	// }, [triggerExitOffer]);

	// Effect to trigger exit offer when address bar on mobile expands after being minimized.
	// 		NOTE: This logic is not limited to mobile for to cut down on complexity, but is
	// 		unlikely to trigger a false positive on desktop.
	// useEffect(() => {
	// 	// Grab initial height of window on page load
	// 	const initialHeight = window.innerHeight;
	// 	// Define boolean that indicates if address bar has already been minimized
	// 	let heightHasIncreased = false;
	// 	const triggerExitOfferCheckOnMobileResize = () => {
	// 		if (exitOfferHasTriggered.current) {
	// 			return;
	// 		}
	// 		// Address bar has been minimized, reducing height. Flip boolean.
	// 		if (!heightHasIncreased && window.innerHeight > initialHeight) {
	// 			heightHasIncreased = true;
	// 			return;
	// 		}
	// 		// If address bar was previously minimized, but now height has returned to initial,
	// 		// it has expanded once more. Trigger exit offer.
	// 		if (window.innerHeight === initialHeight && heightHasIncreased) {
	// 			triggerExitOffer();
	// 		}
	// 	};
	// 	window.addEventListener('resize', triggerExitOfferCheckOnMobileResize);
	// 	return () => {
	// 		window.removeEventListener('resize', triggerExitOfferCheckOnMobileResize);
	// 	};
	// }, [triggerExitOffer]);

	const customStyles = exitOffer.style?.attributes;

	const shouldDisplayTopRightCloseIcon =
		!customStyles?.closeButtonOption ||
		['Button & Icon "X"', 'Close Icon - "X"'].includes(customStyles?.closeButtonOption);

	const primaryButtonTextValue = customStyles?.primaryButtonText || 'buy-now';
	const primaryButtonShouldBeTranslated =
		validPrimaryButtonTranslationKeys[primaryButtonTextValue];
	const primaryButtonContent = primaryButtonShouldBeTranslated ? (
		t(`exit-offer.primary-button.${primaryButtonTextValue}`)
	) : (
		<div dangerouslySetInnerHTML={{ __html: primaryButtonTextValue }} />
	);

	const handleClose = () => setExitOfferActive(false);

	return (
		<div css={customCss(exitOffer.css || '')}>
			<Modal
				css={styles(customStyles || {})}
				open={exitOfferActive}
				keepMounted
				disablePortal
				id="CB-exit-offer-modal"
			>
				<div className="container " id="CB-exit-offer-container">
					{shouldDisplayTopRightCloseIcon ? (
						<IconButton
							aria-label="close"
							onClick={handleClose}
							className="close-icon-button "
							size="large"
							id="CB-close-icon-button"
						>
							<CloseIcon className="close-icon" id="CB-close-icon" />
						</IconButton>
					) : null}
					<div className="content-container" id="CB-module-container">
						{exitOffer.zones[0].widgets.map((widget) => {
							const mappedName = WidgetNameMap[widget.componentName];
							const Component = componentList[mappedName];
							return <Component key={widget.id} {...widget} />;
						})}
					</div>
					<div className="button-container " id="CB-button-container">
						{exitOffer.payLink ? (
							<Link href={exitOffer.payLink} data-testid="exit-offer-paylink">
								<Button
									variant="contained"
									color="primary"
									className="primary-btn"
									id="CB-button"
								>
									{primaryButtonContent}
								</Button>
							</Link>
						) : (
							<Button
								variant="contained"
								color="primary"
								className="primary-btn"
								onClick={handleClose}
								data-testid="exit-offer-close"
								id="CB-button"
							>
								{primaryButtonContent}
							</Button>
						)}
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default ExitOffer;
