// for nextBillingDate
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

import { Layouts, PaymentMethods } from './enums';

import 'dayjs/locale/de';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/pt';

dayjs.extend(LocalizedFormat);
const now = dayjs();

export const getNextBillingDate = (
	initialOffsetValue,
	selectedLanguage,
	frequency,
	frequencyType,
) => {
	return initialOffsetValue
		? now.add(initialOffsetValue, 'days').locale(selectedLanguage).format('LL')
		: now.add(frequency, frequencyType).locale(selectedLanguage).format('LL');
};
export const isDevEnv = process.env.NODE_ENV === 'development';

export const sortObjectsByLabelString = (objectWithLabelArray) => {
	if (!Array.isArray(objectWithLabelArray)) {
		console.error(
			`Invalid argument type of ${typeof objectWithLabelArray} passed to sortObjectsByLabelString`,
		);
		return;
	}

	const objectsWithValidLabelValue = objectWithLabelArray.filter((obj) => {
		const hasLabelValue = Boolean(obj.label) && typeof obj.label === 'string';
		if (!hasLabelValue) {
			console.error(
				'One or more objects given to sortOjectArrayByLabel does not have a "label" property',
			);
		}
		return hasLabelValue;
	});

	return objectsWithValidLabelValue.sort((a, b) => a.label.localeCompare(b.label));
};

export const parse = (string) => {
	let object;
	try {
		object = JSON.parse(string);
	} catch (error) {
		// for RUM
		console.error('Error parsing JSON:', JSON.stringify(error));
	}
	return object;
};

/**
 * Retrieves the full URL for a product image based on the provided file name.
 * The base URL is read from the environment variable REACT_APP_PRODUCT_IMAGE_URL.
 * @param {string} fileName - The name of the image file.
 * @returns {string|undefined} The full URL of the product image, or undefined if fileName is not provided.
 */
export const getProductImage = (fileName) => {
	if (!fileName) {
		console.error('fileName was not provided to getProductImage');
		return;
	}
	return process.env.REACT_APP_PRODUCT_IMAGE_URL + encodeURIComponent(fileName);
};

// from https://stackoverflow.com/a/9039885
// this detects pre-iOS 13
export const isiOS = () => {
	return [
		'iPad Simulator',
		'iPhone Simulator',
		'iPod Simulator',
		'iPad',
		'iPhone',
		'iPod',
	].includes(navigator.platform);
	// TODO: should the above be navigator?.platform || navigator?.userAgentData?.platform || 'unknown'????????
};

export const scrollUserToFormError = (formErrors) => {
	const labelNodes = document.querySelectorAll('.scrollable-label');

	// Return first rendered form field node that contains an error
	const firstErrorField = Array.from(labelNodes).find((node) => {
		return Array.from(Object.keys(formErrors)).find((errorKey) => {
			return node.htmlFor === errorKey;
		});
	});

	if (firstErrorField) {
		// Scroll to the first form field that has an error
		firstErrorField.scrollIntoView({
			behavior: 'smooth',
		});
	}
};

export const isPreview = process.env.REACT_APP_BASE_PATH === '/order-form/';

export const requiredEndpoints = [
	'AFFILIATE',
	'SITE_SETTINGS',
	'TOKEN_EX_IFRAME',
	'PRODUCTS',
	'GEO_CURRENCIES',
	'CALCULATE_CART',
];

if (isPreview) {
	requiredEndpoints.push('TEMPLATE');
}

export const stringIsEqualCaseInsensitive = (a, b) => {
	if (typeof a !== 'string' || typeof b !== 'string') {
		return a === b;
	}
	return a.toLowerCase() === b.toLowerCase();
};

// Allows attempting to read local storage without triggering uncaught error in
// environment where privacy settings prevent access.
export const localStorageSafeRead = (key) => {
	try {
		return localStorage.getItem(key);
	} catch (err) {
		return null;
	}
};

export const disableEmotionFirstChildWarning = () => {
	if (!isDevEnv) {
		return;
	}
	/* eslint-disable no-console */
	const log = console.error.bind(console);
	console.error = (...args) => {
		/* eslint-enable no-console */
		for (let i = 0; i < args.length; i++) {
			if (args[i] && args[i]?.includes && args[i].includes(':first-child')) {
				return;
			}
		}
		log(...args);
	};
};

export const focusNextTabbableNode = (target) => {
	// This will not include all tabbable nodes in the DOM, so any potential items needed
	// to move focus must have tabindex set explicitly to 0 or 1.
	const positiveTabIndex = Array.from(document.querySelectorAll('[tabindex="1"]'));
	const standardTabIndex = Array.from(document.querySelectorAll('[tabIndex="0"]'));
	const tabItems = positiveTabIndex.concat(standardTabIndex);
	const nodeIndex = tabItems.indexOf(target);
	if (nodeIndex !== -1 && tabItems[nodeIndex + 1]) {
		// Redirect focus to next focusable item in DOM to prevent focus being directed to body on unfocus
		tabItems[nodeIndex + 1].focus();
	}
};

export const listCommonCountriesFirst = (countries, t) => {
	if (!Array.isArray(countries)) {
		return countries;
	}
	const commonCountries = ['US', 'GB', 'CA', 'AU', 'FR', 'DE', 'NZ', 'MX', 'ES'];
	const copy = [...countries];
	const commonCountryOptions = copy
		.filter((data) => commonCountries.includes(data.value))
		.sort((a, b) => {
			const aCountryCode = a.value;
			const bCountryCode = b.value;
			const aIndex = commonCountries.indexOf(aCountryCode);
			const bIndex = commonCountries.indexOf(bCountryCode);
			return aIndex - bIndex;
		});
	const otherCountryOptions = copy.sort((a, b) => {
		return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
	});
	const noCommonCountries = commonCountryOptions.length === 0;
	const noOtherCountries = commonCountryOptions.length === copy.length;
	const allOptions = commonCountryOptions.concat(otherCountryOptions);
	if (noCommonCountries || noOtherCountries || allOptions.length < 20) {
		return { options: otherCountryOptions };
	}
	return {
		options: allOptions,
		subheaders: [
			{ start: 0, end: commonCountryOptions.length, label: t('field.country.common') },
			{
				start: commonCountryOptions.length,
				end: allOptions.length,
				label: t('field.country.other'),
			},
		],
	};
};

export const cartIncludesRecurring = (cartStatus) => {
	return !!cartStatus.find((item) => item.isActive === true && item.isSubscription === true);
};

/**
 * Show the immediate access message in the cart only if there are no physical products in the cart, excluding bumps
 * @param {*} allProducts
 * @returns boolean
 */

export const formHasPhysicalProducts = (allProducts) => {
	// return true to avoid showing the message before the cart is loaded
	if (!allProducts) return true;

	return Boolean(
		allProducts.find((product) => {
			return !product.isBump && product.type.toLowerCase().includes('physical');
		}),
	);
};

/**
 * Returns boolean indicating if color argument is one of:
 *   - undefined (i.e. not present when accessed)
 *   - The literal string 'transparent'
 *   - A hex code with a fully transparent alpha
 * @param {string | undefined} color
 * @returns boolean
 */
export const colorIsAbsentOrTransparent = (color) => {
	if (color === undefined || color === 'transparent') {
		return true;
	}
	if (typeof color !== 'string') {
		throw new Error(
			'Non-string value passed to colorIsAbsentOrTransparent: ',
			typeof color === 'object' ? JSON.stringify(color) : color,
		);
	}
	if (color[0] === '#') {
		if (color.length === 9 && color.slice(-2) === '00') {
			return true;
		}
		if (color.length === 5 && color.slice(-1) === '0') {
			return true;
		}
	}
	return false;
};

export const logSplunkRumError = (msg) => {
	if (!window.SplunkRum || typeof window.SplunkRum.error !== 'function') {
		return;
	}
	window.SplunkRum.error(msg);
};

export const closeOverlayAndSetErrorData = (
	errorMessage,
	setSubmitOverlayVisible,
	setErrorData,
) => {
	setSubmitOverlayVisible(false);
	setErrorData(errorMessage);
};

export const cartIncludesPhysical = (lineItems, allProducts) => {
	if (!lineItems || !allProducts) {
		return;
	}
	return Boolean(
		lineItems.find((item) => {
			return allProducts.find((product) => {
				return item.productId === product.id && product.physical;
			});
		}),
	);
};

/**
 * @param { string } str - A string that will have each character checked to ensure all characters are valid in hexadecimal
 * @returns { boolean } A boolean representing whether invalid characters were found
 */
export const stringHasInvalidHexCharacters = (str) => {
	if (typeof str !== 'string') {
		return true;
	}
	for (let i = 0; i < str.length; i++) {
		if (Number.isNaN(parseInt(str[i], 16))) {
			return true;
		}
	}
	return false;
};

/**
 * @description - Accepts input of hex length 3, 4, 6, or 8 and converts to hex of length 6 or 8
 * @param {string} str - String which should be valid hex code of length 3, 4, 6, or 8.
 * @returns Valid hex code of length 6 or 8
 * @throws If passed non-string, non-hex argument
 */
export const standardizeHexLength = (str) => {
	if (
		typeof str !== 'string' ||
		str[0] !== '#' ||
		str.length > 9 ||
		stringHasInvalidHexCharacters(str.slice(1))
	) {
		throw str;
	}
	let hex = str;
	if (hex.length === 4 || hex.length === 5) {
		const splitHex = hex.split('');
		for (let i = 1; i < splitHex.length; i += 2) {
			splitHex.splice(i + 1, 0, splitHex[i]);
		}
		hex = splitHex.join('');
	}
	return hex;
};

/**
 * @param { string } str - A hex-code (including leading #) that will be parsed into an RGBA object
 * @returns { object | null } An object with r, g, b, and a keys representing color
 */
export const hexToRgba = (str) => {
	const result = {
		r: 0,
		g: 0,
		b: 0,
		a: 1,
	};
	try {
		// parseInt behaves strangely when encountering invalid characters, so we

		// check for the existence of these characters in advance
		const hex = standardizeHexLength(str);
		if (hex.length !== 7 && hex.length !== 9) {
			throw str;
		}
		result.r = parseInt(hex.slice(1, 3), 16);
		result.g = parseInt(hex.slice(3, 5), 16);
		result.b = parseInt(hex.slice(5, 7), 16);
		const hasAlpha = hex.length === 9;
		if (hasAlpha) {
			result.a = Number((parseInt(hex.slice(7, 9), 16) / 255).toFixed(2));
		}
		if (Object.values(result).some((n) => Number.isNaN(n))) {
			throw str;
		}
		return result;
	} catch (err) {
		console.error(`Bad hex: ${err}`);
		return result;
	}
};

// source: https://awik.io/determine-color-bright-dark-using-javascript/
export const getLightOrDark = (color) => {
	//  Convert it to RGB: http://gist.github.com/983661
	color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

	const r = color >> 16;
	const g = (color >> 8) & 255;
	const b = color & 255;

	// HSP equation from http://alienryderflex.com/hsp.html
	const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

	// Using the HSP value, determine whether the color is light or dark
	if (hsp > 127.5) {
		return 'light';
	} else {
		return 'dark';
	}
};

export const getContrastingColorFromBackground = (hex) => {
	const lightOrDark = getLightOrDark(hex);
	const rgb = hexToRgba(hex);
	let { r, g, b } = rgb;
	r /= 255;
	g /= 255;
	b /= 255;
	const max = Math.max(r, g, b);
	const min = Math.min(r, g, b);
	let h;
	let s;
	let l = (max + min) / 2;
	if (max === min) {
		h = 0;
		s = 0;
	} else {
		const d = max - min;
		s = (() => {
			if (l > 0.5) {
				return d / (2 - max - min);
			}
			return d / (max + min);
		})();
		switch (max) {
			case r:
				h = (g - b) / d + (g < b ? 6 : 0);
				break;
			case g:
				h = (b - r) / d + 2;
				break;
			case b:
				h = (r - g) / d + 4;
				break;
			default:
				throw new Error('Should be unreachable');
		}
		h /= 6;
	}
	s = Math.round(s * 100);
	h = Math.round(360 * h);
	l = lightOrDark === 'light' ? 20 : 80;

	return `hsl(${h}, ${s}%, ${l}%)`;
};

export const preloadImageSrc = (() => {
	const loadedImages = {};
	return (src) => {
		if (typeof src !== 'string' || loadedImages[src]) {
			return null;
		}
		const node = document.createElement('link');
		node.setAttribute('rel', 'preload');
		node.setAttribute('href', src);
		node.setAttribute('as', 'image');
		document.head.appendChild(node);
		loadedImages[src] = true;
		return src;
	};
})();

const countriesWithStates = ['AU', 'MY', 'MX', 'US']; // Australia, Malaysia, Mexico, United States

const countriesWithProvinces = ['CA', 'IT', 'RU', 'CN']; // Canada, Italy, Russia, China

export const doesCountryHaveStates = (countryCode) => {
	return countriesWithStates.includes(countryCode);
};

export const doesCountryHaveProvinces = (countryCode) => {
	return countriesWithProvinces.includes(countryCode);
};

export const capitalizeWords = (str) => {
	if (!str) {
		return;
	}
	const words = str.split(' ');
	return words
		.map((word) => {
			return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
		})
		.join(' ');
};

export const isTemplateJoined = (template) => {
	if (!template) {
		throw new Error('No template provided to isTemplateJoined');
	}

	return (
		[
			Layouts.JOINED,
			Layouts.JOINED_INPUT_LEFT_BUTTON_LEFT,
			Layouts.JOINED_INPUT_RIGHT_BUTTON_RIGHT,
		].includes(template.layout) || template.style?.attributes?.zoneSeparation === 'connected'
	);
};

/**
 * @description - Checks if the payment method is digital (Apple Pay or Paze)
 * @param {enum} paymentMethod - one of {@link PaymentMethods}
 */
export const isDigitalPaymentMethod = (paymentMethod) => {
	return paymentMethod === PaymentMethods.APPLE_PAY || paymentMethod === PaymentMethods.PAZE;
};

export const leftFillNum = (num, targetLength) => {
	return num.toString().padStart(targetLength, '0');
};
