import { useManualQuery } from 'graphql-hooks';
import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { TOKEN_EX_IFRAME } from './queries';
import { useStore } from '@/state/stores';
import { usePostMountEffect } from '@/utils/hooks';

// This is needed to get a new config when the current one expires
// This also triggers a refresh of the iframe which expires every 20 min
const reloadTime = 19.75 * 60 * 1000; //19 min 45 sec

export const TokenExLoader = ({ allQueriesData, queryFetcher }) => {
	const { urlVars, setTokenExConfig, refreshTokenEx, triggerRefreshTokenEx } = useStore(
		useShallow((state) => ({
			urlVars: state.urlVars,
			setTokenExConfig: state.setTokenExConfig,
			refreshTokenEx: state.refreshTokenEx,
			triggerRefreshTokenEx: state.triggerRefreshTokenEx,
		})),
	);

	useEffect(() => {
		const refreshTokenExInfo = () => {
			triggerRefreshTokenEx();
			setTimeout(refreshTokenExInfo, reloadTime);
		};
		setTimeout(refreshTokenExInfo, reloadTime);
	}, [triggerRefreshTokenEx]);

	const [fetchQuery] = useManualQuery(TOKEN_EX_IFRAME, {
		variables: {
			vendorId: urlVars.vvvv,
		},
	});

	// useEffect will run if allQueriesData.TOKEN_EX_IFRAME changes
	usePostMountEffect(() => {
		if (allQueriesData.TOKEN_EX_IFRAME?.tokenExIframe?.config) {
			setTokenExConfig(allQueriesData.TOKEN_EX_IFRAME.tokenExIframe.config);
		}
	}, [allQueriesData.TOKEN_EX_IFRAME, setTokenExConfig]);

	// useEffect will run after an update to productSKUs
	usePostMountEffect(() => {
		queryFetcher({
			targetQuery: 'TOKEN_EX_IFRAME',
			required: true,
			loadFunction: fetchQuery,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refreshTokenEx, fetchQuery]);

	return null;
};
