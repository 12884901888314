import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';

import './i18n';

import UpdateOverlay from './components/common/UpdateOverlay';
import getFeatureFlagsQuery from './graphql/initialFetch/featureFlags';
import fetchInitialData from './graphql/initialFetch/fetchInitialData';
import prependFetchKeys from './graphql/initialFetch/prependFetchKeys';
import getTemplateQuery from './graphql/initialFetch/template';
import { disableEmotionFirstChildWarning } from './utils/helpers';
import { decodeURLVars } from './utils/urlVars';

window.addEventListener('pageshow', (event) => {
	if (event.persisted) {
		window.location.reload();
	}
});

// mutes the emotion warning because the of the use of the first-child css selector
disableEmotionFirstChildWarning();

// Promise.allSettled polyfill
Promise.allSettled =
	Promise.allSettled ||
	((promises) =>
		Promise.all(
			promises.map((p) =>
				p
					.then((value) => ({
						status: 'fulfilled',
						value,
					}))
					.catch((reason) => ({
						status: 'rejected',
						reason,
					})),
			),
		));

const urlVars = decodeURLVars();
let templatePromise = null;
let dataFetchPromise = null;
let featureFlagsPromise = null;
if (urlVars.vvvv) {
	// Need to know vendor for this to be valid.
	// If absent, fetch will be being called elsewhere (e.g. in Effect for paypal rebuild)
	templatePromise = prependFetchKeys(urlVars).then(getTemplateQuery);
	dataFetchPromise = prependFetchKeys(urlVars).then(fetchInitialData());
	featureFlagsPromise = prependFetchKeys(urlVars).then(getFeatureFlagsQuery);
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	<Router>
		<Suspense fallback={<UpdateOverlay visible />}>
			<App
				dataFetchPromise={dataFetchPromise}
				templatePromise={templatePromise}
				featureFlagsPromise={featureFlagsPromise}
			/>
		</Suspense>
	</Router>,
);
