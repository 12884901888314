import PropTypes from 'prop-types';
import { createContext, useRef } from 'react';

export const BumpSelectionContext = createContext({});

export const BumpSelectionContextProvider = ({ children }) => {
	const triggeringBumpsQueue = useRef([]);
	return (
		<BumpSelectionContext.Provider value={{ triggeringBumpsQueue }}>
			{children}
		</BumpSelectionContext.Provider>
	);
};

BumpSelectionContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
