// Branding vars. Can be exported for individual import or might append to checkoutTheme below to be accessed via Material UI theming

export const cbNeutral = {
	1000: '#FFFFFF',
	990: '#FCFCFD',
	950: '#F1F1F4',
	900: '#E3E3E8',
	800: '#C8C7D1',
	700: '#ACABBA',
	550: '#807E9A',
	400: '#54527A',
	300: '#3F3D5C',
	100: '#0E0D26',
};

export const cbPrimary = {
	970: '#F1F3FD',
	950: '#E8EBFC',
	900: '#D1D8FA',
	800: '#A3B1F5',
	680: '#6C82EF',
	500: '#2C20DF',
	320: '#10069F',
	180: '#090358',
};

export const cbPositive = {
	870: '#E4F2CC',
	370: '#A4BF00',
	170: '#00551E',
};

export const cbIneligible = {
	930: '#FFE8D8',
	560: '#F97224',
	250: '#802C00',
};

export const cbEscalated = {
	900: '#FFEFCC',
	500: '#FFAF01',
	200: '#664600',
};

export const cbUnfinished = {
	900: '#D4F1F7',
	400: '#00A9CD',
	200: '#005466',
};

export const cbNegative = {
	950: '#FCE8EB',
	500: '#D9263E',
	280: '#810E1D',
};

export const cbEvaluation = {
	950: '#F7E8FC',
	400: '#871AB3',
	250: '#551070',
};

export const palette = {
	common: {
		black: '#000000',
		white: '#FFFFFF',
	},
	primary: {
		light: cbPrimary[950],
		main: cbPrimary[500],
		dark: cbPrimary[320],
		contrastText: '#fff',
	},
	secondary: {
		main: cbIneligible[560],
		dark: cbNeutral[300],
	},
	neutral: {
		main: cbNeutral[100],
	},
	error: {
		light: cbNegative[950],
		main: cbNegative[280],
		dark: cbNegative[280],
	},
	action: {
		hover: cbPrimary[950],
		selected: cbPrimary[320],
	},
	text: {
		primary: cbNeutral[300],
		secondary: cbNeutral[400],
		disabled: cbNeutral[700],
		hint: cbNeutral[300],
	},
};

export const cbBrand = {
	logoGrey: palette.secondary.light,
	logoBlue: cbPrimary[320],
	blue: cbPrimary[320],
	border: palette.secondary.light,
	borderHover: cbNeutral[100],
};

// !Note if any of these values are changed they also need to be updated in order-customizer-ui
export const templateBackgroundColor = cbNeutral[990];
export const defaultCBHeaderBackgroundColor = cbNeutral[1000];
export const defaultTermsGuaranteeBackgroundColor = cbNeutral[300];
export const defaultTermsGuaranteeFontColor = palette.common.white;
export const defaultCopyrightBackgroundColor = cbNeutral[100];
export const defaultCopyrightFontColor = palette.common.white;
export const defaultPanelBackgroundColor = palette.common.white;
export const defaultButtonBackgroundColor = cbPrimary[500];
export const defaultRedButtonBackgroundColor = '#e5202e';
export const defaultButtonOutlineColor = 'transparent';
export const defaultButtonTextColor = palette.common.white;
export const defaultBorderColor = cbNeutral[700];
export const defaultPanelLabelColor = cbNeutral[400];
export const defaultPanelHeadingColor = palette.text.primary;
export const defaultBodyFontColor = palette.text.secondary;
export const defaultHeadingFontColor = cbNeutral[300];
export const defaultButtonColor = palette.common.white;
export const alternateLanguageCurrencyColor = '#f1f1f3';
export const defaultPanelBorderColor = cbNeutral[900];
export const defaultPanelBoxShadow = '0px 4px 8px rgba(14, 13, 38, 0.16)';
export const defaultInputLabelFontWeight = 700;
export const defaultInputValueFontWeight = 400;
export const defaultInputBorderColor = cbNeutral[700];
export const defaultInputFocusBorderColor = cbPrimary[500];
export const defaultInputBackgroundColor = cbNeutral[1000];
export const defaultCouponBorderAndFontColor = cbNeutral[300];
export const defaultDisabledCouponBorderAndFontColor = cbNeutral[700];
export const defaultDividerColor = '#C8C7D1';
export const defaultBumpBackgroundColor = cbNeutral[950];
export const defaultBumpBorderColor = '#00000000';
export const defaultSecureBannerBackgroundColor = cbNeutral[900];
export const defaultSecureBannerFontColor = cbNeutral[300];

// SHADOWS

export const defaultTextFieldShadow = 'drop-shadow(0px 0px 4px rgba(44, 32, 223, 0.5))'; // defaultTextFieldShadow must be applied to the filter prop
export const defaultExitOfferBoxShadow = '0px 12px 24px rgba(14, 13, 38, 0.08)';
