const SUBMIT_ORDER = `
    mutation submitOrderMutation(
        $gdpr: GdprOrderForm
        $address: AddressOrderForm
        $shippingAddress: AddressOrderForm
        $lineItems: [OrderFormLineItem!]!
        $fullName: String
        $email: String!
        $vendorId: String!
        $currencyId: String!
        $couponCode: String
        $cardHolderName: String!
        $cvv: String!
        $cardTokenHash: String!
        $cardToken: String!
        $cartTotal: String!
        $expirationDate: String!
        $urlParams: String!
        $locale: String!
        $phone: String
        $kountSid: String!
    ) {
        submitOrder(
            gdpr: $gdpr
            address: $address
            shippingAddress: $shippingAddress
            lineItems: $lineItems
            fullName: $fullName
            cardHolderName: $cardHolderName
            email: $email
            vendorId: $vendorId
            couponCode: $couponCode
            currencyId: $currencyId
            cvv: $cvv
            cardTokenHash: $cardTokenHash
            cardToken: $cardToken
            cartTotal: $cartTotal
            expirationDate: $expirationDate
            urlParams: $urlParams
            locale: $locale
            phone: $phone
            kountSid: $kountSid
        ) {
            vendorId
            paymentMethod
			tokenProvider
            fullName
            cardHolderName
            receipt
            softDescriptor
            sessionToken
            timestamp
            email
            domain
            billingAddress {
                countryCode
                zip
                city
                state
                address1
                address2
            }
            shippingAddress {
                countryCode
                zip
                city
                state
                address1
                address2
            }
            editUrl
            last4cc
            calculatedCart {
                lineItems {
                    productId
                    quantity
                    unitPrice
                    amount
                    recurringUnitPrice
                    recurringAmount
                    discountAmount
                }
                shippingAmount
                subtotal
                discountTotal
                discountRate
                total
                tax
            }
            purchasedProducts {
                link
                productId
                redirect
            }
        }
    }
`;

const REQUEST_DIRECT_PAYPAL_ORDER = `
	mutation requestDirectPayPalOrderMutation(
		$gdpr: GdprOrderForm
		$address: AddressOrderForm
		$lineItems: [OrderFormLineItem!]!
		$fullName: String
		$email: String!
		$vendorId: String!
		$couponCode: String
		$currencyId: String!
		$cartTotal: String!
		$urlParams: String!
		$locale: String!
		$phone: String
		$kountSid: String!
	) {
		requestDirectPayPalOrder(
			gdpr: $gdpr
			address: $address
			lineItems: $lineItems
			fullName: $fullName
			email: $email
			vendorId: $vendorId
			couponCode: $couponCode
			currencyId: $currencyId
			cartTotal: $cartTotal
			urlParams: $urlParams
			locale: $locale
			phone: $phone
			kountSid: $kountSid
		) {
			url
		}
	}
`;

const UPDATE_DIRECT_PAYPAL_ORDER = `
	mutation updateDirectPayPalOrderMutation($order: String!, $token: String!, $payerId: String) {
		updateDirectPayPalOrder(order: $order, token: $token, payerId: $payerId) {
			vendorId
			receipt
			currencyId
			fullName
			cardHolderName
			paymentMethod
			tokenProvider
			softDescriptor
			sessionToken
			timestamp
			email
			domain
			billingAddress {
				countryCode
				zip
				city
				state
				address1
				address2
			}
			shippingAddress {
				countryCode
				zip
				city
				state
				address1
				address2
			}
			editUrl
			last4cc
			calculatedCart {
				lineItems {
					productId
					quantity
					unitPrice
					amount
					recurringUnitPrice
					recurringAmount
					discountAmount
				}
				shippingAmount
				subtotal
				discountTotal
				discountRate
				total
				tax
			}
			purchasedProducts {
				link
				productId
				redirect
			}
		}
	}
`;

const CANCEL_DIRECT_PAYPAL_ORDER = `
	mutation cancelDirectPayPalOrderMutation($order: String!, $token: String!) {
		cancelDirectPayPalOrder(order: $order, token: $token) {
			payload
		}
	}
`;

const ABANDON_ORDER = `
	mutation abandonOrderMutation(
		$address: AddressOrderForm
		$lineItems: [CalculateOrderLineItem!]
		$fullName: String
		$email: String
		$phone: String
		$vendorId: String!
		$couponCode: String
		$currencyId: String!
		$urlParams: String!
		$locale: String!
	) {
		abandonOrder(
			address: $address
			lineItems: $lineItems
			fullName: $fullName
			email: $email
			phone: $phone
			vendorId: $vendorId
			couponCode: $couponCode
			currencyId: $currencyId
			urlParams: $urlParams
			locale: $locale
		)
	}
`;

const APPLE_PAY_CREATE_SESSION = `
	mutation applePayCreateSessionMutation($validationURL:String!){
		createPaymentSession(type: "apple", validationURL: $validationURL) {
			epochTimestamp
			expiresAt
			merchantSessionIdentifier
			nonce
			merchantIdentifier
			domainName
			displayName
			signature
			operationalAnalyticsIdentifier
			retries
		}
	}
`;

const SUBMIT_DIGITAL_PAYMENT = `
	mutation submitDigitalPaymentMutation(
		$appleToken: AppleToken
		$pazeToken: PazeToken
		$gdpr: GdprOrderForm
		$kountSid: String!
		$address: AddressOrderForm
		$shippingAddress: AddressOrderForm
		$lineItems: [OrderFormLineItem!]!
		$fullName: String
		$email: String!
		$phone: String
		$vendorId: String!
		$currencyId: String!
		$couponCode: String
		$cardHolderName: String
		$urlParams: String!
		$locale: String!
		$cartTotal: String
	)  {
		submitDigitalPayment(
			appleToken: $appleToken
			pazeToken: $pazeToken
			gdpr: $gdpr
			address: $address
			shippingAddress: $shippingAddress
			lineItems: $lineItems
			fullName: $fullName
			cardHolderName: $cardHolderName
			email: $email
			vendorId: $vendorId
			couponCode: $couponCode
			currencyId: $currencyId
			urlParams: $urlParams
			locale: $locale
			phone: $phone
			kountSid: $kountSid
			cartTotal: $cartTotal
		) {
			vendorId
			paymentMethod
			tokenProvider
			fullName
			cardHolderName
			receipt
			softDescriptor
			sessionToken
			timestamp
			email
			domain
			billingAddress {
				countryCode
				zip
				city
				state
				address1
				address2
			}
			shippingAddress {
				countryCode
				zip
				city
				state
				address1
				address2
			}
			editUrl
			last4cc
			calculatedCart {
				lineItems {
					productId
					quantity
					unitPrice
					amount
					recurringUnitPrice
					recurringAmount
					discountAmount
				}
				shippingAmount
				subtotal
				discountTotal
				discountRate
				total
				tax
			}
			purchasedProducts {
				link
				productId
				redirect
			}
		}
}
`;

export const SEND_ORDER_IMPRESSION = `
	mutation sendOrderImpressionQuery(
		$address: AddressOrderForm
		$lineItems: [CalculateOrderLineItem!]!
		$vendorId: String!
		$currencyId: String!
		$couponCode: String
		$formLoadTime: String
		$availableItems: [CalculateOrderLineItem!]
		$urlParams: String
	) {
		sendOrderImpression(
			address: $address
			lineItems: $lineItems
			vendorId: $vendorId
			currencyId: $currencyId
			couponCode: $couponCode
			formLoadTime: $formLoadTime
			availableItems: $availableItems
			urlParams: $urlParams
		)
	}`;

export {
	SUBMIT_ORDER,
	REQUEST_DIRECT_PAYPAL_ORDER,
	UPDATE_DIRECT_PAYPAL_ORDER,
	CANCEL_DIRECT_PAYPAL_ORDER,
	ABANDON_ORDER,
	APPLE_PAY_CREATE_SESSION,
	SUBMIT_DIGITAL_PAYMENT,
};
