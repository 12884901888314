import { ClientContext, useManualQuery } from 'graphql-hooks';
import { useContext, useEffect } from 'react';

import { useStore } from '@/state/stores';

const LOGIN_QUERY = `
	query loginUser($username: String!, $password: String!) {
		authorize(username: $username, password: $password)
	}
`;

const DevEnvLogin = () => {
	const { urlVars } = useStore((state) => state.urlVars);

	const client = useContext(ClientContext);
	const password = process.env.REACT_APP_DEV_USER_PASS;
	const username = urlVars?.vvvv;
	const [login] = useManualQuery(LOGIN_QUERY);
	useEffect(() => {
		if (username) {
			(async () => {
				const { data, error } = await login({
					variables: { username, password },
				});

				if (error) {
					console.error(
						'Error getting token for fetching templates locally',
						JSON.stringify(error),
					);
					return;
				}
				// If no error, we should setHeader on the client
				client.setHeader('Authorization', `Bearer ${data.authorize}`);
			})();
		}
	}, [username, client, password, login]);

	return null;
};

export default DevEnvLogin;
