import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import {
	CalculateCartLoader,
	ExitOfferLoader,
	GeoCurrenciesLoader,
	TokenExLoader,
	TrackingPixelsLoader,
	UpsellLoader,
} from './';
import { LoadErrorModal } from '@/components';
import { useStore } from '@/state/stores';
import { errorHandler } from '@/utils/errorHandler';
import { isPreview } from '@/utils/helpers';
import { usePostMountEffect } from '@/utils/hooks';

/**
 * stores number of times any endpoint retries.
 * third retry triggers error message if needed, then that endpoint's count is reset
 */
const retryCount = {};
/**
 * @param {number} status
 * @returns {boolean} boolean that indicates whether the error is a 5xx or other server error
 */
const isServerError = (status) => {
	if (status) {
		return status >= 500;
	} else {
		return true;
	}
};

const basePath = process.env.REACT_APP_BASE_PATH ? process.env.REACT_APP_BASE_PATH : '/';

export const GraphQLLoader = () => {
	const {
		showErrorModal,
		setHasExitOfferQueryFailed,
		setShowErrorModal,
		modalErrorMessage,
		setModalErrorMessage,
		formServerErrorInfo,
		setFormServerErrorInfo,
		errorData,
		setErrorData,
		urlVars,
		setHasTemplateQueryData,
		initialGQLData,
	} = useStore(
		useShallow((state) => ({
			togglePreloader: state.togglePreloader,
			showErrorModal: state.showErrorModal,
			setHasExitOfferQueryFailed: state.setHasExitOfferQueryFailed,
			setShowErrorModal: state.setShowErrorModal,
			modalErrorMessage: state.modalErrorMessage,
			setModalErrorMessage: state.setModalErrorMessage,
			formServerErrorInfo: state.formServerErrorInfo,
			setFormServerErrorInfo: state.setFormServerErrorInfo,
			errorData: state.errorData,
			setErrorData: state.setErrorData,
			setBadProducts: state.setBadProducts,
			urlVars: state.urlVars,
			setIsShippable: state.setIsShippable,
			setHasTemplateQueryData: state.setHasTemplateQueryData,
			setShowPhone: state.setShowPhone,
			initialGQLData: state.initialGQLData,
		})),
	);

	const [allQueriesData, setAllQueriesData] = useState({});
	const requiredEndpoints = useRef([]);
	const [handleErrorData, setHandleErrorData] = useState(false);

	const location = useLocation();

	useEffect(() => {
		if (initialGQLData && initialGQLData.CALCULATE_CART) {
			setAllQueriesData(initialGQLData);
		}
	}, [initialGQLData]);

	// if the app can run in spite of the endpoint failing,
	// log the failure, remove the preloader, reset retry count
	// and set a value for the endpoint's data indicating the failure
	const failSilently = useCallback(
		(error, targetQuery) => {
			//for RUM
			console.error('silent failure:', JSON.stringify({ error: error, query: targetQuery }));

			const optionalErrorMessage = 'Optional GQL error: ' + targetQuery + ' query';
			console.warn(optionalErrorMessage, JSON.stringify(error));

			setAllQueriesData((previousState) => {
				return {
					...previousState,
					...{
						[targetQuery]: -1,
					},
				};
			});
			retryCount[targetQuery] = undefined;
		},
		[setAllQueriesData],
	);

	const queryFetcher = async (params) => {
		const { targetQuery, required, loadFunction, retry } = params;

		// track the required endpoints in an array so we can track when all required items are loaded
		if (!retry && required) {
			const currentEndpoint = requiredEndpoints.current.find(
				(endpoint) => endpoint.targetQuery === targetQuery,
			);
			if (currentEndpoint) {
				currentEndpoint.loaded = false;
			} else {
				requiredEndpoints.current.push({
					targetQuery,
					required,
					loaded: false,
				});
			}
		}

		const handleGQLError = (error, params) => {
			const { targetQuery, required } = params;
			const status = error?.graphQLErrors?.[0]?.extensions?.status;
			const shouldRetry = isServerError(status);

			// increment the endpoint's retry count, then retry or handle the failure
			if (shouldRetry) {
				retryCount[targetQuery] = !retryCount?.[targetQuery]
					? 1
					: retryCount[targetQuery] + 1;
			}

			if (shouldRetry && retryCount?.[targetQuery] <= 2) {
				console.warn('Trying: ' + targetQuery + ' again...');
				const waitTime = retryCount[targetQuery] === 1 ? 0 : 2000;
				setTimeout(() => {
					params.retry = true;
					queryFetcher(params);
				}, waitTime);
			} else if (required) {
				if (targetQuery === 'TOKEN_EX_IFRAME') {
					window.location.reload();
				} else {
					setErrorData(errorHandler({ error }, targetQuery));
				}
			} else {
				failSilently(error, targetQuery);
				if (targetQuery === 'EXIT_OFFER') {
					setHasExitOfferQueryFailed(true);
				}
			}
		};

		const gqlQuery = await loadFunction();

		if (gqlQuery.error) {
			const { fetchError } = gqlQuery.error;
			if (fetchError instanceof DOMException && fetchError.message.includes('abort')) {
				// Fetch was aborted - another is in progress - do nothing
				return;
			}
			// pass the query params so the error function can retry as needed
			handleGQLError(gqlQuery.error, params);
		}

		if (gqlQuery.data) {
			setAllQueriesData((previousState) => {
				return {
					...previousState,
					...{
						[targetQuery]: gqlQuery.data,
					},
				};
			});
		}

		// error and data are not mutually exclusive.
		// if we have data and no error, we can remove the preloader.
		// if there is an error, we leave the preloader and let the error handler determine what to do
		if (gqlQuery.data && !gqlQuery.error) {
			if (targetQuery === 'TEMPLATE') {
				setHasTemplateQueryData(true);
			}

			if (required) {
				requiredEndpoints.current.find(
					(endpoint) => endpoint.targetQuery === targetQuery,
				).loaded = true;
			}
		}
	};

	usePostMountEffect(() => {
		if (handleErrorData) {
			if (errorData) {
				if (errorData.messageType === 'modal') {
					!modalErrorMessage && setModalErrorMessage(errorData.message);
					setShowErrorModal(true);
				} else if (errorData.messageType === 'cart') {
					const formServerError = {
						key: errorData.message,
					};
					if (errorData.vars) {
						formServerError.vars = errorData.vars;
					}
					!formServerErrorInfo && setFormServerErrorInfo(formServerError);
				} else {
					failSilently(errorData.error, errorData.endpoint);
				}
			} else {
				setModalErrorMessage(null);
				setShowErrorModal(false);
				setFormServerErrorInfo(null);
			}
			setHandleErrorData(false);
		}
	}, [
		handleErrorData,
		errorData,
		failSilently,
		formServerErrorInfo,
		modalErrorMessage,
		setFormServerErrorInfo,
		setModalErrorMessage,
		setShowErrorModal,
	]);

	usePostMountEffect(() => {
		setHandleErrorData(true);
	}, [errorData]);

	// stops loaders from running if the user is on the paypal page
	const pathname = location.pathname;
	const checkoutReady = allQueriesData.CALCULATE_CART;
	const hasUpsell = Boolean(urlVars['upsell-key']);
	const hasFormQueries =
		!hasUpsell &&
		urlVars['vvvv'] &&
		(pathname === basePath || pathname === basePath.concat('index.html'));
	const isReceiptPage = pathname === '/order-received';

	const loaderProps = {
		allQueriesData,
		queryFetcher,
	};

	if (hasFormQueries) {
		return (
			<>
				{checkoutReady ? (
					<>
						<TokenExLoader {...loaderProps} />
						<GeoCurrenciesLoader {...loaderProps} />
						<CalculateCartLoader {...loaderProps} />
						<ExitOfferLoader {...loaderProps} />
					</>
				) : null}
				<LoadErrorModal modalOpen={showErrorModal} errorMessage={modalErrorMessage} />
				{!isPreview && <TrackingPixelsLoader {...loaderProps} />}
			</>
		);
	}
	if (!isPreview && hasUpsell) {
		return (
			<>
				<UpsellLoader {...loaderProps} />
				<TrackingPixelsLoader {...loaderProps} />
				<LoadErrorModal modalOpen={showErrorModal} errorMessage={modalErrorMessage} />
			</>
		);
	}
	if (isReceiptPage) {
		return (
			<>
				{!isPreview && <TrackingPixelsLoader {...loaderProps} />}
				<LoadErrorModal modalOpen={showErrorModal} errorMessage={modalErrorMessage} />
			</>
		);
	}
	return <LoadErrorModal modalOpen={showErrorModal} errorMessage={modalErrorMessage} />;
};

export default GraphQLLoader;
