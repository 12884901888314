import cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

import { fetchUrl, fetchWithRetry } from './fetchHelpers';
import { REFRESH_AUTH } from '@/graphql/queries';
import { isPreview } from '@/utils/helpers';
import { getProductsArray } from '@/utils/urlVars';

const urlVarsWhiteList = ['/order-received'];

// Grab all state that can be determined immediately before any data fetching occurs
// (e.g. variables derived from URL query string, GQL authentication header for dev)
const prependFetchKeys = async (urlVars) => {
	if (!urlVars && !urlVarsWhiteList.includes(window.location.pathname)) {
		return Promise.resolve({
			urlVars,
		});
	}
	const urlProductsArray = getProductsArray(urlVars);
	const keys = { urlVars, urlProductsArray };
	const fetchHeaders = {
		'Content-Type': 'application/json',
	};
	if (process.env.NODE_ENV === 'development') {
		if (urlVars?.vvvv && (urlVars?.template || urlVars?.exitoffer)) {
			try {
				// This will run each time this function is invoked. Ideally refactor to more singleton-like pattern
				const LOGIN_QUERY = `
					query loginUser($username: String!, $password: String!) {
						authorize(username: $username, password: $password)
					}
				`;
				const response = await fetchWithRetry('LOGIN_QUERY', fetchUrl, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						query: LOGIN_QUERY,
						variables: {
							username: 'bhanu409',
							password: process.env.REACT_APP_DEV_USER_PASS,
						},
					}),
				});
				fetchHeaders.Authorization = `Bearer ${response.data.authorize}`;
			} catch (err) {
				return Promise.reject({
					errors: {
						FATAL: 'Dev auth failure',
					},
				});
			}
		}
	} else if (isPreview) {
		try {
			const isWAM = !!cookies.get('wamRefreshToken');
			const refreshTokenCookieKey = isWAM ? 'wamRefreshToken' : 'refreshToken';
			const accessTokenCookieKey = isWAM ? 'wamAccessToken' : 'accessToken';
			const accessToken = cookies.get(accessTokenCookieKey);
			fetchHeaders.Authorization = `Bearer ${accessToken}`;
			if (!urlVars && !urlVars.template) {
				console.error('Missing template parameter, re-routing to login page.');
				window.location.href = '/login.htm';
				return;
			}
			const tokenIsExpired = Date.now() >= jwtDecode(accessToken).exp * 1000;
			if (tokenIsExpired) {
				const refreshToken = cookies.get(refreshTokenCookieKey);
				const refreshAuthResponse = await fetchWithRetry('REFRESH_AUTH', fetchUrl, {
					method: 'POST',
					headers: fetchHeaders,
					body: JSON.stringify({
						query: REFRESH_AUTH,
						variables: {
							refreshToken,
						},
					}),
				});
				if (refreshAuthResponse.error) {
					console.error(
						'Error during refreshAuth query, re-routing to login page',
						JSON.stringify(refreshAuthResponse.error),
					);
					window.location.href = '/login.htm';
					return;
				}
				const newAccessToken = refreshAuthResponse.data.refreshAuthentication.accessToken;
				const newRefreshToken = refreshAuthResponse.data.refreshAuthentication.refreshToken;
				cookies.set(accessTokenCookieKey, newAccessToken);
				cookies.set(refreshTokenCookieKey, newRefreshToken);
				fetchHeaders.Authorization = `Bearer ${newAccessToken}`;
			}
		} catch (err) {
			console.error(
				'Error during check Auth query, re-routing to login page',
				JSON.stringify(err),
			);
			window.location.href = '/login.htm';
		}
	}
	keys.fetchHeaders = fetchHeaders;
	return Promise.resolve(keys);
};

export default prependFetchKeys;
