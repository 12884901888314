import { useMutation } from 'graphql-hooks';
import { isEqual } from 'lodash';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { isPreview } from './helpers';
import { inputData } from './regexAndRequiredInputsByCountry';
import { ABANDON_ORDER } from '@/graphql';
import { GeoCurrenciesContext } from '@/state/GeoCurrenciesContext';
import { useFormStore, useStore } from '@/state/stores';

// grabs the previous state of anything created with useState
export const usePrevious = (value) => {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	}, [value]);
	return ref.current;
};

export const useCurrency = (value) => {
	const displayCurrency = useStore((state) => state.displayCurrency);
	const selectedLanguage = useStore((state) => state.selectedLanguage);
	try {
		return value === 0 || value
			? new Intl.NumberFormat(selectedLanguage, {
					style: 'currency',
					currency: displayCurrency ? displayCurrency : 'USD',
				}).format(value)
			: '';
	} catch (err) {
		return '';
	}
};

// returns the value needed for translation keys.
export const useFrequency = (frequency, frequencyType) => {
	const frequencyJoined = `${frequency} ${frequencyType}`;

	if (frequencyJoined === '1 week' || frequencyJoined === '7 day') {
		return 'weeks';
	}
	if (frequencyJoined === '14 day' || frequencyJoined === '2 week') {
		return 'twoweek';
	}
	if (frequencyJoined === '1 month') {
		return 'months';
	}
	if (frequencyJoined === '3 month') {
		return 'quarter';
	}
	if (frequencyJoined === '6 month') {
		return 'halfyearly';
	}
	if (frequencyJoined === '12 month' || frequencyJoined === '1 year') {
		return 'year';
	}

	return null;
};

// This functions as the useEffect hook, but skips out on the first invocation that
// occurs on mount. This is convenient for loading in GQL Loaders with the knowledge
// that we have pre-fetched data and thus do not have to yet query for new data.

// NOTE: Because dependency array is passed as an argument, linter will not warn
// you of `exhaustive-deps` violation. Be sure to double-check values are as needed.
export function usePostMountEffect(cb, dep) {
	const depIsArray = Array.isArray(dep);
	if (!depIsArray && dep !== undefined) {
		throw new Error('Invalid value passed to usePostMountEffect for dependency array');
	}
	if (typeof cb !== 'function') {
		throw new Error('Invalid value passed to usePostMountEffect for callback function');
	}
	if (depIsArray && !dep.length) {
		throw new Error(
			'Zero-length dependency array passed to usePostMountEffect - effect will not run',
		);
	}
	const isMounted = useRef(false);
	useEffect(() => {
		if (!isMounted.current) {
			return;
		}
		const result = cb();
		if (typeof result === 'function') {
			return result;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, dep);
	useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, []);
}

// regex is taken from yup, which is what's currently validating email under the hood.
// https://github.com/jquense/yup/blob/master/src/string.js
const emailRegex = new RegExp(
	// eslint-disable-next-line
	/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i,
);

// matches order-abandon
const phoneRegex = /^[+]?[()\- \d]{4,20}$/;

// If a user has filled out all required fields in their form, we want to persist this data if allowed
// so a user can resume this order conveniently. There are three conditions under which an ABANDON_ORDER
// mutation should be sent if required fields are filled and regulation allows:

// 1) A piece of data that the query relies on outside of our form state changes (e.g. selectedLanguage, selectedCurrency)
// 2) The user blurs a form field that would be persisted with this mutation (e.g. email, zip)
// 3) The form is autofilled.
export const useAbandonedOrderPersist = () => {
	const [submitAbandonedOrderData] = useMutation(ABANDON_ORDER);
	const { getGlobalState, siteSettings, selectedCurrency, validatedCoupon, selectedLanguage } =
		useStore(
			useShallow((state) => ({
				getGlobalState: state.getGlobalState,
				siteSettings: state.siteSettings,
				selectedCurrency: state.selectedCurrency,
				validatedCoupon: state.validatedCoupon,
				selectedLanguage: state.selectedLanguage,
			})),
		);
	const { getFormState, formFieldOnChangeHandlers, formFieldOnBlurHandlers } = useFormStore(
		useShallow((state) => ({
			getFormState: state.getFormState,
			formFieldOnChangeHandlers: state.formFieldOnChangeHandlers,
			formFieldOnBlurHandlers: state.formFieldOnBlurHandlers,
		})),
	);

	const timeoutRef = useRef();

	// init a touchedFields object that is used to store touched fields' names.
	// We later check the touchedFields key length to detect an autofill
	const touchedFieldsRef = useRef({});

	// Save previous payload for diffing and skipping out on sending mutation if data has not changed
	const prevAbandonedOrderPayloadRef = useRef({});

	// This function will check necessary conditions of sending mutation, and send request if these conditions
	// are met
	const attemptAbandonedOrderRequest = useCallback(() => {
		touchedFieldsRef.current = {};
		if (isPreview || !siteSettings?.cartAbandonment) {
			return;
		}
		// Identity should remain stable after site settings are set
		const {
			calculateCartLineItems,
			urlVars,
			selectedCurrency,
			forceGdpr,
			couponCode,
			validatedCoupon,
			selectedLanguage,
		} = getGlobalState();
		const {
			billing: { countryCode, zip, city, state, address1, address2, fullName },
			cardHolderName,
			email,
			phone,
		} = getFormState().formData;

		const doNotSendAbandonedOrderData = 'cbfu' in urlVars;
		const isEmailValid = email && emailRegex.test(email);
		const urlParams = window.location.search;
		const requiredVariables = !!(
			isEmailValid &&
			!forceGdpr &&
			urlVars.vvvv &&
			selectedCurrency &&
			urlParams &&
			calculateCartLineItems
		);
		if (!requiredVariables || doNotSendAbandonedOrderData) {
			return;
		}
		const newPayload = {
			variables: {
				address: {
					countryCode: countryCode,
					zip: zip,
					city: city,
					state: state,
					address1: address1,
					address2: address2,
				},
				lineItems: calculateCartLineItems,
				fullName: cardHolderName || fullName,
				email: email,
				phone: phoneRegex.test(phone) ? phone : null,
				vendorId: urlVars.vvvv,
				couponCode: validatedCoupon || couponCode,
				currencyId: selectedCurrency,
				urlParams: urlParams,
				locale: selectedLanguage,
			},
		};
		if (!isEqual(prevAbandonedOrderPayloadRef.current, newPayload)) {
			submitAbandonedOrderData(newPayload);
			prevAbandonedOrderPayloadRef.current = newPayload;
		}
	}, [getFormState, getGlobalState, submitAbandonedOrderData, siteSettings]);

	useEffect(() => {
		// Logic for persisting abandoned order data when non-form values change
		attemptAbandonedOrderRequest();
	}, [selectedLanguage, selectedCurrency, validatedCoupon, attemptAbandonedOrderRequest]);

	useEffect(() => {
		// Logic for persisting abandoned order data on field blur
		const handler = (fieldName) => {
			const globalState = getGlobalState();
			const stateOptions = globalState.stateOptions.billing;
			const cityOptions = globalState.cityOptions.billing;
			// const { stateOptions, cityOptions } = getGlobalState();
			const { countryCode } = getFormState().formData.billing;
			const fieldsToTrigger = [
				'billing.zip',
				'billing.address1',
				'billing.address2',
				'billing.fullName',
				'cardHolderName',
				'couponCode',
				'email',
				'phone',
				'billing.countryCode',
			];
			const listenToCityInput = countryCode !== 'US' || cityOptions?.length > 1;
			const listenToStateInput =
				!['US', 'CA'].includes(countryCode) || stateOptions?.length > 1;
			if (listenToCityInput) {
				fieldsToTrigger.push('billing.city');
			}
			if (listenToStateInput) {
				fieldsToTrigger.push('billing.state');
			}
			if (fieldsToTrigger.includes(fieldName)) {
				clearInterval(timeoutRef.current);
				timeoutRef.current = setTimeout(attemptAbandonedOrderRequest, 300);
			}
		};
		// Set in form store to be executed onBlur in form inputs
		formFieldOnBlurHandlers.push(handler);
		return () => {
			const idx = formFieldOnBlurHandlers.indexOf(handler);
			if (idx !== -1) {
				formFieldOnBlurHandlers.splice(idx, 1);
			}
		};
	}, [
		getFormState,
		getGlobalState,
		formFieldOnBlurHandlers,
		submitAbandonedOrderData,
		siteSettings,
		attemptAbandonedOrderRequest,
	]);

	useEffect(() => {
		// Logic for persisting abandoned form data on autofill. Because autofill is just a change event,
		// we operationally define an autofill as a condition in which a change event is executed on
		// two or more fields of the form within ~300ms of one another

		// `handler` function defined, will be set in form store to be executed onChange in form inputs
		const handler = (fieldName) => {
			const globalState = getGlobalState();
			const locationIsLoading =
				globalState.locationLoading.billing || globalState.locationLoading.shipping;
			if (locationIsLoading) {
				return;
			}
			const stateOptions = globalState.stateOptions.billing;
			const cityOptions = globalState.cityOptions.billing;
			const { countryCode } = getFormState().formData.billing;
			const fieldsToTrigger = [
				'billing.zip',
				'billing.address1',
				'billing.address2',
				'billing.fullName',
				'couponCode',
				'email',
				'billing.countryCode',
			];
			const listenToCityInput = countryCode !== 'US' || cityOptions?.length > 1;
			const listenToStateInput =
				!['US', 'CA'].includes(countryCode) || stateOptions?.length > 1;
			if (listenToCityInput) {
				fieldsToTrigger.push('billing.city');
			}
			if (listenToStateInput) {
				fieldsToTrigger.push('billing.state');
			}
			if (!fieldsToTrigger.includes(fieldName)) {
				return;
			}

			touchedFieldsRef.current[fieldName] = true;
			// We store a timeout and clear it with each invocation, to debounce and not
			// send many mutations for one single autofill event.
			clearTimeout(timeoutRef.current);
			timeoutRef.current = setTimeout(() => {
				if (Object.keys(touchedFieldsRef.current).length > 2) {
					// If touchedFields has more than two keys it must be from an autofill, so we send mutation before resetting
					// variables in outer scope.
					// Revalidate form to clear outdated errors
					attemptAbandonedOrderRequest();
				} else {
					touchedFieldsRef.current = {};
				}
			}, 300);
		};
		formFieldOnChangeHandlers.push(handler);
		return () => {
			const idx = formFieldOnChangeHandlers.indexOf(handler);
			if (idx !== -1) {
				formFieldOnChangeHandlers.splice(idx, 1);
			}
		};
	}, [getGlobalState, attemptAbandonedOrderRequest, formFieldOnChangeHandlers, getFormState]);

	return null;
};

export const useLocationUpdateSideEffects = (accessor) => {
	const {
		locationLoading,
		setIsCartUpdating,
		setCities,
		setStates,
		refocusCurrentInput,
		calculateCartAddressVars,
		setCalculateCartAddressVars,
		cartInitialized,
	} = useStore(
		useShallow((state) => ({
			locationLoading: state.locationLoading[accessor],
			setIsCartUpdating: state.setIsCartUpdating,
			setCities: state.setCities,
			setStates: state.setStates,
			refocusCurrentInput: state.refocusCurrentInput,
			calculateCartAddressVars: state.calculateCartAddressVars,
			setCalculateCartAddressVars: state.setCalculateCartAddressVars,
			cartInitialized: state.cartInitialized,
		})),
	);

	const {
		countryCode,
		city,
		state,
		zip,
		setFormValue,
		clearFieldError,
		formFieldIsValid,
		getFormValue,
		address1,
	} = useFormStore(
		useShallow((state) => ({
			countryCode: state.formData[accessor].countryCode,
			city: state.formData[accessor].city,
			state: state.formData[accessor].state,
			zip: state.formData[accessor].zip,
			setFormValue: state.setFormValue,
			clearFieldError: state.clearFieldError,
			formFieldIsValid: state.formFieldIsValid,
			getFormValue: state.getFormValue,
			address1: state.formData[accessor].address1,
		})),
	);

	// Preserve previous countryCode at top level rather than inside custom hook to avoid reset after remount with layout change.
	const { countryCodeRef } = useContext(GeoCurrenciesContext);

	useEffect(() => {
		if (!cartInitialized) {
			return;
		}
		const queuedReset = setTimeout(() => {
			const zip = getFormValue(`${accessor}.zip`);
			const isChangingToUS =
				countryCodeRef.current &&
				countryCodeRef.current !== countryCode &&
				countryCode === 'US';
			const isChangingFromUS = countryCodeRef.current === 'US' && countryCode !== 'US';

			if (zip) {
				formFieldIsValid(`${accessor}.zip`).then((isValid) => {
					if (!isValid) {
						setFormValue(`${accessor}.zip`, '');
						clearFieldError(`${accessor}.zip`);
					}
				});
			}

			if (isChangingToUS || isChangingFromUS) {
				setFormValue(`${accessor}.city`, '');
				clearFieldError(`${accessor}.city`);
				setFormValue(`${accessor}.state`, '');
				clearFieldError(`${accessor}.state`);
				setCities(null, accessor);
				setStates(null, accessor);
			}
			countryCodeRef.current = countryCode;
		}, 200);
		return () => {
			clearTimeout(queuedReset);
		};
	}, [
		countryCode,
		setFormValue,
		clearFieldError,
		cartInitialized,
		setCities,
		setStates,
		accessor,
		formFieldIsValid,
		getFormValue,
		countryCodeRef,
	]);

	// we set calculateCart vars on a timeout as well, so we create a ref for that too
	const cartVarsRef = useRef();

	useEffect(() => {
		cartVarsRef.current = calculateCartAddressVars;
	}, [calculateCartAddressVars, accessor]);

	const shouldGetTaxAndLocationByZip = ['US', 'CA'].includes(countryCode);

	// when calculateCartAddressVars gets updated, calculateCart will run.
	// here we check to see if the address has changed before making that update
	const updateCartAddress = useCallback(async () => {
		const resetFormStatus = () => {
			// Invoked anytime this function returns WITHOUT continuing to calculate cart
			// (which is where this logic is otherwise handled)
			setIsCartUpdating(false);
			setTimeout(refocusCurrentInput);
		};
		try {
			setIsCartUpdating(true);

			const validZip = new RegExp(
				inputData[countryCode].calculateCartZipRegex || inputData[countryCode].zipRegex,
			).test(zip);

			if (validZip) {
				clearFieldError(`${accessor}.zip`);
			}
			const returnZip = zip && validZip && shouldGetTaxAndLocationByZip;
			const address1IsValid = await formFieldIsValid(`${accessor}.address1`, address1, false);
			if (address1IsValid) {
				clearFieldError(`${accessor}.address1`);
			}
			const newVars = {
				countryCode: countryCode || null,
				zip: returnZip ? zip : null,
				city: countryCode === 'US' ? city : null,
				state: shouldGetTaxAndLocationByZip ? state : null,
				address1: countryCode === 'US' && address1IsValid ? address1 : '',
			};
			if (!isEqual(cartVarsRef.current, newVars)) {
				setCalculateCartAddressVars(newVars);
				setIsCartUpdating(false);
			} else {
				resetFormStatus();
			}
		} catch (err) {
			resetFormStatus();
		}
	}, [
		shouldGetTaxAndLocationByZip,
		cartVarsRef,
		setCalculateCartAddressVars,
		countryCode,
		zip,
		city,
		state,
		setIsCartUpdating,
		clearFieldError,
		accessor,
		refocusCurrentInput,
		address1,
		formFieldIsValid,
	]);

	// listen for events that trigger calculateCart to update
	// if city or state change, we trigger an update.
	// if locationLoading is false, that means findLocation query is not running and it's safe to update the cart.
	// the timers is to make sure that city/site have updated in case findLocation has set new values for them
	// if we detect city/state changes we should be able to update immediately,
	// but we need to make sure the city/state changes are not triggered by a reset
	const calcCartUpdateInterval = useRef();
	useEffect(() => {
		clearTimeout(calcCartUpdateInterval.current);
		if (!locationLoading && cartInitialized) {
			calcCartUpdateInterval.current = setTimeout(() => {
				updateCartAddress();
			}, 500);
		}
	}, [city, state, locationLoading, cartInitialized, updateCartAddress]);
};

export const useAutofillValidate = () => {
	const formFieldOnChangeHandlers = useFormStore((state) => state.formFieldOnChangeHandlers);
	const formIsValid = useFormStore((state) => state.formIsValid);
	const touchedFieldsRef = useRef(new Set());
	const validationTimeoutRef = useRef();

	useEffect(() => {
		const validateIfNeeded = (name) => {
			window.clearTimeout(validationTimeoutRef.current);
			const set = touchedFieldsRef.current;
			set.add(name);
			validationTimeoutRef.current = window.setTimeout(() => {
				if (set.size > 2) {
					formIsValid(true);
				}
				set.clear();
			}, 300);
		};
		formFieldOnChangeHandlers.push(validateIfNeeded);
		return () => {
			const idx = formFieldOnChangeHandlers.indexOf(validateIfNeeded);
			if (idx !== -1) {
				formFieldOnChangeHandlers.splice(idx, 1);
			}
		};
	}, [formFieldOnChangeHandlers, formIsValid]);
};
