import { css } from '@emotion/react';
import { Grid, Typography } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '..';
import { useStore } from '@/state/stores';
import { cbNeutral, defaultPanelBorderColor, defaultPanelBoxShadow } from '@/theme';

const container = css`
	border: 1 px ${defaultPanelBorderColor};
	box-shadow: ${defaultPanelBoxShadow};

	.receipt-message {
		margin-top: 1.5rem;
		margin-bottom: 2rem;
	}
	.thank-you-header {
		line-height: 2rem;
		margin-bottom: 2rem;
	}

	.order-numbers-container {
		margin-bottom: 2rem;
	}

	h3 {
		margin-bottom: 20px;
		color: ${cbNeutral[300]};
		font-weight: normal;
		overflow-wrap: break-word;
		word-wrap: break-word;
		word-break: break-word;
		hyphens: auto;
	}
`;

const ThankYou = () => {
	const receiptPageData = useStore((state) => state.receiptPageData);
	const { t } = useTranslation('order-received');
	const [addressIndex, setAddressIndex] = useState(0);

	const hasPhysicalProduct = Boolean(
		receiptPageData?.length && receiptPageData.some((data) => data.hasPhysicalProduct),
	);
	const hasMultipleReceipts = receiptPageData?.length > 1;
	const orderNumbers =
		hasMultipleReceipts && receiptPageData.map((receipt) => receipt.orderNumber);

	// when there are multiple receipts and one is a physical product,
	// this finds the first one that includes the shipping address and saves the index
	// so it can be used to show the address in the UI
	useEffect(() => {
		if (hasPhysicalProduct && receiptPageData.length > 1) {
			for (let i = 0; i < receiptPageData.length; i++) {
				if (receiptPageData[i].hasPhysicalProduct) {
					setAddressIndex(i);
					break;
				}
			}
		}
	}, [receiptPageData, setAddressIndex, hasPhysicalProduct]);

	const {
		fullName,
		cardHolderName,
		email,
		address,
		softDescriptor,
		orderNumber,
		editUrl,
		paymentMethod,
	} = receiptPageData[addressIndex] || {};
	const { address1, address2, city, state, zip, countryCode } = address || {};

	const title = t('thank-you.title');

	// remove duplicate soft descriptors
	const softDescriptors = Array.from(new Set(receiptPageData.map((data) => data.softDescriptor)));
	const multipleReceipts = hasMultipleReceipts && (
		<Fragment>
			<Typography variant="h5">{t('thank-you.transactions')}:</Typography>
			{softDescriptors.map((descriptor, index) => {
				return (
					<Typography variant="h5" key={index} gutterBottom>
						{descriptor}
					</Typography>
				);
			})}
			<div className="order-numbers-container">
				<Typography variant="h5" gutterBottom>
					{t('thank-you.order-numbers')}
				</Typography>
				{orderNumbers.map((orderNumber) => {
					return (
						<Typography variant="h5" key={orderNumber} gutterBottom>
							{orderNumber}
						</Typography>
					);
				})}
			</div>
		</Fragment>
	);

	const singleReceipt = !hasMultipleReceipts && (
		<Typography variant="h5" className="thank-you-header">
			{t('thank-you.transaction', {
				transaction: softDescriptor,
				orderNumber: orderNumber,
			})}
		</Typography>
	);

	return (
		<Card css={container}>
			<Typography variant="h3">{title}</Typography>
			<div className="receipt-message">
				{hasMultipleReceipts ? multipleReceipts : singleReceipt}
				<Typography variant="body1" gutterBottom>
					{t('thank-you.receipt', {
						email: email,
					})}{' '}
					<a href={receiptPageData[0]?.editUrl} target="_blank" rel="noopener noreferrer">
						CLKBank.com
					</a>
					.
				</Typography>
			</div>

			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<div className="personal-info-block">
						<Typography variant="h5" gutterBottom>
							{t('thank-you.billing')}
						</Typography>
						<Typography variant="body1">
							{paymentMethod === 'payPal' ? fullName : cardHolderName}
						</Typography>
						<Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
							{email}
						</Typography>
						{!hasPhysicalProduct && (
							<Typography variant="body1">
								<a href={editUrl} target="_blank" rel="noopener noreferrer">
									{t('thank-you.edit-contact')}
								</a>
							</Typography>
						)}
					</div>
				</Grid>
				{hasPhysicalProduct && (
					<Grid item xs={12} sm={6}>
						<Typography variant="h5" gutterBottom>
							{t('thank-you.shipping')}
						</Typography>
						<Typography variant="body1">{fullName}</Typography>
						<Typography variant="body1">{address1}</Typography>
						{address2 && <Typography variant="body1">{address2}</Typography>}
						<Typography variant="body1">
							{city}, {state}
						</Typography>
						<Typography variant="body1">
							{zip}, {countryCode}
						</Typography>
						<Typography variant="body1">
							<a href={editUrl} target="_blank" rel="noopener noreferrer">
								{t('thank-you.edit-contact')}
							</a>
						</Typography>
					</Grid>
				)}
			</Grid>
		</Card>
	);
};

export default ThankYou;
