import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { CustomPixels, FacebookPixels, GoogleAdWordsPixels, GoogleAnalyticsPixels } from '..';
import { useStore } from '@/state/stores';

const pixelWrapper = css`
	display: none;
	img {
		border: 0;
		width: 1px;
		height: 1px;
	}
`;

const PixelManager = () => {
	const trackingPixels = useStore((state) => state.trackingPixels);
	const [fbPixels, setFbPixels] = useState([]);
	const [googleAnalyticPixels, setGoogleAnalyticPixels] = useState([]);
	const [googleAdWordsPixels, setGoogleAdWordsPixels] = useState([]);
	const [customPixels, setCustomPixels] = useState([]);
	const [resetPixels, setResetPixels] = useState(false);
	const pixelExists = useRef({});

	const location = useLocation();
	const page = location.pathname.match(/\/(order-received|paypal-response)/g)
		? 'RECEIPT_PAGE_VIEW'
		: 'ORDER_FORM_VIEW';

	if (resetPixels) {
		// reset the existing pixels when trackingPixels gets updated
		setFbPixels([]);
		setGoogleAnalyticPixels([]);
		setGoogleAdWordsPixels([]);
		setCustomPixels([]);

		trackingPixels &&
			trackingPixels.forEach((trackingPixel) => {
				const pixelType = trackingPixel.service.name;
				switch (pixelType) {
					case 'FACEBOOK_PIXEL':
						setFbPixels((pixels) => [...pixels, trackingPixel]);
						break;
					case 'GOOGLE_ANALYTICS':
						setGoogleAnalyticPixels((pixels) => [...pixels, trackingPixel]);
						break;
					case 'GOOGLE_AD_WORDS':
						setGoogleAdWordsPixels((pixels) => [...pixels, trackingPixel]);
						break;
					case 'CUSTOM_PIXEL':
						setCustomPixels((pixels) => [...pixels, trackingPixel]);
						break;
					default:
						break;
				}
			});
		setResetPixels(false);
	}

	// useEffect will run on initial render and after an update to trackingPixels
	useEffect(() => {
		if (page) {
			setResetPixels(true);
		}
	}, [trackingPixels, page]);

	if (fbPixels.length) Object.assign(pixelExists.current, { facebook: true });
	if (googleAnalyticPixels.length) Object.assign(pixelExists.current, { ga: true });
	if (googleAdWordsPixels.length) Object.assign(pixelExists.current, { adwords: true });
	if (customPixels.length) Object.assign(pixelExists.current, { custom: true });

	// Wait until cart is ready before loading any vendor tracking scripts.
	return (
		<div css={pixelWrapper}>
			{pixelExists.current.facebook && <FacebookPixels pixels={fbPixels} page={page} />}
			{pixelExists.current.ga && (
				<GoogleAnalyticsPixels pixels={googleAnalyticPixels} page={page} />
			)}
			{pixelExists.current.adwords && (
				<GoogleAdWordsPixels pixels={googleAdWordsPixels} page={page} />
			)}
			{pixelExists.current.custom && <CustomPixels pixels={customPixels} page={page} />}
		</div>
	);
};

export default PixelManager;

PixelManager.propTypes = {
	page: PropTypes.string,
};
