import { stringIsEqualCaseInsensitive } from './helpers';

// Some params refer to the same keys, and if multiple are present we must therefore
// account for this
const paramInvalidationMap = {
	zipcode: ['zipc', 'st_zipc'],
	zipc: ['st_zipc'],
};

const decodeURLVars = (str = null) => {
	const argumentIsValidInput = typeof str === 'string' && str[0] === '?';
	const queryString = argumentIsValidInput ? str : window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const varsToObject = {};
	for (const key of urlParams.keys()) {
		varsToObject[key] = urlParams.get(key);
	}
	Object.keys(varsToObject).forEach((key) => {
		const invalidKeys = paramInvalidationMap[key] || [];
		invalidKeys.forEach((invalidKey) => {
			delete varsToObject[invalidKey];
		});
	});
	return varsToObject;
};

// multiple products and quantities can be passed in via `cbitems`
// products are separated by underscores, product and quantity are separated by periods
// ?cbitems=[productSKU].[quantity]_[productSKU].[quantity]...

// a single product and it's quantity can be passed in via `item` & `quantity`
// ?item=[productSKU]&quantity=[quantity]

// cbitems and item/quantity params are never used together.
// check first for cbitems and build the cart with that if possible. if not, check for item/quantity
// if neither are present, an empty array is returned and we can throw errors based on that

const skuPattern = /[^0-9A-Za-z-]/g;
const validSKU = (productSKU) => {
	return !productSKU.match(skuPattern);
};

const getProductsArray = (varsObject) => {
	const cart = [];
	const allSKUs = [];

	// if there is not a quantity, or if the quantity is NaN, quantity = 1
	// if the productSKU is invalid, we don't push it to the cart.
	if (varsObject.cbitems) {
		// break cbitems into an array
		// then push an object with the product id and quantity to the cart array
		const products = varsObject.cbitems.split('_').filter((sku) => !!sku);

		for (const product of products) {
			const productData = product.split('.');
			const productSKU = productData[0];
			const productQuantityAsNumber = Number(productData[1]);
			const productQuantity =
				productQuantityAsNumber && productQuantityAsNumber >= 1
					? productQuantityAsNumber
					: 1;

			const indexToAddQuantityTo = cart.findIndex((item) =>
				stringIsEqualCaseInsensitive(item.sku, productSKU),
			);

			if (validSKU(productSKU)) {
				if (!allSKUs.includes(productSKU)) {
					// if the sku is not already in the cart array, add it
					cart.push({ sku: productSKU, quantity: productQuantity });
				} else {
					// if the sku is already in the array, add the quantity to the existing item
					cart[indexToAddQuantityTo].quantity += productQuantity;
				}
				allSKUs.push(productSKU);
			}
		}
	} else if (varsObject.item) {
		const productSKU = varsObject.item;
		const productQuantityAsNumber = Number(varsObject.quantity);
		const productQuantity =
			productQuantityAsNumber && productQuantityAsNumber >= 1 ? productQuantityAsNumber : 1;
		validSKU(productSKU) && cart.push({ sku: productSKU, quantity: productQuantity });
	}

	return cart;
};

export { decodeURLVars, getProductsArray };
