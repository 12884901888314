import { fetchUrl, fetchWithRetry } from './fetchHelpers';
import { PRODUCTS } from '@/graphql/queries';
import { getProductImage } from '@/utils/helpers';

const getProductsQuery =
	({ urlVars, urlProductsArray, fetchHeaders }) =>
	async () => {
		try {
			if (urlProductsArray.length > 25) {
				throw {
					error: 'More than 25 unique products in URL.  This blocked the call to the PRODUCTS query',
				};
			}
			const productSKUs = urlProductsArray.map((product) => product.sku);
			const body = await fetchWithRetry('PRODUCTS', fetchUrl, {
				method: 'POST',
				headers: fetchHeaders,
				body: JSON.stringify({
					query: PRODUCTS,
					variables: {
						vendorId: urlVars.vvvv,
						skus: productSKUs,
					},
				}),
			});
			if (body.error) {
				throw body;
			}
			const { data } = body;
			const hasProducts = data.products.length !== 0;
			if (!hasProducts) {
				throw { error: 'NO_PRODUCTS' };
			}

			// If all images are large in size, we want the form's interface to load to accommodate these larger sizes
			// Create this element behind the scenes and fulfill promise when loading is done, so we can check naturalHeight in fetchInitialData.js

			// This should also take care of preloading images as a result
			const productImageLoadingPromises = data.products
				.filter((product) => product.image)
				.map((product) => {
					const src = getProductImage(product.image);
					return new Promise((res, rej) => {
						const img = document.createElement('img');
						img.onload = () => res(img);
						img.onerror = () => rej();
						img.src = src;
					}).catch(() => null);
				});

			const state = {
				productImageLoadingPromises,
			};

			return {
				graphql: { PRODUCTS: data },
				state,
			};
		} catch (body) {
			return Promise.reject({ errors: { PRODUCTS: body } });
		}
	};

export default getProductsQuery;
