import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { Bump, Product, SubscriptionBlock } from '..';
import { getZoneStyles } from '@/components/mainContent/contentStyle';
import { useStore } from '@/state/stores';
import { defaultButtonBackgroundColor, defaultButtonTextColor } from '@/theme';
import { getProductImage } from '@/utils/helpers';
import { useCurrency } from '@/utils/hooks';

const baseCSS = (template, bottomBorderColor) => {
	const cartStyles = getZoneStyles(template, 'cart');
	const { button } = cartStyles || {};

	return css`
		&::after {
			display: block;
			content: '';
			margin-top: 1rem;
			margin-bottom: 1rem;
			border-bottom: 1px solid ${bottomBorderColor};
		}

		.custom-button {
			border-radius: ${button?.borderRadius || '4px'};
			background-color: ${button?.backgroundColor || defaultButtonBackgroundColor};
			color: ${button?.fontColor || defaultButtonTextColor};
			font-family: ${button?.fontFamily || 'inherit'};

			&:hover {
				background-color: ${button?.backgroundColor || defaultButtonBackgroundColor};
				background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
			}

			&:disabled {
				filter: grayscale(1);
			}
		}

		.product-summary.product-summary-large-image {
			flex-direction: column;
		}

		.product-summary {
			display: flex;
			flex-direction: row;
			margin-bottom: 1rem;

			.details {
				display: flex;
				flex-direction: column;
				justify-content: center;

				.price-and-quantity-container {
					line-height: 0;
				}
			}

			.details-large-image {
				justify-content: space-between;
				align-items: flex-start;
				flex-grow: 1;
				flex-direction: column;
				margin-top: 16px;

				.price-and-quantity-container {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					width: 100%;
				}

				.price {
					order: 2;
					font-weight: 600;
					margin-top: 8px;
					font-size: 1rem;
				}
			}

			.title {
				font-weight: 700;
				font-size: 1.125rem;
				line-height: 1.263;
				margin-bottom: 8px;
			}

			.wrap-text {
				overflow-wrap: break-word;
				word-wrap: break-word;
				word-break: break-all;
				word-break: break-word;
				hyphens: auto;
			}

			.type {
				font-weight: 600;
				margin-bottom: 8px;
			}

			.image-holder {
				display: flex;
				flex: 0 0 100px;
				justify-content: center;
				align-self: center;
				margin-right: 16px;
				height: 100px;
			}

			.image-holder-large {
				display: flex;
				flex: 0 0 300px;
				justify-content: center;
				align-self: center;
				margin-right: 16px;
				height: 300px;
			}

			.image {
				display: block;
				align-self: center;
				max-width: 100%;
				max-height: 100%;
				object-fit: contain;
			}
		}

		.product-description {
			margin-bottom: 1rem;
		}

		.quantity-remove {
			display: flex;
			align-items: baseline;
			justify-content: space-between;

			.MuiInputBase-input {
				padding-top: 10px;
			}
		}

		.remove {
			cursor: pointer;
			margin-left: auto;
			font-size: 1.125rem;
			&:focus {
				outline: none;
				text-decoration: underline;
			}
		}
	`;
};

const ProductDisplay = (props) => {
	const {
		recurring,
		title,
		textType,
		text,
		isBump,
		sku,
		inBumpList,
		toggleCartItem,
		priceModel,
		quantity,
		maxQuantity,
		image,
		className,
		type,
		isActive,
		description,
		isLargeProductImage,
		bottomBorderColor,
	} = props;

	const {
		subsequentPayments,
		remainingPayments,
		isUnlimited,
		frequency,
		nextBillingDate,
		frequencyType,
		unitPrice,
		initialOffsetValue,
	} = priceModel;

	const { selectedLanguage, siteSettings, template } = useStore(
		useShallow((state) => ({
			selectedLanguage: state.selectedLanguage,
			siteSettings: state.siteSettings,
			template: state.template,
		})),
	);

	const quantityEditable = siteSettings.allowEditQuantity || false;
	const productQuantity = maxQuantity && quantity > maxQuantity ? maxQuantity : quantity;
	const showQuantity = productQuantity > 1 || quantityEditable;
	const formattedUnitPrice = useCurrency(unitPrice);
	const productType = type.includes('PHYSICAL') ? 'physical' : 'digital';
	const { t } = useTranslation('checkout');
	const cartStyles = getZoneStyles(template, 'cart');

	const productImage = image && !inBumpList && image !== 'todo' && (
		<div className={isLargeProductImage ? 'image-holder-large' : 'image-holder'}>
			<img
				src={getProductImage(image)}
				alt={t('alt-tags.placeholder')}
				className="image"
				width={isLargeProductImage ? 300 : 100}
				height={isLargeProductImage ? 300 : 100}
			/>
		</div>
	);

	const subscriptionBlock = recurring && (
		<SubscriptionBlock
			sku={sku}
			isBump={isBump}
			inBumpList={inBumpList}
			frequency={frequency}
			frequencyType={frequencyType}
			subsequentPayments={subsequentPayments}
			nextBillingDate={nextBillingDate}
			isUnlimited={isUnlimited}
			remainingPayments={remainingPayments}
			productType={productType}
			initialOffsetValue={initialOffsetValue}
		/>
	);

	const commonStyles = baseCSS(template, bottomBorderColor);

	const commonProps = {
		className,
		cartStyles,
		productImage,
		selectedLanguage,
		title,
		type,
		formattedUnitPrice,
		sku,
		commonStyles,
		description,
	};

	return isBump ? (
		<Bump
			isActive={isActive}
			text={text}
			textType={textType}
			toggleCartItem={toggleCartItem}
			remainingPayments={remainingPayments}
			isUnlimited={isUnlimited}
			subsequentPayments={subsequentPayments}
			frequencyType={frequencyType}
			frequency={frequency}
			image={image}
			initialOffsetValue={initialOffsetValue}
			{...commonProps}
		/>
	) : (
		<Product
			showQuantity={showQuantity}
			productQuantity={productQuantity}
			maxQuantity={maxQuantity}
			quantityEditable={quantityEditable}
			isLargeProductImage={isLargeProductImage}
			subscriptionBlock={subscriptionBlock}
			{...commonProps}
		/>
	);
};

ProductDisplay.propTypes = {
	bottomBorderColor: PropTypes.string,
	className: PropTypes.string,
	isActive: PropTypes.bool,

	// PROPS NOT AVAILABLE IN THE PRODUCT GQL ENDPOINT:
	quantity: PropTypes.number.isRequired, // from url
	isBump: PropTypes.bool,
	inBumpList: PropTypes.bool,
	isLargeProductImage: PropTypes.bool,

	// return functions
	toggleCartItem: PropTypes.func,
	toggleCartItemWithKeyDown: PropTypes.func,

	// ORDER BUMP PROPS FROM GQL ENDOPOINT:
	sequence: PropTypes.number,
	textType: PropTypes.string, // STANDARD_1, STANDARD_2, UPGRADE_1, UPGRADE_2, URGENT, SUBSCRIPTION_1, SUBSCRIPTION_2, BOOK_1, BOOK_2, CUSTOM // ? dont' think this is needed in ui
	text: PropTypes.string,
	// product endpoint props
	id: PropTypes.string.isRequired,
	maxQuantity: PropTypes.number,
	image: PropTypes.string,
	sku: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired, // STANDARD_DIGITAL, STANDARD_PHYSICAL, RECURRING_DIGITAL, RECURRING_PHYSICAL, STANDARD_DIGITAL_STANDARD_PHYSICAL, STANDARD_PHYSICAL_RECURRING_DIGITAL
	physical: PropTypes.bool.isRequired, // this is a referred value off of the type
	recurring: PropTypes.bool.isRequired, // this is a referred value off of the type
	delayedDelivery: PropTypes.bool.isRequired, // ? ask katie/david on what this shows in comps
	isTestPurchaseOnly: PropTypes.bool.isRequired,
	description: PropTypes.string,
	priceModel: PropTypes.shape({
		frequency: PropTypes.string, // WEEKLY, BI_WEEKLY, MONTHLY, QUARTERLY, HALF_YEARLY, YEARLY, MONTHS, WEEKS, DAYS
		frequencyType: PropTypes.string,
		initialPayment: PropTypes.string,
		subsequentPayments: PropTypes.string,
		remainingPayments: PropTypes.number,
		maxPayments: PropTypes.number, // ? is this needed in ui
		initialOffsetValue: PropTypes.number, // number of days from the current date
		type: PropTypes.string.isRequired, // STANDARD/RECURRING // ? This seems to be a duplicate of the `type` attribute above. Remove?
		refundDaysLimit: PropTypes.number, // ? ask katie/david on what this shows in comps
		nextBillingDate: PropTypes.string,
		isUnlimited: PropTypes.bool,
		unitPrice: PropTypes.string,
	}),
};

export default ProductDisplay;
