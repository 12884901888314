import { css } from '@emotion/react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LanguageIcon from '@mui/icons-material/Language';
import {
	Box,
	Fade,
	Link,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Popover,
	Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { menuArrow } from '@/assets';
import { getZoneStyles } from '@/components/mainContent/contentStyle';
import { MediaQueryContext } from '@/state/MediaQueryContext';
import { useStore } from '@/state/stores';
import { cbNeutral, cbPrimary } from '@/theme';
import { currencies } from '@/utils/currencies';
import { PaymentMethods } from '@/utils/enums';

const languageButton = (headerBackgroundColor) => {
	return css`
		display: flex;
		color: ${cbPrimary[320]};
		background-color: ${headerBackgroundColor || 'inherit'};
	`;
};

const languages = {
	en: 'English',
	de: 'Deutsch',
	es: 'Español',
	fr: 'Français',
	it: 'Italiano',
	pt: 'Português',
};

const languageMenu = (cartStyles) => {
	const { button, body, backgroundColor, header } = cartStyles || {};

	return css`
		${backgroundColor ? `background-color: ${backgroundColor};` : ''}

		.popover-styles {
			margin-top: 20px;
			overflow: visible;
			box-shadow: 0px 8px 16px rgba(14, 13, 38, 0.12);
			border-radius: 4px;
			border: 1px solid ${cbNeutral[900]};

			&:after {
				width: 20px;
				height: 20px;
				content: '';
				position: absolute;
				top: -20px;
				right: 24px;
				z-index: 1;
				background-image: url(${menuArrow});
			}
		}

		.heading {
			padding: 24px;
			display: flex;
			flex-direction: row;
			align-items: center;

			h5 {
				${header?.fontColor ? `color: ${header.fontColor};` : ''}
			}

			.icon {
				color: ${button?.backgroundColor || cbPrimary[320]};
				margin-right: 12px;
			}
		}

		.list-item {
			padding-left: 30px;
			padding-right: 30px;
			border: none;
			background: none;
			color: ${body?.fontColor || 'inherit'};

			&:hover,
			&:focus {
				background-color: ${button?.backgroundColor || cbPrimary[950]};
				color: ${button?.fontColor || 'inherit'};
				outline: none;
			}

			strong {
				display: inline-block;
				min-width: 2.5em;
			}

			.icon {
				justify-content: flex-end;
				color: ${button?.fontColor || '#fff'};
				opacity: 0;
			}

			&.Mui-selected {
				color: ${button?.fontColor || '#fff'};

				.icon {
					opacity: 1;
				}
			}
		}

		.selected.Mui-selected {
			background-color: ${button?.backgroundColor || cbPrimary[320]};
			&:hover,
			&:focus {
				background-color: ${button?.backgroundColor || cbPrimary[320]};
				color: ${button?.fontColor || cbPrimary[800]};
				filter: ${button?.backgroundColor ? 'brightness(85%)' : 'none'};
				outline: none;
			}
		}

		.dropdown-hr {
			border: none;
			border-top: 1px solid ${cbNeutral[700]};
		}

		.header-icon {
			padding-right: 1em;
		}
	`;
};

export default function LanguageAndCurrency({ headerBackgroundColor }) {
	const { t, i18n } = useTranslation('checkout');
	const {
		pazeData,
		applePayData,
		currencyCodes,
		selectedCurrency,
		setSelectedCurrency,
		selectedLanguage,
		urlVars,
		template,
		paymentMethod,
	} = useStore(
		useShallow((state) => ({
			pazeData: state.pazeData,
			applePayData: state.applePayData,
			paymentMethod: state.paymentMethod,
			currencyCodes: state.currencyCodes,
			selectedCurrency: state.selectedCurrency,
			setSelectedCurrency: state.setSelectedCurrency,
			selectedLanguage: state.selectedLanguage,
			urlVars: state.urlVars,
			template: state.template,
		})),
	);
	const { isSmallScreen, isExtraSmallScreen } = useContext(MediaQueryContext);

	const cartStyles = getZoneStyles(template, 'cart');

	const displayCurrencies = () => {
		let codes = currencyCodes;
		if (paymentMethod === PaymentMethods.APPLE_PAY) {
			codes = [applePayData.currencyData.selectedCurrency];
		}
		if (paymentMethod === PaymentMethods.PAZE) {
			codes = [pazeData.currencyData.selectedCurrency];
		}
		const currency = [];
		codes &&
			// currency codes from site settings in GQL
			codes.forEach((code) => {
				// compare currencies in site settings to all currency array of objects
				currencies.forEach((item) => {
					if (code === item.code) {
						// push only the currencies for the current country into the currency array
						currency.push(item);
					}
				});
			});
		return currency;
	};

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLanguageChange = (key) => {
		i18n.changeLanguage(key);
		handleClose();
	};

	const languagesList = () => {
		const listItems = Object.keys(languages).map((key) => (
			<ListItem
				component="button"
				className="list-item"
				key={key}
				selected={selectedLanguage === key}
				onClick={() => {
					handleLanguageChange(key);
				}}
			>
				<ListItemText tabIndex={-1} primary={languages[key]} />
				<ListItemIcon className="icon">
					<CheckCircleIcon className={'checkmark'} fontSize="small" />
				</ListItemIcon>
			</ListItem>
		));
		return <Fragment> {listItems}</Fragment>;
	};

	const handleCurrencyChange = (code) => {
		setSelectedCurrency(code);
		handleClose();
	};

	const currenciesList = () => {
		const listItems = displayCurrencies().map((item) => {
			const listItemString = (
				<Fragment>
					<strong>{item.code}</strong> {item.name}
				</Fragment>
			);
			return (
				<ListItem
					component="button"
					className="list-item"
					key={item.name}
					selected={selectedCurrency === item.code}
					onClick={() => handleCurrencyChange(item.code)}
				>
					<ListItemText tabIndex={-1} primary={listItemString} />
					<ListItemIcon className="icon">
						<CheckCircleIcon className={'checkmark'} fontSize="small" />
					</ListItemIcon>
				</ListItem>
			);
		});

		return <Fragment>{listItems}</Fragment>;
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	// if there is a locale in the URL, use that language on page load
	useEffect(() => {
		const localeInUrl = urlVars?.locale?.toLowerCase();
		const allLocales = Object.keys(languages);

		// make sure the locale matches the languages we support
		const validLocale = allLocales.find((locale) => locale === localeInUrl);
		validLocale && i18n.changeLanguage(validLocale);
	}, [urlVars, i18n]);

	const open = Boolean(anchorEl);
	const elementId = open ? 'language-and-currency-popover' : undefined;
	return (
		<div css={languageMenu(cartStyles)} className="container">
			<Link
				component="button"
				css={languageButton(headerBackgroundColor)}
				aria-describedby={elementId}
				variant="inherit"
				underline="none"
				onClick={handleClick}
				className="link"
				tabIndex={isSmallScreen ? 0 : 1}
			>
				<LanguageIcon />
				{!isExtraSmallScreen && (
					<Typography className="text" variant="subtitle2">
						{t('language-modal.title')}
					</Typography>
				)}
				<ArrowDropDownIcon />
			</Link>
			<Popover
				id={elementId}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				TransitionComponent={Fade}
			>
				<List id="language-and-currency-popup" tabIndex={-1} css={languageMenu(cartStyles)}>
					<Box className="heading" tabIndex={-1}>
						<LanguageIcon className="icon" />
						<Typography variant="h5">{t('language-modal.select-language')}</Typography>
					</Box>

					{languagesList()}

					<hr className="dropdown-hr" tabIndex={-1} />

					<Box className="heading" tabIndex={-1}>
						<LanguageIcon className="icon" />
						<Typography variant="h5">{t('language-modal.select-currency')}</Typography>
					</Box>

					{currenciesList()}
				</List>
			</Popover>
		</div>
	);
}

LanguageAndCurrency.propTypes = {
	headerBackgroundColor: PropTypes.string,
};
