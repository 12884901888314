import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { SubscriptionDetails } from '..';
import { getZoneStyles } from '@/components/mainContent/contentStyle';
import { useStore } from '@/state/stores';
import { cbPrimary } from '@/theme';
import { getNextBillingDate } from '@/utils/helpers';

const subscriptionBlockCSS = (cartStyles) => {
	const { borderColor } = cartStyles || {};
	return css`
		margin-bottom: 8px;
		&:last-child {
			margin-bottom: 0;
		}
		.payment-date-container {
			border: 2px solid ${borderColor || cbPrimary[500]};
			border-radius: 4px;
			text-align: center;
			padding: 20px 0;
			margin-bottom: 12px;
		}

		.details {
			font-size: 0.875rem;
		}
	`;
};

const SubscriptionBlock = (props) => {
	const {
		inBumpList,
		frequency,
		frequencyType,
		subsequentPayments,
		isUnlimited,
		remainingPayments,
		productType,
		initialOffsetValue,
		sku,
	} = props;
	const { template, selectedLanguage } = useStore(
		useShallow((state) => ({
			template: state.template,
			selectedLanguage: state.selectedLanguage,
		})),
	);
	const cartStyles = getZoneStyles(template, 'cart');
	const [nextBillingDate, setNextBillingDate] = useState(null);

	useEffect(() => {
		setNextBillingDate(
			getNextBillingDate(initialOffsetValue, selectedLanguage, frequency, frequencyType),
		);
	}, [selectedLanguage, frequency, frequencyType, initialOffsetValue]);

	return (
		<div
			css={subscriptionBlockCSS(cartStyles)}
			className={`CB-subscription-block CB-subscription-block-${sku}`}
		>
			<SubscriptionDetails
				frequency={frequency}
				frequencyType={frequencyType}
				subsequentPayments={subsequentPayments}
				nextBillingDate={nextBillingDate}
				isUnlimited={isUnlimited}
				inBumpList={inBumpList}
				remainingPayments={remainingPayments}
				productType={productType}
				sku={sku}
				classNames="CB-recurring-terms"
			/>
		</div>
	);
};

export default SubscriptionBlock;

SubscriptionBlock.propTypes = {
	sku: PropTypes.string,
	isBump: PropTypes.bool,
	inBumpList: PropTypes.bool,
	frequency: PropTypes.string,
	subsequentPayments: PropTypes.string,
	nextBillingDate: PropTypes.string,
	frequencyType: PropTypes.string,
	isUnlimited: PropTypes.bool,
	remainingPayments: PropTypes.number,
	productType: PropTypes.string,
	initialOffsetValue: PropTypes.number,
};
