export const primaryFont = 'proxima-nova, sans-serif';

export const htmlFontSize = 16;
export const fontFamily = primaryFont;
export const fontSize = 14;
export const fontWeightLight = 300;
export const fontWeightRegular = 400;
export const fontWeightMedium = 600;
export const fontWeightBold = 700;
export const baseLetterSpacing = '.005rem';
export const defaultHeadingFontWeight = 600;
const noTransform = 'none';

export const typography = {
	fontFamily: primaryFont,

	h1: {
		fontSize: '2rem', // 32px
		fontWeight: fontWeightBold,
		letterSpacing: baseLetterSpacing,
		lineHeight: 1.25, // 32px
		textTransform: noTransform, // WAM
	},
	h2: {
		fontSize: '1.75rem', // 28px
		fontWeight: fontWeightMedium,
		letterSpacing: baseLetterSpacing,
		lineHeight: 1.36, // 32px
		textTransform: noTransform, // WAM
	},
	h3: {
		fontSize: '1.5rem', // 24px
		fontWeight: fontWeightMedium,
		letterSpacing: baseLetterSpacing,
		lineHeight: 1.33, // 32px
	},
	h4: {
		fontSize: '1.25rem', // 20px
		fontWeight: fontWeightBold,
		letterSpacing: baseLetterSpacing,
		lineHeight: 1.4, // 24px
	},
	h5: {
		fontSize: '1.125rem', // 18px
		fontWeight: fontWeightMedium,
		letterSpacing: baseLetterSpacing,
		lineHeight: 1.56, // 24px
	},
	h6: {
		fontSize: '1rem', // 16px
		fontWeight: fontWeightMedium,
		letterSpacing: baseLetterSpacing,
		lineHeight: 1.75, // 24px
	},
	subtitle1: {
		// 'Paragraph Lead' in the CB Design System
		fontSize: '1.25rem', // 20px
		fontWeight: fontWeightRegular,
		lineHeight: 1.6, // 32px
	},
	body1: {
		fontSize: '1.125rem', // 18px
		fontWeight: 'inherit',
		letterSpacing: baseLetterSpacing,
		lineHeight: 1.33, // 24px
	},
	body2: {
		fontSize: '1rem', // 16px
		fontWeight: 'inherit',
		letterSpacing: baseLetterSpacing,
		lineHeight: 1.5, // 24px
	},
	body3: {
		fontSize: '0.875rem', // 14px
		fontWeight: 'inherit',
		letterSpacing: baseLetterSpacing,
		lineHeight: 1.14, // 16px
	},
	button: {
		fontWeight: fontWeightMedium,
		fontSize: '1.125rem', // 18px
		letterSpacing: 0,
		lineHeight: 1.33, // 24px
		textTransform: noTransform,
	},
	overline: {
		fontSize: '0.75rem', // 12px
		letterSpacing: baseLetterSpacing,
		lineHeight: 1.18, // 20px
	},
	caption: {
		fontSize: '0.75rem', // 12px
		fontWeight: fontWeightRegular,
		letterSpacing: '.007rem', // 0.11px
		lineHeight: 1.33, // 16px
	},
};
