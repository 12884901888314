import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import CityAndState from './CityAndState';
import CBTextField from '@/components/common/CBTextField';
import { FindLocationContext } from '@/state/FindLocationContext';
import { useStore } from '@/state/stores';
import { doesCountryHaveProvinces, doesCountryHaveStates } from '@/utils/helpers';
import { isInputRequired } from '@/utils/requiredInputs';

const ZipCityAndState = (props) => {
	const {
		isShippable,
		accessor,
		cartInitialized,
		isCartUpdating,
		countryCode,
		cityOptions,
		stateOptions,
	} = props;

	const selectedLanguage = useStore((state) => state.selectedLanguage);

	const { fetchAndLoadLocationData } = useContext(FindLocationContext);

	const { t } = useTranslation('checkout');

	const countriesToCapitalizeZip = ['GB', 'IE'];

	const showErrorOnZipInput =
		(countryCode === 'US' && cityOptions?.length === 0) ||
		(countryCode === 'CA' && stateOptions?.length === 0);

	const hasStates = doesCountryHaveStates(countryCode);
	const hasProvinces = doesCountryHaveProvinces(countryCode);
	const hasStatesOrProvinces = hasStates || hasProvinces;

	const zipGridWidth = () => {
		if (hasStatesOrProvinces && selectedLanguage === 'es') {
			return 4.5;
		}

		if (hasStatesOrProvinces) {
			return 3.5;
		}

		return 6;
	};

	return (
		<>
			<Grid item xs={12} lmd={zipGridWidth()}>
				<CBTextField
					name={`${accessor}.zip`}
					onChange={fetchAndLoadLocationData(accessor)}
					label={
						countryCode === 'US'
							? t('field.zip-code.label')
							: t('field.postal-code.label')
					}
					required={isInputRequired(
						'zip',
						countryCode,
						isShippable ? 'isShippable' : null,
					)}
					updateOn="change"
					defaultValue=""
					disabled={!cartInitialized || isCartUpdating}
					convertCase={countriesToCapitalizeZip.includes(countryCode) ? 'upper' : null}
					helperTextOverride={
						showErrorOnZipInput
							? t('field.common.error.invalid', {
									inputName: t('field.zip-code.label'),
								})
							: null
					}
					addressAccessor={accessor}
					showPlaceholder={!cartInitialized}
				/>
			</Grid>
			<CityAndState countryCode={countryCode} accessor={accessor} />
		</>
	);
};

ZipCityAndState.propTypes = {
	accessor: PropTypes.oneOf(['billing', 'shipping']),
	cartInitialized: PropTypes.bool,
	isCartUpdating: PropTypes.bool,
	countryCode: PropTypes.string,
	cityOptions: PropTypes.array,
	stateOptions: PropTypes.array,
	zip: PropTypes.string,
	zipError: PropTypes.object,
	showPlaceholder: PropTypes.bool,
	isShippable: PropTypes.bool,
};

ZipCityAndState.defaultProps = {
	showPlaceholder: false,
};

export default ZipCityAndState;
