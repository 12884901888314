import { cloneDeep } from 'lodash';

import i18n from '@/i18n';
import { getMerchantIdentifier } from '@/utils/applePayHelpers';
import { paymentMethodCurrencyDataMap, PaymentMethods } from '@/utils/enums';
import { checkProductComboErrors, logErrors } from '@/utils/errorHandler';
import { preloadImageSrc, stringIsEqualCaseInsensitive } from '@/utils/helpers';
import { getAllProducts, removeDuplicateBumps } from '@/utils/products';
import { inputData } from '@/utils/regexAndRequiredInputsByCountry';
import { addressRegex, getValidationSchema } from '@/utils/validation';

export const fetchUrl = process.env.REACT_APP_GRAPHQL_URL
	? process.env.REACT_APP_GRAPHQL_URL
	: process.env.REACT_APP_BASE_PATH.concat('/graphql');

export const mimicGQLHooksResponse = async (response) => {
	// The rest of the application uses graphql-hooks for data fetching,
	// but we don't have that option here because this code may be running
	// outside of our React app. This function takes in a response from the
	// browser native fetch API, and transforms the data to resemble that
	// provided by graphql-hooks, so logic can stay consistent
	const generateResult = ({ httpError, graphQLErrors, data }) => {
		const errorFound = !!((graphQLErrors && graphQLErrors.length > 0) || httpError);
		return !errorFound ? { data } : { data, error: { httpError, graphQLErrors } };
	};
	if (!response.ok) {
		const body = await response.text();
		const { status, statusText } = response;
		return generateResult({
			httpError: {
				status,
				statusText,
				body,
			},
		});
	} else {
		const { errors, data } = await response.json();
		return generateResult({
			graphQLErrors: errors,
			data,
		});
	}
	// Any fetchErrors will cause a throw to the catch block of a query
};

export const retryWrapper = async (endpoint, cb, fetchOptions) => {
	let retries = 0;
	const operation = JSON.parse(fetchOptions?.body || null)?.query || null;
	return new Promise((resolve, reject) => {
		const attempt = async () => {
			try {
				const response = await cb();
				const status = response.status;
				const shouldRetry = status >= 500 && retries < 3;
				const body = await mimicGQLHooksResponse(response);
				if (body.error) {
					if (operation) {
						logErrors({ operation, result: body });
					}
					// GeoCurrencies endpoint may fail because of a product combo error
					// to avoid retrying with a request we already know is bad as a result,
					// we check for this and reject immediately if this type of error is found
					if (endpoint === 'GEO_CURRENCIES') {
						const badProducts = checkProductComboErrors(body.error);
						if (badProducts) {
							// Return body without retrying, so call can be started again with only good products
							// Resolve so promise does not throw to catch block before processing
							resolve(body);
							return;
						}
					}
					if (shouldRetry) {
						const waitTime = retries ? 2000 : 0;
						setTimeout(attempt, waitTime);
						retries++;
						return;
					}
					reject(body);
					return;
				}
				resolve(body);
			} catch (body) {
				if (operation) {
					logErrors({ operation, result: body });
				}
				reject(body);
			}
		};
		attempt();
	});
};

export const fetchWithRetry = async (endpoint, url, options) => {
	return retryWrapper(endpoint, () => fetch(url, options), options);
};

export const getQuantity = (product) => {
	product.maxQuantity = product.maxQuantity || 99;
	return product.quantity > product.maxQuantity ? product.maxQuantity : product.quantity;
};

// Flattens results returned by nested Promise.all/allSettled calls

// NOTE: Does not respect existing keys. Keys will be added depth-first.
// Be wary about duplicates.
export const flattenResults = (arr) => {
	let flattenedResults = {};
	for (let i = 0; i < arr.length; i++) {
		if (Array.isArray(arr[i])) {
			flattenedResults = { ...flattenedResults, ...flattenResults(arr[i]) };
		} else {
			flattenedResults = {
				...flattenedResults,
				graphql: {
					...flattenedResults?.graphql,
					...arr[i]?.graphql,
				},
				state: {
					...flattenedResults?.state,
					...arr[i]?.state,
				},
				errors: {
					...flattenedResults?.errors,
					...arr[i]?.errors,
				},
			};
		}
	}
	return flattenedResults;
};

// Some of the initial state of our application is determined by the combined state of both
// bump and product queries. However, these queries are split from one another, because
// we want the bump query to be able to fail without immediately causing Promise.all to reject.

// This function takes in the bumps query data, as well as the query data that includes our products
// data, and generates the necessary state from both of those, before sending it on to the next step
// in the chain.
export const deriveCombinedProductAndBumpState =
	({ urlVars, urlProductsArray, rebuildCartFromSku, fetchHeaders }) =>
	async ([bumpsPromiseResult, requiredDataPromiseResult]) => {
		const selectedLanguage = i18n.language;
		const requiredDataFetchHasFailed = requiredDataPromiseResult.status === 'rejected';
		if (requiredDataFetchHasFailed) {
			// If required data fails, immediately reject, as form cannot load
			// reason should be an object which contains errors key
			return Promise.reject(requiredDataPromiseResult.reason);
		}
		// requiredDataPromiseResult is the result of Promise.all, meaning we must combine keys
		// from each query into one object
		const data = requiredDataPromiseResult.value.reduce((a, b) => {
			const combinedGraphql = { ...a.graphql, ...b.graphql };
			const combinedState = { ...a.state, ...b.state };
			const combinedErrors = { ...a.errors, ...b.errors };
			return {
				graphql: combinedGraphql,
				state: combinedState,
				errors: combinedErrors,
			};
		}, {});
		const bumpsDataFetchHasFailed = bumpsPromiseResult.status === 'rejected';
		// Bumps query is not required, so simple skip out on adding data if it fails
		if (!bumpsDataFetchHasFailed) {
			const bumpsQuery = bumpsPromiseResult.value;
			data.graphql = {
				...data.graphql,
				...bumpsQuery.graphql,
			};
			data.state = {
				...data.state,
				...bumpsQuery.state,
			};
			data.errors = {
				...data.errors,
				...bumpsQuery.errors,
			};
		}
		const { graphql, errors } = data;

		// cb-stamp header can come from a successful affiliate query, or an affiliate
		// query in an error state due to a blocked or inactive affiliate
		const stamp =
			graphql?.AFFILIATE?.affiliate?.stamp ||
			errors?.AFFILIATE?.error?.graphQLErrors?.[0]?.extensions?.payload;
		if (stamp) {
			fetchHeaders['cb-stamp'] = stamp;
		}
		// From this point on, simply generate different pieces of state that can be derived from
		// the product and bumps queries
		const productsArray = graphql.PRODUCTS.products;
		const bumpsArray = graphql?.ORDER_BUMP?.orderBump?.orderBumpProducts || [];
		removeDuplicateBumps(productsArray, bumpsArray);
		if (productsArray.length + bumpsArray.length > 25) {
			bumpsArray.splice(0, bumpsArray.length);
		}
		const productsRawData = {
			products: graphql.PRODUCTS.products,
			orderBump: graphql?.ORDER_BUMP?.orderBump,
		};
		const allowEditQuantity = data.state.siteSettings.allowEditQuantity;
		const allProducts = getAllProducts(
			productsRawData,
			rebuildCartFromSku || urlProductsArray,
			selectedLanguage,
			allowEditQuantity,
		);
		const transformedProducts = allProducts.filter((item) => !item.isBump);
		const refundDaysLimit =
			transformedProducts.length === 1 && transformedProducts[0].refundDaysLimit > 0
				? transformedProducts[0].refundDaysLimit
				: null;
		let isInvalidProductInCart = false;
		const cartStatus = allProducts.map((product) => {
			const inCartBeforeGoingToPaypal = rebuildCartFromSku?.find((item) =>
				stringIsEqualCaseInsensitive(item.sku, product.sku),
			);

			if (product.isTestPurchaseOnly) {
				isInvalidProductInCart = true;
			}

			return {
				sku: product.sku,
				isBump: product.isBump,
				isActive: inCartBeforeGoingToPaypal ? true : !product.isBump,
				isSubscription: product.recurring,
				maxQuantity: product.maxQuantity,
				quantity: product.quantity,
			};
		});

		const calculateCartLineItems = cartStatus
			.filter((product) => product.isActive)
			.map((product) => ({
				productId: urlVars.vvvv + '-' + product.sku,
				quantity: getQuantity(product),
				bump: product.isBump,
			}));

		const availableItems = [];
		const allSKUs = [];
		allProducts.forEach((product) => {
			const status = cartStatus.find((item) => item.sku === product.sku);
			if (!status.isActive) {
				const targetAvailableItem = {
					productId: product.id,
					quantity: getQuantity(product),
					bump: product.isBump,
				};
				availableItems.push(targetAvailableItem);
			}
			allSKUs.push(product.sku);
		});
		// Add the state we generated above to the state key. Subsequent queries now have a single object
		// representing combined state of all queries passed
		return {
			...data,
			state: {
				...data.state,
				allProducts,
				refundDaysLimit,
				isInvalidProductInCart,
				cartStatus,
				availableItems,
				allSKUs,
				calculateCartLineItems,
			},
		};
	};

// Map keys as they appear in URL query string to their names in our formData.
const urlParamFormMap = {
	name: {
		formKey: 'fullName',
		billingAndShipping: true,
	},
	st_addr1: {
		formKey: 'address1',
		billingAndShipping: true,
	},
	st_addr2: {
		formKey: 'address2',
		billingAndShipping: true,
	},
	st_city: {
		formKey: 'city',
		billingAndShipping: true,
	},
	zipc: {
		formKey: 'zip',
		billingAndShipping: true,
	},
	zipcode: {
		formKey: 'zip',
		billingAndShipping: true,
	},
	st_zipc: {
		formKey: 'zip',
		billingAndShipping: true,
	},
	emal: {
		formKey: 'email',
		billingAndShipping: false,
	},
	email: {
		formKey: 'email',
		billingAndShipping: false,
	},
	phone: {
		formKey: 'phone',
		billingAndShipping: false,
	},
};

// Form consists of controlled inputs, and we must construct the initial object form will
// read from based on state calculated thus far, and default values mandated through
// URL vars or cart rebuild values
export const constructDefaultFormState = (state, queryParams) => {
	const {
		cityOptions,
		stateOptions,
		defaultCountry,
		urlVars,
		allProducts,
		isShippable,
		validatedCoupon,
		siteSettings,
		cartStatus,
		showPhone,
		showCoupon,
		paymentMethod,
	} = state;
	const cities = cityOptions.billing;
	const states = stateOptions.billing;
	const allowEditQuantity = siteSettings.allowEditQuantity;
	const { initialCoupon, rebuildFormData } = queryParams;
	const couponIsValid = /^[A-Za-z0-9_-]{5,20}$/.test(initialCoupon);
	let couponCodeInputValue = '';
	if (initialCoupon && !couponIsValid) {
		// If coupon is valid, it will have been applied. If invalid,
		// display in form field for validation
		couponCodeInputValue = initialCoupon;
	}
	let phoneInputValue = '';
	const phone = urlVars.phone;
	if (showPhone && phone) {
		// If there is an empty string in the beginning of the phone its because the class URLSearchParams (in URLVars.js)
		// assumes a '+' is an empty space. So if there is an empty space we add back in the '+' taken out by URLSearchParams
		const isFirstPhoneCharEmpty = phone[0] === ' ';
		phoneInputValue = isFirstPhoneCharEmpty ? phone.replace(/^\s/, '+') : phone;
	}

	let defaultFormValues = {};
	if (rebuildFormData) {
		// If we're getting passed rebuild values, then we already have all necessary values,
		// so simply apply them and account for billing/shipping keys
		const locationKeys = [
			'fullName',
			'address1',
			'address2',
			'city',
			'state',
			'zip',
			'countryCode',
		];
		const newFormValues = {
			billing: {},
			shipping: {},
		};
		const incomingKeys = Object.keys(rebuildFormData);
		for (let i = 0; i < incomingKeys.length; i++) {
			const key = incomingKeys[i];
			if (locationKeys.includes(key)) {
				newFormValues.billing[key] = rebuildFormData[key];
				if (key === 'countryCode') {
					newFormValues.shipping[key] = rebuildFormData[key];
				}
			} else {
				newFormValues[key] = rebuildFormData[key];
			}
		}
		defaultFormValues = newFormValues;
		defaultFormValues.couponCode = couponCodeInputValue;
		defaultFormValues.phone = phoneInputValue;
	} else {
		// Otherwise, generate values. Test zip to ensure invalid string isn't added to
		// masked input
		defaultFormValues = {
			billing: {
				countryCode: defaultCountry.countryCode,
			},
			shipping: {
				countryCode: defaultCountry.countryCode,
			},
			couponCode: couponCodeInputValue,
		};
		for (const key in urlVars) {
			const { formKey, billingAndShipping } = urlParamFormMap[key] || {};
			if (formKey) {
				let initialValue = '';
				if (
					formKey === 'zip' &&
					['US', 'CA', 'GB', 'AU'].includes(defaultCountry.countryCode)
				) {
					// Validate zip for countries that restrict zip input with text masking, so value
					// in state does not differ from value in input
					const isValid = new RegExp(inputData[defaultCountry.countryCode].zipRegex).test(
						urlVars[key],
					);
					if (isValid) {
						initialValue = urlVars[key];
					}
				} else if (formKey === 'email') {
					// replaces empty spaces with '+' because URLSearchParams will convert the + to a space
					initialValue = urlVars[key].replace(/ /g, '+');
				} else if (formKey === 'phone') {
					initialValue = phoneInputValue;
				} else if (['address1', 'address2'].includes(formKey)) {
					// Check if the cart is physical. If it is, set their initial value. If not, leave as empty string
					if (isShippable) {
						const isValid = addressRegex.test(urlVars[key]);
						if (isValid) {
							initialValue = urlVars[key];
						}
					}
				} else {
					initialValue = urlVars[key];
				}
				if (billingAndShipping) {
					defaultFormValues.billing[formKey] = initialValue;
				} else {
					defaultFormValues[formKey] = initialValue;
				}
			}
		}
		if (cities && cities.length) {
			const urlCity = urlVars.st_city;
			const fallbackCity = cities.length === 1 ? cities[0].value : '';
			if (urlCity) {
				const matchingCity = cities.find(
					(item) => item.value.toLowerCase() === urlCity.toLowerCase(),
				)?.value;
				const initialSelected = matchingCity || fallbackCity;
				defaultFormValues.billing.city = initialSelected;
			} else {
				defaultFormValues.billing.city = fallbackCity;
			}
		}
		if (states && states.length) {
			defaultFormValues.billing.state = states.length === 1 ? states[0].value : '';
		}
	}
	const newSchema = getValidationSchema(
		allProducts,
		paymentMethod,
		defaultCountry.countryCode,
		defaultCountry.countryCode,
		isShippable,
		validatedCoupon,
		allowEditQuantity,
		cartStatus,
		showPhone,
		showCoupon,
		defaultFormValues.couponCode,
		defaultFormValues.billing?.zip,
		defaultFormValues.shipping?.zip,
		true,
	);
	return {
		formData: defaultFormValues,
		validationSchema: newSchema,
	};
};

/**
 *
 * @param {*} template
 * @returns Custom order form template or exit offer template with parsed attributes
 */
export const parseAttributes = (template) => {
	if (!template) {
		return;
	}
	const newTemplate = cloneDeep(template);

	const templateAttributes = newTemplate.style?.attributes;
	if (templateAttributes && typeof templateAttributes === 'string') {
		newTemplate.style.attributes = JSON.parse(templateAttributes);
	}

	newTemplate.zones.forEach((zone) => {
		const zoneAttributes = zone.style?.attributes;
		if (zoneAttributes && typeof zoneAttributes === 'string') {
			zone.style.attributes = JSON.parse(zoneAttributes);
		}
		if (Array.isArray(zone.widgets)) {
			zone.widgets.forEach((widget) => {
				if (typeof widget?.image?.imageUrl === 'string') {
					const src = widget.image.imageUrl;
					preloadImageSrc(src);
				}
				const widgetAttributes = widget?.style?.attributes;
				if (widgetAttributes && typeof widgetAttributes === 'string') {
					widget.style.attributes = JSON.parse(widgetAttributes);
				}
			});
		}
	});
	return newTemplate;
};

export const canMakeApplePayments = () => {
	try {
		return !!window.ApplePaySession?.canMakePayments();
	} catch (err) {
		console.error('Apple Pay can make payments check failed', err);
		return false;
	}
};

export const canMakeApplePaymentsWithActiveCard = async () => {
	try {
		if (typeof window.ApplePaySession?.canMakePaymentsWithActiveCard !== 'function') {
			return false;
		}
		const merchantIdentifier = getMerchantIdentifier(process.env.REACT_APP_DEPLOYMENT_TARGET);
		const canMakePaymentsWithActiveCard =
			await window.ApplePaySession.canMakePaymentsWithActiveCard(merchantIdentifier);
		return canMakePaymentsWithActiveCard;
	} catch (err) {
		console.error('Apple Pay active card check failed', err);
		return false;
	}
};

/**
 * purpose - Gets the supported countries for a digital payment type
 * @param {object} geoCurrenciesData - geoCurrency data that includes countries array
 * @param {enum} payMethod - one of @see PaymentMethods enum
 * @returns {array} Array of supported country codes
 */
export const getSupportedCountries = (geoCurrenciesData, payMethod) => {
	if (!geoCurrenciesData || !geoCurrenciesData.countries || !payMethod) {
		return [];
	}

	return geoCurrenciesData.countries.filter((country) => {
		return country.currencies.some(
			(currency) =>
				currency.key === paymentMethodCurrencyDataMap[payMethod] &&
				currency.value?.length > 0,
		);
	});
};

export const getSupportedCountryCodes = (supportedCountries) => {
	return supportedCountries.map((countryData) => countryData.countryCode);
};

export const browserSupportsAppleVersion = () => {
	try {
		return !!window.ApplePaySession?.supportsVersion(12);
	} catch (err) {
		console.error('Apple Pay version check failed', err);
		return false;
	}
};

export const getPazeAcceptedCreditCards = (entireAcceptedList) => {
	// paze enum for acceptedPaymentCardNetworks is upper case
	const upperCaseCCs = entireAcceptedList.map((cc) => {
		return cc.toUpperCase();
	});
	return upperCaseCCs.filter((cc) => {
		// paze enum is currently only VISA and MASTERCARD
		return cc === 'VISA' || cc === 'MASTERCARD';
	});
};

export const getPazeDataFromGeoCurrencies = (defaultCountry, geoCurrenciesData, pazeData) => {
	if (defaultCountry.countryCode !== 'US') {
		return pazeData;
	}

	const pazeSupportedCountries = getSupportedCountries(geoCurrenciesData, PaymentMethods.PAZE);
	const USCountry = pazeSupportedCountries.find((country) => country.countryCode === 'US');

	if (USCountry) {
		const pazeCurrencyData = USCountry.currencies.find(
			(paymentType) => paymentType.key === paymentMethodCurrencyDataMap[PaymentMethods.PAZE],
		);
		const USDCurrency = pazeCurrencyData.value.find((currency) => currency.code === 'USD');

		if (USDCurrency) {
			return {
				acceptedCCs: getPazeAcceptedCreditCards(USDCurrency.accepted),
				consumerPresent: pazeData.consumerPresent,
				supportedCountries: ['US'],
				checkoutResponse: pazeData.checkoutResponse,
				uuid: pazeData.uuid,
				currencyData: {
					currencyData: pazeCurrencyData,
					// TODO: Hardcoded for now as Paze only supports USD in the initial release
					selectedCurrency: 'USD',
					defaultCountryCode: 'US',
				},
			};
		}
	}
	return pazeData;
};

/**
 * purpose - This sets up the accepted credit cards/supported countries and other data for both Paze and Apple Pay
 * @param {object} defaultCountry - default country for the order
 * @param {object} geoCurrenciesData - geoCurrency data that includes countries array
 * @param {string} selectedCurrency - currently user selected currency
 * @param {boolean} isPreview - whether the user is in preview mode
 * @returns {object} Object that contains apple pay data for the order
 */
export const getApplePayDataFromGeoCurrencies = async (
	defaultCountry,
	geoCurrenciesData,
	selectedCurrency,
	isPreview,
) => {
	const appleSupportedCountries = getSupportedCountries(
		geoCurrenciesData,
		PaymentMethods.APPLE_PAY,
	);
	const newApplePayData = {
		acceptedCCs: [],
		showApplePayButton: false,
		defaultToApplePay: false,
		supportedCountries: getSupportedCountryCodes(appleSupportedCountries),
		// TODO: Update when designs are in for new currency selection UI.
		// Apple Pay only supports the default selected currency and default country at this time
		currencyData: {
			currencyData: null,
			selectedCurrency: '',
			defaultCountryCode: '',
		},
	};
	try {
		const { currencies } = defaultCountry;
		for (const currency of currencies) {
			const matchingCurrencyValue = currency.value.find(
				(currencyValue) => currencyValue.code === selectedCurrency,
			);
			if (matchingCurrencyValue) {
				if (currency.key === paymentMethodCurrencyDataMap[PaymentMethods.APPLE_PAY]) {
					const showAppleButton =
						canMakeApplePayments() && browserSupportsAppleVersion() && !isPreview;
					newApplePayData.currencyData = {
						currencyData: currency,
						selectedCurrency: selectedCurrency,
						defaultCountryCode: defaultCountry.countryCode,
					};
					newApplePayData.acceptedCCs = matchingCurrencyValue.accepted;
					newApplePayData.showApplePayButton = showAppleButton;
					newApplePayData.defaultToApplePay =
						showAppleButton && (await canMakeApplePaymentsWithActiveCard());
				}
			}
		}
	} catch (error) {
		console.error('error setting digital payment data', error);
	}

	return newApplePayData;
};
