import Error from '@mui/icons-material/Error';
import Info from '@mui/icons-material/Info';
import Warning from '@mui/icons-material/Warning';
import { createTheme } from '@mui/material/styles';

import {
	breakpoints,
	cbNegative,
	cbNeutral,
	cbPrimary,
	defaultBorderColor,
	defaultInputLabelFontWeight,
	defaultInputValueFontWeight,
	defaultTextFieldShadow,
	palette,
	typography,
} from './index';

export const initialWidgetPadding = '0 0 0 0';
export const initialDividerWidgetPadding = '8px 0 8px 0';
export const orderFormHeaderHeight = '52px';
export const receiptPageFooterHeight = '90px';
export const layoutMaxWidth = `994px`;
export const exitOfferWidth = '510px';

export const muiOutlinedInputDefaultClasses = {
	root: 'CB-outlined-input-root',
	disabled: 'CB-outlined-input-disabled',
	error: 'CB-outlined-input-error',
	focused: 'CB-outlined-input-focused',
	adornedEnd: 'CB-outlined-input-adornedEnd',
	adornedStart: 'CB-outlined-input-adornedStart',
	colorSecondary: 'CB-outlined-input-colorSecondary',
	input: 'CB-outlined-input-input',
	inputAdornedEnd: 'CB-outlined-input-inputAdornedEnd',
	inputAdornedStart: 'CB-outlined-input-inputAdornedStart',
	inputMultiline: 'CB-outlined-input-inputMultiline',
	inputSizeSmall: 'CB-outlined-input-inputSizeSmall',
	inputTypeSearch: 'CB-outlined-input-inputTypeSearch',
	multiline: 'CB-outlined-input-multiline',
	notchedOutline: 'CB-outlined-input-notchedOutline',
	sizeSmall: 'CB-outlined-input-sizeSmall',
};

const themeConfig = {
	palette,
	typography,
	breakpoints,
};

// Material UI theme with overrides
export const checkoutTheme = createTheme(themeConfig, {
	components: {
		MuiAccordion: {
			defaultProps: {
				classes: {
					disabled: 'CB-accordion-disabled',
					expanded: 'CB-accordion-expanded',
					gutters: 'CB-accordion-gutters',
					region: 'CB-accordion-region',
					root: 'CB-accordion-root',
					rounded: 'CB-accordion-rounded',
				},
			},
			styleOverrides: {
				root: {
					'&:last-of-type': {
						borderRadius: '0',
					},
				},
			},
		},
		MuiAccordionDetails: {
			defaultProps: {
				classes: {
					root: 'CB-accordion-details-root',
				},
			},
		},
		MuiAccordionSummary: {
			defaultProps: {
				classes: {
					disabled: 'CB-accordion-summary-disabled',
					expanded: 'CB-accordion-summary-expanded',
					focusVisible: 'CB-accordion-summary-focusVisible',
					content: 'CB-accordion-summary-content',
					expandIconWrapper: 'CB-accordion-summary-expandIconWrapper',
					gutters: 'CB-accordion-summary-gutters',
					root: 'CB-accordion-summary-root',
				},
			},
		},
		MuiAlert: {
			defaultProps: {
				iconMapping: {
					error: <Error color="error" />,
					info: <Info color="info" />,
					warning: <Warning color="warning" />,
				},
				variant: 'outlined',
				classes: {
					action: 'CB-alert-action',
					colorError: 'CB-alert-colorError',
					colorInfo: 'CB-alert-colorInfo',
					colorSuccess: 'CB-alert-colorSuccess',
					colorWarning: 'CB-alert-colorWarning',
					filled: 'CB-alert-filled',
					icon: 'CB-alert-icon',
					message: 'CB-alert-message',
					outlined: 'CB-alert-outlined',
					root: 'CB-alert-root',
					standard: 'CB-alert-standard',
				},
			},
			styleOverrides: {
				root: {
					padding: '1rem',
					backgroundColor: cbNeutral[950],
					borderRadius: 8,
					'& .MuiAlert-icon': {
						display: 'flex',
						alignItems: 'center',
					},
				},
				message: {
					// fontFamily: primaryFont,
					'& h5': {
						fontSize: '1.25rem',
					},
				},
				outlinedError: {
					background: palette.error.light,
					borderColor: cbNeutral[900],
					borderLeftColor: palette.error.main,
					borderWidth: '1px 1px 1px 8px',

					'& .MuiAlert-icon': {
						color: palette.error.main,
					},

					'& .MuiAlert-message': {
						color: palette.error.dark,
					},
				},
			},
		},
		MuiCard: {
			defaultProps: {
				classes: {
					root: 'CB-card-root',
				},
			},
			styleOverrides: {
				root: {
					borderRadius: '0px',
				},
			},
		},
		MuiCardContent: {
			styleOverrides: {
				root: {
					padding: '1rem 1.5rem',
					'&:last-child': {
						paddingBottom: '1rem',
					},
				},
			},
		},
		MuiTypography: {
			defaultProps: {
				classes: {
					root: 'CB-typography-root',
					alignCenter: 'CB-typography-alignCenter',
					alignJustify: 'CB-typography-alignJustify',
					alignLeft: 'CB-typography-alignLeft',
					alignRight: 'CB-typography-alignRight',
					body1: 'CB-typography-body1',
					body2: 'CB-typography-body2',
					button: 'CB-typography-button',
					caption: 'CB-typography-caption',
					gutterBottom: 'CB-typography-gutterBottom',
					h1: 'CB-typography-h1',
					h2: 'CB-typography-h2',
					h3: 'CB-typography-h3',
					h4: 'CB-typography-h4',
					h5: 'CB-typography-h5',
					h6: 'CB-typography-h6',
					inherit: 'CB-typography-inherit',
					noWrap: 'CB-typography-noWrap',
					overline: 'CB-typography-overline',
					paragraph: 'CB-typography-paragraph',
					subtitle1: 'CB-typography-subtitle1',
					subtitle2: 'CB-typography-subtitle2',
				},
			},
			styleOverrides: {
				gutterBottom: {
					marginBottom: '.5em',
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					fontSize: '1rem',
					color: cbNeutral[400],
					fontWeight: defaultInputLabelFontWeight,
					position: 'absolute',
					transform: 'translate(12px, 12px) scale(1)',
					overflow: 'visible',
					'&.tokenex-label-large': {
						transform: 'translate(12px, 12px) scale(1.25)',
					},
				},
				shrink: {
					transform: 'translate(12px, 7px) scale(.85)',
					fontSize: '.75rem',
					fontWeight: defaultInputLabelFontWeight,
				},
			},
		},
		MuiInput: {
			defaultProps: {
				classes: {
					root: 'CB-input-root',
					disabled: 'CB-input-disabled',
					error: 'CB-input-error',
					focused: 'CB-input-focused',
					colorSecondary: 'CB-input-colorSecondary',
					formControl: 'CB-input-formControl',
					fullWidth: 'CB-input-fullWidth',
					input: 'CB-input-input',
					inputAdornedEnd: 'CB-input-inputAdornedEnd',
					inputMultiline: 'CB-input-inputMultiline',
					inputSizeSmall: 'CB-input-inputSizeSmall',
					inputTypeSearch: 'CB-input-inputTypeSearch',
					multiline: 'CB-input-multiline',
					sizeSmall: 'CB-input-sizeSmall',
					underline: 'CB-input-underline',
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					height: '3rem',
					borderColor: defaultBorderColor,
					'&.Mui-disabled': {
						background: cbNeutral[950],
						pointerEvents: 'none',
					},
					'& fieldset': {
						borderColor: defaultBorderColor,
					},
					'&.Mui-error': {
						color: palette.error.main,
						'&.Mui-focused .MuiInputBase-input': {
							color: cbNeutral[400],
						},
						'&.Mui-focused fieldset': {
							borderColor: palette.primary.main,
						},
						'.MuiOutlinedInput-notchedOutline': {
							borderColor: cbNegative[500],
						},
					},
					'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
						borderWidth: '1px',
					},
					'&:hover': {
						'&.Mui-error fieldset': {
							borderColor: cbNegative[500],
						},
						'&.Mui-focused fieldset': {
							borderWidth: '1px',
							borderColor: palette.primary.main,
						},
						'.MuiOutlinedInput-notchedOutline': {
							filter: defaultTextFieldShadow,
							borderColor: defaultBorderColor,
						},
					},
					'.MuiNativeSelect-select': {
						paddingBottom: '4px',
					},
				},
				input: {
					height: 'inherit',
					fontSize: '1rem',
					paddingTop: '21px',
					paddingLeft: '12px',
					boxSizing: 'border-box',
					paddingBottom: '8px',
					fontWeight: defaultInputValueFontWeight,
					'&.Mui-disabled': {
						background: cbNeutral[950],
						color: cbNeutral[400],
						WebkitTextFillColor: cbNeutral[400],
					},
				},
				multiline: {
					height: '100%',
				},
				select: {
					paddingBottom: '4px',
				},

				notchedOutline: {
					borderColor: defaultBorderColor,
				},

				adornedStart: {
					paddingLeft: 0,
				},
			},
			defaultProps: {
				notched: false,
				classes: muiOutlinedInputDefaultClasses,
			},
		},
		MuiTextField: {
			defaultProps: {
				classes: {
					root: 'CB-text-field-root',
				},
			},
		},
		MuiSelect: {
			defaultProps: {
				classes: {
					disabled: 'CB-select-disabled',
					error: 'CB-select-error',
					filled: 'CB-select-filled',
					icon: 'CB-select-icon',
					iconFilled: 'CB-select-iconFilled',
					iconOpen: 'CB-select-iconOpen',
					iconOutlined: 'CB-select-iconOutlined',
					iconStandard: 'CB-select-iconStandard',
					multiple: 'CB-select-multiple',
					nativeInput: 'CB-select-nativeInput',
					outlined: 'CB-select-outlined',
					root: 'CB-select-root',
					select: 'CB-select-select',
					standard: 'CB-select-standard',
				},
			},
			styleOverrides: {
				root: {
					paddingTop: '0',
					paddingBottom: '0',
					minHeight: '3rem',
					fontSize: '1.125rem',
				},
			},
		},
		MuiFormHelperText: {
			defaultProps: {
				classes: {
					disabled: 'CB-form-helper-text-disabled',
					error: 'CB-form-helper-text-error',
					focused: 'CB-form-helper-text-focused',
					required: 'CB-form-helper-text-required',
					contained: 'CB-form-helper-text-contained',
					filled: 'CB-form-helper-text-filled',
					root: 'CB-form-helper-text-root',
					sizeSmall: 'CB-form-helper-text-sizeSmall',
				},
			},
			styleOverrides: {
				root: {
					fontSize: '0.875rem',
				},
				contained: {
					marginLeft: '.75rem',
					marginRight: '.75rem',
				},
			},
		},
		MuiLink: {
			defaultProps: {
				classes: {
					focusVisible: 'CB-link-focusVisible',
					button: 'CB-link-button',
					root: 'CB-link-root',
					underlineAlways: 'CB-link-underlineAlways',
					underlineHover: 'CB-link-underlineHover',
					underlineNone: 'CB-link-underlineNone',
				},
			},
			styleOverrides: {
				root: {
					textDecoration: 'none',
				},
			},
		},
		MuiListItem: {
			defaultProps: {
				classes: {
					disabled: 'CB-list-item-disabled',
					focusVisible: 'CB-list-item-focusVisible',
					selected: 'CB-list-item-selected selected',
					alignItemsFlexStart: 'CB-list-item-alignItemsFlexStart',
					button: 'CB-list-item-button',
					container: 'CB-list-item-container',
					dense: 'CB-list-item-dense',
					divider: 'CB-list-item-divider',
					gutters: 'CB-list-item-gutters',
					padding: 'CB-list-item-padding',
					root: 'CB-list-item-root',
					secondaryAction: 'CB-list-item-secondaryAction',
				},
			},
			styleOverrides: {
				root: {
					'&$selected': {
						color: '#fff',
					},
				},
			},
		},
		MuiListItemText: {
			defaultProps: {
				classes: {
					dense: 'CB-list-item-text-dense',
					inset: 'CB-list-item-text-inset',
					multiline: 'CB-list-item-text-multiline',
					primary: 'CB-list-item-text-primary',
					root: 'CB-list-item-text-root',
					secondary: 'CB-list-item-text-secondary',
				},
			},
		},
		MuiListItemIcon: {
			defaultProps: {
				classes: {
					root: 'CB-list-item-icon-root',
					alignItemsFlexStart: 'CB-list-item-icon-alignItemsFlexStart',
				},
			},
		},
		MuiButton: {
			defaultProps: {
				disableElevation: true,
				variant: 'default',
				classes: {
					disabled: 'CB-button-disabled',
					focusVisible: 'CB-button-focusVisible',
					colorError: 'CB-button-colorError',
					colorInfo: 'CB-button-colorInfo',
					colorInherit: 'CB-button-colorInherit',
					colorPrimary: 'CB-button-colorPrimary',
					colorSecondary: 'CB-button-colorSecondary',
					colorSuccess: 'CB-button-colorSuccess',
					colorWarning: 'CB-button-colorWarning',
					contained: 'CB-button-contained',
					disableElevation: 'CB-button-disableElevation',
					endIcon: 'CB-button-endIcon',
					fullWidth: 'CB-button-fullWidth',
					icon: 'CB-button-icon',
					outlined: 'CB-button-outlined',
					root: 'CB-button-root',
					sizeLarge: 'CB-button-sizeLarge',
					sizeMedium: 'CB-button-sizeMedium',
					sizeSmall: 'CB-button-sizeSmall',
					startIcon: 'CB-button-startIcon',
					text: 'CB-button-text',
				},
			},
			variants: [
				{
					props: { variant: 'default' },
					style: {
						backgroundColor: palette.primary.main,
						color: cbNeutral[1000],
						'&:hover': {
							backgroundColor: cbPrimary[320],
						},
					},
				},
			],
			styleOverrides: {
				root: {
					whiteSpace: 'nowrap',
					fontWeight: 700,
					'&.Mui-disabled': {
						backgroundColor: cbNeutral[900],
						color: cbNeutral[400],
						pointerEvents: 'unset',
						border: 'none',
						'&:hover': {
							cursor: 'not-allowed',
							backgroundColor: cbNeutral[900],
						},
					},
				},
				sizeSmall: {
					height: '2rem',
					padding: '.5rem 1rem',
					fontSize: '1rem',
				},
				sizeMedium: {
					height: '3rem',
					padding: '.75rem 1.5rem',
					fontSize: '1.125rem',
				},
				sizeLarge: {
					height: '3.5rem',
					padding: '1rem 2rem',
					fontSize: '1.125rem',
					'& .MuiButton-startIcon': {
						marginRight: '16px',
					},
					'& .MuiButton-endIcon': {
						marginLeft: '16px',
					},
				},
				contained: {
					color: palette.primary.main,
					backgroundColor: cbPrimary[950],
					'&:hover': {
						backgroundColor: cbPrimary[900],
					},
				},
				containedSecondary: {
					backgroundColor: cbNeutral[400],
					'&:hover': {
						backgroundColor: cbNeutral[100],
					},
				},
				outlined: {
					color: cbNeutral[300],
					borderColor: cbNeutral[400],
					'&:hover': {
						backgroundColor: cbNeutral[950],
						borderColor: cbNeutral[400],
					},
				},
				text: {
					color: palette.primary.main,
					'&:hover': {
						backgroundColor: cbPrimary[950],
					},
				},
				textError: {
					color: palette.error.main,
					'&:hover': {
						backgroundColor: cbNegative[950],
					},
				},
			},
		},
		MuiCheckbox: {
			defaultProps: {
				size: '1.125rem',
				classes: {
					checked: 'CB-checkbox-checked checked',
					disabled: 'CB-checkbox-disabled',
					colorPrimary: 'CB-checkbox-colorPrimary',
					colorSecondary: 'CB-checkbox-colorSecondary',
					indeterminate: 'CB-checkbox-indeterminate',
					root: 'CB-checkbox-root checkbox',
					sizeMedium: 'CB-checkbox-sizeMedium',
					sizeSmall: 'CB-checkbox-sizeSmall',
				},
			},
			styleOverrides: {
				root: {
					backgroundColor: 'transparent',
					'.Mui-checked': {
						color: palette.primary.main,
					},
				},
			},
		},
		MuiFormControlLabel: {
			defaultProps: {
				classes: {
					disabled: 'CB-form-control-label-disabled',
					error: 'CB-form-control-label-error',
					required: 'CB-form-control-label-required',
					asterisk: 'CB-form-control-label-asterisk',
					label: 'CB-form-control-label-label label',
					labelPlacementBottom: 'CB-form-control-label-labelPlacementBottom',
					labelPlacementStart: 'CB-form-control-label-labelPlacementStart',
					labelPlacementTop: 'CB-form-control-label-labelPlacementTop',
					root: 'CB-form-control-label-root checkbox-input scrollable-label',
				},
			},
			styleOverrides: {
				label: {
					fontSize: '1rem',
				},
			},
		},
		MuiGrid: {
			defaultProps: {
				classes: {
					container: 'CB-grid-container',
					'direction-xs-column': 'CB-grid-direction-xs-column',
					'direction-xs-column-reverse': 'CB-grid-direction-xs-column-reverse',
					'direction-xs-row-reverse': 'CB-grid-direction-xs-row-reverse',
					'grid-xs-1': 'CB-grid-grid-xs-1',
					'grid-xs-10': 'CB-grid-grid-xs-10',
					'grid-xs-11': 'CB-grid-grid-xs-11',
					'grid-xs-12': 'CB-grid-grid-xs-12',
					'grid-xs-2': 'CB-grid-grid-xs-2',
					'grid-xs-3': 'CB-grid-grid-xs-3',
					'grid-xs-4': 'CB-grid-grid-xs-4',
					'grid-xs-5': 'CB-grid-grid-xs-5',
					'grid-xs-6': 'CB-grid-grid-xs-6',
					'grid-xs-7': 'CB-grid-grid-xs-7',
					'grid-xs-8': 'CB-grid-grid-xs-8',
					'grid-xs-9': 'CB-grid-grid-xs-9',
					'grid-xs-auto': 'CB-grid-grid-xs-auto',
					'grid-xs-true': 'CB-grid-grid-xs-true',
					item: 'CB-grid-item',
					root: 'CB-grid-root',
					'spacing-xs-1': 'CB-grid-spacing-xs-1',
					'spacing-xs-10': 'CB-grid-spacing-xs-10',
					'spacing-xs-11': 'CB-grid-spacing-xs-11',
					'spacing-xs-2': 'CB-grid-spacing-xs-2',
					'spacing-xs-3': 'CB-grid-spacing-xs-3',
					'spacing-xs-4': 'CB-grid-spacing-xs-4',
					'spacing-xs-5': 'CB-grid-spacing-xs-5',
					'spacing-xs-6': 'CB-grid-spacing-xs-6',
					'spacing-xs-7': 'CB-grid-spacing-xs-7',
					'spacing-xs-8': 'CB-grid-spacing-xs-8',
					'spacing-xs-9': 'CB-grid-spacing-xs-9',
					'wrap-xs-nowrap': 'CB-grid-wrap-xs-nowrap',
					'wrap-xs-wrap-reverse': 'CB-grid-wrap-xs-wrap-reverse',
					zeroMinWidth: 'CB-grid-zeroMinWidth',
				},
			},
		},
		MuiRadio: {
			defaultProps: {
				classes: {
					checked: 'CB-radio-checked',
					disabled: 'CB-radio-disabled',
					colorPrimary: 'CB-radio-colorPrimary',
					colorSecondary: 'CB-radio-colorSecondary',
					root: 'CB-radio-root',
					sizeSmall: 'CB-radio-sizeSmall',
				},
			},
		},
		MuiAutocomplete: {
			defaultProps: {
				classes: {
					expanded: 'CB-autocomplete-expanded',
					focused: 'CB-autocomplete-focused',
					focusVisible: 'CB-autocomplete-focusVisible',
					clearIndicator: 'CB-autocomplete-clearIndicator',
					endAdornment: 'CB-autocomplete-endAdornment',
					fullWidth: 'CB-autocomplete-fullWidth',
					groupLabel: 'CB-autocomplete-groupLabel',
					groupUl: 'CB-autocomplete-groupUl',
					hasClearIcon: 'CB-autocomplete-hasClearIcon',
					hasPopupIcon: 'CB-autocomplete-hasPopupIcon',
					input: 'CB-autocomplete-input',
					inputFocused: 'CB-autocomplete-inputFocused',
					inputRoot: 'CB-autocomplete-inputRoot',
					listbox: 'CB-autocomplete-listbox',
					loading: 'CB-autocomplete-loading',
					noOptions: 'CB-autocomplete-noOptions',
					option: 'CB-autocomplete-option',
					paper: 'CB-autocomplete-paper',
					popper: 'CB-autocomplete-popper',
					popperDisablePortal: 'CB-autocomplete-popperDisablePortal',
					popupIndicator: 'CB-autocomplete-popupIndicator',
					popupIndicatorOpen: 'CB-autocomplete-popupIndicatorOpen',
					root: 'CB-autocomplete-root',
					tag: 'CB-autocomplete-tag',
					tagSizeMedium: 'CB-autocomplete-tagSizeMedium',
					tagSizeSmall: 'CB-autocomplete-tagSizeSmall',
				},
			},
		},
		MuiCircularProgress: {
			defaultProps: {
				classes: {
					circle: 'CB-circularProgress-circle',
					circleDeterminate: 'CB-circular-progress-circleDeterminate',
					circleDisableShrink: 'CB-circular-progress-circleDisableShrink',
					circleIndeterminate: 'CB-circular-progress-circleIndeterminate',
					colorPrimary: 'CB-circular-progress-colorPrimary',
					colorSecondary: 'CB-circular-progress-colorSecondary',
					determinate: 'CB-circular-progress-determinate',
					indeterminate: 'CB-circular-progress-indeterminate',
					root: 'CB-circular-progress-root dark-spinner',
					svg: 'CB-circular-progress-svg',
				},
			},
		},
		MuiPopover: {
			defaultProps: {
				classes: {
					paper: 'CB-popover-paper popover-styles',
					root: 'CB-popover-root',
				},
			},
		},
		MuiDialog: {
			defaultProps: {
				classes: {
					container: 'CB-dialog-container',
					paper: 'CB-dialog-paper',
					paperFullScreen: 'CB-dialog-paperFullScreen',
					paperFullWidth: 'CB-dialog-paperFullWidth',
					paperScrollBody: 'CB-dialog-paperScrollBody',
					paperScrollPaper: 'CB-dialog-paperScrollPaper',
					paperWidthFalse: 'CB-dialog-paperWidthFalse',
					paperWidthLg: 'CB-dialog-paperWidthLg',
					paperWidthMd: 'CB-dialog-paperWidthMd',
					paperWidthSm: 'CB-dialog-paperWidthSm',
					paperWidthXl: 'CB-dialog-paperWidthXl',
					paperWidthXs: 'CB-dialog-paperWidthXs',
					root: 'CB-dialog-root',
					scrollBody: 'CB-dialog-scrollBody',
					scrollPaper: 'CB-dialog-scrollPaper',
				},
			},
		},
		MuiDialogContent: {
			defaultProps: {
				classes: {
					root: 'CB-dialog-content-root',
					dividers: 'CB-dialog-content-dividers',
				},
			},
		},
		MuiDialogActions: {
			defaultProps: {
				classes: {
					root: 'CB-dialog-actions-root',
					spacing: 'CB-dialog-actions-spacing',
				},
			},
		},
		MuiDialogTitle: {
			defaultProps: {
				classes: {
					root: 'CB-dialog-title-root',
				},
			},
		},
		MuiBox: {
			defaultProps: {
				classes: {
					root: 'CB-box-root',
				},
			},
		},
		MuiSkeleton: {
			defaultProps: {
				classes: {
					circular: 'CB-skeleton-circular',
					fitContent: 'CB-skeleton-fitContent',
					heightAuto: 'CB-skeleton-heightAuto',
					pulse: 'CB-skeleton-pulse',
					rectangular: 'CB-skeleton-rectangular',
					root: 'CB-skeleton-root',
					rounded: 'CB-skeleton-rounded',
					text: 'CB-skeleton-text',
					wave: 'CB-skeleton-wave',
					withChildren: 'CB-skeleton-withChildren',
				},
			},
		},
		MuiIconButton: {
			defaultProps: {
				classes: {
					disabled: 'CB-icon-button-disabled',
					colorError: 'CB-icon-button-colorError',
					colorInfo: 'CB-icon-button-colorInfo',
					colorInherit: 'CB-icon-button-colorInherit',
					colorPrimary: 'CB-icon-button-colorPrimary',
					colorSecondary: 'CB-icon-button-colorSecondary',
					colorSuccess: 'CB-icon-button-colorSuccess',
					colorWarning: 'CB-icon-button-colorWarning',
					edgeEnd: 'CB-icon-button-edgeEnd',
					edgeStart: 'CB-icon-button-edgeStart',
					root: 'CB-icon-button-root',
					sizeLarge: 'CB-icon-button-sizeLarge',
					sizeMedium: 'CB-icon-button-sizeMedium',
					sizeSmall: 'CB-icon-button-sizeSmall',
				},
			},
		},
		MuiModal: {
			defaultProps: {
				classes: {
					root: 'CB-modal-root',
					backdrop: 'CB-modal-backdrop',
				},
			},
		},
		MuiInputAdornment: {
			defaultProps: {
				classes: {
					disablePointerEvents: 'CB-input-adornment-disablePointerEvents',
					filled: 'CB-input-adornment-filled',
					hiddenLabel: 'CB-input-adornment-hiddenLabel',
					outlined: 'CB-input-adornment-outlined',
					positionEnd: 'CB-input-adornment-positionEnd',
					positionStart: 'CB-input-adornment-positionStart',
					root: 'CB-input-adornment-root',
					sizeSmall: 'CB-input-adornment-sizeSmall',
					standard: 'CB-input-adornment-standard',
				},
			},
		},
		MuiContainer: {
			defaultProps: {
				classes: {
					disableGutters: 'CB-mui-container-disableGutters',
					fixed: 'CB-mui-container-fixed',
					maxWidthLg: 'CB-mui-container-maxWidthLg',
					maxWidthMd: 'CB-mui-container-maxWidthMd',
					maxWidthSm: 'CB-mui-container-maxWidthSm',
					maxWidthXl: 'CB-mui-container-maxWidthXl',
					maxWidthXs: 'CB-mui-container-maxWidthXs',
					root: 'CB-mui-container-root',
				},
			},
		},
		MuiTooltip: {
			defaultProps: {
				classes: {
					arrow: 'CB-tooltip-arrow',
					popper: 'CB-tooltip-popper',
					popperArrow: 'CB-tooltip-popperArrow',
					popperClose: 'CB-tooltip-popperClose',
					popperInteractive: 'CB-tooltip-popperInteractive',
					tooltip: 'CB-tooltip-tooltip',
					tooltipArrow: 'CB-tooltip-tooltipArrow',
					tooltipPlacementBottom: 'CB-tooltip-tooltipPlacementBottom',
					tooltipPlacementLeft: 'CB-tooltip-tooltipPlacementLeft',
					tooltipPlacementRight: 'CB-tooltip-tooltipPlacementRight',
					tooltipPlacementTop: 'CB-tooltip-tooltipPlacementTop',
					touch: 'CB-tooltip-touch',
				},
			},
		},
		MuiInputBase: {
			defaultProps: {
				classes: {
					disabled: 'CB-input-base-disabled',
					error: 'CB-input-base-error',
					focused: 'CB-input-base-focused',
					readOnly: 'CB-input-base-readOnly',
					adornedEnd: 'CB-input-base-adornedEnd',
					adornedStart: 'CB-input-base-adornedStart',
					colorSecondary: 'CB-input-base-colorSecondary',
					formControl: 'CB-input-base-formControl',
					fullWidth: 'CB-input-base-fullWidth',
					hiddenLabel: 'CB-input-base-hiddenLabel',
					input: 'CB-input-base-input',
					inputAdornedEnd: 'CB-input-base-inputAdornedEnd',
					inputAdornedStart: 'CB-input-base-inputAdornedStart',
					inputHiddenLabel: 'CB-input-base-inputHiddenLabel',
					inputMultiline: 'CB-input-base-inputMultiline',
					inputSizeSmall: 'CB-input-base-inputSizeSmall',
					inputTypeSearch: 'CB-input-base-inputTypeSearch',
					multiline: 'CB-input-base-multiline',
					root: 'CB-input-base-root',
					sizeSmall: 'CB-input-base-sizeSmall',
				},
			},
		},
	},
});
