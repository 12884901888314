import { get } from 'lodash';

export const maskDefinitions = {
	'@': /[\dA-Za-z]/, // Alphanumeric
	'.': /[a-zA-Z0-9- ]/, // Numbers,letters, and hyphens, no other special characters
	'#': /[+()\-\s\d]/, // Valid phone characters
};

export const getMask = (name, value, country) => {
	const inputLength = value?.replace(/\s/g, '').length || 0;
	const zipOptions = {
		US: `00000${inputLength >= 6 ? '-' : '0'}0000`,
		CA: `a0a${inputLength >= 4 ? ' ' : '0'}0a0`,
		GB: `@@${Array(3)
			.fill(null)
			.map((_, i) => (inputLength === i + 5 ? ' ' : '@'))
			.join('')}@@@`,
		AU: '0000',
		IE: `@@@${inputLength >= 4 ? ' ' : '@'}@@@@`,
		//allows numbers,letters, and hyphens, no other special characters
		default: '.'.repeat(10),
	};
	const addressField = {
		zip: zipOptions,
		fullName: '*'.repeat(255),
		address1: '*'.repeat(150),
		address2: '*'.repeat(150),
		city: '*'.repeat(50),
		state: '*'.repeat(50),
	};
	const options = {
		phone: '#'.repeat(15), // Valid phone characters
		billing: addressField,
		shipping: addressField,
		cardHolderName: '*'.repeat(50),
		expirationDate: `00${inputLength >= 3 ? '/' : '0'}00`,
		securityCode: '0000',
		couponCode: '*'.repeat(20),
	};

	const isZipInput = name.split('.').slice(-1)[0] === 'zip';
	if (isZipInput) {
		return zipOptions[country] || zipOptions.default;
	}
	return get(options, name) || false;
};
