import { css } from '@emotion/react';
import { Container, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { SubFooter } from '..';
import { getZoneStyles } from '@/components/mainContent/contentStyle';
import { useStore } from '@/state/stores';
import {
	checkoutTheme,
	defaultTermsGuaranteeBackgroundColor,
	defaultTermsGuaranteeFontColor,
	fontWeightBold,
	layoutMaxWidth,
} from '@/theme';

const checkoutFooterStyles = (termsGuaranteeStyles, footerStyles) => {
	const isGuaranteeToSCopyrightNarrow = termsGuaranteeStyles?.width === 'narrow';

	return css`
		background-color: ${footerStyles?.backgroundColor || 'transparent'};
		margin-top: auto;

		.footer-inner-wrapper {
			width: 100%;
			max-width: ${isGuaranteeToSCopyrightNarrow ? layoutMaxWidth : '100%'};
			margin: 0 auto;
		}

		${checkoutTheme.breakpoints.up('sm')} {
			padding: ${isGuaranteeToSCopyrightNarrow ? '0 16px' : 0};

			.footer-inner-wrapper {
				width: ${isGuaranteeToSCopyrightNarrow ? 'inherit' : '100%'};
				max-width: ${isGuaranteeToSCopyrightNarrow ? layoutMaxWidth : '100%'};
			}
		}

		${checkoutTheme.breakpoints.up('md')} {
			margin-bottom: 0;
		}

		.footer-main {
			background-color: ${termsGuaranteeStyles?.backgroundColor ||
			defaultTermsGuaranteeBackgroundColor};
			color: ${termsGuaranteeStyles?.body?.fontColor || defaultTermsGuaranteeFontColor};
			margin: 0;
			max-width: 100%;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 16px;
			${checkoutTheme.breakpoints.down('sm')} {
				padding: 24px;
			}
		}

		.footer-container {
			max-width: ${layoutMaxWidth};
			width: 100%;
			margin: 0;
			justify-content: center;
		}

		.footer-main .column {
			&.terms-section {
				padding-top: 16px;
				padding-bottom: 16px;
				${checkoutTheme.breakpoints.down('sm')} {
					padding: 24px 0;
				}

				${checkoutTheme.breakpoints.up('sm')} {
					padding-left: 32px;
					padding-right: 24px;
				}
			}

			&.guarantee-section {
				padding: 16px 0;

				${checkoutTheme.breakpoints.up('sm')} {
					padding-left: 24px;
				}
			}

			${checkoutTheme.breakpoints.up('md')} {
				max-width: ${layoutMaxWidth};
			}
		}

		.footer-header {
			margin-bottom: 1rem;
			font-size: 1rem;
			font-weight: ${fontWeightBold};
		}

		.footer-list {
			padding-left: 20px;

			li {
				font-weight: normal;
			}
		}

		a {
			color: ${termsGuaranteeStyles?.body?.fontColor || defaultTermsGuaranteeFontColor};
		}

		.footer-copy {
			font-size: ${14 / 16}rem;
		}
	`;
};

const determineSoftDescriptor = (
	paymentMethod,
	payPalCommerceEnabled,
	cardType,
	softDescriptor,
) => {
	if (paymentMethod === 'payPal') {
		return 'PAYPAL *CLICKBANK';
	} else {
		if (!softDescriptor) {
			return 'CLKBANK*COM';
		}
		if ('americanExpress' === cardType) {
			return softDescriptor.substring(0, 19);
		} else if ('visa' === cardType) {
			return softDescriptor.substring(0, 25);
		} else {
			return softDescriptor.substring(0, 22); // Default length
		}
	}
};

const CheckoutFooter = ({ affiliateName, trackingCode }) => {
	const { t } = useTranslation('common');
	const {
		orderTotals,
		cardType,
		paymentMethod,
		refundDaysLimit,
		cartInitialized,
		payPalCommerceEnabled,
		template,
		siteSettings,
	} = useStore(
		useShallow((state) => ({
			orderTotals: state.orderTotals,
			cardType: state.cardType,
			paymentMethod: state.paymentMethod,
			refundDaysLimit: state.refundDaysLimit,
			cartInitialized: state.cartInitialized,
			payPalCommerceEnabled: state.payPalCommerceEnabled,
			template: state.template,
			siteSettings: state.siteSettings,
		})),
	);

	const termsGuaranteeStyles = getZoneStyles(template, 'termsGuarantee');
	const footerStyles = getZoneStyles(template, 'footer');
	const softDescriptor = orderTotals && orderTotals[0] ? orderTotals[0].softDescriptor : null;
	const softDescriptorValue = determineSoftDescriptor(
		paymentMethod,
		payPalCommerceEnabled,
		cardType,
		softDescriptor,
	);
	const { adminRefundDaysLimit, adminRestrictFlexibleRefund } = siteSettings;
	const refundDays = adminRestrictFlexibleRefund ? adminRefundDaysLimit : refundDaysLimit;

	// We need to remove the spacing on the first grid, its making the spacing inconsistent
	return (
		<footer
			css={checkoutFooterStyles(termsGuaranteeStyles, footerStyles)}
			className="CB-background"
			id="CB-footer"
		>
			<div className="footer-inner-wrapper">
				<Container className="footer-main" id="CB-upper-footer">
					<Grid className="footer-container" container spacing={3}>
						{refundDays ? (
							<Grid item xs={12} sm={6} className="column guarantee-section">
								<Typography variant="h3" className="footer-header" gutterBottom>
									{t('footer.guarantee-title')}
								</Typography>
								{refundDays && cartInitialized ? (
									<Typography
										variant="body2"
										gutterBottom
										className="footer-copy"
									>
										{t('footer.guarantee', { refundDaysLimit: refundDays })}
										<a
											href="https://support.clickbank.com/hc/articles/115015425367-Return-And-Cancellation-Policy"
											target="_blank"
											rel="noopener noreferrer"
										>
											{t('footer.return-policy')}
										</a>
									</Typography>
								) : cartInitialized ? (
									<Typography
										variant="body2"
										gutterBottom
										className="footer-copy"
									>
										{t('footer.guarantee-generic')}
										<a
											href="https://support.clickbank.com/hc/articles/115015425367-Return-And-Cancellation-Policy"
											target="_blank"
											rel="noopener noreferrer"
										>
											{t('footer.return-and-cancellation')}
										</a>
									</Typography>
								) : null}
							</Grid>
						) : null}
						<Grid item xs={12} sm={6} className="column terms-section">
							<Typography variant="body2" className=" footer-copy" gutterBottom>
								{t('footer.purchase', {
									purchaseName: softDescriptorValue,
								})}
							</Typography>
							<Typography variant="h3" className="footer-header" gutterBottom>
								{t('footer.need-help')}
							</Typography>
							<Typography className="footer-copy" gutterBottom>
								<Trans
									i18nKey="common:footer.contact"
									components={[
										<a key="0" href="http://clkbank.com/" />,
										<a key="1" href="tel:18003906035" />,
									]}
								/>
							</Typography>
						</Grid>
					</Grid>
				</Container>
				<SubFooter affiliateName={affiliateName} trackingCode={trackingCode} />
			</div>
		</footer>
	);
};
export default CheckoutFooter;

CheckoutFooter.propTypes = {
	affiliateName: PropTypes.string,
	trackingCode: PropTypes.string,
};
