import { css } from '@emotion/react';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import AddressAutoComplete from './AddressAutoComplete';
import ZipCityAndState from './ZipCityAndState';
import Bumps from '@/components/cart/Bumps';
import Card from '@/components/common/Card';
import TextField from '@/components/common/CBTextField';
import InputPlaceholder from '@/components/common/InputPlaceholder';
import SelectField from '@/components/common/SelectField';
import SubmitButton from '@/components/common/SubmitButton';
import ZoneWrapper from '@/components/layoutFactory/ZoneWrapper';
import {
	getHeaderStyles,
	getInputStyles,
	getZoneStyles,
} from '@/components/mainContent/contentStyle';
import { MediaQueryContext } from '@/state/MediaQueryContext';
import { useFormStore, useStore } from '@/state/stores';
import {
	checkoutTheme,
	defaultPanelBackgroundColor,
	defaultPanelBorderColor,
	defaultPanelBoxShadow,
} from '@/theme';
import { Layouts, PaymentMethods } from '@/utils/enums';
import { isTemplateJoined, listCommonCountriesFirst } from '@/utils/helpers';
import { useLocationUpdateSideEffects } from '@/utils/hooks';

const addressCardStyles = (template, zonesAreConnected, showBumpsInShippingSection) => {
	const shippingStyles = getZoneStyles(template, 'shipping');
	const { body, header, borderRadius } = shippingStyles || {};

	const inputStyles = getInputStyles(body);
	const headerStyles = getHeaderStyles(header);

	return css`
		background: transparent;
		border-radius: ${borderRadius && !zonesAreConnected ? borderRadius : '0px'};
		box-shadow: none;

		.shipping-grid {
			margin-top: 16px;
			margin-bottom: ${showBumpsInShippingSection ? '24px' : undefined};
		}

		${checkoutTheme.breakpoints.down('sm')} {
			box-shadow: none;
		}
		${headerStyles}
		${inputStyles}
	`;
};

const styles = (template, zonesAreConnected, isTwoColumnLayout) => {
	const shippingStyles = getZoneStyles(template, 'shipping');
	const {
		backgroundColor,
		boxShadow: customBoxShadow,
		borderSize,
		borderColor,
		borderRadius,
	} = shippingStyles || {};

	const isJoined = isTwoColumnLayout || zonesAreConnected;

	const isBorderTransparent = borderColor?.length > 7 && borderColor?.slice(-2) === '00';
	const hideBorder = !borderColor || isBorderTransparent || isJoined;
	const borderWidth = borderSize || '1px';
	const borderColorValue = borderColor || defaultPanelBorderColor;
	const border = hideBorder ? 'none' : `${borderWidth} solid ${borderColorValue}`;
	const boxShadow = zonesAreConnected ? 'none' : customBoxShadow;

	return css`
		background: ${backgroundColor || defaultPanelBackgroundColor};
		box-shadow: ${isJoined ? 'none' : boxShadow || defaultPanelBoxShadow};
		overflow: hidden;
		margin-top: ${isTwoColumnLayout || isJoined ? '0' : '16px'};

		.MuiCardContent-root {
			padding-top: 0;
		}

		${checkoutTheme.breakpoints.up('sm')} {
			border: ${border};
			border-radius: ${borderRadius && !isJoined ? borderRadius : '0'};
		}

		${checkoutTheme.breakpoints.down('sm')} {
			box-shadow: none;
		}

		// overrides MUI styles to make the autofill background color look like it should
		.MuiAutocomplete-root {
			.MuiOutlinedInput-root.MuiInputBase-sizeSmall {
				padding: 0;

				.MuiAutocomplete-input {
					padding: 12.5px 0px 2.5px 10px;
				}
			}
		}

		.MuiGrid-item {
			&.less-padding {
				${checkoutTheme.breakpoints.up('lmd')} {
					padding-left: 8px;
				}
			}
		}
	`;
};

/**
 * The big picture:
 * Inputs are loaded as controlled inputs bound to values in formStore
 * City and state are disabled until a valid zip is entered.
 * When country/zip/city/state change, inputs are disabled and query is triggered
 * Changes to country/city/state/zip all will trigger calculateCart to run,
 * but only after any needed queries have run and all inputs have been updated.
 * Country changes reset zip/city/state.
 * for US:
 * Valid zip changes trigger findLocation query to run, which populates the city/state dropdowns.
 * for non-US:
 * city/state are text inputs.
 */

const ShippingAddress = ({ bumpList, showBumpsInShippingSection }) => {
	const { t } = useTranslation('checkout');
	const { isSmallScreen, isTwoColumnLayout } = useContext(MediaQueryContext);

	// This component will always act on the billing key, regardless of whether the heading says
	// "billing" or "shipping". This will reduce the chance of error, as we won't be trying to
	// handle the two-address case that is not yet relevant in production.
	const accessor = 'billing';
	const {
		countryOptions,
		stateOptions,
		cityOptions,
		cartInitialized,
		isShippable,
		isCartUpdating,
		template,
		paymentMethod,
		selectedCountryData,
	} = useStore(
		useShallow((state) => ({
			countryOptions: state.countryOptions,
			stateOptions: state.stateOptions[accessor],
			cityOptions: state.cityOptions[accessor],
			cartInitialized: state.cartInitialized,
			isShippable: state.isShippable,
			isCartUpdating: state.isCartUpdating,
			template: state.template,
			paymentMethod: state.paymentMethod,
			selectedCountryData: state.selectedCountryData[accessor],
		})),
	);
	const { countryCode, zip, zipError } = useFormStore(
		useShallow((state) => ({
			countryCode: state.formData[accessor].countryCode,
			zip: state.formData[accessor].zip,
			zipError: state.formErrors?.[accessor]?.zip,
		})),
	);

	useLocationUpdateSideEffects(accessor);

	const shouldGetTaxAndLocationByZip = ['US', 'CA'].includes(countryCode);

	const multiOptionCountryDefault = {
		value: '',
		label: t('checkout:field.common.placeholder.select-country'),
	};

	const { options: orderedCountryOptions, subheaders: countrySubheaders } = countryOptions
		? listCommonCountriesFirst(countryOptions, t)
		: { options: countryOptions };

	// Apple pay and Paze handle shipping address, so we don't need to show this form
	if (paymentMethod === PaymentMethods.APPLE_PAY) {
		return null;
	}

	const shouldRenderAutoComplete = selectedCountryData.autocomplete;
	const shouldShowSubmitButtonInShippingSection =
		!isSmallScreen &&
		cartInitialized &&
		[
			Layouts.STANDARD_INPUT_LEFT_BUTTON_LEFT,
			Layouts.STANDARD_INPUT_RIGHT_BUTTON_RIGHT,
			Layouts.JOINED_INPUT_RIGHT_BUTTON_RIGHT,
			Layouts.JOINED_INPUT_LEFT_BUTTON_LEFT,
		].includes(template.layout);

	const zonesAreConnected = isTemplateJoined(template);

	return (
		<div
			css={styles(template, zonesAreConnected, isTwoColumnLayout)}
			className="CB-form"
			id="CB-shipping-address-container"
		>
			<Card css={addressCardStyles(template, zonesAreConnected, showBumpsInShippingSection)}>
				<Grid className="shipping-grid">
					{cartInitialized ? (
						<Typography
							variant="h4"
							className="heading CB-font-title"
							id="CB-font-title"
						>
							{t('card-title.shipping')}
						</Typography>
					) : (
						<InputPlaceholder showPlaceholder>
							<div style={{ height: '3em' }} />
						</InputPlaceholder>
					)}
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<SelectField
								data={orderedCountryOptions}
								subheaders={countrySubheaders}
								name={`${accessor}.countryCode`}
								label={t('field.country.label')}
								required={true}
								disabled={!cartInitialized}
								defaultValue={multiOptionCountryDefault}
								showPlaceholder={!cartInitialized}
								includeDefaultInList={!countrySubheaders}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								name={`${accessor}.fullName`}
								label={t('field.full-name.label')}
								required={true}
								addressAccessor={accessor}
								showPlaceholder={!cartInitialized}
							/>
						</Grid>
						<Grid item xs={12}>
							{shouldRenderAutoComplete ? (
								<AddressAutoComplete />
							) : (
								<TextField
									name={`${accessor}.address1`}
									label={t('field.address1.label')}
									required={true}
									addressAccessor={accessor}
									showPlaceholder={!cartInitialized}
								/>
							)}
						</Grid>
						<Grid item xs={12}>
							<TextField
								name={`${accessor}.address2`}
								label={t('field.address2.label')}
								addressAccessor={accessor}
								showPlaceholder={!cartInitialized}
							/>
						</Grid>
						<ZipCityAndState
							isShippable={isShippable}
							accessor={accessor}
							cartInitialized={cartInitialized}
							isCartUpdating={isCartUpdating}
							countryCode={countryCode}
							cityOptions={cityOptions}
							stateOptions={stateOptions}
							zip={zip}
							zipError={zipError}
							shouldGetTaxAndLocationByZip={shouldGetTaxAndLocationByZip}
						/>
					</Grid>
				</Grid>

				{showBumpsInShippingSection && <Bumps bumpList={bumpList} />}

				{shouldShowSubmitButtonInShippingSection && <SubmitButton />}
			</Card>
			{isSmallScreen && <ZoneWrapper zoneId="rightColumnBottom" />}
			<ZoneWrapper zoneId="leftColumnBottom" />
		</div>
	);
};

ShippingAddress.propTypes = {
	bumpList: PropTypes.array,
	showBumpsInShippingSection: PropTypes.bool,
};

export default ShippingAddress;
