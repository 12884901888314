import { css } from '@emotion/react';
import { Box, Modal, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { cbNeutral } from '@/theme';

const styles = css`
	.MuiBox-root {
		max-width: 590px;
		background-color: ${cbNeutral[1000]};
		border: 1px solid ${cbNeutral[900]};
		border-radius: 8px;
		margin: 120px auto 0;
		padding: 24px 24px 32px;
		outline: 0;

		h2 {
			font-weight: 700;
			text-align: center;
			margin-bottom: 24px;
			font-size: 1.25rem;
		}
		p {
			text-align: center;
			font-size: 1rem;
		}
	}
`;

const PurchaseCompleteModal = () => {
	const { t } = useTranslation('checkout');

	return (
		<Modal css={styles} aria-labelledby="customized-dialog-title" open hideBackdrop>
			<Box css={styles}>
				<Typography className="purchase-complete-title" variant="h6" component="h2">
					{t('purchase-complete.title')}
				</Typography>
				<Typography className="purchase-complete-description">
					{t('purchase-complete.description')}
				</Typography>
			</Box>
		</Modal>
	);
};

export default PurchaseCompleteModal;
