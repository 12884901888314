import { useManualQuery } from 'graphql-hooks';
import { isEqual } from 'lodash';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { CALCULATE_CART } from './queries';
import { useFormStore, useStore } from '@/state/stores';
import { PaymentMethods } from '@/utils/enums';
import { isDigitalPaymentMethod, stringIsEqualCaseInsensitive } from '@/utils/helpers';
import { usePostMountEffect } from '@/utils/hooks';
import { getProductsWithPricing } from '@/utils/products';

const sortByProductId = (a, b) => {
	return a.productId > b.productId ? 1 : -1;
};

export const CalculateCartLoader = ({ allQueriesData, queryFetcher }) => {
	const {
		pazeData,
		paymentMethod,
		applePayData,
		allProducts,
		setAllProducts,
		cartStatus,
		orderTotals,
		setOrderTotals,
		urlVars,
		selectedCurrency,
		selectedLanguage,
		couponCode,
		setCouponCode,
		validatedCoupon,
		setValidatedCoupon,
		calculateCartLineItems,
		setCalculateCartLineItems,
		availableItems,
		setAvailableItems,
		allSKUs,
		setAllSKUs,
		submitOrderLineItems,
		setSubmitOrderLineItems,
		cardsAccepted,
		currencyCodes,
		selectedCountryData,
		calculateCartAddressVars,
		setCouponErrorMessageOverride,
		setCouponSuccessMessage,
		triggerCalculateCart,
		setTriggerCalculateCart,
		currencyData,
		setIsCartUpdating,
		refocusCurrentInput,
		cartInitialized,
		setDisplayCurrency,
	} = useStore(
		useShallow((state) => {
			const shippingActive = !state.isShippingSameAsBilling;
			const locationAccessor = shippingActive ? 'shipping' : 'billing';
			return {
				pazeData: state.pazeData,
				paymentMethod: state.paymentMethod,
				applePayData: state.applePayData,
				allProducts: state.allProducts,
				setAllProducts: state.setAllProducts,
				cartStatus: state.cartStatus,
				orderTotals: state.orderTotals,
				setOrderTotals: state.setOrderTotals,
				urlVars: state.urlVars,
				selectedCurrency: state.selectedCurrency,
				selectedLanguage: state.selectedLanguage,
				couponCode: state.couponCode,
				setCouponCode: state.setCouponCode,
				validatedCoupon: state.validatedCoupon,
				setValidatedCoupon: state.setValidatedCoupon,
				calculateCartLineItems: state.calculateCartLineItems,
				setCalculateCartLineItems: state.setCalculateCartLineItems,
				availableItems: state.availableItems,
				setAvailableItems: state.setAvailableItems,
				allSKUs: state.allSKUs,
				setAllSKUs: state.setAllSKUs,
				submitOrderLineItems: state.submitOrderLineItems,
				setSubmitOrderLineItems: state.setSubmitOrderLineItems,
				cardsAccepted: state.cardsAccepted,
				currencyCodes: state.currencyCodes,
				selectedCountryData: state.selectedCountryData[locationAccessor],
				calculateCartAddressVars: state.calculateCartAddressVars,
				setCouponErrorMessageOverride: state.setCouponErrorMessageOverride,
				setCouponSuccessMessage: state.setCouponSuccessMessage,
				triggerCalculateCart: state.triggerCalculateCart,
				setTriggerCalculateCart: state.setTriggerCalculateCart,
				currencyData: state.currencyData,
				setIsCartUpdating: state.setIsCartUpdating,
				refocusCurrentInput: state.refocusCurrentInput,
				cartInitialized: state.cartInitialized,
				setDisplayCurrency: state.setDisplayCurrency,
			};
		}),
	);

	const setFormValue = useFormStore((state) => state.setFormValue);

	const { t } = useTranslation(['checkout']);
	const [queryData, setQueryData] = useState(allQueriesData.CALCULATE_CART.calculateCart);
	const [validatedDiscount, setValidatedDiscount] = useState(queryData.discountRate);
	const [orderTotalsWithValidatedCoupon, setOrderTotalsWithValidatedCoupon] = useState(
		validatedDiscount ? queryData : null,
	);
	const updateOrderTotals = useRef(false);
	const [fetchReady, setFetchReady] = useState(false);

	// load products and bumps
	// use products and bumps to create lineItems and availableItems objects needed for calculateCart
	// hit the calculateCart endpoint
	// use the pricing returned to populate pricing on products and bumps

	const digitalPaymentCurrency =
		paymentMethod === PaymentMethods.APPLE_PAY
			? applePayData.currencyData.selectedCurrency
			: pazeData.currencyData.selectedCurrency;

	const defaultDigitalPaymentAddress = {
		address1: '',
		city: '',
		countryCode:
			paymentMethod === PaymentMethods.APPLE_PAY
				? applePayData.currencyData.defaultCountryCode
				: pazeData.currencyData.defaultCountryCode,
		state: '',
		zip: null,
	};

	const getCalculateCartAddressVars = () => {
		if (paymentMethod === PaymentMethods.PAZE) {
			// Paze add address details to the vars when the checkout response is available
			return pazeData.checkoutResponse
				? calculateCartAddressVars
				: defaultDigitalPaymentAddress;
		}
		if (paymentMethod === PaymentMethods.APPLE_PAY) {
			// Apple Pay bypasses CalculateCartLoader by manually calling CALCULATE_CART
			// in the apple flow, so we can ignore calculateCartAddressVars here
			return defaultDigitalPaymentAddress;
		}
		return calculateCartAddressVars;
	};
	const addressVars = getCalculateCartAddressVars();

	const [fetchQuery] = useManualQuery(CALCULATE_CART, {
		variables: {
			lineItems: calculateCartLineItems,
			vendorId: urlVars.vvvv,
			currencyId: isDigitalPaymentMethod(paymentMethod)
				? digitalPaymentCurrency
				: selectedCurrency,
			couponCode: validatedCoupon && !couponCode ? validatedCoupon : couponCode,
			address: addressVars,
			availableItems: availableItems,
			urlParams: window.location.search,
		},
	});

	const getQuantity = (product) => {
		product.maxQuantity = product.maxQuantity || 99;
		return product.quantity > product.maxQuantity ? product.maxQuantity : product.quantity;
	};

	const getCalculateCartLineItems = useCallback(() => {
		const calculateCartLineItemsArr = [];

		cartStatus.forEach((product) => {
			if (product.isActive) {
				// create lineItems for calculateCart query
				calculateCartLineItemsArr.push({
					productId: urlVars.vvvv + '-' + product.sku,
					quantity: getQuantity(product),
					bump: product.isBump,
				});
			}
		});
		!isEqual(calculateCartLineItems, calculateCartLineItemsArr) &&
			setCalculateCartLineItems(calculateCartLineItemsArr);
	}, [urlVars.vvvv, cartStatus, calculateCartLineItems, setCalculateCartLineItems]);

	/**
	 * `lineItems` are all products in the cart
	 */
	const getSubmitOrderLineItems = useCallback(() => {
		const submitOrderLineItemsArr = [];

		cartStatus.forEach((product) => {
			if (product.isActive) {
				const currentProduct = allProducts.find((item) =>
					stringIsEqualCaseInsensitive(item.sku, product.sku),
				);
				submitOrderLineItemsArr.push({
					productId: urlVars.vvvv + '-' + product.sku,
					quantity: getQuantity(product),
					unitPrice: currentProduct.priceModel.unitPrice,
					bump: product.isBump,
				});
			}
		});
		!isEqual(submitOrderLineItems, submitOrderLineItemsArr) &&
			setSubmitOrderLineItems(submitOrderLineItemsArr);
	}, [allProducts, cartStatus, setSubmitOrderLineItems, submitOrderLineItems, urlVars.vvvv]);

	/**
	 * `availableItems` are products that are not in the cart (bumps)
	 */
	const getAvailableItems = useCallback(() => {
		const updatedAvailableItems = [];
		const skuArray = [];
		allProducts.forEach((product) => {
			cartStatus.forEach((item) => {
				const isNotInCart = item.sku === product.sku && !item.isActive;
				if (isNotInCart) {
					const targetAvailableItem = {
						productId: product.id,
						quantity: getQuantity(product),
						bump: product.isBump,
					};
					updatedAvailableItems.push(targetAvailableItem);
				}
			});
			skuArray.push(product.sku);
		});
		!isEqual(
			availableItems.sort(sortByProductId),
			updatedAvailableItems.sort(sortByProductId),
		) && setAvailableItems(updatedAvailableItems);
		!allSKUs.length && setAllSKUs(skuArray);
	}, [allProducts, allSKUs.length, setAllSKUs, setAvailableItems, cartStatus, availableItems]);

	const injectPricing = useCallback(() => {
		const productsWithPricing = getProductsWithPricing(
			allProducts,
			allQueriesData.CALCULATE_CART,
		);
		!isEqual(productsWithPricing, allProducts) && setAllProducts(productsWithPricing);
	}, [allProducts, allQueriesData, setAllProducts]);

	const checkFetchConditions = useCallback(() => {
		// need to make sure country input and the currencies are in sync.
		let countryCodeMatch = addressVars.countryCode === selectedCountryData?.countryCode;
		let currencyMatch = currencyData?.value?.find((currency) => {
			return currency.code === selectedCurrency;
		});
		if (isDigitalPaymentMethod(paymentMethod)) {
			countryCodeMatch = true;
			currencyMatch = true;
		}

		if (
			countryCodeMatch &&
			currencyMatch &&
			selectedCurrency &&
			calculateCartLineItems?.length &&
			availableItems &&
			addressVars?.countryCode
		) {
			return true;
		}
		return false;
	}, [
		paymentMethod,
		selectedCurrency,
		calculateCartLineItems,
		availableItems,
		addressVars,
		currencyData,
		selectedCountryData,
	]);

	// when queryData updates set a flag to update the orderTotals and see if the coupon is in error
	usePostMountEffect(() => {
		if (queryData) {
			updateOrderTotals.current = true;
		}
	}, [queryData]);

	// update coupon error and success messages when we have updated queryData
	usePostMountEffect(() => {
		if (updateOrderTotals.current && queryData) {
			let orderTotalsData = queryData;
			const hasDiscount = Number(queryData.discountTotal || 0) !== 0;

			if (hasDiscount) {
				// the latest coupon was good.
				// save this data to reuse if there is an invalid coupon submitted after a valid one is applied
				setOrderTotalsWithValidatedCoupon(queryData);
				couponCode !== '' && setValidatedCoupon(couponCode);
				setValidatedDiscount(queryData.discountRate);

				// set coupon error and success messages
				setCouponErrorMessageOverride(null);
				setCouponSuccessMessage(
					t('field.coupon-code.success', {
						code: validatedCoupon && !couponCode ? validatedCoupon : couponCode,
						discount: Math.floor(queryData.discountRate * 100),
					}),
				);
			} else {
				// the latest coupon was bad, or we are modifying cart contents
				if (couponCode?.length && orderTotalsWithValidatedCoupon) {
					// if we just submitted a coupon, we'll have a length,
					// so if we have that and orderTotalsWithValidatedCoupon, use it.
					orderTotalsData = orderTotalsWithValidatedCoupon;
				} else {
					// if we didn't just submit a coupon, we are adding/removing items from the cart
					// so if the query returned no discounts, clear the validated coupon values
					if (!hasDiscount) {
						setOrderTotalsWithValidatedCoupon(null);
						setValidatedCoupon(null);
						setValidatedDiscount(null);
					}
				}

				// set coupon error and success messages
				// if we just submitted a coupon, it was bad. show the error.
				if (couponCode?.length) {
					setCouponErrorMessageOverride(
						t('field.coupon-code.error.invalid-submitted', {
							code: couponCode,
						}),
					);
				}

				// if the coupon was bad and we are using validatedCoupon data
				// or if we have a line item discount in the cart, show the success message
				(couponCode?.length && validatedCoupon) || hasDiscount
					? setCouponSuccessMessage(
							t('field.coupon-code.success', {
								code: validatedCoupon,
								discount: Math.floor(validatedDiscount * 100),
							}),
						)
					: setCouponSuccessMessage(null);
			}
			// clear the input and the stored value so we don't have to worry about stale input data
			setFormValue('couponCode', '');
			setCouponCode('');
			setOrderTotals([orderTotalsData]);
			setIsCartUpdating(false);
			setDisplayCurrency(selectedCurrency);
			updateOrderTotals.current = false;
			// Add to async queue - .focus invocation will be no-op if input is still disabled
			setTimeout(refocusCurrentInput);
		}
		setIsCartUpdating(false);
	}, [
		couponCode,
		orderTotalsWithValidatedCoupon,
		queryData,
		setCouponCode,
		setCouponErrorMessageOverride,
		setCouponSuccessMessage,
		setOrderTotals,
		setOrderTotalsWithValidatedCoupon,
		setValidatedCoupon,
		setFormValue,
		t,
		validatedCoupon,
		validatedDiscount,
	]);

	// all fetch/don't fetch logic is distilled into this flag
	// fetch when true, toggle off when done
	usePostMountEffect(() => {
		if (fetchReady) {
			queryFetcher({
				targetQuery: 'CALCULATE_CART',
				required: true,
				loadFunction: fetchQuery,
			});
			setFetchReady(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchReady, fetchQuery, setFetchReady]);

	// manually trigger CALCULATE_CART
	usePostMountEffect(() => {
		if (triggerCalculateCart) {
			getCalculateCartLineItems();
			setTriggerCalculateCart(false);
		}
	}, [triggerCalculateCart, getCalculateCartLineItems, setTriggerCalculateCart]);

	// run CALCULATE_CART on page load and when bumps are added or removed
	usePostMountEffect(() => {
		getCalculateCartLineItems();
	}, [cartStatus, getCalculateCartLineItems]);

	// when allProducts has data, create the submitOrderLineItems object
	usePostMountEffect(() => {
		orderTotals && getSubmitOrderLineItems();
	}, [orderTotals, getSubmitOrderLineItems]);

	// if the allProducts array exists and we don't have the availableItems array, create it
	usePostMountEffect(() => {
		if (cartStatus.length) {
			getAvailableItems();
		}
	}, [cartStatus, getAvailableItems]);

	// when CALCULATE_CART returns updated data, update pricing and the cart data
	usePostMountEffect(() => {
		if (calculateCartLineItems && allQueriesData.CALCULATE_CART?.calculateCart) {
			injectPricing();
			setQueryData(allQueriesData.CALCULATE_CART?.calculateCart);
		}
	}, [
		queryData,
		selectedLanguage,
		allQueriesData.CALCULATE_CART,
		calculateCartLineItems,
		injectPricing,
		setOrderTotals,
	]);

	// a coupon has been submitted.
	// hit the endpoint if all conditions are good
	usePostMountEffect(() => {
		const shouldFetch = couponCode !== '' && checkFetchConditions();
		if (!shouldFetch) {
			setIsCartUpdating(false);
			return;
		}
		setFetchReady(true);
	}, [couponCode]);

	// the address has been updated, or cart contents have changed
	// hit the endpoint if all conditions are good
	usePostMountEffect(() => {
		const shouldFetch = checkFetchConditions();
		if (!shouldFetch) {
			setIsCartUpdating(false);
			return;
		}
		setFetchReady(true);
	}, [
		selectedCountryData,
		cardsAccepted,
		currencyCodes,
		selectedCurrency,
		calculateCartLineItems,
		availableItems,
		checkFetchConditions,
		setFetchReady,
		cartStatus.length,
		cartInitialized,
	]);

	usePostMountEffect(() => {
		const shouldFetch = checkFetchConditions();
		if (!shouldFetch) {
			setDisplayCurrency(selectedCurrency);
		}
	}, [selectedCurrency, setDisplayCurrency]);

	return null;
};
