import { css, Global } from '@emotion/react';
import { ClientContext } from 'graphql-hooks';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useFormStore, useStore } from './stores';
import fetchInitialData from '@/graphql/initialFetch/fetchInitialData';
import prependFetchKeys from '@/graphql/initialFetch/prependFetchKeys';
import getTemplateQuery from '@/graphql/initialFetch/template';
import { Layouts } from '@/utils/enums';
import { errorHandler } from '@/utils/errorHandler';
import { isDevEnv, isPreview, requiredEndpoints } from '@/utils/helpers';
import { useAbandonedOrderPersist, useAutofillValidate, usePostMountEffect } from '@/utils/hooks';
import { getValidationSchema } from '@/utils/validation';

const urlVarsWhitelist = ['/order-received'];

// getValidationSchema is very expensive invocation, and with so many dependencies going into the effect, the
// easiest way to throttle validation until we need it it to completely prevent rendering of the
// calculating component until we're 100% ready to recalculate.

// Initial validation schema is generated as part of `initialDataFetch`.
const ValidationCalculator = (itiRef) => {
	const {
		allProducts,
		paymentMethod,
		isShippable,
		validatedCoupon,
		allowEditQuantity,
		cartStatus,
		showPhone,
		showCoupon,
		isShippingSameAsBilling,
		isPayPalDirectEnabled,
	} = useStore(
		useShallow((state) => ({
			allProducts: state.allProducts,
			paymentMethod: state.paymentMethod,
			isShippable: state.isShippable,
			validatedCoupon: state.validatedCoupon,
			allowEditQuantity: state.siteSettings.allowEditQuantity,
			cartStatus: state.cartStatus,
			showPhone: state.showPhone,
			showCoupon: state.showCoupon,
			isShippingSameAsBilling: state.isShippingSameAsBilling,
			isPayPalDirectEnabled: state.isPayPalDirectEnabled,
			token: state.token,
		})),
	);

	const {
		billingZip,
		shippingZip,
		couponCode,
		billingCountry,
		shippingCountry,
		setValidationSchema,
		handleIncomingRecurringAgreements,
		setValidationSchemaCalculating,
	} = useFormStore(
		useShallow((state) => ({
			billingZip: state.formData.billing.zip,
			shippingZip: state.formData.shipping.zip,
			couponCode: state.formData.couponCode,
			billingCountry: state.formData.billing.countryCode,
			shippingCountry: state.formData.shipping.countryCode,
			setValidationSchema: state.setValidationSchema,
			handleIncomingRecurringAgreements: state.handleIncomingRecurringAgreements,
			setValidationSchemaCalculating: state.setValidationSchemaCalculating,
		})),
	);

	useEffect(() => {
		handleIncomingRecurringAgreements(cartStatus);
	}, [cartStatus, handleIncomingRecurringAgreements]);

	usePostMountEffect(() => {
		setValidationSchemaCalculating(true);
		const validationTimeout = setTimeout(() => {
			const newSchema = getValidationSchema(
				allProducts,
				paymentMethod,
				billingCountry,
				shippingCountry,
				isShippable,
				validatedCoupon,
				allowEditQuantity,
				cartStatus,
				showPhone,
				showCoupon,
				couponCode,
				billingZip,
				shippingZip,
				isShippingSameAsBilling,
				isPayPalDirectEnabled,
				itiRef,
			);
			setValidationSchema(newSchema);
			setValidationSchemaCalculating(false);
		}, 200);
		return () => {
			clearTimeout(validationTimeout);
		};
	}, [
		allProducts,
		paymentMethod,
		billingCountry,
		shippingCountry,
		isShippable,
		validatedCoupon,
		allowEditQuantity,
		cartStatus,
		showPhone,
		showCoupon,
		setValidationSchema,
		billingZip,
		shippingZip,
		couponCode,
		isShippingSameAsBilling,
	]);

	return null;
};

const failSilently = (error, endpoint) => {
	console.error('silent failure:', JSON.stringify({ error: error, query: endpoint }));
	const optionalErrorMessage = 'Optional GQL error: ' + endpoint + ' query';
	console.warn(optionalErrorMessage, JSON.stringify(error));
};

const StateInit = ({
	dataFetchPromise,
	templatePromise,
	exitOfferPromise,
	featureFlagsPromise,
	itiRef,
}) => {
	const {
		selectedLanguage,
		setSelectedLanguage,
		mergeInitialState,
		cartInitialized,
		setErrorData,
		setShowErrorModal,
		setModalErrorMessage,
		setPayPalRebuildHandler,
		setFormServerErrorInfo,
		setInitialGQLData,
		urlVars,
		hasTemplateQueryFailed,
		setTemplate,
		setPazeData,
	} = useStore(
		useShallow((state) => ({
			selectedLanguage: state.selectedLanguage,
			setSelectedLanguage: state.setSelectedLanguage,
			mergeInitialState: state.mergeInitialState,
			cartInitialized: state.cartInitialized,
			setErrorData: state.setErrorData,
			setShowErrorModal: state.setShowErrorModal,
			setModalErrorMessage: state.setModalErrorMessage,
			setPayPalRebuildHandler: state.setPayPalRebuildHandler,
			setFormServerErrorInfo: state.setFormServerErrorInfo,
			setInitialGQLData: state.setInitialGQLData,
			urlVars: state.urlVars,
			hasTemplateQueryFailed: state.hasTemplateQueryFailed,
			setTemplate: state.setTemplate,
			setPazeData: state.setPazeData,
		})),
	);

	const {
		mergeInitialFormState,
		mergeFormData,
		setFormValue,
		setValidationSchema,
		allowValidationCalc,
		setInitialFormValuesForUser,
		formFieldIsValid,
	} = useFormStore(
		useShallow((state) => ({
			mergeInitialFormState: state.mergeInitialFormState,
			mergeFormData: state.mergeFormData,
			setFormValue: state.setFormValue,
			setValidationSchema: state.setValidationSchema,
			allowValidationCalc: state.allowValidationCalc,
			setInitialFormValuesForUser: state.setInitialFormValuesForUser,
			formFieldIsValid: state.formFieldIsValid,
		})),
	);

	const client = useContext(ClientContext);

	// Load and process url vars
	const pathname = window.location.pathname;
	useEffect(() => {
		// If there are no URL vars, display error modal
		if (!window.location.search && !urlVarsWhitelist.includes(pathname)) {
			setErrorData(errorHandler('no-url-vars'));
		}
	}, [pathname, setErrorData]);

	useEffect(() => {
		// layout preview is always undefined unless we're in preview or dev mode and there is no template in the URL
		const hasLayoutPreview =
			(isPreview || isDevEnv) &&
			!urlVars.template &&
			Object.values(Layouts).includes(urlVars.layout);

		if (hasLayoutPreview) {
			setTemplate((prev) => ({ ...prev, layout: urlVars.layout }));
		}
	}, [urlVars.layout, urlVars.template, setTemplate]);

	// This function both returns a boolean indicating whether the data in question contains an
	// error that should prevent successful mounting of the form, AND executes side effects to handle
	// said errors, such as triggering error modal/logging to WAM.
	const hasFatalError = useCallback(
		(errors) => {
			if (!errors) {
				return false;
			}
			const errorKeys = Object.keys(errors);
			// First, run all errors through `errorHandler` so they all present in RUM
			const loggedErrors = [];
			for (let i = 0; i < errorKeys.length; i++) {
				const endpoint = errorKeys[i];
				const newErrorData = errorHandler(errors[endpoint].error, endpoint);
				loggedErrors.push({
					endpoint,
					newErrorData,
				});
			}
			// Once all have been logged, then iterate through the data and choose what to do from there
			for (let i = 0; i < loggedErrors.length; i++) {
				const { endpoint, newErrorData } = loggedErrors[i];
				if (requiredEndpoints.includes(endpoint) || endpoint === 'FATAL') {
					const errorMessage = newErrorData.message;
					setErrorData(newErrorData);
					if (newErrorData.messageType === 'cart') {
						// Show the cart error if this ever happens
						setFormServerErrorInfo({ key: errorMessage });
					} else if (newErrorData.messageType === 'modal') {
						setModalErrorMessage(errorMessage);
						setShowErrorModal(true);
						return true;
					} else {
						const { error, endpoint: errorDataEndpoint } = newErrorData;
						if (error && endpoint) {
							// Affiliate error, affiliate blocked or inactive
							failSilently(error, errorDataEndpoint);
						}
					}
				} else {
					failSilently(errors[endpoint], endpoint);
				}
			}
			return false;
		},
		[setErrorData, setModalErrorMessage, setShowErrorModal, setFormServerErrorInfo],
	);

	// Incoming template data must be received and handled separately from
	// other pieces of state, as we want to add it to the store ASAP to render
	// the custom template
	const handleIncomingCustomizerAndFeatureFlagData = useCallback(
		(results) => {
			const { state, errors, graphql } = results || {};
			hasFatalError(errors);
			if (!state) {
				return;
			}
			if (errors?.TEMPLATE) {
				mergeInitialState({ hasTemplateQueryFailed: true });
				return;
			}
			if (errors?.EXIT_OFFER) {
				mergeInitialState({ hasExitOfferQueryFailed: true });
				return;
			}

			if (errors?.IS_FEATURE_ENABLED_QUERY) {
				mergeInitialState({ hasIsFeatureEnabledQueryFailed: true });
				return;
			}
			if (graphql) {
				setInitialGQLData((prev) => ({ ...prev, ...graphql }));
			}
			mergeInitialState(state);
			return results;
		},
		[mergeInitialState, setInitialGQLData, hasFatalError],
	);

	useEffect(() => {
		// Handle template promise invoked in index.js and drilled down
		if (!templatePromise) {
			return;
		}
		templatePromise.then(
			handleIncomingCustomizerAndFeatureFlagData,
			handleIncomingCustomizerAndFeatureFlagData,
		);
	}, [templatePromise, handleIncomingCustomizerAndFeatureFlagData]);

	useEffect(() => {
		// Handle exit offer promise invoked in index.js and drilled down
		if (!exitOfferPromise) {
			return;
		}
		exitOfferPromise.then(
			handleIncomingCustomizerAndFeatureFlagData,
			handleIncomingCustomizerAndFeatureFlagData,
		);
	}, [exitOfferPromise, handleIncomingCustomizerAndFeatureFlagData]);

	useEffect(() => {
		// Handle feature flags promise invoked in index.js and drilled down
		if (!featureFlagsPromise) {
			return;
		}
		featureFlagsPromise.then(
			handleIncomingCustomizerAndFeatureFlagData,
			handleIncomingCustomizerAndFeatureFlagData,
		);
	}, [featureFlagsPromise, handleIncomingCustomizerAndFeatureFlagData]);

	const handleIncomingFetchData = useCallback(
		async (results) => {
			const { state, errors, graphql } = results;
			// cb-stamp header can come from a successful affiliate query, or an affiliate
			// query in an error state due to a blocked or inactive affiliate
			const stamp =
				graphql?.AFFILIATE?.affiliate?.stamp ||
				errors?.AFFILIATE?.error?.graphQLErrors?.[0]?.extensions?.payload;
			if (stamp) {
				client.setHeader('cb-stamp', stamp);
			}
			if (errors && hasFatalError(errors)) {
				return;
			}
			if (!state) {
				// On page that does not need state
				return;
			}
			if (graphql) {
				setInitialGQLData((prev) => ({ ...prev, ...graphql }));
			}
			const {
				formData,
				validationSchema: incomingSchema,
				checkInitialEmailForPaze,
				pazeData: incomingPazeData,
				...remainingState
			} = state;
			mergeInitialFormState({
				validationSchema: incomingSchema,
				allowValidationCalc: true,
			});
			if (formData) {
				if (formData.couponCode) {
					formFieldIsValid('couponCode', formData.couponCode);
				}
				mergeFormData(formData);
				setInitialFormValuesForUser(formData);
			}
			mergeInitialState(remainingState);
			// Pluck keys that we do not want to replace based on initial fetch
			// eslint-disable-next-line no-unused-vars
			const { consumerPresent, checkoutResponse, ...remainingPazeData } = incomingPazeData;
			setPazeData((prev) => ({
				...prev, // consumerPresent or checkoutResponse may have changed while data was loading - do not clobber
				...remainingPazeData, // acceptedCCs, supportedCountries, etc. from getDigitalPaymentDataFromGeoCurrencies
			}));
			checkInitialEmailForPaze.then((isPazeEmail) => {
				setPazeData((prev) => ({
					...prev,
					// If not changed by user (i.e. is still null), use result from checkInitialEmailForPaze
					consumerPresent:
						prev.consumerPresent === null ? isPazeEmail : prev.consumerPresent,
				}));
			});
			return results;
		},
		[
			formFieldIsValid,
			hasFatalError,
			mergeInitialFormState,
			mergeFormData,
			mergeInitialState,
			setInitialFormValuesForUser,
			setInitialGQLData,
			client,
			setPazeData,
		],
	);

	useEffect(() => {
		// Handle general data-fetch promise invoked in index.js and drilled down
		if (!dataFetchPromise) {
			return;
		}
		dataFetchPromise.then(handleIncomingFetchData, handleIncomingFetchData);
	}, [
		dataFetchPromise,
		mergeInitialState,
		mergeFormData,
		setInitialFormValuesForUser,
		setFormValue,
		mergeInitialFormState,
		setValidationSchema,
		setErrorData,
		setModalErrorMessage,
		setShowErrorModal,
		formFieldIsValid,
		hasFatalError,
		handleIncomingFetchData,
	]);

	const { i18n } = useTranslation(['countries']);

	useEffect(() => {
		if (selectedLanguage !== i18n.language) {
			setSelectedLanguage(i18n.language);
		}
	}, [i18n.language, setSelectedLanguage, selectedLanguage]);

	useEffect(() => {
		// Define a function that will execute a fetch of initial form data
		// in the event of a paypal rebuild. This will then be accessed from global store
		// and invoked in PaypalResponse if needed.
		const rebuildPayPal = async (rebuildData, rebuildURLVars) => {
			const fetchKeys = await prependFetchKeys(rebuildURLVars);
			const getInitialData = fetchInitialData(rebuildData);
			getInitialData(fetchKeys).then(handleIncomingFetchData, handleIncomingFetchData);

			getTemplateQuery(fetchKeys).then(
				handleIncomingCustomizerAndFeatureFlagData,
				handleIncomingCustomizerAndFeatureFlagData,
			);
		};
		setPayPalRebuildHandler(rebuildPayPal);
	}, [
		setPayPalRebuildHandler,
		handleIncomingFetchData,
		handleIncomingCustomizerAndFeatureFlagData,
	]);

	useAbandonedOrderPersist();
	useAutofillValidate();

	return (
		<>
			{cartInitialized && allowValidationCalc ? (
				<ValidationCalculator itiRef={itiRef} />
			) : null}
			{urlVars.template && !hasTemplateQueryFailed ? (
				<Global
					styles={css`
						input:-webkit-autofill,
						input:-webkit-autofill:focus,
						select:-webkit-autofill,
						select:-webkit-autofill:focus {
							// Cannot override autofill transition of background-color with transparent,
							// so instead indefinitely delay transition
							transition-delay: ${Number.MAX_SAFE_INTEGER}s;
						}
					`}
				/>
			) : null}
		</>
	);
};

StateInit.propTypes = {
	dataFetchPromise: PropTypes.shape({
		then: PropTypes.func.isRequired,
		catch: PropTypes.func.isRequired,
	}),
	templatePromise: PropTypes.shape({
		then: PropTypes.func.isRequired,
		catch: PropTypes.func.isRequired,
	}),
	exitOfferPromise: PropTypes.shape({
		then: PropTypes.func.isRequired,
		catch: PropTypes.func.isRequired,
	}),
	featureFlagsPromise: PropTypes.shape({
		then: PropTypes.func.isRequired,
		catch: PropTypes.func.isRequired,
	}),
	itiRef: PropTypes.object,
};

export default StateInit;
