// Autocomplete values: https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
export const autoCompleteValues = {
	email: 'email',
	phone: 'tel',
	fullName: 'name',
	address1: 'street-address address-line1',
	address2: 'street-address address-line2',
	zip: 'postal-code',
	city: 'address-level2',
	state: 'address-level1',
	countryCode: 'country',
	paymentMethod: 'cc-type',
	cardHolderName: 'cc-name',
	cardToken: 'cc-number',
	expirationDate: 'cc-exp',
	securityCode: 'cc-csc',
	'billing.fullName': 'name',
	'billing.address1': 'street-address address-line1',
	'billing.address2': 'street-address address-line2',
	'billing.zip': 'postal-code',
	'billing.city': 'address-level2',
	'billing.state': 'address-level1',
	'billing.countryCode': 'country',
};
