import { default as MuiTextField } from '@mui/material/TextField';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import InputPlaceholder from './InputPlaceholder';
import { useFormStore, useStore } from '@/state/stores';
import { muiOutlinedInputDefaultClasses } from '@/theme';
import { autoCompleteValues } from '@/utils/autoCompleteValues';

const SelectField = (props) => {
	const {
		name,
		label,
		disabled,
		required,
		defaultValue,
		data,
		helperTextOverride,
		showPlaceholder,
		subheaders,
		includeDefaultInList,
	} = props;

	const { t } = useTranslation(['checkout']);
	const { isCurrentInput } = useStore(
		useShallow((state) => ({
			isCurrentInput: state.currentInput?.name === name,
		})),
	);
	const {
		errorMessageData,
		value,
		setFormValue,
		formFieldIsValid,
		formFieldOnChangeHandlers,
		formFieldOnBlurHandlers,
	} = useFormStore(
		useShallow((state) => ({
			value: get(state.formData, name),
			errorMessageData: get(state.formErrors, name),
			setFormValue: state.setFormValue,
			formFieldIsValid: state.formFieldIsValid,
			formFieldOnChangeHandlers: state.formFieldOnChangeHandlers,
			formFieldOnBlurHandlers: state.formFieldOnBlurHandlers,
		})),
	);

	const errorMessage = errorMessageData
		? t(errorMessageData?.key, { inputName: label, ...errorMessageData?.options })
		: null;

	const handleChange = (newValue) => {
		if (value !== newValue) {
			setFormValue(name, newValue);
			formFieldIsValid(name, newValue);
			formFieldOnChangeHandlers.forEach((cb) => cb(name));
		}
	};

	const handleBlur = () => {
		formFieldOnBlurHandlers.forEach((cb) => cb(name));
	};

	// if data updates, update the selected value
	useEffect(() => {
		// See if the existing value of the input is in the data. yes? don't reset. no? reset to ''
		const dataMatch = data?.find((item) => item.value === value);
		!dataMatch && setFormValue(name, '');
	}, [name, data, value, setFormValue]);

	const labelText = (
		<>
			{label}
			{required && <span className="required">*</span>}
		</>
	);

	let options = data?.map((option, index) => (
		<option key={index} value={option.value || option}>
			{option.label || option}
		</option>
	));

	if (subheaders) {
		options = subheaders.map((header) => {
			return (
				<optgroup key={header.label} label={header.label}>
					{options.slice(header.start, header.end)}
				</optgroup>
			);
		});
	}

	return (
		<InputPlaceholder showPlaceholder={showPlaceholder} label={label}>
			<MuiTextField
				className="CB-field"
				onFocus={() => {}}
				onChange={(e) => {
					const newValue = e.target.value;
					handleChange(newValue);
				}}
				onBlur={() => {
					handleBlur();
				}}
				select
				value={value}
				id={name}
				name={name}
				label={labelText}
				variant="outlined"
				fullWidth
				disabled={disabled}
				error={!!helperTextOverride || !!errorMessageData}
				autoComplete={autoCompleteValues[name]}
				helperText={helperTextOverride || t(errorMessage)}
				// InputProps are material-ui props
				InputProps={{
					classes: {
						...muiOutlinedInputDefaultClasses,
						root: isCurrentInput
							? `Mui-focused ${muiOutlinedInputDefaultClasses.root} ${muiOutlinedInputDefaultClasses.focused}`
							: muiOutlinedInputDefaultClasses.root,
					},
				}}
				InputLabelProps={{
					className: 'scrollable-label',
					disableAnimation: true,
					shrink: true,
					error: false,
					focused: false,
				}}
				// SelectProps are material-ui props
				SelectProps={{
					notched: false,
					native: true,
				}}
			>
				{/* If we do not want to include in list, only render if input's value is default */}
				{defaultValue && (includeDefaultInList || value === defaultValue.value) && (
					<option disabled value={defaultValue.value}>
						{defaultValue.label}
					</option>
				)}
				{options}
			</MuiTextField>
		</InputPlaceholder>
	);
};

SelectField.propTypes = {
	name: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	data: PropTypes.array,
	value: PropTypes.string,
	defaultValue: PropTypes.object,
	helperTextOverride: PropTypes.string,
	label: PropTypes.string,
	required: PropTypes.bool,
	showPlaceholder: PropTypes.bool,
	subheaders: PropTypes.array,
	includeDefaultInList: PropTypes.bool,
};

SelectField.defaultProps = {
	showPlaceholder: false,
	subheaders: null,
	includeDefaultInList: true,
};

export default SelectField;
