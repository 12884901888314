import PropTypes from 'prop-types';

import COMPONENT_LIST from './componentList';
import { WidgetNameMap } from './layoutFactoryConstants';
import { useStore } from '@/state/stores';

const ZoneWrapper = ({ zoneId }) => {
	const template = useStore((state) => state.template);
	const currentZone = template.zones.find((zone) => zoneId === zone.id);

	return (
		<div className={`zone-${zoneId}`}>
			{currentZone.widgets?.map((props, index) => {
				const mappedName = WidgetNameMap[props.componentName];
				if (COMPONENT_LIST[mappedName]) {
					const NewComponent = COMPONENT_LIST[mappedName];
					return (
						<div className={`${mappedName}-Component`} key={index}>
							<NewComponent zoneId={zoneId} {...props} />
						</div>
					);
				}
			})}
		</div>
	);
};

ZoneWrapper.propTypes = {
	zoneId: PropTypes.string,
	componentName: PropTypes.string,
};

export default ZoneWrapper;
