import { css } from '@emotion/react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { applePayMark, payPalColor, pazeLogo } from '@/assets';
import { useFormStore, useStore } from '@/state/stores';
import { PaymentMethods, TokenProviders } from '@/utils/enums';
import { cartIncludesRecurring, formHasPhysicalProducts } from '@/utils/helpers';
import { useCurrency } from '@/utils/hooks';
import { taxName } from '@/utils/taxName';

const OrderTotalsCSS = (isReceiptPage, borderBottomColor) => {
	return css`
		.all-details {
			padding-bottom: 16px;
			border-bottom: 1px solid ${borderBottomColor};
		}

		.payment-details {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 4px;

			.total {
				font-weight: 700;
			}

			.apple-pay-mark {
				height: 27px;
			}

			.pay-pal-mark {
				height: 20px;
			}

			.small-paze-logo {
				height: 16px;
			}

			${isReceiptPage
				? `p {
					font-size: 1.125rem;
				}`
				: ''}
		}

		.immediate-access {
			margin-top: 8px;
		}
	`;
};

const OrderTotals = ({ orderTotals, receiptPageData, bottomBorderColor }) => {
	const { t } = useTranslation('common');
	const {
		cartStatus,
		couponSuccessMessage,
		template,
		isShippingSameAsBilling,
		allProducts,
		applePayData,
		paymentMethodFromStore,
	} = useStore(
		useShallow((state) => ({
			paymentMethodFromStore: state.paymentMethod,
			applePayData: state.applePayData,
			couponSuccessMessage: state.couponSuccessMessage,
			template: state.template,
			isShippingSameAsBilling: state.isShippingSameAsBilling,
			cartStatus: state.cartStatus,
			allProducts: state.allProducts,
		})),
	);
	const isReceiptPage = !!receiptPageData?.email;
	const countryCode = useFormStore((state) => {
		const formData = isShippingSameAsBilling ? state.formData.billing : state.formData.shipping;
		return formData.countryCode;
	});
	const { paymentMethod, last4cc, tokenProvider } = receiptPageData || {};
	const subtotal = useCurrency(orderTotals?.subtotal || 0);
	const shippingAmountCurrency = useCurrency(orderTotals?.shippingAmount || 0);
	const freeShipping = Number(orderTotals?.shippingAmount) === 0;
	const shippingAmount = freeShipping
		? t('payment-details.shipping-free')
		: shippingAmountCurrency;
	const discountTotal = useCurrency(`-${orderTotals?.discountTotal}` || 0);
	const tax = useCurrency(orderTotals?.tax || 0);
	const total = useCurrency(orderTotals?.total || 0);
	const [showShipping, setShowShipping] = useState(false);
	const vatCountry = taxName.vat.find((country) => {
		if (paymentMethodFromStore === PaymentMethods.APPLE_PAY) {
			return country === applePayData.currencyData.defaultCountryCode;
		}
		return country === countryCode;
	});
	const gstCountry = taxName.gst.find((country) => {
		if (paymentMethodFromStore === PaymentMethods.APPLE_PAY) {
			return country === applePayData.currencyData.defaultCountryCode;
		}
		return country === countryCode;
	});

	const getTaxWord = () => {
		if (paymentMethodFromStore === PaymentMethods.PAZE) {
			return t('payment-details.tax');
		}
		if (vatCountry) {
			return t('payment-details.vat');
		}
		if (gstCountry) {
			return t('payment-details.gst');
		}
		return t('payment-details.tax');
	};

	useEffect(() => {
		if (orderTotals?.shippingAmount && orderTotals?.shippingAmount !== null) {
			setShowShipping(true);
		}
	}, [orderTotals, setShowShipping]);

	const PaymentMethod = () => {
		// tokenProvider is used for digital payments, if its not a digital payment
		// then it uses the default value of tokenEx_default
		if (
			tokenProvider === TokenProviders.PAYPAL_COMMERCE ||
			tokenProvider === TokenProviders.PAYPAL_EXPRESS
		) {
			return <img src={payPalColor} className="pay-pal-mark" alt="PayPal mark" />;
		}
		if (tokenProvider === TokenProviders.APPLE_PAY) {
			return <img src={applePayMark} className="apple-pay-mark" alt="Apple Pay mark" />;
		}
		if (tokenProvider === TokenProviders.PAZE) {
			return <img src={pazeLogo} className="small-paze-logo" alt="Paze log" />;
		}

		let updatedMethod;
		if (paymentMethod === 'visa') updatedMethod = 'Visa';
		if (paymentMethod === 'masterCard') updatedMethod = 'Mastercard';
		if (paymentMethod === 'diners') updatedMethod = 'Diners Club';
		if (paymentMethod === 'discover') updatedMethod = 'Discover';
		if (paymentMethod === 'americanExpress') updatedMethod = 'AMEX';
		if (paymentMethod === 'test') updatedMethod = 'Test Card';

		return (
			<Typography variant="body1">
				{updatedMethod} {last4cc && last4cc}
			</Typography>
		);
	};

	return (
		<div css={OrderTotalsCSS(isReceiptPage, bottomBorderColor)}>
			{/* This is for adding pseudo elements with custom CSS */}
			{template?.css ? (
				<>
					<div id="CB-pre-order-summary" />
					{allProducts?.map((product) => {
						if (!product.isBump) {
							return (
								<div key={product.sku} id={`CB-pre-order-summary-${product.sku}`} />
							);
						}
					})}
				</>
			) : null}
			<div className="all-details" id="CB-order-summary">
				<div className="payment-details">
					<Typography variant="body2">{t('payment-details.subtotal')}</Typography>
					<Typography variant="body2">{subtotal}</Typography>
				</div>
				{showShipping && (
					<div className="payment-details">
						<Typography variant="body2">{t('payment-details.shipping')}</Typography>
						<Typography variant="body2">{shippingAmount}</Typography>
					</div>
				)}
				{couponSuccessMessage && (
					<div className="payment-details">
						<Typography variant="body2">{couponSuccessMessage}</Typography>
						<Typography variant="body2">{discountTotal}</Typography>
					</div>
				)}
				<div className="payment-details">
					<Typography variant="body2">{getTaxWord()}</Typography>
					<Typography variant="body2">{tax}</Typography>
				</div>
				<div className="payment-details">
					<Typography variant="body2" className="total">
						{cartIncludesRecurring(cartStatus)
							? t('payment-details.todays-payment')
							: t('payment-details.total')}
					</Typography>
					<Typography variant="body2" className="total" id="total">
						{total}
					</Typography>
				</div>
				{paymentMethod && (
					<div className="payment-details">
						<Typography variant="body2">{t('payment-details.payment')}</Typography>
						<PaymentMethod />
					</div>
				)}
				{!formHasPhysicalProducts(allProducts) && !isReceiptPage && (
					<div className="immediate-access">
						<Typography variant="body3">
							{t('payment-details.immediate-access')}
						</Typography>
					</div>
				)}
			</div>
		</div>
	);
};

OrderTotals.propTypes = {
	orderTotals: PropTypes.object,
	receiptPageData: PropTypes.object,
	bottomBorderColor: PropTypes.string,
};

export default OrderTotals;
