import { css } from '@emotion/react';
import ErrorIcon from '@mui/icons-material/Error';
import { default as MuiTextField } from '@mui/material/TextField';
import { get } from 'lodash';
import mailcheck from 'mailcheck';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { IMask } from 'react-imask';
import { useShallow } from 'zustand/react/shallow';

import InputPlaceholder from './InputPlaceholder';
import { getInputStyles, getZoneStyles } from '@/components/mainContent/contentStyle';
import { useFormStore, useStore } from '@/state/stores';
import {
	cbNegative,
	cbPrimary,
	fontWeightBold,
	fontWeightMedium,
	muiOutlinedInputDefaultClasses,
} from '@/theme';
import { autoCompleteValues } from '@/utils/autoCompleteValues';
import { PaymentMethods } from '@/utils/enums';
import { pazeCanCheckout, resetPazeData } from '@/utils/pazeHelpers';
import { getMask, maskDefinitions } from '@/utils/textMasking';

const emailAndSuggestionStyles = css`
	color: ${cbNegative[280]};
	display: flex;
	align-items: center;
	background: ${cbNegative[950]};
	padding: 18px;
	border: 1px solid ${cbNegative[500]};
	border-left: 8px solid ${cbNegative[500]};
	border-radius: 8px;
	margin-top: 8px;
	width: 100%;
	cursor: pointer;
	font-family: proxima-nova, sans-serif;
	font-size: 0.95rem;
	text-align: left;

	svg {
		color: ${cbNegative[500]};
		margin-right: 18px;
	}
	.email-suggestion-action {
		&.suggestion {
			word-break: break-word;
			font-weight: ${fontWeightMedium};
		}
		&.secondary {
			color: ${cbPrimary[500]};
			font-weight: ${fontWeightBold};
		}
	}
`;

const textfieldStyles = (cartStyles) => {
	const { body } = cartStyles || {};
	return css`
		${getInputStyles(body)}
	`;
};

let validationDebounce;
let pazeCanCheckoutDebounce;

// Do not mask email input due to previous `setSelectionRange` error
// TODO - Go behind on if we want to mask this somehow in the future
const unmaskedInputTypes = ['email'];

const CBTextField = (props) => {
	const {
		name,
		type,
		label,
		required,
		disabled,
		focused,
		maxLength,
		helperTextOverride,
		inputMode,
		placeholderText,
		prependHtml,
		maxQuantity: max,
		minQuantity: min,
		onKeyPress,
		convertCase,
		validateOnChange,
		onBlur: onBlurProp,
		onChange: onChangeProp,
		updateOn = 'change',
		addressAccessor,
		showPlaceholder,
		inputProps,
		inputLabelProps,
		InputProps,
		onClick,
	} = props;

	const { t } = useTranslation(['checkout']);
	const {
		template,
		paymentMethod,
		pazeData,
		setPazeData,
		setPaymentMethod,
		setFormServerErrorInfo,
	} = useStore(
		useShallow((state) => {
			return {
				setPaymentMethod: state.setPaymentMethod,
				setFormServerErrorInfo: state.setFormServerErrorInfo,
				pazeData: state.pazeData,
				setPazeData: state.setPazeData,
				template: state.template,
				paymentMethod: state.paymentMethod,
			};
		}),
	);
	const {
		value,
		errorMessageData,
		setFormValue,
		formFieldIsValid,
		countryCode,
		emailSuggestion,
		setEmailSuggestion,
		formFieldOnChangeHandlers,
		formFieldOnBlurHandlers,
	} = useFormStore(
		useShallow((state) => ({
			value: get(state.formData, name),
			errorMessageData: get(state.formErrors, name),
			emailSuggestion: state.emailSuggestion,
			setEmailSuggestion: state.setEmailSuggestion,
			setFormValue: state.setFormValue,
			formFieldIsValid: state.formFieldIsValid,
			countryCode:
				name.split('.').slice(-1)[0] === 'zip'
					? state.formData[addressAccessor].countryCode
					: null,
			formFieldOnChangeHandlers: state.formFieldOnChangeHandlers,
			formFieldOnBlurHandlers: state.formFieldOnBlurHandlers,
		})),
	);

	const errorMessage = errorMessageData
		? t(errorMessageData?.key, { inputName: label, ...errorMessageData?.options })
		: null;

	const hideFromMouseflow = ['cardToken', 'securityCode'].includes(name);

	const checkEmail = (email) => {
		mailcheck.run({
			email,
			suggested: (mailCheckSuggestion) => {
				setEmailSuggestion(mailCheckSuggestion.full);
			},
			empty: () => {
				if (emailSuggestion) {
					setEmailSuggestion(null);
				}
			},
		});
	};

	// removes all leading spaces, trailing spaces, and more than one space between chars
	const removeExtraSpaces = (value) => {
		const newValue = value.replace(/\s+/g, ' ').trim();
		return newValue;
	};

	const shouldUseTextMasking = !unmaskedInputTypes.includes(type);

	const handleChange = (newValue) => {
		if (updateOn === 'change') {
			if (shouldUseTextMasking) {
				const masked = IMask.createMask({
					mask: getMask(name, newValue, countryCode),
					placeholderChar: '\u2000',
					definitions: maskDefinitions,
				});
				masked.resolve(newValue);
				setFormValue(name, masked.value);
			} else {
				setFormValue(name, newValue);
			}
		}
		formFieldOnChangeHandlers.forEach((cb) => cb(name));
	};

	const handleBlur = (newValue) => {
		if (name === 'email') {
			checkEmail(newValue);
		}
		let conformedValue = removeExtraSpaces(newValue);
		const valueNeedsTrimming = value.match(/^\s|\s{2,}|\s$/);
		if (updateOn === 'blur' || valueNeedsTrimming) {
			if (shouldUseTextMasking) {
				const masked = IMask.createMask({
					mask: getMask(name, newValue, countryCode),
					placeholderChar: '\u2000',
					definitions: maskDefinitions,
				});
				masked.resolve(conformedValue);
				conformedValue = masked.value;
			}
			setFormValue(name, conformedValue);
		}
		if (paymentMethod !== PaymentMethods.APPLE_PAY) {
			formFieldIsValid(name, conformedValue);
		}
		formFieldOnBlurHandlers.forEach((cb) => cb(name));
	};

	const validateOnInputChange = (newValue) => {
		// check validity when typing pauses
		clearTimeout(validationDebounce);
		validationDebounce = setTimeout(() => {
			formFieldIsValid(name, newValue);
		}, 300);
	};

	const debouncePazeCheckOnChange = (newValue) => {
		// check if email is valid for paze
		clearTimeout(pazeCanCheckoutDebounce);
		pazeCanCheckoutDebounce = setTimeout(async () => {
			// Reset checkoutResponse when email changes

			const emailIsValid = await formFieldIsValid(name, newValue, false);
			if (emailIsValid) {
				if (pazeData.checkoutResponse) {
					resetPazeData(setPazeData);
				}
				pazeCanCheckout(
					newValue,
					setPazeData,
					paymentMethod,
					setPaymentMethod,
					setFormServerErrorInfo,
				);
			}
		}, 500);
	};

	const labelText = (
		<>
			{label}
			{required && <span className="required">*</span>}
		</>
	);

	const getConvertedInput = (incomingValue) => {
		switch (convertCase) {
			case 'upper':
				return incomingValue.toUpperCase();
			case 'lower':
				return incomingValue.toLowerCase();
			default:
				return incomingValue;
		}
	};

	const handleKeyPress = (ev) => {
		if (onKeyPress) {
			onKeyPress(ev);
		}
	};

	const acceptEmailSuggestion = () => {
		const suggestion = emailSuggestion;
		setFormValue('email', suggestion);
		setEmailSuggestion(null);
		pazeCanCheckout(
			suggestion,
			setPazeData,
			paymentMethod,
			setPaymentMethod,
			setFormServerErrorInfo,
		);
	};

	const assignHelperText = () => {
		if (name === 'email') {
			if (errorMessage) {
				return errorMessage;
			}
			if (emailSuggestion) {
				return (
					<button
						css={emailAndSuggestionStyles}
						type="button"
						onClick={acceptEmailSuggestion}
					>
						<ErrorIcon />
						<span>
							<Trans
								i18nKey="checkout:field.email.suggestion2"
								emailSuggestion={emailSuggestion}
							>
								Did you mean{' '}
								<span className="email-suggestion-action suggestion">
									{{ emailSuggestion }}
								</span>
								? (
								<span className="email-suggestion-action secondary">
									Click to update
								</span>
								)
							</Trans>
						</span>
					</button>
				);
			}
			return `${t('field.email.explanation')}`;
		} else if (name === 'phone') {
			return errorMessage || `${t('field.phone.explanation')}`;
		} else {
			return errorMessage;
		}
	};

	const cartStyles = getZoneStyles(template, 'cart');

	return (
		<div css={textfieldStyles(cartStyles)}>
			<InputPlaceholder showPlaceholder={showPlaceholder} label={label}>
				<MuiTextField
					className="CB-field"
					onKeyPress={handleKeyPress}
					onFocus={() => {}}
					onChange={(e) => {
						const newValue = getConvertedInput(e.target.value);
						validateOnChange && validateOnInputChange(newValue);
						name === 'email' && debouncePazeCheckOnChange(newValue);
						//keep the submit button hidden onChange only for android devices
						handleChange(newValue);
						if (onChangeProp) {
							onChangeProp(e);
						}
					}}
					onBlur={(e) => {
						const newValue = getConvertedInput(e.target.value);
						handleBlur(newValue);
						if (onBlurProp) {
							onBlurProp(e);
						}
					}}
					onClick={onClick}
					value={value || ''}
					id={name}
					name={name}
					type={type}
					label={labelText}
					fullWidth
					disabled={disabled}
					error={
						!!helperTextOverride ||
						!!errorMessageData ||
						!!(name === 'email' && emailSuggestion)
					}
					autoComplete={autoCompleteValues[name]}
					placeholder={placeholderText}
					helperText={helperTextOverride || assignHelperText()}
					InputLabelProps={{
						className: 'scrollable-label ',
						...inputLabelProps,
					}}
					variant="outlined"
					// InputProps are material-ui props
					InputProps={{
						inputComponent: 'input',
						startAdornment: prependHtml,
						size: 'small',
						classes: {
							...muiOutlinedInputDefaultClasses,
							root:
								focused && (name === 'cardToken' || name === 'phone')
									? `Mui-focused ${muiOutlinedInputDefaultClasses.root} ${muiOutlinedInputDefaultClasses.focused}`
									: muiOutlinedInputDefaultClasses.root,
							input: hideFromMouseflow
								? `no-mouseflow ${muiOutlinedInputDefaultClasses.input}`
								: muiOutlinedInputDefaultClasses.input,
						},
						...InputProps,
					}}
					// inputProps are html properties
					inputProps={{
						maxLength,
						inputMode,
						max,
						min,
						tabIndex: 0,
						...inputProps,
					}}
				/>
			</InputPlaceholder>
		</div>
	);
};

CBTextField.propTypes = {
	inputLabelProps: PropTypes.object,
	name: PropTypes.string.isRequired,
	type: PropTypes.string,
	label: PropTypes.string,
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	focused: PropTypes.bool,
	maxLength: PropTypes.number,
	inputMode: PropTypes.string,
	placeholderText: PropTypes.string,
	helperTextOverride: PropTypes.string,
	autoFocus: PropTypes.bool,
	prependHtml: PropTypes.object,
	maxQuantity: PropTypes.number,
	minQuantity: PropTypes.number,
	onKeyDown: PropTypes.object,
	convertCase: PropTypes.string,
	validateOnChange: PropTypes.bool,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	updateOn: PropTypes.oneOf(['change', 'blur', 'none']),
	addressAccessor: PropTypes.oneOf(['shipping', 'billing']),
	showPlaceholder: PropTypes.bool,
	inputProps: PropTypes.object,
	InputProps: PropTypes.object,
	onKeyPress: PropTypes.func,
	onClick: PropTypes.func,
};

CBTextField.defaultProps = {
	type: 'text',
	required: false,
	disabled: false,
	focused: false,
	updateOn: 'change',
	addressAccessor: null,
	showPlaceholder: false,
	inputProps: {},
};

export default CBTextField;
