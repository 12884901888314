import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { createContext, useEffect, useMemo, useState } from 'react';

import { useStore } from './stores';
import { CountdownTimerActionType } from '@/utils/enums';

export const CountdownTimerContext = createContext({});

const SECOND_IN_MS = 1000;
const MINUTE_IN_MS = SECOND_IN_MS * 60;
const HOUR_IN_MS = MINUTE_IN_MS * 60;
const DAY_IN_MS = HOUR_IN_MS * 24;

const getDateObjFromTimestamp = (timestamp) => {
	let diff = timestamp - Date.now();
	const days = Math.max(0, Math.floor(diff / DAY_IN_MS));
	diff -= days * DAY_IN_MS;
	const hours = Math.max(0, Math.floor(diff / HOUR_IN_MS));
	diff -= hours * HOUR_IN_MS;
	const minutes = Math.max(0, Math.floor(diff / MINUTE_IN_MS));
	diff -= minutes * MINUTE_IN_MS;
	const seconds = Math.max(0, Math.floor(diff / SECOND_IN_MS));
	diff -= seconds * SECOND_IN_MS;

	// We do not currently care about remaining ms
	return {
		days,
		hours,
		minutes,
		seconds,
	};
};

const interval = 1000;

export const CountdownTimerContextProvider = ({ children }) => {
	const template = useStore((state) => state.template);
	if (!template.countdownTimer) {
		// This should only be rendered after we have retrieved template - assumes template will not change
		return <>{children}</>;
	}
	const countdownTimerHasExpired = useStore((state) => state.countdownTimerHasExpired);
	const setCountdownTimerHasExpired = useStore((state) => state.setCountdownTimerHasExpired);
	const endTimestamp = useMemo(() => {
		const { type, seconds, timestamp } = template.countdownTimer.timer;
		return type === 'STATIC' ? timestamp : dayjs().add(seconds, 'seconds').valueOf();
	}, [template.countdownTimer.timer]);
	const [timeLeft, setTimeLeft] = useState(() => getDateObjFromTimestamp(endTimestamp));
	const [timerReinitBool, setTimerReinitBool] = useState(false);

	useEffect(() => {
		if (countdownTimerHasExpired) {
			return;
		}
		setTimeLeft(getDateObjFromTimestamp(endTimestamp));
		let timeout;
		let expected = Date.now() + interval;

		const tick = () => {
			setTimeLeft(getDateObjFromTimestamp(endTimestamp));
			const now = Date.now();
			if (now >= endTimestamp) {
				setCountdownTimerHasExpired(true);
				if (template.countdownTimer.actionType === CountdownTimerActionType.PAYLINK) {
					if (template.countdownTimer.payLink) {
						window.location.href = template.countdownTimer.payLink;
					}
				}
				return;
			}
			const dt = now - expected;
			if (dt > interval) {
				// Something has gone wrong beyond simple drift
				// Trigger effect cleanup and restart so we get back on track
				setTimerReinitBool((prev) => !prev);
				return;
			}
			expected += interval;
			timeout = window.setTimeout(tick, Math.max(0, interval - dt));
		};

		timeout = window.setTimeout(tick, interval);
		return () => {
			window.clearTimeout(timeout);
		};
	}, [
		timerReinitBool,
		setTimerReinitBool,
		countdownTimerHasExpired,
		setCountdownTimerHasExpired,
		endTimestamp,
		template.countdownTimer,
	]);

	const ctx = useMemo(() => ({ timeLeft }), [timeLeft]);

	return <CountdownTimerContext.Provider value={ctx}>{children}</CountdownTimerContext.Provider>;
};

CountdownTimerContextProvider.propTypes = {
	children: PropTypes.node,
};
