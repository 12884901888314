import { css } from '@emotion/react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { Card, ConsentCheckbox, ProductDisplay, UpdateOverlay } from '..';
import CartFooter from './CartFooter';
import ZoneWrapper from '@/components/layoutFactory/ZoneWrapper';
import { getHeaderStyles, getZoneStyles } from '@/components/mainContent/contentStyle';
import { MediaQueryContext } from '@/state/MediaQueryContext';
import { useStore } from '@/state/stores';
import {
	cbNeutral,
	checkoutTheme,
	defaultBodyFontColor,
	defaultDividerColor,
	defaultHeadingFontColor,
	defaultHeadingFontWeight,
	defaultPanelBackgroundColor,
	defaultPanelBorderColor,
	defaultPanelBoxShadow,
	fontFamily,
	fontWeightRegular,
} from '@/theme';
import { PaymentMethods } from '@/utils/enums';
import { isTemplateJoined, stringIsEqualCaseInsensitive } from '@/utils/helpers';

const cartCardStyles = (cartStyles, zonesAreConnected) => {
	const { borderRadius, header } = cartStyles || {};
	const headerStyles = getHeaderStyles(header);

	return css`
		display: flex;
		flex-direction: column;
		max-width: 100%;
		background: transparent;
		border-radius: ${borderRadius && !zonesAreConnected ? borderRadius : '0px'};
		box-shadow: none;

		.add-to-cart-link {
			cursor: pointer;
			display: block;
			font-size: 1.125rem;
			text-align: right;
			width: 100%;
			padding-bottom: 1.5rem;
			margin-bottom: 1.5rem;
			border-bottom: 1px solid ${cbNeutral[700]};
			&:focus {
				outline: none;
				text-decoration: underline;
			}
		}
		${headerStyles}
	`;
};

const styles = (
	isTwoColumnLayout,
	cartStyles,
	zonesAreConnected,
	isReceiptPage,
	isSmallScreen,
	summary,
) => {
	const {
		backgroundColor,
		boxShadow: customBoxShadow,
		borderSize,
		borderColor,
		borderRadius,
		header,
		body,
	} = cartStyles || {};
	const boxShadow = zonesAreConnected || isTwoColumnLayout ? 'none' : customBoxShadow;

	const isJoined = zonesAreConnected || isTwoColumnLayout;
	const isBorderTransparent = borderColor?.length > 7 && borderColor?.slice(-2) === '00';
	const hideBorder = !borderColor || isBorderTransparent || isJoined;
	const borderWidth = borderSize || '1px';
	const borderColorValue = borderColor || defaultPanelBorderColor;
	const border = hideBorder ? 'none' : `${borderWidth} solid ${borderColorValue}`;
	const { headerFontColor: summaryHeaderFontColor, headerFontWeight: summaryHeaderFontWeight } =
		summary || {};
	const headingFontColor =
		(isTwoColumnLayout ? summaryHeaderFontColor : header?.fontColor) || defaultHeadingFontColor;
	const headingFontWeight =
		(isTwoColumnLayout ? summaryHeaderFontWeight : header?.fontWeight) ||
		defaultHeadingFontWeight;

	const bodyFontColor = (() => {
		if (isReceiptPage) {
			return defaultBodyFontColor;
		}
		if (!isSmallScreen && isTwoColumnLayout && summary?.bodyFontColor) {
			return summary.bodyFontColor;
		}
		if ((!isTwoColumnLayout || isSmallScreen) && body?.fontColor) {
			return body.fontColor;
		}
		return defaultBodyFontColor;
	})();

	const bodyFontFamily = (() => {
		if (isReceiptPage) {
			return fontFamily;
		}
		if (isSmallScreen) {
			return `${body?.fontFamily}, sans-serif   ` || fontFamily;
		}
		if (isTwoColumnLayout) {
			return summary?.bodyFontFamily || fontFamily;
		}
		return body?.fontFamily || fontFamily;
	})();

	const bodyFontWeight = (() => {
		if (isReceiptPage) {
			return fontWeightRegular;
		}
		if (!isSmallScreen && isTwoColumnLayout && summary?.bodyFontWeight) {
			return summary.bodyFontWeight;
		}
		if (body?.fontWeight) {
			return body.fontWeight;
		}
		return fontWeightRegular;
	})();

	return css`
		background: ${(isTwoColumnLayout && 'transparent') ||
		backgroundColor ||
		defaultPanelBackgroundColor};
		box-shadow: ${zonesAreConnected ? 'none' : boxShadow || defaultPanelBoxShadow};
		overflow: hidden;

		${checkoutTheme.breakpoints.up('sm')} {
			border: ${border};
			border-radius: ${borderRadius && !isJoined ? borderRadius : '0px'};
		}

		.MuiTypography-root.heading-large-image {
			margin-bottom: 4px;
		}

		.MuiSvgIcon-root,
		.MuiTypography-root {
			color: ${bodyFontColor};
		}

		.MuiTypography-root {
			font-family: ${bodyFontFamily};
			font-weight: ${bodyFontWeight};
		}

		.MuiTypography-root.heading {
			color: ${headingFontColor};
			font-weight: ${headingFontWeight};
		}

		${checkoutTheme.breakpoints.down('sm')} {
			box-shadow: none;
		}
	`;
};

const Cart = ({ bumpList, shouldDisplayBumps }) => {
	const {
		allProducts,
		setCartStatus,
		regulations,
		setRegulations,
		template,
		cartInitialized,
		urlVars,
		paymentMethod,
		isLargeProductImage,
	} = useStore(
		useShallow((state) => ({
			allProducts: state.allProducts,
			setCartStatus: state.setCartStatus,
			showCoupon: state.showCoupon,
			regulations: state.regulations,
			setRegulations: state.setRegulations,
			template: state.template,
			cartInitialized: state.cartInitialized,
			urlVars: state.urlVars,
			paymentMethod: state.paymentMethod,
			isLargeProductImage: state.isLargeProductImage,
		})),
	);

	const { t } = useTranslation('checkout');
	const location = useLocation();
	const isReceiptPage = location.pathname === '/order-received';
	const { isSmallScreen, isTwoColumnLayout } = useContext(MediaQueryContext);
	const summary = template?.style?.attributes?.summary || {};
	const cartStyles = getZoneStyles(template, 'cart');
	const zonesAreConnected = isTemplateJoined(template);

	const cartRef = useRef();

	const toggleCartItem = (productSKU) => {
		setCartStatus((prev) => {
			const newStatus = prev.map((product) => {
				if (product.sku === productSKU) {
					return {
						...product,
						isActive: !product.isActive,
					};
				}
				return product;
			});
			return newStatus;
		});
	};
	const hasProducts = !!allProducts?.length;

	const sortProducts = (a, b) => {
		const removeSkuQuantity = (sku) => {
			if (typeof sku !== 'string') {
				return sku;
			}
			// Remove any quantity indicators
			const quantityIndex = sku.indexOf('.');
			if (quantityIndex !== -1) {
				return sku.slice(0, quantityIndex);
			}
			return sku;
		};
		const urlSkus = urlVars.cbitems && urlVars.cbitems.split('_').filter((sku) => !!sku);
		if (!urlSkus) {
			return 0;
		}
		let aPriority = urlSkus.findIndex((sku) =>
			stringIsEqualCaseInsensitive(removeSkuQuantity(sku), a.sku),
		);
		if (aPriority === undefined) {
			aPriority = Infinity;
		}
		let bPriority = urlSkus.findIndex((sku) =>
			stringIsEqualCaseInsensitive(removeSkuQuantity(sku), b.sku),
		);
		if (bPriority === undefined) {
			bPriority = Infinity;
		}
		if (!Number.isFinite(aPriority) && !Number.isFinite(bPriority)) {
			return 0;
		}
		return aPriority - bPriority;
	};

	const bottomBorderColor = (() => {
		if (isReceiptPage) {
			return defaultDividerColor;
		}
		if (isTwoColumnLayout && summary?.dividerColor && !isSmallScreen) {
			return summary?.dividerColor;
		}
		if ((!isTwoColumnLayout || isSmallScreen) && cartStyles?.body?.dividerColor) {
			return cartStyles?.body?.dividerColor || defaultDividerColor;
		}
		return defaultDividerColor;
	})();

	const inCart = hasProducts
		? allProducts
				.filter((product) => !product.isBump)
				.sort(sortProducts)
				.map((product) => {
					const productProps = {
						className: 'product',
						toggleCartItem: () => toggleCartItem(product.sku),
						inBumpList: false,
					};
					return (
						<ProductDisplay
							{...productProps}
							{...product}
							isLargeProductImage={isLargeProductImage}
							key={product.id}
							isActive
							bottomBorderColor={bottomBorderColor}
						/>
					);
				})
		: [];

	return (
		<div
			css={styles(
				isTwoColumnLayout,
				cartStyles,
				zonesAreConnected,
				isReceiptPage,
				isSmallScreen,
				summary,
			)}
			ref={cartRef}
			className={!isTwoColumnLayout ? 'CB-form' : null}
			id="CB-cart-container"
		>
			{isSmallScreen && <ZoneWrapper zoneId="leftColumnTop" />}
			<ZoneWrapper zoneId="rightColumnTop" />
			<Card css={cartCardStyles(cartStyles, zonesAreConnected)}>
				<Typography
					variant="h4"
					className={
						isLargeProductImage
							? 'heading heading-large-image CB-font-title'
							: 'heading CB-font-title'
					}
				>
					{t('card-title.cart-summary')}
				</Typography>

				{cartInitialized ? (
					inCart
				) : (
					<div style={{ height: '325px', marginBottom: '15px', position: 'relative' }}>
						<UpdateOverlay visible transparentBackground />
					</div>
				)}

				{/* only shows in UI when regulations exist */}
				{isSmallScreen && paymentMethod !== PaymentMethods.APPLE_PAY
					? regulations.map((regulation) => (
							<ConsentCheckbox
								key={regulation.value}
								regulation={regulation}
								setRegulations={setRegulations}
							/>
						))
					: null}

				<CartFooter
					bumpList={bumpList}
					shouldDisplayBumps={shouldDisplayBumps}
					bottomBorderColor={bottomBorderColor}
				/>
			</Card>
			{!isSmallScreen && <ZoneWrapper zoneId="rightColumnBottom" />}
		</div>
	);
};

Cart.propTypes = {
	bumpList: PropTypes.array,
	shouldDisplayBumps: PropTypes.bool,
};

export default Cart;
