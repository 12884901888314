import { css } from '@emotion/react';
import { Fade, Skeleton } from '@mui/material';
import PropTypes from 'prop-types';

const inputCSS = css`
	position: relative;
	.input-overlay {
		border-radius: 4px;
		position: absolute;
		width: 100%;
	}
`;

const InputPlaceholder = ({ showPlaceholder, children }) => {
	return (
		<div className="input-placeholder" css={inputCSS}>
			{children ? (
				<>
					<Fade className="input-overlay" in={showPlaceholder}>
						<Skeleton variant="rectangular" width="100%" height="3rem"></Skeleton>
					</Fade>
					<Fade in={!showPlaceholder}>{children}</Fade>
				</>
			) : (
				<Skeleton variant="rectangular" width="100%" height="3rem"></Skeleton>
			)}
		</div>
	);
};

InputPlaceholder.propTypes = {
	showPlaceholder: PropTypes.bool,
	label: PropTypes.string,
	children: PropTypes.node,
};

export default InputPlaceholder;
