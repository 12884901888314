import { css } from '@emotion/react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { amex, mostCards } from '@/assets';
import Modal from '@/components/common/Modal';

const securityModalStyles = css`
	.credit-card {
		display: block;
		margin: 1rem auto 0 auto;
	}

	.caption {
		text-align: center;
		display: block;
		font-size: 14px;
		font-style: italic;
	}
`;

const SecurityCodeModal = (props) => {
	const { modalOpen, closeModal } = props;
	const { t } = useTranslation(['checkout']);

	const dialogTitle = t('security-modal.find');

	const dialogContent = (
		<div css={securityModalStyles}>
			<Typography variant="subtitle1" component="p" gutterBottom>
				{t('security-modal.digits')}
			</Typography>
			<img className="credit-card" src={mostCards} alt={t('alt-tags.back-most-cards')} />
			<Typography variant="caption" className="caption" gutterBottom>
				{t('security-modal.3-digits')}
			</Typography>
			<Typography variant="subtitle1" component="p" gutterBottom>
				{t('security-modal.amex')}
			</Typography>
			<img className="credit-card" src={amex} alt={t('alt-tags.back-amex')} />
			<Typography variant="caption" className="caption" gutterBottom>
				{t('security-modal.4-digits')}
			</Typography>
		</div>
	);

	return (
		<Modal
			modalOpen={modalOpen}
			closeModal={closeModal}
			dialogTitle={dialogTitle}
			dialogContent={dialogContent}
		/>
	);
};

SecurityCodeModal.propTypes = {
	modalOpen: PropTypes.bool,
	closeModal: PropTypes.func,
};

export default SecurityCodeModal;
