import { css } from '@emotion/react';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { Card, OrderProducts, OrderTotals } from '..';
import { useStore } from '@/state/stores';
import { cbNeutral, checkoutTheme, defaultPanelBorderColor, defaultPanelBoxShadow } from '@/theme';

dayjs.extend(utc);
dayjs.extend(LocalizedFormat);

const container = (isUpsell) => css`
	border: 1 px ${defaultPanelBorderColor};
	box-shadow: ${defaultPanelBoxShadow};
	.order-number-date {
		margin-top: 1.5rem;
		margin-bottom: 3rem;

		${checkoutTheme.breakpoints.up('lg')} {
			display: flex;
			align-items: center;
			flex-direction: row;
			justify-content: space-between;
		}

		.order-number {
			margin-bottom: 1em;

			${checkoutTheme.breakpoints.up('lg')} {
				margin-bottom: 0;
			}
		}

		.date-badge {
			background: ${cbNeutral[900]};
			padding: 8px;
			border-radius: 4px;
			width: fit-content;
			display: flex;
			align-items: center;
			justify-content: center;

			.order-date {
				font-weight: 600;
				font-size: 0.875rem;
				color: ${cbNeutral[100]};
			}
		}
	}

	.footer {
		flex-direction: column;
		width: 100%;
		margin-bottom: ${isUpsell ? '48px' : 0};
		${checkoutTheme.breakpoints.up('sm')} {
			max-width: 300px;
			margin-left: auto;
			margin-bottom: ${isUpsell ? '64px' : 0};
		}

		.footer-line {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			color: ${cbNeutral[400]};

			span {
				color: ${cbNeutral[100]};
				font-weight: bold;
			}
		}
	}
	h3 {
		margin-bottom: 20px;
	}
`;

const OrderDetails = () => {
	const { t } = useTranslation('order-received');
	const title = t('order-details.title');
	const { selectedLanguage, receiptPageData, receiptPageProducts, orderTotals } = useStore(
		useShallow((state) => ({
			selectedLanguage: state.selectedLanguage,
			receiptPageData: state.receiptPageData,
			receiptPageProducts: state.receiptPageProducts,
			orderTotals: state.orderTotals,
		})),
	);
	const isUpsell = receiptPageData?.length > 1;

	return (
		<Card css={container(isUpsell)}>
			<Typography variant="h3">{title}</Typography>
			{receiptPageProducts?.map((product, index) => {
				const timestamp = parseInt(receiptPageData[index].timestamp);
				const date = dayjs.utc(timestamp).locale(selectedLanguage).format('LL');
				const isRecurring = !!product?.find((product) => product.recurring === true);

				return (
					<div key={index}>
						<div className="order-number-date">
							<Typography className="order-number" variant="h4">
								{t('common.order-number')}: {receiptPageData[index]?.orderNumber}
							</Typography>
							<div className="date-badge">
								<Typography className="order-date">
									{`${t('order-details.date')} ${date}`}
								</Typography>
							</div>
						</div>

						<OrderProducts products={product} />

						<div className="footer">
							<OrderTotals
								isRecurring={isRecurring}
								orderTotals={orderTotals?.[index]}
								receiptPageData={receiptPageData[index]}
							/>
						</div>
					</div>
				);
			})}
		</Card>
	);
};

export default OrderDetails;

OrderDetails.propTypes = {
	products: PropTypes.array,
	receiptIndex: PropTypes.number,
};
