import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

const clickbankEventNameMap = {
	ORDER_FORM_VIEW: 'clickbank-order-form',
	RECEIPT_PAGE_VIEW: 'clickbank-confirmation-page',
};

const GoogleAnalyticsPixels = (props) => {
	const { pixels, page } = props;
	const [sendClickbankEvent, setSendClickbankEvent] = useState(false);
	const pagesRendered = useRef({});
	const pixelConfigLocations = useRef({});
	const gtagFuncs = useRef({});

	// Set a namespace for each GA account
	useEffect(() => {
		if (pixels?.length && !pagesRendered.current[page]) {
			pixels.forEach((pixel) => {
				const id = pixel.service.googleAnalyticsId;
				const srcUrl = pixel.service.srcUrl;
				if (pixelConfigLocations.current[id]) {
					// Pixel already configured
					return;
				}
				// Store URL where it was configured, to not resend page view.
				pixelConfigLocations.current[id] = srcUrl;

				const customDataLayerName = id.replace(/-/g, '');

				window[customDataLayerName] = window[customDataLayerName] || [];

				function gtag() {
					window[customDataLayerName].push(arguments);
				}

				gtagFuncs.current[id] = gtag;

				const script = document.createElement('script');
				script.async = true;
				script.src = `https://www.googletagmanager.com/gtag/js?id=${id}&l=${customDataLayerName}`;
				document.head.appendChild(script);

				gtag('js', new Date());
				const domains = pixel.service.domains;
				if (domains && domains.length) {
					gtag('set', 'linker', { domains: pixel.service.domains });
				}
				gtag('config', id);
				gtag('consent', 'ad_storage', 'denied');
				gtag('consent', 'analytics_storage', 'denied');
			});
		}
	}, [pixels, page]);

	// Send a page view event whenever the page changes
	useEffect(() => {
		if (page && !pagesRendered.current[page]) {
			setSendClickbankEvent(true);
			Object.assign(pagesRendered.current, { [page]: true });
		}
	}, [page]);

	useEffect(() => {
		const event_name = clickbankEventNameMap[page] || 'clickbank-page-view';
		if (sendClickbankEvent && pixels?.length) {
			pixels.forEach((pixel) => {
				const id = pixel.service.googleAnalyticsId;
				const gtag = gtagFuncs.current[id];
				if (typeof gtag !== 'function') {
					return;
				}
				gtag('event', event_name, {
					'clickbank-data': pixel.service.srcUrl,
				});
			});
			setSendClickbankEvent(false);
		}
	}, [sendClickbankEvent, pixels, page]);

	return null;
};

export default GoogleAnalyticsPixels;

GoogleAnalyticsPixels.propTypes = {
	pixels: PropTypes.array,
	page: PropTypes.oneOf(['ORDER_FORM_VIEW', 'RECEIPT_PAGE_VIEW']),
};
