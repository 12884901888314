import { css } from '@emotion/react';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getZoneStyles } from '@/components/mainContent/contentStyle';
import { useStore } from '@/state/stores';
import {
	cbNeutral,
	checkoutTheme,
	defaultCopyrightBackgroundColor,
	defaultCopyrightFontColor,
	palette,
} from '@/theme';
import { receiptPageFooterHeight } from '@/theme/checkoutTheme';

const legalFooter = (legalFooterStyles) => {
	const fontColor = legalFooterStyles?.body?.fontColor || defaultCopyrightFontColor;
	const alternativeLinkColor = fontColor === palette.common.white ? cbNeutral[900] : fontColor;
	return css`
		background-color: ${legalFooterStyles?.backgroundColor || defaultCopyrightBackgroundColor};
		color: ${fontColor};
		text-align: center;
		padding: 8px 40px;
		min-height: ${receiptPageFooterHeight};
		margin-top: auto;

		${checkoutTheme.breakpoints.down('sm')} {
			padding: 32px 16px;
		}

		.footer-link {
			color: ${fontColor};
			&:hover,
			&:active,
			&:focus,
			&:visited {
				color: ${alternativeLinkColor};
			}
		}
	`;
};

const LegalFooter = ({ affiliateName, trackingCode }) => {
	const { t } = useTranslation('common');
	const receiptPageData = useStore((state) => state.receiptPageData);
	const template = useStore((state) => state.template);
	const legalFooterStyles = getZoneStyles(template, 'copyright');

	const domain = receiptPageData[0]?.domain;
	const thisYear = new Date().getFullYear();
	const affiliateID = affiliateName || `[${t('common:footer.affiliate-id-none')}]`;

	return (
		<Grid container css={legalFooter(legalFooterStyles)} id="CB-footer-lower-section">
			<Grid item xs={12}>
				<Typography variant="body2" gutterBottom>
					{t('footer.affiliate-id', { affiliateID })}
				</Typography>
				{trackingCode && (
					<Typography variant="body2" gutterBottom>
						TID = {trackingCode}
					</Typography>
				)}
				{domain && (
					<Typography variant="body2">
						{t('footer.trusted-reseller', { domain: domain })}
					</Typography>
				)}
				<Typography variant="body2">
					{t('footer.copyright-1', { thisYear: thisYear })}
					<a
						href="https://www.clickbank.com"
						className="footer-link"
						target="_blank"
						rel="noopener noreferrer"
					>
						ClickBank
					</a>
					{t('footer.copyright-2', { thisYear: thisYear })}
				</Typography>
			</Grid>
		</Grid>
	);
};

LegalFooter.propTypes = {
	affiliateName: PropTypes.string,
	trackingCode: PropTypes.string,
};

export default LegalFooter;
