import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useFormStore, useStore } from '@/state/stores';
import { paymentMethodCurrencyDataMap, PaymentMethods } from '@/utils/enums';
import { usePostMountEffect } from '@/utils/hooks';

export const getRegulationsArr = (
	billingCountryData,
	shippingCountryData,
	isShippingSameAsBilling,
) => {
	const billingRegulations = billingCountryData?.regulations;
	const shippingRegulations = shippingCountryData?.regulations;
	return (Array.isArray(billingRegulations) ? billingRegulations : [])
		.concat(
			!isShippingSameAsBilling && Array.isArray(shippingRegulations)
				? shippingRegulations
				: [],
		)
		.reduce((a, b) => {
			if (!a.find((reg) => reg.value === b.value)) {
				a.push(b);
			}
			return a;
		}, []);
};

export const GeoCurrenciesLoader = ({ allQueriesData }) => {
	const {
		pazeData,
		applePayData,
		selectedLanguage,
		setCountries,
		setCurrencyCodes,
		selectedCurrency,
		setSelectedCurrency,
		urlVars,
		setCardsAccepted,
		billingCountryData,
		shippingCountryData,
		setSelectedCountryData,
		setDefaultCountry,
		setForceGdpr,
		paymentMethod,
		setCurrencyData,
		setPayPalEnabled,
		setPayPalCommerceEnabled,
		setIsShippable,
		setRegulations,
		isShippingSameAsBilling,
		setIsPayPalDirectEnabled,
	} = useStore(
		useShallow((state) => ({
			pazeData: state.pazeData,
			setPazeData: state.setPazeData,
			applePayData: state.applePayData,
			selectedLanguage: state.selectedLanguage,
			setCountries: state.setCountries,
			setCurrencyCodes: state.setCurrencyCodes,
			selectedCurrency: state.selectedCurrency,
			setSelectedCurrency: state.setSelectedCurrency,
			urlVars: state.urlVars,
			affiliate: state.affiliate,
			setCardsAccepted: state.setCardsAccepted,
			allSKUs: state.allSKUs,
			billingCountryData: state.selectedCountryData.billing,
			shippingCountryData: state.selectedCountryData.shipping,
			setSelectedCountryData: state.setSelectedCountryData,
			setDefaultCountry: state.setDefaultCountry,
			setForceGdpr: state.setForceGdpr,
			paymentMethod: state.paymentMethod,
			setCurrencyData: state.setCurrencyData,
			setPayPalEnabled: state.setPayPalEnabled,
			setPayPalCommerceEnabled: state.setPayPalCommerceEnabled,
			setIsShippable: state.setIsShippable,
			setRegulations: state.setRegulations,
			isShippingSameAsBilling: state.isShippingSameAsBilling,
			setIsPayPalDirectEnabled: state.setIsPayPalDirectEnabled,
		})),
	);

	const data = allQueriesData?.GEO_CURRENCIES?.geoCurrencies;
	const forceGdpr =
		data &&
		(data.forceGdpr ||
			(data.countries && data.countries.every((country) => country.gdprMember)));

	const { t } = useTranslation(['countries']);

	const updateCountryLabels = useCallback(
		(countryCodes) => {
			const countriesArray = [];
			countryCodes.forEach((data) => {
				countriesArray.push({
					value: data.countryCode,
					label: t(`countries.${data.countryCode}`),
				});
			});
			setCountries(countriesArray);
		},
		[setCountries, t],
	);

	const updateCurrenciesAndCards = useCallback(() => {
		if (paymentMethod === PaymentMethods.APPLE_PAY) {
			const { selectedCurrency, currencyData } = applePayData.currencyData;
			setSelectedCurrency(selectedCurrency);
			setCurrencyCodes([selectedCurrency]);
			setCurrencyData(currencyData);
			return;
		}
		if (paymentMethod === PaymentMethods.PAZE) {
			const { selectedCurrency, currencyData } = pazeData.currencyData;
			setSelectedCurrency(selectedCurrency);
			setCurrencyCodes([selectedCurrency]);
			setCurrencyData(currencyData);
			return;
		}
		if (billingCountryData?.currencies) {
			const currencyList = [];
			const currencyData = billingCountryData.currencies.find((currency) => {
				return currency.key === paymentMethodCurrencyDataMap[paymentMethod];
			});

			if (!currencyData) {
				console.error('No currency data found for the selected payment method');
				return;
			}
			// If the updated country does not include the selected currency,
			// loadNewCurrency is a flag used change the selected currency to the default for the country
			const loadNewCurrency = !currencyData.value.find(
				(currency) => currency.code === selectedCurrency,
			);

			currencyData.value.forEach((currency) => {
				loadNewCurrency && currency.default && setSelectedCurrency(currency.code);
				currencyList.push(currency.code);
			});

			setCurrencyCodes(currencyList);
			setCurrencyData(currencyData); // this is used again in CalculateCartLoader
		}
	}, [
		applePayData.currencyData,
		pazeData.currencyData,
		billingCountryData,
		setCurrencyCodes,
		setSelectedCurrency,
		paymentMethod,
		setCurrencyData,
		selectedCurrency,
	]);

	const { billingState, shippingState, billingCountry, shippingCountry, setFormValue } =
		useFormStore(
			useShallow((state) => ({
				billingState: state.formData.billing.state,
				shippingState: state.formData.shipping.state,
				billingCountry: state.formData.billing.countryCode,
				shippingCountry: state.formData.shipping.countryCode,
				setFormValue: state.setFormValue,
			})),
		);

	const geoRegulation = data?.regulation; // 'GDPR | 'CCPA' | 'NULL' | undefined (if query fails)

	const regulationsArr = useMemo(
		() => getRegulationsArr(billingCountryData, shippingCountryData, isShippingSameAsBilling),
		[billingCountryData, shippingCountryData, isShippingSameAsBilling],
	);

	const getRegulations = useCallback(() => {
		if (Array.isArray(regulationsArr)) {
			const activeRegulations = [];
			regulationsArr.forEach((item) => {
				const newItem = {
					key: item.key,
					value: item.value,
					checked: false,
				};
				const meetsCCPACriteria =
					item.value === 'CCPA' &&
					((billingCountryData.countryCode === 'US' && billingState === 'CA') ||
						(shippingCountryData.countryCode === 'US' && shippingState === 'CA'));
				const meetsGDPRCriteria = item.value === 'GDPR';
				if (meetsCCPACriteria || meetsGDPRCriteria) {
					activeRegulations.push(newItem);
				}
			});
			if (
				geoRegulation === 'CCPA' &&
				!activeRegulations.find((reg) => reg.value === 'CCPA')
			) {
				activeRegulations.push({
					key: 'CCPA',
					value: 'CCPA',
					checked: false,
				});
			} else if (
				(geoRegulation === 'GDPR' || forceGdpr) &&
				!activeRegulations.find((reg) => reg.value === 'GDPR')
			) {
				activeRegulations.push({
					key: 'GDPR',
					value: 'GDPR',
					checked: false,
				});
			}
			return activeRegulations;
		}
		return null;
	}, [
		regulationsArr,
		geoRegulation,
		billingCountryData,
		shippingCountryData,
		billingState,
		shippingState,
		forceGdpr,
	]);

	usePostMountEffect(() => {
		setRegulations((prev) => {
			const incomingRegulations = getRegulations() || [];
			// Keep values if we already have them
			return incomingRegulations.map((reg) => {
				const existingRegulation = prev.find((item) => item.value === reg.value);
				if (existingRegulation) {
					return existingRegulation;
				}
				return reg;
			});
		});
		// eslint-disable-next-line
	}, [setRegulations, getRegulations]);

	usePostMountEffect(() => {
		(async () => {
			if (
				allQueriesData.GEO_CURRENCIES &&
				allQueriesData.GEO_CURRENCIES !== -1 &&
				allQueriesData.GEO_CURRENCIES.geoCurrencies
			) {
				const { country, ctry } = urlVars;
				const queryData = allQueriesData.GEO_CURRENCIES.geoCurrencies;

				// if a valid country is in the urlVars that will override the default returned from geoCurrencies
				const urlDefaultCountry =
					(country &&
						queryData.countries.find(
							(item) => item.countryCode === country.toUpperCase(),
						)) ||
					(ctry &&
						queryData.countries.find(
							(item) => item.countryCode === ctry.toUpperCase(),
						));

				// set default on load. this gets used in the address component.
				// when the country input changes, effects here will react
				const defaultCountry = urlDefaultCountry
					? urlDefaultCountry
					: queryData.countries.find((country) => {
							return country.default === true;
						});
				const usCountry =
					!defaultCountry &&
					queryData.countries.find((country) =>
						country.countryCode === 'US' ? country : null,
					);

				const queryDefaultCountry = defaultCountry
					? defaultCountry
					: usCountry
						? usCountry
						: queryData.countries[0];

				const isPayPalDirectEnabled = billingCountryData?.currencies.some(
					(currency) =>
						currency.key === 'PAYPAL' &&
						currency.value.length > 0 &&
						currency.value.some((value) => value.accepted.includes('payPal')),
				);

				const isPayPalCommerceEnabled = billingCountryData?.currencies.some(
					(currency) =>
						currency.key === 'PAYPAL' &&
						currency.value.length > 0 &&
						currency.value.some((value) => value.accepted.includes('payPalCommerce')),
				);

				setDefaultCountry(queryDefaultCountry);
				setPayPalEnabled(isPayPalDirectEnabled || isPayPalCommerceEnabled);
				setPayPalCommerceEnabled(isPayPalCommerceEnabled);
				setIsPayPalDirectEnabled(isPayPalDirectEnabled);
				setIsShippable(queryData.forceShipping);
			}
		})();
	}, [
		allQueriesData.GEO_CURRENCIES,
		billingCountryData,
		setSelectedCountryData,
		setDefaultCountry,
		urlVars,
		setPayPalEnabled,
		setPayPalCommerceEnabled,
		setIsShippable,
		selectedCurrency,
	]);

	// when selectedCountryData changes, re-run the function to set currency list, selected currency and cards accepted
	usePostMountEffect(() => {
		if (billingCountryData) {
			setFormValue('billing.countryCode', billingCountryData.countryCode);
			updateCurrenciesAndCards();
		}
	}, [setFormValue, billingCountryData, updateCurrenciesAndCards]);

	// when the language changes, update the country input labels
	usePostMountEffect(() => {
		data && updateCountryLabels(data.countries);
	}, [data, selectedLanguage, updateCountryLabels]);

	// when the countries input changes, store the geo currencies data for the selected country
	usePostMountEffect(() => {
		if (data) {
			const countryGeoData = data.countries.find((country) => {
				return country.countryCode === billingCountry;
			});
			countryGeoData && setSelectedCountryData(countryGeoData, 'billing');
		}
	}, [billingCountry, data, setSelectedCountryData]);

	usePostMountEffect(() => {
		if (data) {
			const countryGeoData = data.countries.find((country) => {
				return country.countryCode === shippingCountry;
			});
			countryGeoData && setSelectedCountryData(countryGeoData, 'shipping');
		}
	}, [shippingCountry, data, setSelectedCountryData]);

	// when the currency changes, update the cards accepted for credit card payments
	usePostMountEffect(() => {
		if (billingCountryData) {
			const countryCurrency = billingCountryData.currencies.find((currency) => {
				return currency.key === 'CREDIT_CARD';
			});
			const acceptedCards = countryCurrency?.value?.find(
				(data) => data.code === selectedCurrency,
			)?.accepted;
			acceptedCards && setCardsAccepted(acceptedCards);
		}
	}, [selectedCurrency, billingCountryData, setCardsAccepted]);

	// set forceGdpr when the value comes back from the service
	usePostMountEffect(() => {
		forceGdpr !== undefined && setForceGdpr(forceGdpr);
	}, [forceGdpr, setForceGdpr]);

	return null;
};
