import { css } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useFormStore, useStore } from '@/state/stores';
import { cbIneligible, cbNeutral, cbPrimary, fontWeightBold } from '@/theme';

const suggestedAddressStyles = css`
	.MuiPaper-root {
		max-width: 656px;
	}
	.MuiDialogContent-root {
		padding: 0 40px 24px 40px;
	}
	.ensure-delivery {
		padding-bottom: 16px;
	}
	.address {
		margin-top: 16px;
	}
	.actions-container.MuiDialogActions-root {
		justify-content: flex-start;
		padding: 0;
		margin-top: 16px;
	}
	.dialog-title.MuiTypography-root {
		font-weight: 700;
		color: ${cbNeutral[400]};
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 32px 40px;
	}
	.edited-field {
		font-weight: ${fontWeightBold};
		color: ${cbPrimary[500]};
	}
	.reason-orange {
		font-weight: ${fontWeightBold};
		color: ${cbIneligible[560]};
	}
	.use-my-entry-button.MuiButton-root,
	.use-suggested-address-button.MuiButton-root {
		margin-left: 16px;
	}
	.address-subtitle.MuiDialogContentText-root {
		font-weight: ${fontWeightBold};
	}
	.close-icon {
		font-size: 2rem;
	}
	p,
	.close-icon-button {
		color: ${cbNeutral[300]};
	}
	hr {
		margin: 16px 0;
	}
`;

const SuggestedAddressDialog = ({
	isDialogOpen,
	setIsDialogOpen,
	verifiedAddress,
	setVerifiedAddress,
	submitterId,
	handleSubmit,
}) => {
	const setSubmitOverlayVisible = useStore((state) => state.setSubmitOverlayVisible);
	const getFormData = useFormStore((state) => state.getFormData);
	const { billing } = getFormData();
	const { t } = useTranslation('checkout');
	const { suggestion, reason, editedFields, result } = verifiedAddress || {};

	const handleUseSuggestedAddress = (e) => {
		handleSubmit(e, submitterId, suggestion);
		setVerifiedAddress(null);
		setIsDialogOpen(false);
	};

	const handleEditAddress = () => {
		setVerifiedAddress(null);
		setIsDialogOpen(false);
		setSubmitOverlayVisible(false);
	};

	const handleUseMyEntry = (e) => {
		handleSubmit(e, submitterId);
		setVerifiedAddress(null);
		setIsDialogOpen(false);
	};

	const SuggestedSecondaryAddressLine = () => {
		const reasonMessage = reason
			? reason === 'SECONDARY_MISSING'
				? t('suggested-address.address-line-two-optional')
				: t('suggested-address.address-line-two-incorrect')
			: '';
		const secondaryClass = reason
			? 'reason-orange'
			: editedFields.includes('address2')
				? 'edited-field'
				: '';

		const secondaryCopy = reasonMessage
			? reasonMessage
			: suggestion
				? suggestion.address2
				: billing.address2;

		return (
			<DialogContentText variant="subtitle1" className={secondaryClass}>
				{secondaryCopy}
			</DialogContentText>
		);
	};

	const EnteredAddress = () => (
		<>
			<DialogContentText variant="subtitle1" className="address-subtitle">
				{t('suggested-address.entered-address')}
			</DialogContentText>
			<div className="address">
				<DialogContentText variant="subtitle1">{billing.address1}</DialogContentText>
				<DialogContentText variant="subtitle1">{billing.address2}</DialogContentText>
				<DialogContentText variant="subtitle1">
					{billing.city} {billing.state} {billing.zip}
				</DialogContentText>
			</div>
			<DialogActions className="actions-container">
				<Button variant="contained" onClick={handleEditAddress}>
					{t('suggested-address.edit-address')}
				</Button>
				<Button
					variant="outlined"
					className="use-my-entry-button"
					onClick={handleUseMyEntry}
				>
					{t('suggested-address.use-my-entry')}
				</Button>
			</DialogActions>
		</>
	);

	return (
		<>
			{suggestion || reason ? (
				<Dialog css={suggestedAddressStyles} open={isDialogOpen}>
					<DialogTitle variant="h3" className="dialog-title">
						{t('suggested-address.verify-shipping-address')}
						<IconButton
							aria-label="close"
							onClick={handleEditAddress}
							className="close-icon-button"
						>
							<CloseIcon className="close-icon" />
						</IconButton>
					</DialogTitle>

					<DialogContent>
						<DialogContentText variant="subtitle1" className="ensure-delivery">
							{t('suggested-address.ensure-delivery')}
						</DialogContentText>
						<DialogContentText variant="subtitle1" className="address-subtitle">
							{t('suggested-address.suggested-address')}
						</DialogContentText>
						<div className="address">
							<DialogContentText
								variant="subtitle1"
								className={editedFields.includes('address1') ? 'edited-field' : ''}
							>
								{suggestion
									? suggestion.address1
									: reason !== 'SECONDARY_INCORRECT'
										? billing.address1
										: ''}
							</DialogContentText>
							<SuggestedSecondaryAddressLine />
							<DialogContentText
								variant="subtitle1"
								className={
									['city', 'state', 'zip'].some((field) =>
										editedFields.includes(field),
									)
										? 'edited-field'
										: ''
								}
							>
								{suggestion
									? `${suggestion.city} ${suggestion.state} ${suggestion.zip}`
									: reason !== 'SECONDARY_INCORRECT'
										? `${billing.city} ${billing.state} ${billing.zip}`
										: ''}
							</DialogContentText>
						</div>
						<DialogActions className="actions-container">
							<Button variant="contained" onClick={handleEditAddress}>
								{t('suggested-address.edit-address')}
							</Button>
							{reason !== 'SECONDARY_INCORRECT' && (
								<Button
									className="use-suggested-address-button"
									onClick={handleUseSuggestedAddress}
								>
									{t('suggested-address.use-suggested-address')}
								</Button>
							)}
						</DialogActions>
						<hr />
						<EnteredAddress />
					</DialogContent>
				</Dialog>
			) : !result ? (
				<Dialog css={suggestedAddressStyles} open={isDialogOpen}>
					<DialogTitle variant="h3" className="dialog-title">
						{t('suggested-address.invalid-address')}
						<IconButton
							aria-label="close"
							onClick={handleEditAddress}
							className="close-icon-button"
						>
							<CloseIcon className="close-icon" />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<DialogContentText variant="subtitle1" className="ensure-delivery">
							{t('suggested-address.update-shipping-address')}
						</DialogContentText>
						<EnteredAddress />
					</DialogContent>
				</Dialog>
			) : null}
		</>
	);
};

SuggestedAddressDialog.propTypes = {
	setIsDialogOpen: PropTypes.func.isRequired,
	isDialogOpen: PropTypes.bool.isRequired,
	verifiedAddress: PropTypes.object,
	setVerifiedAddress: PropTypes.func.isRequired,
	submitterId: PropTypes.string,
	handleSubmit: PropTypes.func.isRequired,
};

export default SuggestedAddressDialog;
