import { css } from '@emotion/react';
import DownloadIcon from '@mui/icons-material/Download';
import { Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { SubscriptionDetails } from '..';
import ProductType from '@/components/common/ProductType';
import useStore from '@/state/stores/globalStore';
import { cbNeutral, checkoutTheme } from '@/theme';
import { getProductImage } from '@/utils/helpers';
import { useCurrency } from '@/utils/hooks';

const productCSS = (nonEnglishLanguage) => css`
	margin-bottom: 2em;
	border-bottom: 1px solid ${cbNeutral[700]};

	.product-details {
		padding-bottom: 2em;
	}

	.title-type-price {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: center;
		align-content: center;
		margin-bottom: 1.5rem;

		${checkoutTheme.breakpoints.up('md')} {
			flex-direction: row;
			align-items: center;
			margin-bottom: 0;

			> * {
				flex-grow: 1;
			}
		}
	}

	.product-title {
		align-self: flex-start;
		flex-shrink: 0;
		color: ${cbNeutral[300]};
		width: 100%;
	}

	.product-type {
		margin-bottom: 0.5rem;
	}

	.price-quantity-container {
		${checkoutTheme.breakpoints.up('xs')} {
			margin-top: -2rem;
		}
	}

	.product-price {
		font-weight: bold;
		padding-left: 7.2rem;
		${checkoutTheme.breakpoints.up('md')} {
			text-align: right;
			margin-bottom: 0.5rem;
		}
	}

	.product-price-no-image {
		font-weight: bold;
		margin-bottom: 0.5rem;
		padding-top: 0.3rem;
		${checkoutTheme.breakpoints.up('md')} {
			text-align: right;
			margin-bottom: 0.5rem;
		}
	}

	.product-quantity {
		padding-left: 7.2rem;
		font-weight: 500;
		margin-bottom: 1rem;
		${checkoutTheme.breakpoints.up('md')} {
			text-align: right;
			margin-bottom: 1rem;
		}
	}

	.product-buttons {
		${checkoutTheme.breakpoints.up('sm')} {
			display: table;
		}

		.stacked-download-button {
			margin-bottom: 16px;
		}
	}

	&.has-image {
		.product-image {
			display: block;
			float: left;
			max-width: 100px;
			margin-bottom: 1.5rem;

			img {
				width: 100%;
			}
		}

		.title-type-price {
			min-height: 100px;
			margin-left: 116px;

			${checkoutTheme.breakpoints.up('sm')} {
				min-height: auto;
			}
		}

		.product-buttons {
			${checkoutTheme.breakpoints.up('sm')} {
				margin-left: 116px;
			}
		}
	}

	button {
		${nonEnglishLanguage ? `white-space: pre-wrap; height: 4rem;` : ''}
	}
`;

// Had to create a mini component because hooks must be called from the top level of a component.
// Tried calling useCurrency directly in the map function in the return but it threw errors.
const GetPrice = (props) => {
	return useCurrency(props.price);
};

GetPrice.propTypes = {
	price: PropTypes.string,
};

const OrderProducts = (props) => {
	//Use localProductData if you need to load the receipt page without making a purchase
	const { products } = props;
	const selectedLanguage = useStore((state) => state.selectedLanguage);
	const nonEnglishLanguage = selectedLanguage !== 'en';

	const { t } = useTranslation('order-received');

	function getLinks(product) {
		return product.type === 'STANDARD_PHYSICAL_RECURRING_DIGITAL' ||
			product.type === 'STANDARD_DIGITAL_STANDARD_PHYSICAL' ? (
			// Load two links if it's a hybrid product
			<Fragment>
				<Button
					size="small"
					className="stacked-download-button"
					fullWidth
					onClick={() => {
						window.open(product.link, '_blank');
					}}
					startIcon={<DownloadIcon />}
				>
					{t('button.download')}
				</Button>
				<Button
					size="small"
					fullWidth
					onClick={() => {
						window.open(product.link, '_blank');
					}}
					variant="contained"
					color="primary"
					className="product-button"
				>
					{t('button.more-info')}
				</Button>
			</Fragment>
		) : (
			// otherwise load a singe link
			<Button
				size="small"
				fullWidth
				onClick={() => {
					window.open(product.link, '_blank');
				}}
				startIcon={<DownloadIcon />}
			>
				{product.physical ? t('button.more-info') : t('button.download')}
			</Button>
		);
	}

	return (
		products?.length &&
		products.map((product, index) => {
			const classes = product.image ? 'has-image' : '';
			const frequency = product.subscriptionDetails.frequency;
			const frequencyType = product.subscriptionDetails.frequencyType;
			const subsequentPayments = product.subscriptionDetails.subsequentPayments;
			const title = product.title;
			const productType = product.type.includes('PHYSICAL') ? 'physical' : 'digital';
			const remainingPayments = product.subscriptionDetails.remainingPayments;
			const isUnlimited = product.subscriptionDetails.isUnlimited;
			const initialOffsetValue = product.subscriptionDetails.initialOffsetValue;
			const isTrialProduct = product.initialPayment === '0' || initialOffsetValue > 0;

			return (
				<div css={productCSS(nonEnglishLanguage)} className={classes} key={index}>
					<div className="product-details">
						{product.image && (
							<div className="product-image">
								<img
									src={getProductImage(product.image)}
									alt={t('alt-tags.product-image')}
									width="100"
								/>
							</div>
						)}
						<div className="title-type-price">
							<Typography variant="h6" className="product-title" gutterBottom>
								{product.title}
							</Typography>
							<Typography variant="body2" className="product-type">
								<ProductType type={product.type} />
							</Typography>
						</div>
						<div className="price-quantity-container">
							<Typography
								variant="body1"
								className={
									product.image ? 'product-price' : 'product-price-no-image'
								}
							>
								<GetPrice price={product.price} />
							</Typography>
							{product.quantity > 1 && (
								<Typography variant="body1" className="product-quantity">
									{`${t('order-details.quantity')}: ${product.quantity}`}
								</Typography>
							)}
						</div>
						<div className="product-buttons">{getLinks(product)}</div>
					</div>

					{product.recurring && (
						<SubscriptionDetails
							classNames="gutter-bottom"
							frequency={frequency}
							subsequentPayments={subsequentPayments}
							isTrialProduct={isTrialProduct}
							title={title}
							productType={productType}
							isUnlimited={isUnlimited}
							remainingPayments={remainingPayments}
							initialOffsetValue={initialOffsetValue}
							frequencyType={frequencyType}
						/>
					)}
				</div>
			);
		})
	);
};

export default OrderProducts;

OrderProducts.propTypes = {
	products: PropTypes.array,
};
