import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

const CustomPixels = (props) => {
	const { pixels, page } = props;
	const [sendPageView, setSendPageView] = useState(false);
	const pagesRendered = useRef({});

	useEffect(() => {
		if (page && !pagesRendered.current[page]) {
			setSendPageView(true);
			Object.assign(pagesRendered.current, { [page]: true });
		}
	}, [page]);

	if (sendPageView && pixels?.length) {
		pixels.forEach((pixel) => {
			const img = document.createElement('img');
			img.title = `custom pixel for ${pixel.vendorId} on ${page}`;
			img.src = pixel.service.srcUrl;
		});
		setSendPageView(false);
	}

	return null;
};

export default CustomPixels;

CustomPixels.propTypes = {
	pixels: PropTypes.array,
	page: PropTypes.string,
};
