import dayjs from 'dayjs';
import intlTelInput from 'intl-tel-input';
import { isEqual } from 'lodash';

import { errorHandler } from './errorHandler';
import { closeOverlayAndSetErrorData, logSplunkRumError } from './helpers';
import { isInputRequired } from './requiredInputs';

export const getRecurringPaymentStartDate = ({ initialOffsetValue }, now) => {
	return new Date(initialOffsetValue ? now.add(initialOffsetValue, 'days') : now);
};

export const getRecurringPaymentEndDate = (
	{ initialOffsetValue, frequency, frequencyType, remainingPayments },
	now,
) => {
	if (initialOffsetValue === 0) {
		return new Date(dayjs(now).add(frequency * remainingPayments + 1, frequencyType));
	}
	return new Date(
		now.add(frequency * remainingPayments, frequencyType).add(initialOffsetValue, 'days'),
	);
};

export const countryIsNotSupported = (countryCode, supportedAppleCountries) => {
	if (!countryCode) return true;
	return !supportedAppleCountries.find((supportedCountry) => {
		return supportedCountry === countryCode;
	});
};

export const getRequiredShippingFields = (shippingAddress) => {
	const postalCodeIsRequired = isInputRequired('zip', shippingAddress.country, 'isShippable');
	const regionIsRequired = isInputRequired('state', shippingAddress.country, 'isShippable');

	const requiredShippingFields = ['city'];
	if (postalCodeIsRequired) requiredShippingFields.push('postalCode');
	if (regionIsRequired) requiredShippingFields.push('region');
	return requiredShippingFields;
};

export const getMissingRequiredShippingFields = (requiredShippingFields, shippingAddress) => {
	return requiredShippingFields.filter((requiredField) => {
		return Boolean(shippingAddress[requiredField]) === false;
	});
};

export const getShippingAddressErrors = (missingRequiredFields) => {
	return missingRequiredFields.reduce((acc, field) => {
		acc[field] = `${field} is required`;
		return acc;
	}, {});
};

export const getProductsInCart = (cartStatus, allProducts) => {
	return cartStatus.reduce((acc, status) => {
		if (status.isActive) {
			const matchingProduct = allProducts.find((product) => {
				return product.sku === status.sku;
			});

			const updatedFrequencyProduct = {
				...matchingProduct,
				priceModel: {
					...matchingProduct.priceModel,
					...(matchingProduct.priceModel.frequency && {
						frequency: parseInt(matchingProduct.priceModel.frequency, 10),
					}),
				},
			};

			acc.push({ ...updatedFrequencyProduct, ...status });
		}
		return acc;
	}, []);
};

export const getRecurringProductsInCart = (productsInCart) => {
	return productsInCart.reduce((acc, product) => {
		if (product.isSubscription) {
			if (product.priceModel.frequencyType === 'week') {
				product.priceModel.frequency = product.priceModel.frequency * 7;
				product.priceModel.frequencyType = 'day';
			}
			acc.push(product);
		}
		return acc;
	}, []);
};

export const isTrialProduct = (product) => {
	return product.priceModel.initialPayment === '0' || product.priceModel.initialOffsetValue > 0;
};

export const getRecurringTrialsInCart = (recurringProducts) => {
	return recurringProducts.filter((product) => {
		return isTrialProduct(product);
	});
};

export const frequencyAndDurationData = (recurringProducts, now) => {
	const frequencyData = [];
	const durationData = [];
	let earliestRebillDateAfterTrial;
	recurringProducts.forEach((product, index) => {
		const { frequency, frequencyType, maxPayments, initialOffsetValue, isUnlimited } =
			product.priceModel;
		const recurringPaymentStartDate = getRecurringPaymentStartDate(product.priceModel, now);
		if (index === 0) {
			earliestRebillDateAfterTrial = recurringPaymentStartDate;
		} else {
			if (dayjs(recurringPaymentStartDate).isBefore(dayjs(earliestRebillDateAfterTrial))) {
				earliestRebillDateAfterTrial = recurringPaymentStartDate;
			}
		}
		const recurringPaymentEndDate = new Date(
			now.add(frequency * maxPayments, frequencyType).add(initialOffsetValue, 'days'),
		);
		frequencyData.push({
			frequency,
			frequencyType,
		});
		durationData.push({ endDate: recurringPaymentEndDate, isUnlimited });
	});
	return {
		frequencyData,
		durationData,
	};
};

export const getNextRebillDate = (priceModel, now) => {
	if (priceModel.initialOffsetValue === 0) {
		return new Date(now.add(priceModel.frequency, priceModel.frequencyType));
	}
	return new Date(now.add(priceModel.initialOffsetValue, 'days'));
};

export const getLongestDurationSubscriptionEndDate = (recurringProducts, now) => {
	const millisecondEndDateArray = recurringProducts
		.map((recurringProduct) => {
			return getRecurringPaymentEndDate(recurringProduct.priceModel, now).getTime();
		})
		.sort((a, b) => a - b);

	return new Date(millisecondEndDateArray[recurringProducts.length - 1]);
};

export const getSortedNextRebillDateArray = (recurringProducts, now) => {
	const rebillMillisecondArray = recurringProducts
		.map((recurringProduct) => {
			const { priceModel } = recurringProduct;
			return getNextRebillDate(priceModel, now).getTime();
		})
		.sort((a, b) => a - b);
	return rebillMillisecondArray.map((millisecond) => {
		return new Date(millisecond);
	});
};
export const getEarliestRebillDate = (recurringProducts, now) => {
	const sortedProducts = getSortedNextRebillDateArray(recurringProducts, now);
	return sortedProducts[0];
};
export const getEarliestRebillProduct = (recurringProducts, now) => {
	return recurringProducts.find((recurringProduct) => {
		const currentRebillDate = getNextRebillDate(recurringProduct.priceModel, now).getTime();
		const earliestRebillDate = getEarliestRebillDate(recurringProducts, now).getTime();
		return currentRebillDate === earliestRebillDate;
	});
};

export const getFurthestNextBillDate = (recurringProducts, now) => {
	const sortedProducts = getSortedNextRebillDateArray(recurringProducts, now);
	return sortedProducts[recurringProducts.length - 1];
};
export const getFurthestNextBillRecurringProduct = (recurringProducts, now) => {
	return recurringProducts.find((recurringProduct) => {
		const currentEndDate = getNextRebillDate(recurringProduct.priceModel, now).getTime();
		const furthestEndDate = getFurthestNextBillDate(recurringProducts, now).getTime();
		return currentEndDate === furthestEndDate;
	});
};

export const getRecurringProductReduction = (recurringProducts) => {
	return recurringProducts.reduce((acc, product, index) => {
		const { priceModel } = product;
		if (index === 0) {
			acc.initialPaymentSum = parseFloat(priceModel.initialPayment);
			acc.rebillSum = parseFloat(priceModel.subsequentPayments);
			acc.shortestFrequency = priceModel.frequency;
			acc.numberOfUnlimitedDurations = priceModel.isUnlimited ? 1 : 0;
		} else {
			if (priceModel.frequency < acc.shortestFrequency) {
				acc.shortestFrequency = priceModel.frequency;
			}
			if (priceModel.isUnlimited) {
				acc.numberOfUnlimitedDurations++;
			}

			acc.initialPaymentSum += parseFloat(priceModel.initialPayment);
			acc.rebillSum += parseFloat(priceModel.subsequentPayments);
		}
		return acc;
	}, {});
};

export const getMerchantIdentifier = (REACT_APP_DEPLOYMENT_TARGET) => {
	if (REACT_APP_DEPLOYMENT_TARGET === 'test' || REACT_APP_DEPLOYMENT_TARGET === 'dev') {
		return 'merchant.net.clickbank-tst.orders';
	}

	if (REACT_APP_DEPLOYMENT_TARGET === 'prod') {
		return 'merchant.net.clickbank.orders';
	}
};

export const createSingleRecurringPaymentRequest = (recurringProduct, t, now) => {
	const { initialPayment, frequency, frequencyType, subsequentPayments, isUnlimited, unitPrice } =
		recurringProduct.priceModel;
	const singleRecurringBillingAgreement = t('apple-misc.single-recurring-billing-agreement');
	const paymentDescription =
		'A description of the recurring payment to display to the user in the payment sheet.';
	const hasTrial = isTrialProduct(recurringProduct);
	const initialAndRecurringPriceAreEqual = initialPayment === subsequentPayments;
	const lastRebillDate = getRecurringPaymentEndDate(recurringProduct.priceModel, now);
	const firstRebillDate = getNextRebillDate(recurringProduct.priceModel, now);
	let request;
	const additionalLineItems = [];
	const regularBilling = {
		amount: subsequentPayments,
		paymentTiming: 'recurring',
		recurringPaymentIntervalUnit: frequencyType,
		recurringPaymentIntervalCount: frequency,
	};
	if (hasTrial || !initialAndRecurringPriceAreEqual) {
		// HAS A TRIAL (test with 15) OR INITIAL AND RECURRING PRICE ARE DIFFERENT (test with mike-recurring-digital)
		Object.assign(regularBilling, {
			label: t('apple-misc.future-title', { title: recurringProduct.title }),
			recurringPaymentStartDate: firstRebillDate,
			...(!isUnlimited && {
				recurringPaymentEndDate: hasTrial
					? getRecurringPaymentEndDate(recurringProduct.priceModel, now)
					: lastRebillDate,
			}),
		});
		const trialBilling = {
			label: t('apple-misc.initial-title', { title: recurringProduct.title }),
			amount: initialPayment,
			paymentTiming: 'recurring',
			recurringPaymentEndDate: firstRebillDate,
			// in order to get the form to read 'x days' in the right column no matter the value of the amount property is
			// interval count just needs to be at least as many days from now until the recurringPaymentStartDate
			recurringPaymentIntervalUnit: hasTrial ? 'day' : frequencyType,
			recurringPaymentIntervalCount: hasTrial
				? dayjs(getRecurringPaymentStartDate(recurringProduct.priceModel, now)).diff(
						now,
						'days',
					) + 1
				: frequency,
		};

		request = {
			trialBilling,
			regularBilling,
		};
		additionalLineItems.push({ ...trialBilling, amount: unitPrice });
	} else {
		// IS NOT A TRIAL, INITIAL AND RECURRING PRICE ARE THE SAME (test with 16)
		Object.assign(regularBilling, {
			label: recurringProduct.title,
			...(!isUnlimited && { recurringPaymentEndDate: lastRebillDate }),
		});

		request = { regularBilling };
	}
	additionalLineItems.push(regularBilling);
	return {
		recurringPaymentRequest: {
			paymentDescription,
			billingAgreement: singleRecurringBillingAgreement,
			managementURL: 'https://clkbank.com',
			...request,
		},
		additionalLineItems,
	};
};

export const createMultipleRecurringPaymentRequest = (recurringProducts, t, now) => {
	const multipleRecurringBillingAgreement = t('apple-misc.multiple-recurring-billing-agreement');
	const paymentDescription =
		'A description of the recurring payment to display to the user in the payment sheet.';
	const recurringTrialsInCart = getRecurringTrialsInCart(recurringProducts);
	const cartHasAtLeastOneTrial = recurringTrialsInCart.length > 0;
	const { frequencyData, durationData } = frequencyAndDurationData(recurringProducts, now);

	const frequenciesMatch = frequencyData.every((frequencyObj, index) => {
		if (index === 0) return true;
		return isEqual(frequencyObj, frequencyData[index - 1]);
	});
	const durationsMatch = durationData.every((durationObject, index) => {
		if (index === 0) return true;
		return isEqual(durationObject, durationData[index - 1]);
	});
	const frequenciesAndDurationsMatch = frequenciesMatch && durationsMatch;
	const allInitialAndRebillMatch = recurringProducts.every((product) => {
		return product.priceModel.initialPayment === product.priceModel.subsequentPayments;
	});
	const recurringProductReduction = getRecurringProductReduction(recurringProducts);

	let request;
	const additionalLineItems = [];

	if (cartHasAtLeastOneTrial || !allInitialAndRebillMatch) {
		const earliestRebillProduct = getEarliestRebillProduct(recurringProducts, now);
		const furthestNextBillDateProduct = getFurthestNextBillRecurringProduct(
			recurringProducts,
			now,
		);
		const trialBilling = {
			label: t('apple-misc.initial-multiple-subscriptions'),
			amount: recurringProductReduction.initialPaymentSum,
			paymentTiming: 'recurring',
			recurringPaymentEndDate: getFurthestNextBillDate(recurringProducts, now),
			recurringPaymentIntervalUnit: furthestNextBillDateProduct.priceModel.frequencyType,
			recurringPaymentIntervalCount:
				furthestNextBillDateProduct.priceModel.frequencyType === 'day'
					? dayjs(getFurthestNextBillDate(recurringProducts, now)).diff(now, 'days') + 1
					: furthestNextBillDateProduct.priceModel.frequency,
		};

		const regularBilling = {
			label: t('apple-misc.future-multiple-subscriptions'),
			amount: recurringProductReduction.rebillSum.toString(),
			paymentTiming: 'recurring',
			recurringPaymentStartDate: cartHasAtLeastOneTrial
				? getRecurringPaymentStartDate(earliestRebillProduct.priceModel, now)
				: getFurthestNextBillDate(recurringProducts, now),
			...(frequenciesAndDurationsMatch
				? {
						recurringPaymentIntervalUnit: recurringProducts[0].priceModel.frequencyType,
						recurringPaymentIntervalCount: recurringProducts[0].priceModel.frequency,
					}
				: {
						recurringPaymentIntervalCount: recurringProductReduction.shortestFrequency,
					}),
			...(recurringProductReduction.numberOfUnlimitedDurations === 0 && {
				recurringPaymentEndDate: getLongestDurationSubscriptionEndDate(
					recurringProducts,
					now,
				),
			}),
		};

		request = {
			regularBilling,
			trialBilling,
		};

		recurringProducts.forEach((product) => {
			const hasTrial = isTrialProduct(product);
			const { priceModel } = product;
			const initialAndRecurringPriceAreEqual =
				priceModel.initialPayment === priceModel.subsequentPayments;
			if (hasTrial) {
				const trialEndDate = getNextRebillDate(priceModel, now);
				additionalLineItems.push({
					label: t('apple-misc.initial-title', { title: product.title }),
					amount: priceModel.unitPrice,
					paymentTiming: 'recurring',
					recurringPaymentEndDate: trialEndDate,
					recurringPaymentIntervalUnit: 'day',
					// in order to get the form to read 'x days' in the right column no matter the value of the amount property is
					// interval count just needs to be at least as many days from now until the recurringPaymentStartDate
					recurringPaymentIntervalCount:
						priceModel.frequencyType === 'day'
							? dayjs(getNextRebillDate(priceModel, now)).diff(now, 'days') + 1
							: priceModel.frequency,
				});
				additionalLineItems.push({
					label: t('apple-misc.future-title', { title: product.title }),
					amount: priceModel.subsequentPayments,
					paymentTiming: 'recurring',
					...(priceModel.initialOffsetValue > 0 && {
						recurringPaymentStartDate: getRecurringPaymentStartDate(priceModel, now),
					}),
					recurringPaymentIntervalCount: priceModel.frequency,
					recurringPaymentIntervalUnit: priceModel.frequencyType,
					...(!priceModel.isUnlimited && {
						recurringPaymentEndDate: getRecurringPaymentEndDate(
							product.priceModel,
							now,
						),
					}),
				});
				return;
			}

			if (!hasTrial && !initialAndRecurringPriceAreEqual) {
				const nextRebillDate = getNextRebillDate(product.priceModel, now);
				additionalLineItems.push({
					label: t('apple-misc.initial-title', { title: product.title }),
					amount: priceModel.unitPrice,
					paymentTiming: 'recurring',
					recurringPaymentEndDate: nextRebillDate,
					recurringPaymentIntervalUnit: priceModel.frequencyType,
					recurringPaymentIntervalCount: priceModel.frequency,
				});
				additionalLineItems.push({
					label: t('apple-misc.future-title', { title: product.title }),
					amount: priceModel.subsequentPayments,
					paymentTiming: 'recurring',
					recurringPaymentStartDate: nextRebillDate,
					recurringPaymentIntervalCount: priceModel.frequency,
					recurringPaymentIntervalUnit: priceModel.frequencyType,
					...(!priceModel.isUnlimited && {
						recurringPaymentEndDate: getRecurringPaymentEndDate(
							product.priceModel,
							now,
						),
					}),
				});
				return;
			}

			additionalLineItems.push({
				label: product.title,
				amount: priceModel.subsequentPayments,
				paymentTiming: 'recurring',
				...(priceModel.initialOffsetValue > 0 && {
					recurringPaymentStartDate: getRecurringPaymentStartDate(priceModel, now),
				}),
				recurringPaymentIntervalCount: priceModel.frequency,
				recurringPaymentIntervalUnit: priceModel.frequencyType,
				...(!priceModel.isUnlimited && {
					recurringPaymentEndDate: getRecurringPaymentEndDate(product.priceModel, now),
				}),
			});
		});
	}

	if (!cartHasAtLeastOneTrial && allInitialAndRebillMatch) {
		// If no subscriptions have a trial, and all the subscriptions have the same initial and rebill price…
		const regularBilling = {
			label: t('apple-misc.multiple-subscriptions'),
			amount: recurringProductReduction.rebillSum.toString(),
			paymentTiming: 'recurring',
			...(frequenciesAndDurationsMatch
				? {
						recurringPaymentIntervalUnit: recurringProducts[0].priceModel.frequencyType,
						recurringPaymentIntervalCount: recurringProducts[0].priceModel.frequency,
					}
				: {
						recurringPaymentIntervalCount: recurringProductReduction.shortestFrequency,
					}),
			...(durationsMatch &&
				!recurringProducts[0].priceModel.isUnlimited && {
					recurringPaymentEndDate: getLongestDurationSubscriptionEndDate(
						recurringProducts,
						now,
					),
				}),
		};

		request = { regularBilling };

		recurringProducts.forEach((product) => {
			const { priceModel } = product;
			additionalLineItems.push({
				label: product.title,
				amount: priceModel.subsequentPayments,
				paymentTiming: 'recurring',
				...(priceModel.initialOffsetValue > 0 && {
					recurringPaymentStartDate: getRecurringPaymentStartDate(priceModel, now),
				}),
				recurringPaymentIntervalCount: priceModel.frequency,
				recurringPaymentIntervalUnit: priceModel.frequencyType,
				...(!priceModel.isUnlimited && {
					recurringPaymentEndDate: getRecurringPaymentEndDate(product.priceModel, now),
				}),
			});
		});
	}

	return {
		recurringPaymentRequest: {
			paymentDescription,
			billingAgreement: multipleRecurringBillingAgreement,
			managementURL: 'https://clkbank.com',
			...request,
		},
		additionalLineItems,
	};
};

const validateShipping = (response) => {
	return new Promise((resolve) => {
		if (response.shippingAddress) {
			if (Boolean(response.shippingAddress.addressLine[0].trim()) === false) {
				resolve({
					error: 'Shipping Address incomplete',
					shippingAddressErrors: { addressLine: 'Update Address Line 1' },
				});
			}
		}
		resolve(null);
	});
};

function fixField(requestOrResponse, event, validator) {
	return new Promise((resolve) => {
		// Browser keeps calling this until promise resolves.
		requestOrResponse.addEventListener(event, async function listener(ev) {
			const promiseToValidate = validator(requestOrResponse);
			ev.updateWith(promiseToValidate);
			const errors = await promiseToValidate;
			if (!errors) {
				event.removeEventListener(event, listener);
				resolve();
			}
		});
		resolve();
	});
}

const recursiveValidate = async (request, response) => {
	const promisesToFixThings = [];
	const errors = await validateShipping(response);
	if (!errors) {
		return;
	}
	if (errors.shippingAddress) {
		const promise = fixField(request, 'shippingaddresschange', validateShipping);
		promisesToFixThings.push(promise);
	}
	await Promise.all([response.retry(errors), ...promisesToFixThings]);
	await recursiveValidate(request, response);
};

export const processApplePay = async (
	setSubmitOverlayVisible,
	setErrorData,
	getFormData,
	urlVars,
	orderTotals,
	kountSessionId,
	submitOrderLineItems,
	validatedCoupon,
	couponCode,
	selectedCurrency,
	selectedLanguage,
	showPhone,
	applePayData,
	handleCreditResponse,
	initializeApplePaySession,
	processApplePayResponse,
	cartStatus,
	allProducts,
	calculateCartOnApplePayChange,
	cartIncludesShippableProduct,
	vendorBrand,
	t,
) => {
	if (!window.PaymentRequest) {
		// Not sure that this will ever occur, but nice safety net
		console.warn('Apple Pay not supported');
		closeOverlayAndSetErrorData(
			{ message: 'apple-not-supported', messageType: 'cart' },
			setSubmitOverlayVisible,
			setErrorData,
		);
		return;
	}
	try {
		const { email, phone } = getFormData();

		// Setting gdpr consent to false as we are currently (and maybe always) unable
		// to add gdpr/ccpa to apple pay form because the Payment Request API, including the Apple Pay integration, does not have built-in support.
		// It looks as though we will need to collect this info using the user's IP address or ask for this information
		// after the user authorizes the apple pay transaction (we currently require gdpr in the submit digital order query)

		const applePaySubmitVariables = urlVars &&
			orderTotals && {
				gdpr: { vendorConsent: false, clickBankConsent: false },
				kountSid: kountSessionId,
				lineItems: submitOrderLineItems,
				vendorId: urlVars.vvvv,
				couponCode: validatedCoupon || couponCode,
				currencyId: selectedCurrency,
				locale: selectedLanguage,
				urlParams: window.location.search,
			};

		if (!applePaySubmitVariables) {
			// setting message as connection-fail just to reuse the copy
			setErrorData({ message: 'connection-fail', messageType: 'cart' });
			return;
		}

		// Apple Pay accepts the supported Network 'amex', not 'americanExpress'
		const acceptedCCs = applePayData.acceptedCCs.map((type) =>
			type === 'americanExpress' ? 'amex' : type,
		);

		// Define MerchantIdentifier
		const merchantIdentifier = getMerchantIdentifier(process.env.REACT_APP_DEPLOYMENT_TARGET);

		const formHasEmailOrPhoneFilledOut = [email, phone].some((fieldValue) =>
			Boolean(fieldValue),
		);

		const shippingContact = formHasEmailOrPhoneFilledOut
			? { emailAddress: email, phoneNumber: phone }
			: null;

		const requiredShippingContactFields = () => {
			const requiredFields = ['email'];
			if (showPhone) {
				requiredFields.push('phone');
			}
			if (cartIncludesShippableProduct) {
				requiredFields.push('postalAddress');
			}
			return requiredFields;
		};

		// Define PaymentMethodData
		const paymentMethodData = [
			{
				supportedMethods: 'https://apple.com/apple-pay',
				// https://developer.apple.com/documentation/apple_pay_on_the_web/applepayrequest
				data: {
					version: 12,
					merchantIdentifier,
					merchantCapabilities: ['supports3DS', 'supportsDebit', 'supportsCredit'],
					supportedNetworks: acceptedCCs,
					countryCode: 'US',
					requiredBillingContactFields: ['postalAddress', 'name'],
					requiredShippingContactFields: requiredShippingContactFields(),
					...(formHasEmailOrPhoneFilledOut && { shippingContact }),
					supportedCountries: applePayData.supportedCountries,
				},
			},
		];

		const productsInCart = getProductsInCart(cartStatus, allProducts);
		const recurringProductsInCart = getRecurringProductsInCart(productsInCart);
		const hasRecurringProductsInCart = recurringProductsInCart.length > 0;

		const now = dayjs();

		const modifierData = () => {
			const nonRecurringProductsInCart = productsInCart.filter((product) => {
				return !product.isSubscription;
			});
			const nonRecurringLineItems = nonRecurringProductsInCart.map((product) => {
				return {
					label: product.title,
					amount: product.priceModel.unitPrice,
				};
			});

			if (hasRecurringProductsInCart) {
				const { recurringPaymentRequest, additionalLineItems } =
					recurringProductsInCart.length === 1
						? createSingleRecurringPaymentRequest(recurringProductsInCart[0], t, now)
						: createMultipleRecurringPaymentRequest(recurringProductsInCart, t, now);

				return {
					recurringPaymentRequest,
					additionalLineItems: [...additionalLineItems, ...nonRecurringLineItems],
				};
			} else {
				return {
					additionalLineItems: nonRecurringLineItems,
				};
			}
		};

		// Define PaymentDetails -> should come from calculate cart
		const paymentDetails = {
			total: {
				label: vendorBrand ? `${vendorBrand} (via ClickBank)` : 'ClickBank',
				amount: {
					value: orderTotals[0]?.subtotal,
					currency: selectedCurrency,
				},
			},
			modifiers: [
				{
					supportedMethods: 'https://apple.com/apple-pay',
					data: modifierData(),
				},
			],
		};

		// Define PaymentOptions
		const paymentOptions = {
			requestPayerName: false,
			requestBillingAddress: true,
			requestPayerEmail: true,
			requestPayerPhone: showPhone,
			requestShipping: cartIncludesShippableProduct,
			shippingType: 'shipping',
			postalAddress: true,
		};

		// Create PaymentRequest
		const request = new PaymentRequest(paymentMethodData, paymentDetails, paymentOptions);
		request.onmerchantvalidation = async (event) => {
			const sessionResponse = await initializeApplePaySession(event.validationURL);
			event.complete(sessionResponse);
		};

		request.onpaymentmethodchange = (event) => {
			event.updateWith(calculateCartOnApplePayChange(event));
		};

		request.onshippingaddresschange = (event) => {
			event.updateWith(calculateCartOnApplePayChange(event));
		};

		const response = await request.show();
		await recursiveValidate(request, response);
		const { data, error } = await processApplePayResponse(response, applePaySubmitVariables);

		if (error) {
			closeOverlayAndSetErrorData(
				errorHandler({ error }, 'Apple'),
				setSubmitOverlayVisible,
				setErrorData,
			);
			response.complete('fail');
		}

		if (data?.submitDigitalPayment?.receipt) {
			response.complete('success');
			// Because Apple Pay uses user CC, we can treat the receipt page the same as using credit card as the payment method
			handleCreditResponse(data.submitDigitalPayment);
		}
	} catch (e) {
		if (e.name === 'AbortError') {
			console.warn('Apple Pay aborted', e);
			setSubmitOverlayVisible(false);
			return;
		}
		console.error(e);
		closeOverlayAndSetErrorData(
			errorHandler({ error: e }, 'Apple'),
			setSubmitOverlayVisible,
			setErrorData,
		);
	}
};

export const formatApplePayerPhone = async (phone, details, hiddenInputRef) => {
	const { default: utilScript } = await import('intl-tel-input/build/js/utils');
	const hiddenInput = intlTelInput(hiddenInputRef.current, {
		utilScript,
		nationalMode: false,
	});

	const rawValue = phone.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
	hiddenInput.setNumber(rawValue);
	hiddenInput.setCountry(details.billingContact.countryCode);
	const isValid = hiddenInput.isValidNumber();

	if (isValid) {
		return hiddenInput.getNumber();
	} else {
		logSplunkRumError(
			`Phone number considered invalid. Error code: ${hiddenInput.getValidationError()}`,
		);
		return rawValue;
	}
};
