import { get as _get, set as _set, unset as _unset, cloneDeep } from 'lodash';
import { create } from 'zustand';

const initialLocationData = {
	fullName: '',
	address1: '',
	address2: '',
	zip: '',
	city: '',
	state: '',
	countryCode: '',
};

export const initialFormData = {
	email: '',
	phone: '',
	shipping: { ...initialLocationData },
	billing: { ...initialLocationData },
	paymentMethod: '',
	cardHolderName: '',
	cardNumber: '',
	expirationDate: '',
	securityCode: '',
	phoneIsValid: true,
};

const inputsThatAllowAccents = [
	'email',
	'cardHolderName',
	'fullName',
	'address1',
	'address2',
	'city',
	'state',
	'billing.fullName',
	'billing.address1',
	'billing.address2',
	'billing.city',
	'billing.state',
	'shipping.fullName',
	'shipping.address1',
	'shipping.address2',
	'shipping.city',
	'shipping.state',
];

/**
 * purpose - before validation, remove accents from characters that are common in non-english languages
 * @param {string} key - input name
 * @param {string} value - input value
 * @returns {string} value without accents if it is included in inputsThatAllowAccents array
 */
const removeAccents = (key, value) => {
	if (inputsThatAllowAccents.includes(key) && typeof value === 'string') {
		return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
	}
	return value;
};

const useFormStore = create((set, get) => ({
	formData: initialFormData,
	setFormData: (data) => set({ formData: data }),
	// Sometimes needed to beat closures
	getFormData: () => get().formData,
	getFormValue: (key) => _get(get().formData, key),
	setFormValue: (key, value) => {
		set((state) => ({ formData: _set(cloneDeep(state.formData), key, value) }));
	},
	/**
	 * @description Merges object into formData value, with option to leave existing values as-is or replace them.
	 * @param {object} incomingData - Data to be merged
	 * @param {boolean} [replaceExisting=false] - Indicates whether to replace keys whose values are truthy
	 * @returns void
	 */
	mergeFormData: (incomingData, replaceExisting = false) => {
		set((state) => {
			const updatedFormData = { ...state.formData };
			const incomingKeys = Object.keys(incomingData);
			if (replaceExisting) {
				for (let i = 0; i < incomingKeys.length; i++) {
					const key = incomingKeys[i];
					const existingValue = updatedFormData[key];
					if (
						existingValue &&
						typeof existingValue === 'object' &&
						!Array.isArray(existingValue)
					) {
						// Retain values from objects one level down (e.g. billing and shipping)
						const nestedIncoming = incomingData[key];
						updatedFormData[key] = {
							...existingValue,
							...nestedIncoming,
						};
					} else {
						updatedFormData[key] = incomingData[key];
					}
				}
			} else {
				for (let i = 0; i < incomingKeys.length; i++) {
					const key = incomingKeys[i];
					const existingValue = updatedFormData[key];
					if (!existingValue) {
						// Only replace if it doesn't exist
						updatedFormData[key] = incomingData[key];
					} else if (typeof existingValue === 'object' && !Array.isArray(existingValue)) {
						// Retain values from objects one level down (e.g. billing and shipping)
						const nestedCopy = { ...existingValue };
						const nestedIncoming = incomingData[key];
						const nestedIncomingKeys = Object.keys(nestedIncoming);
						for (let j = 0; j < nestedIncomingKeys.length; j++) {
							const nestedKey = nestedIncomingKeys[j];
							const nestedExistingValue = nestedCopy[nestedKey];
							if (!nestedExistingValue) {
								nestedCopy[nestedKey] = nestedIncoming[nestedKey];
							}
						}
						updatedFormData[key] = nestedCopy;
					}
					// If it exists and is not an object, do nothing
				}
			}
			return { formData: updatedFormData };
		});
	},
	formErrors: {},
	clearFieldError: (key) => {
		set((state) => {
			const newErrors = cloneDeep(state.formErrors);
			_unset(newErrors, key);
			return { formErrors: newErrors };
		});
	},
	clearAllFieldErrors: () => {
		set({ formErrors: {} });
	},
	formIsValid: async (updateDOM = false) => {
		try {
			const { validationSchema, formData } = get();
			if (!validationSchema) {
				return false;
			}
			// iterate through formData and remove accents from values before validating in order to allow characters with accents to be used on the form.
			// The back end will sanitize these values before saving them to the database.
			const formDataWithoutAccents = Object.keys(formData).reduce((acc, key) => {
				const value = formData[key];

				if (typeof value === 'object') {
					// this block is for nested objects like billing and shipping
					acc[key] = Object.keys(value).reduce((acc, key) => {
						acc[key] = removeAccents(key, value[key]);
						return acc;
					}, {});
					return acc;
				}
				acc[key] = removeAccents(key, value);
				return acc;
			}, {});

			await validationSchema.validate(formDataWithoutAccents, { abortEarly: false });
			updateDOM && set({ formErrors: {} });
			return true;
		} catch (err) {
			if (!updateDOM) {
				return false;
			}
			if (err.name === 'ValidationError') {
				const errors = {};
				if (err.inner.length > 0) {
					for (let i = 0; i < err.inner.length; i++) {
						const error = err.inner[i];
						const key = error.params.path;
						if (!_get(errors, key)) {
							_set(errors, key, error.message);
						}
					}
				} else {
					const key = err.params.path;
					_set(errors, key, err.message);
				}
				set({ formErrors: errors });
				return false;
			} else {
				throw err;
			}
		}
	},
	/**
	 * @description Checks if value currently associated with form key, or incoming value, is valid, and optionally updates errors.
	 * @param {string} key - formData key to validate.
	 * @param {*} [newValue=null] - Incoming value to check for validity. If not provided, value that currently exists is used.
	 * @param {boolean} [updateDOM=true] - Indicates whether to update formErrors (which will error message on the DOM) or not.
	 * @returns {Promise} Promise that resolves to a boolean representing whether or not field is valid
	 */
	formFieldIsValid: async (key, newValue = null, updateDOM = true) => {
		try {
			const { validationSchema, formData } = get();
			if (!validationSchema) {
				return false;
			}
			let objectToTest = formData;
			if (newValue) {
				const valueWithoutAccents = removeAccents(key, newValue);
				objectToTest = _set(cloneDeep(formData), key, valueWithoutAccents);
			}
			await validationSchema.validateAt(key, objectToTest);
			if (updateDOM) {
				set((state) => {
					const newErrors = cloneDeep(state.formErrors);
					_unset(newErrors, key);
					return { formErrors: newErrors };
				});
			}
			return true;
		} catch (err) {
			if (err.name === 'ValidationError') {
				if (updateDOM) {
					set((state) => {
						const errorsCopy = cloneDeep(state.formErrors);
						_set(errorsCopy, key, err.message);
						return {
							formErrors: errorsCopy,
						};
					});
				}
				return false;
			} else {
				throw err;
			}
		}
	},
	emailSuggestion: null,
	setEmailSuggestion: (incomingSuggestion) => {
		set({ emailSuggestion: incomingSuggestion });
	},
	validationSchema: null,
	setValidationSchema: (incoming) => set({ validationSchema: incoming }),
	mergeInitialFormState: (incomingState) => set(incomingState),
	allowValidationCalc: false,
	validationSchemaCalculating: false,
	setValidationSchemaCalculating: (bool) => set({ validationSchemaCalculating: bool }),
	resetForm: () =>
		set((state) => ({ formData: state.initialFormValuesForUser || initialFormData })),
	initialFormValuesForUser: null,
	setInitialFormValuesForUser: (values) => set({ initialFormValuesForUser: values }),
	handleIncomingRecurringAgreements: (cartStatus) => {
		set((state) => {
			const isSubTermsPropInData = 'subscriptionTerms' in state.formData;
			const cartHasSubscription = cartStatus.some(
				(product) => product.isActive && product.isSubscription,
			);

			if (!cartHasSubscription) {
				isSubTermsPropInData && delete state.formData.subscriptionTerms;
				return {
					formData: state.formData,
					formErrors: state.formErrors,
				};
			}

			if (cartHasSubscription) {
				return {
					formData: isSubTermsPropInData
						? state.formData
						: { ...state.formData, subscriptionTerms: false },
					formErrors: state.formErrors,
				};
			}
		});
	},
	toggleRecurringChargeAgreement: (value) => {
		set((state) => {
			return {
				formData: {
					...state.formData,
					subscriptionTerms: value,
				},
				formErrors: state.formErrors,
			};
		});
	},
	getFormState: () => get(),

	// Refs
	formFieldOnChangeHandlers: [],
	formFieldOnBlurHandlers: [],
}));

export default useFormStore;
