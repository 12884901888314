import { CssBaseline } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';

import { PixelManager, RouteManager } from './components';
import ErrorFallback from './ErrorFallback';
import { GraphQLLoader, GraphQLProvider } from './graphql';
import { FindLocationContextProvider } from './state/FindLocationContext';
import { MediaQueryContextProvider } from './state/MediaQueryContext';
import { SecurityLogosContextProvider } from './state/SecurityLogosContext';
import StateInit from './state/StateInit';
import { checkoutTheme } from './theme';
import DevEnvLogin from './utils/DevEnvLogin';

import './index.css';

import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

import { BumpSelectionContextProvider } from './state/BumpSelectionContext';
import { GeoCurrenciesContextProvider } from './state/GeoCurrenciesContext';

const cache = createCache({
	key: 'css',
	prepend: false,
});

const OrderFormApp = ({ dataFetchPromise, templatePromise, featureFlagsPromise }) => {
	return (
		<GraphQLProvider dataFetchPromise={dataFetchPromise}>
			<StyledEngineProvider injectFirst>
				<CacheProvider value={cache}>
					<ThemeProvider theme={checkoutTheme}>
						<ErrorBoundary
							FallbackComponent={ErrorFallback}
							onError={(error, info) => {
								console.error(JSON.stringify(error), JSON.stringify(info));
							}}
						>
							<MediaQueryContextProvider>
								<SecurityLogosContextProvider>
									<BumpSelectionContextProvider>
										<GeoCurrenciesContextProvider>
											<FindLocationContextProvider>
												<StateInit
													dataFetchPromise={dataFetchPromise}
													templatePromise={templatePromise}
													featureFlagsPromise={featureFlagsPromise}
												/>
												{process.env.NODE_ENV === 'development' && (
													<DevEnvLogin />
												)}
												<CssBaseline />
												<GraphQLLoader />
												<RouteManager />
												<PixelManager />
											</FindLocationContextProvider>
										</GeoCurrenciesContextProvider>
									</BumpSelectionContextProvider>
								</SecurityLogosContextProvider>
							</MediaQueryContextProvider>
						</ErrorBoundary>
					</ThemeProvider>
				</CacheProvider>
			</StyledEngineProvider>
		</GraphQLProvider>
	);
};

OrderFormApp.propTypes = {
	history: PropTypes.object,
	dataFetchPromise: PropTypes.shape({
		then: PropTypes.func.isRequired,
		catch: PropTypes.func.isRequired,
	}),
	templatePromise: PropTypes.shape({
		then: PropTypes.func.isRequired,
		catch: PropTypes.func.isRequired,
	}),
	featureFlagsPromise: PropTypes.shape({
		then: PropTypes.func.isRequired,
		catch: PropTypes.func.isRequired,
	}),
};

export default OrderFormApp;
