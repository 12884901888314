import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { useStore } from '@/state/stores';

const GoogleAdWordsPixels = (props) => {
	const { pixels, page } = props;
	const orderTotals = useStore((state) => state.orderTotals);
	const total = orderTotals?.total || 0;

	useEffect(() => {
		if (page && pixels?.length) {
			pixels.forEach((pixel, key) => {
				const adWordsScriptNode = document.getElementById('adWordsScript' + key);
				adWordsScriptNode && adWordsScriptNode.remove();

				const script1 = document.createElement('script');
				script1.id = 'adWordsScript' + key;
				script1.async = true;
				let innerHTML = `/* <![CDATA[ */\n`;
				// break script using contact to avoid Google Tag assistant to find a false negative error
				innerHTML = innerHTML
					.concat('var google')
					.concat('_conversion_id =')
					.concat(`${pixel.service.googleAdWordsId};\n`);
				innerHTML = innerHTML.concat(`var google_conversion_language = 'en_US';\n`);
				innerHTML = innerHTML.concat(`var google_conversion_format = '3';\n`);
				innerHTML = innerHTML.concat(`var google_conversion_color = 'ffffff';\n`);

				innerHTML = innerHTML.concat('var google').concat('_conversion_label = ');
				if (page === 'ORDER_FORM_VIEW') {
					innerHTML = innerHTML.concat(`'${pixel.service.pageViewLabel}';\n`);
				} else if (page === 'RECEIPT_PAGE_VIEW') {
					innerHTML = innerHTML.concat(`'${pixel.service.purchaseLabel}';\n`);
				}
				innerHTML = innerHTML.concat(`var google_conversion_value = ${total};\n`);
				innerHTML = innerHTML.concat(`/* ]]> */\n`);
				script1.innerHTML += innerHTML;
				document.body.appendChild(script1);

				const adWordsNoScriptNode = document.getElementById('adWordsNoScript');
				adWordsNoScriptNode && adWordsNoScriptNode.remove();

				const noscriptPixel = document.createElement('noscript');
				noscriptPixel.id = 'adWordsNoScript';
				noscriptPixel.innerHTML += `<img src='${pixel.service.srcUrl}' alt="Google AdWords Pixel" />`;
				document.body.appendChild(noscriptPixel);
			});

			if (!window.googleAdWordsLoaded) {
				const script2 = document.createElement('script');
				script2.async = true;
				script2.src = 'https://www.googleadservices.com/pagead/conversion_async.js';
				script2.onload = window.googleAdWordsLoaded = true;
				document.body.appendChild(script2);
			}
		}
	}, [pixels, page, total]);

	return null;
};

export default GoogleAdWordsPixels;

GoogleAdWordsPixels.propTypes = {
	pixels: PropTypes.array,
	page: PropTypes.string,
};
