import { css } from '@emotion/react';
import LockIcon from '@mui/icons-material/Lock';
import { Box, Grid, Typography } from '@mui/material';
import { memo, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { LanguageModal } from '..';
import { blackLogo, blackLogoSmall, blueLogo, whiteLogo, whiteLogoSmall } from '@/assets';
import { getZoneStyles } from '@/components/mainContent/contentStyle';
import { MediaQueryContext } from '@/state/MediaQueryContext';
import { useStore } from '@/state/stores';
import {
	alternateLanguageCurrencyColor,
	cbBrand,
	cbNeutral,
	cbPrimary,
	checkoutTheme,
	defaultCBHeaderBackgroundColor,
	layoutMaxWidth,
	orderFormHeaderHeight,
} from '@/theme';

const basePath = process.env.REACT_APP_BASE_PATH ? process.env.REACT_APP_BASE_PATH : '/';

const headerWrapperStyles = (backgroundColor, languageCurrencyColor) => css`
	position: relative;
	z-index: ${checkoutTheme.zIndex.appBar};
	background-color: ${backgroundColor};
	padding: 10px 12px;
	line-height: 0;
	display: flex;
	justify-content: center;
	height: ${orderFormHeaderHeight};

	.header-grid {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		max-width: ${layoutMaxWidth};
		width: 100%;
	}

	.cb-logo {
		margin-right: auto;
		display: block;
		max-width: 100%;
		width: 135px;
	}

	.secure-checkout-header-container {
		display: flex;
		align-items: center;
		gap: 8px;
		font-weight: 400;
		font-size: 1rem;
	}

	.language-selector {
		margin: 0;
		display: flex;
		align-items: center;
		height: 100%;
		padding-left: 1em;
		cursor: pointer;

		${checkoutTheme.breakpoints.up('md')} {
			position: initial;
			right: initial;
			align-self: center;
		}

		.link {
			color: ${languageCurrencyColor};
			display: flex;

			.text {
				transition: max-width 0.5s;
				overflow: hidden;
				white-space: nowrap;
				font-weight: bold;
				margin-left: 0.2rem;
				margin-top: 0.05rem;
				max-width: 0;

				${checkoutTheme.breakpoints.up('xs')} {
					max-width: 400px;
				}
			}
		}
	}
`;

const languageCurrencyColors = {
	[cbNeutral[100]]: alternateLanguageCurrencyColor,
	[defaultCBHeaderBackgroundColor]: cbBrand['blue'],
	[cbPrimary[180]]: defaultCBHeaderBackgroundColor,
};

const Header = () => {
	const location = useLocation();
	const selectedLanguage = useStore((state) => state.selectedLanguage);
	const template = useStore((state) => state.template);
	const { isExtraSmallScreen, isTwoColumnLayout } = useContext(MediaQueryContext);

	const headerStyles = getZoneStyles(template, 'clickBankHeader');
	const cbHeaderBackgroundColor = headerStyles?.backgroundColor || defaultCBHeaderBackgroundColor;
	const languageCurrencyColor = languageCurrencyColors[cbHeaderBackgroundColor];
	const secureCheckoutInHeader = isTwoColumnLayout;

	let logo;
	let secureCheckoutLabelColor = cbNeutral[300];

	if (cbHeaderBackgroundColor === defaultCBHeaderBackgroundColor) {
		logo = isExtraSmallScreen ? whiteLogoSmall : whiteLogo;
	} else if (cbHeaderBackgroundColor === cbNeutral[100]) {
		logo = isExtraSmallScreen ? blackLogoSmall : blueLogo;
		secureCheckoutLabelColor = 'white';
	} else {
		logo = isExtraSmallScreen ? blackLogoSmall : blackLogo;
		secureCheckoutLabelColor = 'white';
	}

	useEffect(() => {
		document.documentElement.lang = selectedLanguage;
	}, [selectedLanguage]);

	return (
		<Grid
			container
			item
			component="header"
			css={headerWrapperStyles(cbHeaderBackgroundColor, languageCurrencyColor)}
			id="CB-logo-language"
		>
			<Grid item xs={12} className="header-grid">
				<img src={logo} alt="ClickBank Logo" />
				{secureCheckoutInHeader && (
					<div className="secure-checkout-header-container">
						<LockIcon style={{ color: secureCheckoutLabelColor }} />
						<Typography
							variant="body2"
							className="secure-checkout-label"
							component="span"
							style={{ color: secureCheckoutLabelColor }}
						>
							Secure Checkout
						</Typography>
					</div>
				)}

				{[basePath, basePath.concat('index.html')].some(
					(path) => path === location.pathname,
				) && (
					<Box className="language-selector" component="menu">
						<LanguageModal headerBackgroundColor={cbHeaderBackgroundColor} />
					</Box>
				)}
			</Grid>
		</Grid>
	);
};

export default memo(Header);
