import { Grid } from '@mui/material';
import { memo, useContext } from 'react';
import { useShallow } from 'zustand/react/shallow';

import ConnectedStandardLayout from './ConnectedStandardLayout';
import MobileLayout from './MobileLayout';
import SecureCheckout from './SecureCheckout';
import StandardLayout from './StandardLayout';
import ZoneWrapper from './ZoneWrapper';
import ProductDisplay from '@/components/cart/ProductDisplay';
import { MediaQueryContext } from '@/state/MediaQueryContext';
import { useStore } from '@/state/stores';
import { isTemplateJoined } from '@/utils/helpers';

const LayoutFactory = () => {
	const {
		allProducts,
		template,
		cartInitialized,
		isLargeProductImage,
		cartStatus,
		setCartStatus,
	} = useStore(
		useShallow((state) => ({
			allProducts: state.allProducts,
			template: state.template,
			cartInitialized: state.cartInitialized,
			isLargeProductImage: state.isLargeProductImage,
			cartStatus: state.cartStatus,
			setCartStatus: state.setCartStatus,
		})),
	);

	const { isSmallScreen, isTwoColumnLayout } = useContext(MediaQueryContext);
	const zonesAreConnected = isTemplateJoined(template);

	const secureCheckoutInHeader = isTwoColumnLayout;

	const toggleCartItem = (productSKU) => {
		setCartStatus((prev) => {
			const newStatus = prev.map((product) => {
				if (product.sku === productSKU) {
					return {
						...product,
						isActive: !product.isActive,
					};
				}
				return product;
			});
			return newStatus;
		});
	};

	const skuMap = cartStatus.reduce((a, b) => {
		return {
			...a,
			[b.sku]: b,
		};
	}, {});

	const hasProducts = !!allProducts?.length;

	const shouldDisplayBumps =
		cartInitialized && hasProducts ? !!allProducts.find((product) => product.isBump) : false;

	const bumpList = hasProducts
		? allProducts
				.filter((product) => {
					return product.isBump;
				})
				.map((product) => {
					const { sku } = product;
					const productStatus = skuMap[sku];
					const productProps = {
						className: 'product',
						toggleCartItem: () => toggleCartItem(sku),
						inBumpList: true,
					};
					return (
						<div className="bump-item CB-bumps" key={sku} id={`CB-bumps-item-${sku}`}>
							<ProductDisplay
								{...productProps}
								{...product}
								isActive={productStatus.isActive}
								isLargeProductImage={isLargeProductImage}
							/>
						</div>
					);
				})
		: [];

	const layoutToRender = () => {
		if (isSmallScreen) {
			return (
				<Grid container className="small-screen-container">
					<MobileLayout shouldDisplayBumps={shouldDisplayBumps} bumpList={bumpList} />
				</Grid>
			);
		} else if (zonesAreConnected) {
			return (
				<ConnectedStandardLayout
					shouldDisplayBumps={shouldDisplayBumps}
					bumpList={bumpList}
				/>
			);
		} else {
			return <StandardLayout shouldDisplayBumps={shouldDisplayBumps} bumpList={bumpList} />;
		}
	};

	return (
		<Grid container className="layout-factory-inner-wrapper">
			<Grid
				className="header-zone-background CB-background"
				container
				id="CB-background-top-module-zone"
			>
				<Grid container item className="column zone-container">
					<ZoneWrapper zoneId="header" />
				</Grid>
			</Grid>
			{!secureCheckoutInHeader && <SecureCheckout />}
			{layoutToRender()}
			<Grid
				className="footer-zone-background CB-background"
				container
				id="CB-background-bottom-module-zone"
			>
				<Grid container item className="column zone-container">
					<ZoneWrapper zoneId="footer" />
				</Grid>
			</Grid>
		</Grid>
	);
};

export default memo(LayoutFactory);
