/**
 * Form validation rules and error messages
 * Documentation:  https://github.com/jquense/yup
 */

import * as yup from 'yup';

import { inputData } from './regexAndRequiredInputsByCountry.js';
import { isInputRequired } from './requiredInputs';

// Zip code regexes (backend is using this too, must convert to JavaScript regex to use):
// https://gist.github.com/jamesbar2/1c677c22df8f21e869cca7e439fc3f5b#file-postal-codes-json
const regexs = {
	phone: /^([+]{0,1})?[()-\s\d]+$/,
	cvv: /^[0-9]{3,4}$/,
	expirationDate: /^(0[1-9]|1[0-2])\/?\d{2}$/,
	couponCode: /^[a-zA-Z\d_-]*$/,
	fullNameFirstLast: /^.+[ ].+$/,
	commonValidChars: /^$|^[a-zA-Z0-9 .'#,/,-]+$/,
	creditCardCheck: /(?:\d[ -]?){15}/,
	city: /^$|^[a-zA-Z0-9 .'#,)(-]+$/,
};

// Only used in calculateCart.js and fetchHelpers.js to verify address coming from url params
export const addressRegex = /^[a-zA-Z0-9 .'#,-]{1,150}$/;

// NOTE: Error messages sent to TextField and SelectField need to be wrapped in an object
// {
//   key: String // json path of the error message
//   options: Object // object of key/value pairs for the error message
// }
// Example:
// {
//   key: 'field.coupon-code.error.already-applied',
//   options: { value: formControl?.getValues()?.couponCode }
// }

const commonError = (errorType, vars = {}) => {
	return {
		key: 'field.common.error.' + errorType,
		options: vars,
	};
};

const buildQuantitySchema = (productList = [], cartStatus = []) => {
	const productsWithQuantity = productList.filter(
		(product) => product.maxQuantity > 1 && !product.isBump,
	);

	const schemaObject = {};
	// Build the quantity schema,  only include the items that are active in cartStatus
	productsWithQuantity.forEach((product) => {
		cartStatus.forEach((item) => {
			if (product.sku === item.sku && item.isActive === true) {
				schemaObject[`quantity${product.sku}`] = yup
					.number()
					.required()
					.min(1)
					.max(product.maxQuantity, 'field.quantity.error.max');
			}
		});
	});

	return schemaObject;
};

// The logic is the same for both message and boolean so we combined them into one method
// TokenEx's validator returns 'format' or 'required' errors
// If we get a success on validation, we pass 'ok'
// Submitted empty = 'required', never submitted = '' => required message
// Submitted invalid = 'format', not 'ok' = unknown data => invalid message
const processTokenEx = (data) => {
	const theData = data?.value !== undefined ? data.value : data;
	const theDataHasUndefinedValue =
		typeof theData === 'object' && theData !== null && theData.value === undefined;

	const result =
		theData === '' || theData === 'required' || theDataHasUndefinedValue
			? { valid: false, message: commonError('required') }
			: theData === 'format' || theData !== 'ok'
				? { valid: false, message: commonError('invalid') }
				: { valid: true, message: '' };
	return result;
};

export const getValidationSchema = (
	productList,
	paymentMethod,
	billingCountry,
	shippingCountry,
	isShippable,
	validatedCoupon,
	quantityEditable,
	cartStatus,
	showPhone,
	showCoupon,
	couponCodeValue,
	billingZip,
	shippingZip,
	isShippingSameAsBilling,
	isPayPalDirectEnabled,
) => {
	const creditCardSchema = {
		cardHolderName: yup
			.string()
			.required(commonError('required'))
			.max(50, commonError('max-length', { maxLength: 50 }))
			.matches(regexs.fullNameFirstLast, () => commonError('first-last'))
			.matches(regexs.commonValidChars, () => commonError('invalid'))
			.test('cc-in-wrong-input', commonError('invalid'), (value) => {
				return !regexs.creditCardCheck.test(value);
			}),
		cardToken: yup.string().test(
			'card-valid',
			(data) => {
				// get the error message
				return processTokenEx(data).message;
			},
			(value) => {
				// get the boolean
				return processTokenEx(value).valid;
			},
		),
		expirationDate: yup
			.string()
			.required(commonError('required'))
			.matches(regexs.expirationDate, () => commonError('invalid'))
			.test('past-date', commonError('invalid'), function (value) {
				// store month and year from the input
				const [inputMonth, inputYear] = value.split('/');
				// store the current year and month
				const today = new Date();
				const currentYear = today.getFullYear() % 100;
				const currentMonth = today.getMonth() + 1;

				return (
					inputYear > currentYear ||
					(Number(inputYear) === currentYear && inputMonth >= currentMonth)
				);
			}),
		securityCode: yup
			.string()
			.required(commonError('required'))
			.matches(regexs.cvv, () => commonError('invalid')),
	};

	const phoneSchema = {
		phone: yup
			.string()
			.required(commonError('required'))
			.test('phone-number', commonError('invalid'), function () {
				const data = this.parent;
				const { phoneIsValid } = data;
				return phoneIsValid;
			}),
	};

	const couponCodeSchema = {
		couponCode: yup
			.string()
			.test('min-length', commonError('min-length', { minLength: 5 }), (value) => {
				// if empty, ignore. throw flag if 1-4 char long
				return !(value.length > 0 && value.length < 5);
			})
			.max(20, commonError('max-length', { maxLength: 20 }))
			.matches(regexs.couponCode, () => commonError('invalid'))
			.test(
				'matches-previous-coupon',
				{
					key: 'field.coupon-code.error.already-applied',
					options: { value: couponCodeValue },
				},
				(value) => {
					return value.length === 0 ? true : value !== '' && value !== validatedCoupon;
				},
			),
	};

	const quantity = quantityEditable ? buildQuantitySchema(productList, cartStatus) : null;
	const creditCard = paymentMethod === 'credit' ? creditCardSchema : null;
	const isBillingZipRequired = isInputRequired(
		'zip',
		billingCountry,
		isShippable ? 'isShippable' : null,
	);
	const isShippingZipRequired = isInputRequired(
		'zip',
		shippingCountry,
		isShippable ? 'isShippable' : null,
	);
	const isBillingStateRequired = isInputRequired('state', billingCountry);
	const isShippingStateRequired = isInputRequired('state', shippingCountry);
	const phone = showPhone ? phoneSchema : null;
	const couponCode = showCoupon ? couponCodeSchema : null;

	const alwaysValidSchema = yup.mixed(() => true);

	const getAddressSchema = (accessor) => {
		if (!['billing', 'shipping'].includes(accessor)) {
			const err = 'Attempt to get invalid address type';
			console.error(err);
			throw new Error(err);
		}
		const isBillingAddress = accessor === 'billing';
		const isPartialAddressForm = isBillingAddress && !isShippable;
		const stateRequired = isBillingAddress ? isBillingStateRequired : isShippingStateRequired;
		const isZipRequired = isBillingAddress ? isBillingZipRequired : isShippingZipRequired;
		const countryCode = isBillingAddress ? billingCountry : shippingCountry;
		const zip = isBillingAddress ? billingZip : shippingZip;
		const forceNameForPayPal = isPayPalDirectEnabled && paymentMethod === 'payPal';
		return yup.object().shape({
			fullName:
				isPartialAddressForm && !forceNameForPayPal
					? alwaysValidSchema
					: yup
							.string()
							.required(commonError('required'))
							.matches(regexs.fullNameFirstLast, () => commonError('first-last'))
							.matches(regexs.commonValidChars, () => commonError('invalid'))
							.max(255, commonError('max-length', { maxLength: 255 }))
							.test('cc-in-wrong-input', commonError('invalid'), (value) => {
								return !regexs.creditCardCheck.test(value);
							}),
			address1: isPartialAddressForm
				? alwaysValidSchema
				: yup
						.string()
						.required(commonError('required'))
						.matches(regexs.commonValidChars, () => commonError('invalid'))
						.max(150, commonError('max-length', { maxLength: 150 }))
						.test('cc-in-wrong-input', commonError('invalid'), (value) => {
							return !regexs.creditCardCheck.test(value);
						}),
			address2: isPartialAddressForm
				? alwaysValidSchema
				: yup
						.string()
						.matches(regexs.commonValidChars, () => commonError('invalid'))
						.max(150, commonError('max-length', { maxLength: 150 }))
						.test('cc-in-wrong-input', commonError('invalid'), (value) => {
							return !regexs.creditCardCheck.test(value);
						}),
			city:
				isPartialAddressForm && countryCode !== 'US'
					? alwaysValidSchema
					: yup
							.string()
							.required(commonError('required'))
							.matches(regexs.city, () => commonError('invalid'))
							.max(50, commonError('max-length', { maxLength: 50 }))
							.test('cc-in-wrong-input', commonError('invalid'), (value) => {
								return !regexs.creditCardCheck.test(value);
							}),
			state:
				isPartialAddressForm && !['US', 'CA'].includes(countryCode)
					? alwaysValidSchema
					: stateRequired
						? yup
								.string()
								.required(commonError('required'))
								.matches(regexs.commonValidChars, () => commonError('invalid'))
								.max(50, commonError('max-length', { maxLength: 50 }))
								.test('cc-in-wrong-input', commonError('invalid'), (value) => {
									return !regexs.creditCardCheck.test(value);
								})
						: yup
								.string()
								.matches(regexs.commonValidChars, () => commonError('invalid'))
								.max(50, commonError('max-length', { maxLength: 50 }))
								.test('cc-in-wrong-input', commonError('invalid'), (value) => {
									return !regexs.creditCardCheck.test(value);
								}),
			zip: yup.string().when('zip-code-by-country', {
				// if the zip is required, then check for both length and a valid zip
				is: () => isZipRequired,
				then: yup
					.string()
					.required(commonError('required'))
					.test('zip-code-by-country', commonError('invalid'), (value) => {
						const currentCountry = inputData[countryCode];
						const regex = new RegExp(currentCountry.zipRegex);
						return value && regex.test(value);
					}),
				// if the zip isn't required, only validate the zip if there is length
				otherwise: yup.string().when('zip-code-by-country', {
					is: () => zip?.length,
					then: yup
						.string()
						.test('zip-code-by-country', commonError('invalid'), (value) => {
							const currentCountry = inputData[countryCode];
							const regex = new RegExp(currentCountry.zipRegex);
							return regex.test(value);
						}),
				}),
			}),
			countryCode: yup.string().required(commonError('required')),
		});
	};

	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.email(commonError('invalid'))
			.required(commonError('required'))
			.max(255, commonError('max-length', { maxLength: 255 })),
		gdprOptIn: yup.boolean(),
		billing: getAddressSchema('billing'),
		shipping:
			isShippable && !isShippingSameAsBilling
				? getAddressSchema('shipping')
				: alwaysValidSchema,
		subscriptionTerms: yup.boolean().oneOf([true], { message: 'field.terms.error.required' }),
		...creditCard,
		...quantity,
		...phone,
		...couponCode,
	});

	return validationSchema;
};
