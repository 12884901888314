import { useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import { createContext } from 'react';

import { useStore } from './stores';
import { checkoutTheme } from '@/theme';
import { Layouts } from '@/utils/enums';

export const MediaQueryContext = createContext({});

export const MediaQueryContextProvider = ({ children }) => {
	const isSmallScreen = useMediaQuery(checkoutTheme.breakpoints.down('sm'), { noSsr: true });
	const isExtraSmallScreen = useMediaQuery(checkoutTheme.breakpoints.down('xsm'), {
		noSsr: true,
	});

	const template = useStore((state) => state.template);

	const isTwoColumnLayout = isSmallScreen
		? false
		: [
				Layouts.STANDARD_INPUT_LEFT_BUTTON_LEFT,
				Layouts.STANDARD_INPUT_RIGHT_BUTTON_RIGHT,
			].includes(template.layout);

	return (
		<MediaQueryContext.Provider
			value={{ isSmallScreen, isExtraSmallScreen, isTwoColumnLayout }}
		>
			{children}
		</MediaQueryContext.Provider>
	);
};

MediaQueryContextProvider.propTypes = {
	children: PropTypes.node,
};
