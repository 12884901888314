import { fetchUrl, fetchWithRetry } from './fetchHelpers';
import { SITE_SETTINGS } from '@/graphql/queries';

const getSiteSettingsQuery =
	({ urlVars, fetchHeaders, initialCoupon }) =>
	async () => {
		try {
			const body = await fetchWithRetry('SITE_SETTINGS', fetchUrl, {
				method: 'POST',
				headers: fetchHeaders,
				body: JSON.stringify({
					query: SITE_SETTINGS,
					variables: {
						vendorId: urlVars.vvvv,
					},
				}),
			});
			if (body.error) {
				throw body;
			}
			const { data } = body;
			if (!data || !data.siteSettings) {
				throw { error: 'Missing site settings' };
			}
			const siteSettings = data.siteSettings;
			const urlCoupon = Boolean(siteSettings.couponDisplayed === 'URL' && initialCoupon);
			const showCoupon = siteSettings.couponDisplayed === 'ALWAYS' || urlCoupon;
			const state = {
				siteSettings,
				showCoupon,
			};

			state.isSiteActivated = siteSettings.activated;

			return {
				graphql: { SITE_SETTINGS: data },
				state,
			};
		} catch (body) {
			return Promise.reject({ errors: { SITE_SETTINGS: body } });
		}
	};

export default getSiteSettingsQuery;
