import { css } from '@emotion/react';
import { TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { getInputStyles, getZoneStyles } from '@/components/mainContent/contentStyle';
import { MediaQueryContext } from '@/state/MediaQueryContext';
import { useFormStore, useStore } from '@/state/stores';
import { cbNegative, palette, primaryFont } from '@/theme';
import { stringIsEqualCaseInsensitive } from '@/utils/helpers';

const quantityCSS = (template, isTwoColumnLayout) => {
	const cartStyles = getZoneStyles(template, 'cart');
	const { body } = cartStyles || {};
	const inputStyles = getInputStyles(body);
	const { bodyFontColor: twoColumnBodyFontColor, bodyFontFamily: twoColumnBodyFontFamily } =
		template?.style?.attributes?.summary || {};

	return css`
		display: flex;
		align-items: baseline;
		margin-top: 4px;

		.label {
			margin-right: 0.5rem;
		}

		.input-container {
			display: flex;
			align-items: center;
			flex-direction: row;
		}

		.error {
			color: ${cbNegative[280]};
			font-size: 0.875rem;
			font-weight: 400;
			margin-top: 3px;
		}

		${inputStyles}

		.MuiOutlinedInput-root.MuiInputBase-root {
			height: 2rem;
			width: ${40 / 16}rem;

			.MuiInputBase-input {
				padding-left: 4px;
				padding-right: 0;
				font-weight: 700;
				${isTwoColumnLayout
					? `
					color: ${twoColumnBodyFontColor || palette.text.secondary};
					font-family: ${twoColumnBodyFontFamily || primaryFont};
				`
					: ''}
			}
		}
	`;
};
let debounce;

const QuantityInput = (props) => {
	const { quantity, maxQuantity, quantityEditable, sku } = props;
	const name = `quantity${sku}`;

	const { value, formErrors, formFieldIsValid, setFormValue } = useFormStore(
		useShallow((state) => ({
			value: state.formData[name],
			formErrors: state.formErrors,
			formFieldIsValid: state.formFieldIsValid,
			setFormValue: state.setFormValue,
		})),
	);
	const { cartStatus, setCartStatus, setTriggerCalculateCart, template } = useStore(
		useShallow((state) => ({
			cartStatus: state.cartStatus,
			setCartStatus: state.setCartStatus,
			setTriggerCalculateCart: state.setTriggerCalculateCart,
			template: state.template,
		})),
	);
	const { t } = useTranslation('checkout');
	const { isSmallScreen, isTwoColumnLayout } = useContext(MediaQueryContext);

	const cartStatusIndex = cartStatus.findIndex((item) =>
		stringIsEqualCaseInsensitive(sku, item.sku),
	);

	const error = formErrors[name];

	useEffect(() => {
		if (value === undefined) {
			const initialQuantity = cartStatus[cartStatusIndex].quantity;
			setFormValue(name, initialQuantity);
		}
	}, [setFormValue, cartStatus, cartStatusIndex, name, value]);

	const cleanInput = (value) => {
		const numberRegex = new RegExp(/^[0-9]{1,2}$/);
		// only allows two characters
		const maxLengthTwo = value.length > 2 ? value.substring(0, 2) : value;

		// make sure the value is a number so calculateCart doesn't return an error.
		const newValue = numberRegex.test(maxLengthTwo) ? maxLengthTwo : '';
		return newValue;
	};

	const validate = async (newValue) => {
		clearTimeout(debounce);
		setFormValue(name, newValue);
		formFieldIsValid(name, newValue);
	};

	const runUpdates = async (newValue) => {
		await validate(newValue);

		// update cart and run calculateCart if the input has valid data
		if (newValue > 0 && newValue <= maxQuantity) {
			setCartStatus((prevState) => {
				return prevState.map((item, index) => {
					if (cartStatusIndex === index) {
						return {
							...item,
							quantity: Number(newValue),
						};
					}
					return item;
				});
			});
			setTriggerCalculateCart(true);
		}
	};

	const debounceUpdates = (newValue) => {
		// check validity when typing pauses
		clearTimeout(debounce);

		debounce = setTimeout(() => {
			runUpdates(newValue);
		}, 500);
	};

	const handleChange = (ev) => {
		setFormValue(name, ev.target.value);
	};

	const handleBlur = (ev) => {
		formFieldIsValid(name, ev.target.value);
	};

	if (value === undefined) {
		return null;
	}

	return (
		<div css={quantityCSS(template, isTwoColumnLayout)}>
			{quantityEditable ? (
				<div>
					<div className={'input-container'}>
						<Typography variant="body3" className="label" htmlFor={name}>
							{t('field.quantity.label')}:
						</Typography>
						<TextField
							className="CB-field"
							id={name}
							value={cleanInput(value)}
							type="number"
							name={`quantity${sku}`}
							variant="outlined"
							onChange={(e) => {
								debounceUpdates(cleanInput(e.target.value));
								handleChange(e);
							}}
							onBlur={(e) => {
								runUpdates(cleanInput(e.target.value));
								handleBlur(e);
							}}
							// inputProps are html properties
							inputProps={{
								min: 1,
								max: maxQuantity,
								inputMode: 'numeric',
								tabIndex: isSmallScreen ? 0 : 1,
							}}
							error={!!error}
						/>
					</div>
					{error && (
						<Typography className="error">
							{error.type === 'max'
								? t('field.quantity.error.max', { maxQuantity: maxQuantity })
								: t('field.common.error.invalid', {
										inputName: t('field.quantity.label'),
									})}
						</Typography>
					)}
				</div>
			) : (
				<Typography variant="body1">
					{t('field.quantity.label')}: {quantity}
				</Typography>
			)}
		</div>
	);
};

export default QuantityInput;

QuantityInput.propTypes = {
	quantity: PropTypes.number,
	maxQuantity: PropTypes.number,
	quantityEditable: PropTypes.bool,
	sku: PropTypes.string,
};
