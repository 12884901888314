import { Grid, Typography } from '@mui/material';
import { useMutation } from 'graphql-hooks';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import {
	Card,
	CheckoutForm,
	Footer,
	Header,
	Kount,
	ReceiptPageSkeleton,
	UpdateOverlay,
	UpsellPaymentDeclineModal,
} from '@/components';
import PurchaseCompleteModal from '@/components/common/PurchaseCompleteModal';
import ExitOffer from '@/components/exitOffer/ExitOffer';
import LayoutFactory from '@/components/layoutFactory/LayoutFactory';
import MainContent from '@/components/mainContent/MainContent';
import { SEND_ORDER_IMPRESSION } from '@/graphql/mutations';
import { CountdownTimerContextProvider } from '@/state/CountdownTimerContext';
import { useFormStore, useStore } from '@/state/stores';
import { errorHandler } from '@/utils/errorHandler';
import { isPreview } from '@/utils/helpers';
import { decodeURLVars } from '@/utils/urlVars';

let impressionHasBeenMade = false;

const Checkout = () => {
	const {
		affiliate,
		submitOverlayVisible,
		isUpsellLoading,
		isSiteActivated,
		isInvalidProductInCart,
		urlVars,
		hasTemplateQueryFailed,
		hasTemplateQueryData,
		orderFormHasUnloaded,
		setOrderFormHasUnloaded,
		setErrorData,
		exitOffer,
		displayPurchaseComplete,
		cartInitialized,
		impressionVars,
		validatedCoupon,
	} = useStore(
		useShallow((state) => ({
			affiliate: state.affiliate,
			submitOverlayVisible: state.submitOverlayVisible,
			siteSettings: state.siteSettings,
			isUpsellLoading: state.isUpsellLoading,
			isSiteActivated: state.isSiteActivated,
			isInvalidProductInCart: state.isInvalidProductInCart,
			urlVars: state.urlVars,
			hasTemplateQueryFailed: state.hasTemplateQueryFailed,
			hasTemplateQueryData: state.hasTemplateQueryData,
			orderFormHasUnloaded: state.orderFormHasUnloaded,
			setOrderFormHasUnloaded: state.setOrderFormHasUnloaded,
			setErrorData: state.setErrorData,
			exitOffer: state.exitOffer,
			displayPurchaseComplete: state.displayPurchaseComplete,
			cartInitialized: state.cartInitialized,
			impressionVars: state.impressionVars,
			validatedCoupon: state.validatedCoupon,
		})),
	);

	const resetForm = useFormStore((state) => state.resetForm);

	// Send order impression for BI
	const [submitOrderImpression] = useMutation(SEND_ORDER_IMPRESSION);

	useEffect(() => {
		if (isPreview || impressionHasBeenMade || !cartInitialized || !impressionVars) {
			return;
		}
		impressionHasBeenMade = true;
		const entries = performance.getEntriesByType('navigation');
		let formLoadTime = null;
		entries.forEach((entry) => {
			if (entry.secureConnectionStart && entry.secureConnectionStart > 0) {
				const loadEventTime = performance.now() - entry.secureConnectionStart;
				if (loadEventTime > 0) {
					formLoadTime = Math.round(loadEventTime).toString();
				}
			}
		});
		submitOrderImpression({
			variables: {
				...impressionVars,
				formLoadTime,
				couponCode: validatedCoupon || '',
			},
		}).then();
	}, [cartInitialized, validatedCoupon, impressionVars, submitOrderImpression]);

	useEffect(() => {
		if (orderFormHasUnloaded) {
			// User has been redirected away from Checkout form, indicating they have been sent to /order-received
			// (PayPal unloads SOF entirely, so this condition should never occur)
			window.location.reload();
		}
		return () => {
			setOrderFormHasUnloaded(true);
			resetForm();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resetForm, setOrderFormHasUnloaded]);

	useEffect(() => {
		const hasVars = !!window.location.search;
		if (!hasVars) {
			// We handle no-url-vars in StateInit
			return;
		}
		// Decode URL vars fresh within this effect, to be sure we don't erroneously
		// display missing url vars error due to stale urlVars in store
		const decodedUrlVars = decodeURLVars();
		if (!decodedUrlVars.vvvv || !(decodedUrlVars.cbitems || decodedUrlVars.item)) {
			setErrorData(errorHandler('missing-url-vars'));
		}
	}, [setErrorData]);

	const { t } = useTranslation(['checkout', 'common']);
	const affiliateName = affiliate?.name || '';
	const trackingCode = affiliate?.trackingCode ? affiliate.trackingCode : '';
	const hasTemplateInUrl = !!urlVars?.template;

	if (isUpsellLoading) {
		return <ReceiptPageSkeleton />;
	}
	if (hasTemplateInUrl && !hasTemplateQueryData && !hasTemplateQueryFailed) {
		return <UpdateOverlay visible={true} />;
	}

	const isTestPurchase = isInvalidProductInCart || !isSiteActivated;

	return (
		<CountdownTimerContextProvider>
			<Kount />
			<Header />
			<MainContent>
				<CheckoutForm>
					<Grid className="layout-factory-wrapper" container>
						{isTestPurchase && (
							<Grid container className="column not-approved">
								<Card className="not-approved-card">
									<Typography variant="body1">
										{/* Only show site error if it's an approved product */}
										{!isInvalidProductInCart
											? t('cart.not-approved-site')
											: t('cart.not-approved')}
									</Typography>
								</Card>
							</Grid>
						)}
						<LayoutFactory />
					</Grid>
				</CheckoutForm>
			</MainContent>
			<Footer affiliateName={affiliateName} trackingCode={trackingCode} />
			{displayPurchaseComplete ? <PurchaseCompleteModal /> : null}
			<UpdateOverlay visible={submitOverlayVisible} />
			{'cbpd' in urlVars ? <UpsellPaymentDeclineModal /> : null}
			{exitOffer ? <ExitOffer /> : null}
		</CountdownTimerContextProvider>
	);
};

export default Checkout;
