import { css } from '@emotion/react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { lazy, Suspense, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { Card, CBTextField } from '..';
import InputPlaceholder from '@/components/common/InputPlaceholder';
import ZoneWrapper from '@/components/layoutFactory/ZoneWrapper';
import {
	getHeaderStyles,
	getInputStyles,
	getZoneStyles,
} from '@/components/mainContent/contentStyle';
import { MediaQueryContext } from '@/state/MediaQueryContext';
import { useStore } from '@/state/stores';
import {
	checkoutTheme,
	defaultPanelBackgroundColor,
	defaultPanelBorderColor,
	defaultPanelBoxShadow,
} from '@/theme';
import { PaymentMethods } from '@/utils/enums';
import { isTemplateJoined } from '@/utils/helpers';

const CBPhoneInput = lazy(() => import('../common/CBPhoneInput'));

const addressCardStyles = (template, zonesAreConnected, isTwoColumnLayout) => {
	// 'cart', 'payment', and 'shipping' IDs pull from the same styles for the time being
	// Using 'cart' as a placeholder - may change to include 'customerInfo' ID in the future
	const shippingStyles = getZoneStyles(template, 'cart');

	const { body, header, borderRadius } = shippingStyles || {};

	const inputStyles = getInputStyles(body);
	const headerStyles = getHeaderStyles(header);
	const isJoined = isTwoColumnLayout || zonesAreConnected;

	return css`
		background: transparent;
		border-radius: ${borderRadius && !isJoined ? borderRadius : '0px'};
		box-shadow: none;
		overflow: visible;
		${headerStyles}
		${inputStyles}
	`;
};

const addressStyles = (template, zonesAreConnected, isTwoColumnLayout) => {
	// 'cart', 'payment', and 'shipping' IDs pull from the same styles for the time being
	// Using 'cart' as a placeholder - may change to include 'customerInfo' ID in the future
	const shippingStyles = getZoneStyles(template, 'cart');

	const {
		backgroundColor,
		boxShadow: customBoxShadow,
		borderSize,
		borderColor,
		borderRadius,
	} = shippingStyles || {};
	const boxShadow = zonesAreConnected ? 'none' : customBoxShadow;
	const isJoined = isTwoColumnLayout || zonesAreConnected;

	const isBorderTransparent = borderColor?.length > 7 && borderColor?.slice(-2) === '00';
	const hideBorder = !borderColor || isBorderTransparent || isJoined;
	const borderWidth = borderSize || '1px';
	const borderColorValue = borderColor || defaultPanelBorderColor;
	const border = hideBorder ? 'none' : `${borderWidth} solid ${borderColorValue}`;

	/**
	 *
	 * @returns adjusted radius value to account for border width so that the
	 * background color is not visible between the border and the content
	 */
	const adjustRadius = () => {
		const radius = parseInt(borderRadius.replace('px', ''));
		const width = parseInt(borderWidth.replace('px', ''));
		const newRadius = radius - width;
		return `${newRadius}px`;
	};

	return css`
		background: ${backgroundColor || defaultPanelBackgroundColor};
		box-shadow: ${zonesAreConnected ? 'none' : boxShadow || defaultPanelBoxShadow};

		${checkoutTheme.breakpoints.up('sm')} {
			margin-bottom: ${isTwoColumnLayout ? '0' : '16px'};
			border-bottom: ${isTwoColumnLayout ? 'none' : border};
			border: ${border};
			border-radius: ${borderRadius && !isJoined ? borderRadius : '0px'};
		}

		${checkoutTheme.breakpoints.down('sm')} {
			box-shadow: none;
		}

		.zone-leftColumnTop {
			overflow: hidden;
			border-top-right-radius: ${borderRadius && !isJoined
				? adjustRadius(borderRadius)
				: '0px'};
			border-top-left-radius: ${borderRadius && !isJoined
				? adjustRadius(borderRadius)
				: '0px'};
		}
	`;
};

const CustomerInfoBlock = () => {
	const { isSmallScreen, isTwoColumnLayout } = useContext(MediaQueryContext);
	const { t } = useTranslation('checkout');
	const { showPhone, template, paymentMethod } = useStore(
		useShallow((state) => ({
			showPhone: state.showPhone,
			template: state.template,
			paymentMethod: state.paymentMethod,
		})),
	);

	// When the input field is of type email any spacebar keypress does not fire an onChange event
	// therefore we aren't running onChange logic against the email field if its an empty space change.
	// This is in place to ignore any spacebar empty space added by key down
	const handleEmailKeyPress = (e) => {
		if (e.key === ' ') {
			e.preventDefault();
		}
	};
	const zonesAreConnected = isTemplateJoined(template);

	return (
		<div
			css={addressStyles(template, zonesAreConnected, isTwoColumnLayout)}
			className="CB-form"
			id="CB-customer-info-container"
		>
			{!isSmallScreen && <ZoneWrapper zoneId="leftColumnTop" />}
			<Card css={addressCardStyles(template, zonesAreConnected, isTwoColumnLayout)}>
				{/* This input is a bug fix for autofill in Chrome.  
				When the TokenEx input is auto filled it somehow clears the first input on the page.
				This hidden input is the one it clears instead of the email address. */}
				<input
					type="text"
					style={{ display: 'none', visibility: 'hidden' }}
					disabled
					aria-hidden="true"
					tabIndex="-1"
					hidden
				/>
				<Grid marginTop="0">
					<Typography variant="h4" className="heading CB-font-title">
						{t('card-title.customer-info')}
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<CBTextField
								name="email"
								label={t('field.email.label')}
								type="email"
								required={paymentMethod !== PaymentMethods.APPLE_PAY}
								maxLength={255}
								onKeyPress={handleEmailKeyPress}
							/>
						</Grid>
						{showPhone && (
							<Grid item xs={12}>
								<Suspense fallback={<InputPlaceholder showPlaceholder />}>
									<CBPhoneInput />
								</Suspense>
							</Grid>
						)}
					</Grid>
				</Grid>
			</Card>
		</div>
	);
};

export default CustomerInfoBlock;
