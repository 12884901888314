import PropTypes from 'prop-types';
import { createContext, useRef } from 'react';

export const GeoCurrenciesContext = createContext({});

export const GeoCurrenciesContextProvider = ({ children }) => {
	const countryCodeRef = useRef(null);
	return (
		<GeoCurrenciesContext.Provider value={{ countryCodeRef }}>
			{children}
		</GeoCurrenciesContext.Provider>
	);
};

GeoCurrenciesContextProvider.propTypes = {
	children: PropTypes.node,
};
