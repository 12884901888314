import { css } from '@emotion/react';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ForwardIcon from '@mui/icons-material/Forward';
import { Checkbox, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import BumpLayouts from './BumpLayouts';
import { BumpSelectionContext } from '@/state/BumpSelectionContext';
import { useStore } from '@/state/stores';
import {
	cbNegative,
	defaultDividerColor,
	defaultInputBorderColor,
	fontWeightBold,
	fontWeightRegular,
	palette,
} from '@/theme';
import { getProductImage } from '@/utils/helpers';
import { useCurrency, useFrequency } from '@/utils/hooks';

const bumpListCSS = (cartStyles) => {
	const { bump } = cartStyles || {};
	return css`
		padding: 1px;
		cursor: pointer;
		background: transparent;
		border: none;
		border-radius: 0;
		text-align: left;
		display: block;
		letter-spacing: inherit;
		line-height: inherit;
		word-spacing: inherit;
		font-weight: inherit;
		font-size: inherit;
		font-family: inherit;
		font-style: inherit;
		text-rendering: inherit;
		text-shadow: inherit;
		text-indent: inherit;
		width: 100%;
		padding: 8px;
		&::after {
			display: none;
		}
		.bump-lower-section {
			display: flex;
			align-items: flex-start;
		}
		.type {
			font-size: 0.875rem;
			margin-top: 8px;
		}

		.add-checkbox {
			/* Added display flex here to center the checkbox after removing padding from it */
			/* Padding was removed to allow for a white background (background is circular by default) */
			/* See OFC-4386 and the sx prop on the checkbox in Bump.js for context*/
			display: flex;
			justify-content: center;
			align-items: flex-start;
			position: relative;
			svg {
				width: 1em;
				height: 1em;
			}
		}

		.bump-spin {
			animation: spin 1000ms infinite linear;
			font-size: 18px;
			box-sizing: content-box;
			display: block;
			margin-right: 2px;
			color: ${bump?.checkboxColor || defaultInputBorderColor};
			position: relative;
			right: 2px;
		}

		hr {
			display: block;
			margin: 12px 0 8px 8px;
			border: none;
			border-bottom: 1px solid ${bump?.dividerColor || defaultDividerColor};
		}
		.bump-info-container {
			display: grid;
			grid-template-columns: 40px 1fr;
		}
		.product-description {
			margin: 0;
			white-space: pre-wrap;
		}
		.details {
			margin: 0;

			& > p {
				margin-bottom: 4px;
			}
		}

		.checkbox {
			border-radius: 0px;
			height: 12px;
			width: 12px;
			padding: 0px;
			background-color: transparent;
			margin-right: 8px;
			margin-top: 3px;
			svg {
				color: ${bump?.checkboxColor || defaultInputBorderColor};
			}
		}

		.arrow-svg {
			color: ${bump?.arrowColor || cbNegative[500]};
			display: ${bump?.arrowDisplay || 'none'};
			position: relative;
			top: -7px;
		}

		.recurring-product-container {
			display: flex;
			width: 100%;
			flex-direction: column;
			margin-bottom: 8px;

			.future-payment-container {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-top: 8px;

				.remainingPayments {
					display: flex;
					align-items: center;
				}

				svg {
					margin-right: 8px;
					// color: ${palette.text.secondary};
					width: 16px;
				}

				p {
					font-size: ${14 / 16}rem;
				}
			}

			.payment {
				font-size: ${14 / 16}rem;
				font-weight: ${fontWeightBold};
			}
		}

		.description {
			font-size: ${14 / 16}rem;
			margin-top: 8px;
			font-weight: ${bump?.descriptionFontWeight || fontWeightRegular};
			font-family: ${bump?.descriptionFontFamily || 'inherit'};
		}

		.disclaimer {
			font-size: ${12 / 16}rem;
			margin-top: 12px;
		}

		.recurring-image-container {
			display: flex;

			.future-payment-container {
				margin-top: 0;
			}

			.recurring-product-container {
				justify-content: space-between;
			}
		}

		.bump-image {
			max-width: 100px;
			max-height: 100px;
			display: ${bump?.productImageDisplay || 'none'};
			margin-right: 8px;
		}
		.product-type.product-type {
			font-size: ${14 / 16}rem;
			font-weight: ${fontWeightBold};
		}

		.non-recurring-product-container {
			display: flex;
			justify-content: space-between;
		}

		.bump-text-margin {
			margin-top: 8px;
		}
	`;
};

const frequencyConditions = (frequencyType, isUnlimited) => {
	// returns undefined if frequencyType !== day, week, or month
	if (frequencyType === 'day' || frequencyType === 'week' || frequencyType === 'month') {
		if (isUnlimited) {
			//  returns the right keys keys for translations
			if (frequencyType === 'week') return 'oneweek';
			if (frequencyType === 'month') return 'month';
		}
		return frequencyType;
	}
};

const Bump = (props) => {
	const {
		className,
		cartStyles,
		title,
		formattedUnitPrice,
		type,
		sku,
		isActive,
		textType,
		text,
		toggleCartItem,
		remainingPayments,
		isUnlimited,
		subsequentPayments,
		frequencyType,
		frequency,
		commonStyles,
		image,
		initialOffsetValue,
		description,
	} = props;

	const { isCartUpdating, submitOverlayVisible, orderTotals } = useStore(
		useShallow((state) => ({
			isCartUpdating: state.isCartUpdating,
			submitOverlayVisible: state.submitOverlayVisible,
			orderTotals: state.orderTotals,
		})),
	);
	const { triggeringBumpsQueue } = useContext(BumpSelectionContext);
	const [bumpCausingRecalculation, setBumpCausingRecalculation] = useState(false);

	useEffect(() => {
		const queue = triggeringBumpsQueue.current;
		if (queue[0] === sku) {
			setTimeout(() => queue.shift());
		}
		if (queue.length <= 1) {
			// Final bump update is finishing
			setBumpCausingRecalculation(false);
		}
	}, [orderTotals, setBumpCausingRecalculation, sku, triggeringBumpsQueue]);

	const { t } = useTranslation('checkout');
	const formattedSubsequentPayments = useCurrency(subsequentPayments);
	const decodedFrequency = useFrequency(frequency, frequencyType);

	//translation var 3
	const displayRemainingPayments = () => {
		if (frequencyConditions(frequencyType, isUnlimited)) return 1;
		if (
			!isUnlimited ||
			decodedFrequency === 'quarter' ||
			decodedFrequency === 'halfyearly' ||
			decodedFrequency === 'year'
		) {
			return remainingPayments;
		}
	};

	// translation var 4
	const recurringFrequencyType = () => {
		return t(`bump-recurring.${frequencyType}_friendly`, { frequency: frequency });
	};

	// returns either "payment" or "payments"
	const paymentWord = () => {
		if (isUnlimited || displayRemainingPayments() > 1) {
			return t('bump-payment.plural');
		}
		return t('bump-payment.singular');
	};

	//translation var 5
	const frequencyOrPayment = () => {
		const conditions = frequencyConditions(frequencyType, isUnlimited);
		if (conditions) {
			if (isUnlimited) {
				return t(`bump-recurring.${conditions}`);
			} else {
				return paymentWord();
			}
		}
		return t(`bump-recurring.${frequencyType}`);
	};

	//translation var 7
	const frequencyOrPaymentAlt = () => {
		const conditions = frequencyConditions(frequencyType, isUnlimited);
		if (conditions) {
			return t(`bump-recurring.${conditions}_friendly`, { frequency: frequency });
		}
		return paymentWord();
	};

	const bumpCopyTranslationVars = {
		title: title, // 0
		itemPrice: formattedUnitPrice, // 1 & 2
		remainingPayments: displayRemainingPayments(), // 3
		recurringFrequencyType: recurringFrequencyType(), // 4
		frequencyOrPayment: frequencyOrPayment(), // 5
		subsequentPayments: formattedSubsequentPayments, // 6
		frequencyOrPaymentAlt: frequencyOrPaymentAlt(), // 7
	};

	const translationKey =
		isUnlimited && (textType === 'SUBSCRIPTION_1' || textType === 'SUBSCRIPTION_2')
			? `checkout:bump-copy-name-bolded.UNLIMITED_${textType}`
			: `checkout:bump-copy-name-bolded.${textType}`;

	const bumpCopy = (
		<Typography variant="body3" className="product-description">
			{textType === 'CUSTOM' ? (
				text
			) : (
				<Trans i18nKey={translationKey} values={bumpCopyTranslationVars} />
			)}
		</Typography>
	);

	const disableBump = isCartUpdating || submitOverlayVisible;

	const handleClick = () => {
		setBumpCausingRecalculation(true);
		triggeringBumpsQueue.current.push(sku);
		toggleCartItem(sku);
	};

	// bump layout vars
	const imageUrl = image && getProductImage(image);
	const isRecurring = type.toLowerCase().includes('recurring');
	const showImage = imageUrl && cartStyles?.bump?.productImageDisplay === 'block';
	const showHr = isRecurring || showImage;

	return (
		<button
			type="button"
			className={className}
			//TODO see if we can remove commonStyles
			css={[commonStyles, bumpListCSS(cartStyles)]}
			onClick={handleClick}
			disabled={disableBump || bumpCausingRecalculation}
			tabIndex={-1}
		>
			<BumpLayouts
				imageUrl={imageUrl}
				imageAlt={'this needs to be updated'}
				isRecurring={isRecurring}
				remainingPayments={remainingPayments}
				type={type}
				showImage={showImage}
				frequency={frequency}
				frequencyType={frequencyType}
				subsequentPayments={subsequentPayments}
				isUnlimited={isUnlimited}
				initialOffsetValue={initialOffsetValue}
				description={description}
			/>
			{showHr ? <hr /> : null}
			<div className={!showHr ? 'bump-lower-section bump-text-margin' : 'bump-lower-section'}>
				<div className="add-checkbox">
					<ForwardIcon className="arrow-svg" sx={{ fontSize: '2rem' }} />
					{bumpCausingRecalculation ? (
						<AutorenewIcon className="bump-spin" />
					) : (
						<Checkbox
							checked={isActive}
							disabled={disableBump || bumpCausingRecalculation}
							disableRipple
							onKeyUp={(ev) => {
								ev.preventDefault();
								if (ev.key === ' ') {
									handleClick();
								}
							}}
						/>
					)}
				</div>
				{bumpCopy}
			</div>
		</button>
	);
};

Bump.propTypes = {
	className: PropTypes.string,
	isActive: PropTypes.bool,
	cartStyles: PropTypes.object,
	title: PropTypes.string,
	type: PropTypes.string,
	formattedUnitPrice: PropTypes.string,
	subscriptionBlock: PropTypes.node,
	sku: PropTypes.string,
	textType: PropTypes.string, // STANDARD_1, STANDARD_2, UPGRADE_1, UPGRADE_2, URGENT, SUBSCRIPTION_1, SUBSCRIPTION_2, BOOK_1, BOOK_2, CUSTOM // ? dont' think this is needed in ui
	text: PropTypes.string, // this is the description for the bump. only bumps can have text.
	toggleCartItem: PropTypes.func,
	remainingPayments: PropTypes.number,
	isUnlimited: PropTypes.bool,
	subsequentPayments: PropTypes.string,
	frequency: PropTypes.string, // WEEKLY, BI_WEEKLY, MONTHLY, QUARTERLY, HALF_YEARLY, YEARLY, MONTHS, WEEKS, DAYS
	frequencyType: PropTypes.string,
	commonStyles: PropTypes.object,
	image: PropTypes.string,
	initialOffsetValue: PropTypes.number,
	description: PropTypes.string,
};

export default Bump;
