import { stringIsEqualCaseInsensitive } from './helpers';

function getProduct(product, productProps) {
	const isShippable = product.shippable || product.priceModel.shippable;
	const isPhysical = product.type.toLowerCase().includes('physical');
	const isRecurring = product.priceModel.type.toLowerCase().includes('recurring');
	const initialOffsetValue = product.priceModel.initialOffsetValue;
	const isUnlimited = product.priceModel.maxPayments === 999;
	let remainingPayments = 0;
	if (isUnlimited) {
		remainingPayments = product.priceModel.maxPayments;
	} else if (product.priceModel.maxPayments) {
		// `maxPayments` will be 0 if single purchase
		remainingPayments = product.priceModel.maxPayments - 1;
	}
	const [frequency, frequencyType] = product?.priceModel?.frequency?.includes(' ')
		? product.priceModel.frequency.toLowerCase().split(' ')
		: [];

	return {
		...productProps,
		id: product.id,
		image: product.image,
		sku: product.sku,
		title: product.title,
		physical: isPhysical,
		type: product.type,
		recurring: isRecurring,
		refundDaysLimit: product.refundDaysLimit,
		delayedDelivery: product.delayedDelivery,
		isTestPurchaseOnly: product.isTestPurchaseOnly,
		isShippable: isShippable,
		phoneRequired: product.phoneRequired,
		description: product.description,
		priceModel: {
			frequency: frequency,
			frequencyType: frequencyType,
			isUnlimited: isUnlimited,
			maxPayments: product.priceModel.maxPayments,
			remainingPayments,
			initialOffsetValue: initialOffsetValue,
			type: product.priceModel.type,
			refundDaysLimit: product.priceModel.refundDaysLimit,
		},
	};
}

const getAllProducts = (productsRawData, urlProductsArray, selectedLanguage, quantityEditable) => {
	// parse gql data into allProducts array
	const { products, orderBump } = productsRawData || {};
	const productsArr = [];
	const bumpsArr = [];

	// Add data for Arr in the URL
	products.map((product) => {
		//maxQuantity can be null, so we need to check for that
		const maxQuantity = product.maxQuantity || 10;
		const quantity = urlProductsArray.find((item) =>
			stringIsEqualCaseInsensitive(item.sku, product.sku),
		)?.quantity;

		// this insures that the quantity is not greater than the maxQuantity
		const getProductQuantity = () => {
			return Math.min(Math.max(quantity, 1), maxQuantity);
		};

		const productProps = {
			maxQuantity,
			quantity: quantityEditable ? getProductQuantity() : 1,
			isBump: false,
		};

		const productData = getProduct(product, productProps, selectedLanguage);
		return productsArr.push(productData);
	});

	//add product data for bumps
	orderBump?.orderBumpProducts.map((bump) => {
		const productProps = {
			quantity: 1,
			isBump: true,
			sequence: bump.sequence,
			textType: bump.textType,
			text: bump.text !== 'null' ? bump.text : null,
		};

		const productData = getProduct(bump.product, productProps, selectedLanguage);
		return bumpsArr.push(productData);
	});

	return productsArr.concat(bumpsArr);
};

// builds the array of objects for the receipt page using data returned from the back end when an order is placed.
const buildReceiptPageProducts = (products, purchasedProducts, calculatedCart) => {
	const receiptPageProducts = [];
	products.map((productItem) => {
		purchasedProducts.map((purchasedProductItem) => {
			calculatedCart.map((calculatedCartItem) => {
				if (
					productItem.id === purchasedProductItem.productId &&
					productItem.id === calculatedCartItem.productId
				) {
					const [frequency, frequencyType] = productItem?.priceModel?.frequency?.includes(
						' ',
					)
						? productItem.priceModel.frequency.toLowerCase().split(' ')
						: [productItem.priceModel.frequency, productItem.priceModel.frequencyType];

					const isRecurring = productItem.type.toLowerCase().includes('recurring');
					const isPhysical = productItem.type.toLowerCase().includes('physical');

					const maxPayments = productItem.priceModel.maxPayments;
					const isUnlimited = maxPayments === 999;
					let remainingPayments = 0;
					if (isUnlimited) {
						remainingPayments = maxPayments;
					} else if (maxPayments) {
						// `maxPayments` will be 0 if single purchase
						remainingPayments = maxPayments - 1;
					}

					receiptPageProducts.push({
						link: purchasedProductItem.link,
						title: productItem.title,
						price: calculatedCartItem.amount,
						physical: isPhysical,
						isTestPurchaseOnly: productItem.isTestPurchaseOnly,
						image: productItem.image,
						recurring: isRecurring,
						type: productItem.type,
						quantity: calculatedCartItem.quantity,
						initialPayment: calculatedCartItem.amount,
						subscriptionDetails: {
							initialOffsetValue: productItem.priceModel.initialOffsetValue,
							frequency: frequency,
							frequencyType: frequencyType,
							subsequentPayments: calculatedCartItem.recurringAmount,

							isUnlimited,
							maxPayments,
							remainingPayments,
						},
					});
				}
				return null;
			});
			return null;
		});
		return null;
	});

	return receiptPageProducts;
};

const removeDuplicateBumps = (products, bumps) => {
	if (Array.isArray(products) && Array.isArray(bumps) && bumps.length) {
		products.forEach((product) => {
			for (let i = 0; i < bumps.length; i++) {
				const bump = bumps[i];
				//if there is no product value, remove the bump
				if (!bump.product) {
					bumps.splice(i--, 1);
					// if there are matching skus between products and bumps, remove the bump.
				} else if (product.sku === bump.product.sku) {
					bumps.splice(i--, 1);
				}
			}
		});
	}
	return bumps;
};

const getProductsWithPricing = (allProducts, calculateCartData) => {
	const lineItems = calculateCartData.calculateCart.lineItems;
	const availableItems = calculateCartData.calculateCart.availableItems;
	const allItems = availableItems.length ? lineItems.concat(availableItems) : lineItems;

	if (!allItems) {
		return [];
	}

	return allProducts.map((product) => {
		const targetItem = allItems.find((item) => product.id === item.productId);
		return {
			...product,
			priceModel: {
				...product.priceModel,
				unitPrice: targetItem.unitPrice,
				initialPayment: targetItem.amount,
				recurringUnitPrice: targetItem.recurringUnitPrice,
				subsequentPayments: targetItem.recurringAmount,
			},
		};
	});
};

export { getAllProducts, buildReceiptPageProducts, removeDuplicateBumps, getProductsWithPricing };
