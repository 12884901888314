import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { lazy } from 'react';

import { getVisibilityStyle } from './layoutFactoryConstants';
import { initialWidgetPadding } from '@/theme';
import { WidgetVisibility } from '@/utils/enums';
import { isPreview } from '@/utils/helpers';

const flexDirections = {
	center: 'center',
	left: 'flex-start',
	right: 'flex-end',
};

const Metadata = isPreview ? lazy(() => import('./ImagePreviewMetadata')) : null;

const imageStyles = (widgetStyle) => {
	const {
		backgroundColor,
		borderRadius,
		borderSize,
		borderStyle,
		borderColor,
		imageWidth,
		imagePosition,
		imageBorderRadius,
		padding,
	} = widgetStyle || {};
	const hasBorder = !!borderSize;
	const border = hasBorder
		? `${borderSize} ${borderStyle || 'solid'} ${borderColor || '#000000'}`
		: 'none';
	const bgColor = backgroundColor || 'transparent';

	return css`
		width: 100%;
		padding: ${padding || initialWidgetPadding};
		border: ${border};
		box-sizing: border-box;
		background-color: ${bgColor};
		display: flex;
		justify-content: ${imagePosition ? flexDirections[imagePosition] : 'center'};
		position: relative;
		border-radius: ${borderRadius || '0'};

		.image-container {
			display: flex;
			width: ${imageWidth};
			position: relative;
		}

		img {
			width: 100%;
			border-radius: ${imageBorderRadius || '0px'};
			align-self: center;
		}
	`;
};

const ImageWidget = ({ image, visibility, style }) => {
	const metadata = {};
	const { imageAlt, imageUrl } = image || {};
	if (imageAlt) {
		metadata.alt = imageAlt;
	}

	return imageUrl ? (
		<section
			css={[imageStyles(style?.attributes), getVisibilityStyle(visibility)]}
			className="CB-image-module"
		>
			<div className="image-container">
				<img src={imageUrl} {...metadata} />
				{isPreview && <Metadata imageAlt={imageAlt} />}
			</div>
		</section>
	) : null;
};

ImageWidget.propTypes = {
	style: PropTypes.shape({
		attributes: PropTypes.object,
	}),
	visibility: PropTypes.arrayOf(
		PropTypes.oneOf([
			WidgetVisibility.MOBILE,
			WidgetVisibility.MOBILE_2,
			WidgetVisibility.TABLET,
			WidgetVisibility.TABLET_2,
			WidgetVisibility.DESKTOP,
			WidgetVisibility.DESKTOP_2,
		]),
	),
	image: PropTypes.shape({
		imageKey: PropTypes.string,
		imageTitle: PropTypes.string,
		imageUrl: PropTypes.string,
		imageAlt: PropTypes.string,
	}),
};

ImageWidget.defaultProps = {
	style: undefined,
	image: null,
};

export default ImageWidget;
