import { inputData } from './regexAndRequiredInputsByCountry.js';

export const isInputRequired = (name, countryCode, condition) => {
	const input = inputData[countryCode];

	return (
		input &&
		!!(
			input.fields[name].required === 'always' ||
			(condition !== undefined && input.fields[name].required === condition)
		)
	);
};
