import { fetchUrl, fetchWithRetry } from './fetchHelpers';
import { AFFILIATE } from '@/graphql/queries';

const getClientHintHeaders = async () => {
	const headers = {};
	try {
		const { architecture, model, platformVersion, fullVersionList, bitness } =
			await navigator.userAgentData.getHighEntropyValues([
				'architecture',
				'model',
				'platformVersion',
				'fullVersionList',
				'bitness',
			]);

		if (architecture) headers['Arch'] = architecture;
		if (model) headers['Model'] = model;
		if (platformVersion) headers['Platform-Version'] = platformVersion;
		if (fullVersionList) headers['Full-Version-List'] = JSON.stringify(fullVersionList);
		if (bitness) headers['Bitness'] = bitness;
	} catch (error) {
		//Do nothing if error. Not all browsers support user agent headers
	}
	return headers;
};

const getAffiliateQuery =
	({ urlVars, fetchHeaders }) =>
	async () => {
		const clientHintHeaders = await getClientHintHeaders();
		try {
			const body = await fetchWithRetry('AFFILIATE', fetchUrl, {
				method: 'POST',
				headers: {
					...fetchHeaders,
					...clientHintHeaders,
				},
				body: JSON.stringify({
					query: AFFILIATE,
					variables: {
						vendorId: urlVars.vvvv,
						urlParams: window.location.search,
					},
				}),
			});
			if (body.error) {
				throw body;
			}
			const { data } = body;
			const state = {
				affiliate: data?.affiliate || null,
			};
			return {
				graphql: { AFFILIATE: data },
				state,
			};
		} catch (body) {
			// If we determine affiliate is blocked or inactive, do not reject, as we want to continue
			// execution. If it failed for any other reason, consider it a fatal error;
			const statusCode = body?.error?.graphQLErrors?.[0]?.extensions?.status;
			const field = body?.error?.graphQLErrors?.[0]?.extensions?.details[0]?.field || null;
			const affiliateBlockedOrInactive = statusCode === 403 && field === 'affiliate';
			if (affiliateBlockedOrInactive) {
				// TODO: Need to parse affiliate name? In response payload?
				console.error('Affiliate blocked or inactive.');
			}
			const payload = {
				errors: { AFFILIATE: body },
				state: { affiliateBlockedOrInactive },
			};
			return affiliateBlockedOrInactive ? Promise.resolve(payload) : Promise.reject(payload);
		}
	};

export default getAffiliateQuery;
