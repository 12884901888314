import { css } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { cbNeutral } from '@/theme';

const modalStyles = css`
	.CB-dialog-paper {
		flex-grow: 1;
	}

	.title {
		background-color: ${cbNeutral[900]};
		color: ${cbNeutral[200]};
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		padding-right: 0.5rem;
		min-height: 5.25rem;

		.title-text {
			font-size: 2rem;
			line-height: 1.25;
		}
	}

	.icon-button {
		margin-top: -3px;
		margin-bottom: -3px;
		background-color: transparent;
	}

	.close-icon {
		color: ${cbNeutral[400]};
		width: 50px;
		height: 50px;
	}

	.content {
		padding-top: 2rem;
		padding-bottom: 2rem;
		min-height: 40vh;
		display: flex;
		align-items: center;

		p {
			display: inline-block;
			margin-top: 0.5rem;
			margin-bottom: 0.5rem;
		}
	}
`;

const Modal = (props) => {
	const { modalOpen, closeModal, dialogTitle, dialogContent } = props;
	const [open, setOpen] = useState(modalOpen);

	if (modalOpen !== open) {
		setOpen(modalOpen);
	}

	const handleClose = () => {
		closeModal ? closeModal() : setOpen(false);
	};

	return (
		<Dialog
			css={modalStyles}
			onClose={handleClose}
			aria-labelledby="customized-dialog-title"
			open={open}
		>
			<DialogTitle className="title">
				<span className="title-text">{dialogTitle}</span>
				{closeModal && (
					<IconButton
						aria-label="close"
						onClick={handleClose}
						className="icon-button"
						size="large"
					>
						<CloseIcon className="close-icon" />
					</IconButton>
				)}
			</DialogTitle>
			<DialogContent dividers className="content">
				{dialogContent}
			</DialogContent>
		</Dialog>
	);
};

Modal.propTypes = {
	modalOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	dialogTitle: PropTypes.node,
	dialogContent: PropTypes.node,
};

export default Modal;
