// Librarues
import { useManualQuery } from 'graphql-hooks';
import { useCallback, useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { parseAttributes } from './initialFetch/fetchHelpers';
import { EXIT_OFFER } from './queries';
import { useStore } from '@/state/stores';
import { isDevEnv, isPreview } from '@/utils/helpers';

export const ExitOfferLoader = ({ allQueriesData, queryFetcher }) => {
	const [fetchQuery] = useManualQuery(EXIT_OFFER);
	const { featureFlags, exitOffer, setExitOffer, urlVars, hasExitOfferQueryFailed } = useStore(
		useShallow((state) => ({
			featureFlags: state.featureFlags,
			exitOffer: state.exitOffer,
			setExitOffer: state.setExitOffer,
			urlVars: state.urlVars,
			hasExitOfferQueryFailed: state.hasExitOfferQueryFailed,
		})),
	);

	const runExitOfferQuery = useCallback(() => {
		queryFetcher({
			targetQuery: 'EXIT_OFFER',
			required: false,
			loadFunction: () =>
				fetchQuery({
					variables: {
						alias: urlVars.exitoffer,
						vendorId: urlVars.vvvv,
						// this tells the BE to change out the image id for the image URL when the env is not prod.
						preview: isPreview || isDevEnv,
					},
				}),
		});
	}, [fetchQuery, queryFetcher, urlVars]);

	useEffect(() => {
		if (exitOffer || !urlVars.exitoffer || hasExitOfferQueryFailed) {
			return;
		}
		runExitOfferQuery();
	}, [urlVars.exitoffer, exitOffer, featureFlags, runExitOfferQuery, hasExitOfferQueryFailed]);

	useEffect(() => {
		if (allQueriesData.EXIT_OFFER?.exitOfferTemplate) {
			const parsedExitOffer = parseAttributes(allQueriesData.EXIT_OFFER.exitOfferTemplate);
			setExitOffer(parsedExitOffer);
		}
	}, [allQueriesData, setExitOffer]);
};
