import { v4 as uuidv4 } from 'uuid';

import getAffiliateQuery from './affiliate';
import getCalculateCartQuery from './calculateCart';
import {
	constructDefaultFormState,
	deriveCombinedProductAndBumpState,
	flattenResults,
} from './fetchHelpers';
import getGeoCurrenciesQuery from './geoCurrencies';
import getBumpsQuery from './orderBumps';
import getProductsQuery from './products';
import getSiteSettingsQuery from './siteSettings';
import getTokenExQuery from './tokenex';
import { isDevEnv, isPreview, requiredEndpoints } from '@/utils/helpers';

const basePath = process.env.REACT_APP_BASE_PATH ? process.env.REACT_APP_BASE_PATH : '/';
const isCheckout = [basePath, basePath + 'index.html'].includes(window.location.pathname);
const addressSearchId = uuidv4();

const fetchInitialData =
	(rebuildData = null) =>
	async ({ urlVars, urlProductsArray, fetchHeaders }) => {
		try {
			const { rebuildCartFromSku, rebuildFormData } = rebuildData || {};
			const hasUpsell = Boolean(urlVars['upsell-key']);

			// If we already have a coupon from a cart rebuild, we know it is valid. Otherwise,
			// if we have a URL var, try to transform it to be suitable
			const initialCoupon =
				rebuildFormData?.couponCode || urlVars.coupon?.slice(0, 20).toUpperCase() || '';

			if (hasUpsell || (!isCheckout && !rebuildData)) {
				// We are on a page that doesn't require this initial fetch, or will invoke
				// it elsewhere (e.g. after PayPal rebuild information is processed)
				return {};
			}

			const pazeScript = new Promise((resolve, reject) => {
				if (!process.env.REACT_APP_PAZE_CLIENT_ID) {
					reject();
					return;
				}

				const pazeScriptEl = document.createElement('script');
				pazeScriptEl.src = process.env.REACT_APP_PAZE_JDK_URL;
				pazeScriptEl.type = 'text/javascript';
				pazeScriptEl.addEventListener('load', () => resolve());
				pazeScriptEl.addEventListener('error', () => {
					console.error('Paze script load error.');
					reject();
				});
				document.body.appendChild(pazeScriptEl);
			});
			const pazeInit = pazeScript
				.then(async () => {
					try {
						const CBPazeClient = {
							client: {
								id: process.env.REACT_APP_PAZE_CLIENT_ID,
								name: 'ClickBank',
								profileId: 'ClickBank',
							},
						};
						await window.DIGITAL_WALLET_SDK.initialize(CBPazeClient);
						return true;
					} catch (e) {
						return false;
					}
				})
				.catch(() => false);

			// Construct an object that will be curried to the functions that invoke GQL queries
			const queryParams = {
				urlVars,
				urlProductsArray,
				initialCoupon,
				rebuildCartFromSku,
				rebuildFormData,
				fetchHeaders,
			};

			// All return functions that send GQL query and process returned data,
			// which will be accumulated into final, initial state
			const tokenExQuery = getTokenExQuery(queryParams);
			const affiliateQuery = getAffiliateQuery(queryParams);
			const siteSettingsQuery = getSiteSettingsQuery(queryParams);
			const productsQuery = getProductsQuery(queryParams);
			const bumpsQuery = getBumpsQuery(queryParams);
			const geoCurrenciesQuery = getGeoCurrenciesQuery(queryParams, addressSearchId);
			const calculateCartQuery = getCalculateCartQuery(queryParams);

			// Affiliate only loads early in a non-dev, non-preview environment if there is no upsell, we are loading the
			// checkout page, and there is no rebuild data from paypal
			const affiliateLoadsEarly =
				!isDevEnv && !isPreview && !hasUpsell && isCheckout && !rebuildData;

			if (affiliateLoadsEarly && !window.cbAffiliateResult) {
				return Promise.reject({
					errors: {
						AFFILIATE: 'Affiliate data absent',
					},
				});
			}

			// If certain queries fail (e.g. PRODUCTS, GEO_CURRENCIES), we want them to immediately break the chain of execution
			// and expedite reaching the form in a fatal error state. For others (e.g. ORDER_BUMPS), we can continue to attempt
			// a successful form load, even if they fail.
			const requests = [
				Promise.all([
					Promise.allSettled([
						bumpsQuery(),
						Promise.all([
							// It may not be set on the window at this point? Maybe a newly constructed promise that only resolves when it IS available and passes along with that promise?
							affiliateLoadsEarly ? window.cbAffiliateResult : affiliateQuery(),
							productsQuery(),
							siteSettingsQuery(),
						]),
					])
						.then(deriveCombinedProductAndBumpState(queryParams))
						.then(geoCurrenciesQuery)
						.then(calculateCartQuery)
						.catch((err) => err),
					tokenExQuery(),
				]).catch((err) => err),
			];
			// `requests` will be a series of nested arrays containing objects with `state`, `errors`,
			// and `graphql` keys.
			const result = await Promise.all(requests);
			delete window.cbAffiliateResult;
			// Flatten these arrays and combine their values into one object
			const flattenedResults = flattenResults(result);
			if (
				flattenedResults.errors &&
				Object.keys(flattenedResults.errors).some((endpoint) => {
					// AFFILIATE query may fail because affiliate is blocked or inactive, in which
					// case we want to continue form load
					const blockedAffiliate =
						endpoint === 'AFFILIATE' &&
						flattenedResults?.state?.affiliateBlockedOrInactive;
					if (blockedAffiliate) {
						return false;
					}
					return requiredEndpoints.includes(endpoint);
				})
			) {
				// A necessary endpoint failed, so skip out on any of the below logic, as we simply want
				// to load the form in an error state
				return flattenedResults;
			}
			// Add final few keys to initial state object
			const { state } = flattenedResults;
			state.urlVars = urlVars;
			state.addressSearchId = addressSearchId;
			if (rebuildCartFromSku) {
				state.rebuildCartFromSku = rebuildCartFromSku;
			}
			// Add initial data specifically for our formStore
			flattenedResults.state = {
				...flattenedResults.state,
				...constructDefaultFormState(state, queryParams),
			};
			if (isDevEnv || isPreview) {
				flattenedResults.fetchHeaders = fetchHeaders;
			}

			// Handle resolution of product images sizing, as images have likely loaded by now.
			const { productImageLoadingPromises } = flattenedResults.state;
			flattenedResults.state.checkInitialEmailForPaze = pazeInit.then(async (shouldInit) => {
				try {
					if (!shouldInit) {
						return false;
					}
					const emailAddress = flattenedResults.state.formData.email;
					if (!emailAddress) {
						return false;
					}
					const canCheckoutResult = await window.DIGITAL_WALLET_SDK.canCheckout({
						emailAddress,
					});
					return canCheckoutResult.consumerPresent;
				} catch (e) {
					console.error('error occurred with Paze canCheckout', JSON.stringify(e));
				}
				return false;
			});
			const productImages = (await Promise.all(productImageLoadingPromises)).filter(Boolean);
			const isLargeProductImage =
				productImages.length > 0 &&
				!productImages.find((img) => {
					const height = img.naturalHeight ?? 0;
					return height < 300;
				});
			delete flattenedResults.state.productImageLoadingPromises;
			flattenedResults.state.isLargeProductImage = isLargeProductImage;

			return flattenedResults;
		} catch (err) {
			console.error(err);
		}
	};

export default fetchInitialData;
