import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useStore } from '@/state/stores';
import { getNextBillingDate } from '@/utils/helpers';
import { useCurrency, useFrequency } from '@/utils/hooks';

const BumpLayouts = ({
	imageUrl,
	imageAlt,
	remainingPayments = 1,
	type,
	showImage,
	isRecurring,
	frequency,
	frequencyType,
	subsequentPayments,
	isUnlimited,
	initialOffsetValue,
	description,
}) => {
	const { t } = useTranslation('common');
	const [nextBillingDate, setNextBillingDate] = useState(null);
	const selectedLanguage = useStore((state) => state.selectedLanguage);

	useEffect(() => {
		setNextBillingDate(
			getNextBillingDate(initialOffsetValue, selectedLanguage, frequency, frequencyType),
		);
	}, [selectedLanguage, frequency, frequencyType, initialOffsetValue]);

	const isDigital = !type.includes('PHYSICAL');
	const productType = isDigital ? 'digital' : 'physical';

	const decodeFrequencyForTranslations = useFrequency(frequency, frequencyType);

	const payments = useCurrency(subsequentPayments);

	const decodedFrequency =
		decodeFrequencyForTranslations && remainingPayments > 1
			? decodeFrequencyForTranslations
			: 'generic';

	const paymentHeader = (() => {
		const args = {
			numberOfPayments: remainingPayments,
			paymentAmount: payments,
		};
		if (isUnlimited) {
			return t(`subscription.payment.unlimited.${decodedFrequency}`, args);
		} else {
			return Number(remainingPayments) > 1
				? t(`subscription.payment.plural.${decodedFrequency}`, args)
				: t(`subscription.payment.singular.${decodedFrequency}`, args);
		}
	})();

	const getProductType = () => {
		if (type === 'STANDARD_DIGITAL_STANDARD_PHYSICAL') {
			return t('product-type.digital-with-physical');
		}
		if (type === 'STANDARD_PHYSICAL_RECURRING_DIGITAL') {
			return t('product-type.physical-with-recurring-digital');
		}
		if (isDigital) {
			return t('product-type.digital');
		}
		return t('product-type.physical');
	};

	const rebillFrequency = () => {
		const paymentTypeSingular = t(`subscription.payment.type.${frequencyType}`);
		const paymentTypePlural = t(`subscription.payment.type.plural.${frequencyType}`);

		return frequency > 1 ? `${frequency} ${paymentTypePlural}` : paymentTypeSingular;
	};

	const RecurringWithImage = () => (
		<>
			<div className="recurring-image-container">
				<img src={imageUrl} alt={imageAlt} className="bump-image" />
				<div className="recurring-product-container">
					<Typography variant="body2" className="product-type">
						{getProductType()}
					</Typography>
					<div className="future-payment-container">
						<div className="remainingPayments">
							<AccessTimeIcon fontSize="small" classes={{ root: 'time-icon' }} />
							<Typography className="future-payment">
								{remainingPayments > 1 ? 'Future Payments' : 'Future Payment'}
							</Typography>
						</div>
					</div>
					<Typography className="payment">{paymentHeader}</Typography>
				</div>
			</div>
			{description && <Typography className="description">{description}</Typography>}
			<Typography variant="body2" className="disclaimer">
				{t(`subscription.${productType}-next-bill-frequency-dof`, {
					nextBillingDate: nextBillingDate,
					rebillFrequency: rebillFrequency(),
				})}
			</Typography>
		</>
	);

	const RecurringWithoutImage = () => (
		<div className="recurring-product-container">
			<Typography variant="body2" className="product-type">
				{getProductType()}
			</Typography>
			<div className="future-payment-container">
				<Typography className="payment">{paymentHeader}</Typography>
				<div className="remainingPayments">
					<AccessTimeIcon fontSize="small" classes={{ root: 'time-icon' }} />
					<Typography className="future-payment">
						{remainingPayments > 1 ? 'Future Payments' : 'Future Payment'}
					</Typography>
				</div>
			</div>
			{description && <Typography className="description">{description}</Typography>}
			<Typography variant="body2" className="disclaimer">
				{t(`subscription.${productType}-next-bill-frequency-dof`, {
					nextBillingDate: nextBillingDate,
					rebillFrequency: rebillFrequency(),
				})}
			</Typography>
		</div>
	);

	const NonRecurringWithImage = () => (
		<div className="non-recurring-product-container">
			<img src={imageUrl} alt={imageAlt} className="bump-image" />
			<Typography variant="body2" className="product-type">
				{getProductType()}
			</Typography>
		</div>
	);

	const NonRecurringWithoutImage = () => (
		<div className="non-recurring-product-container">
			<Typography variant="body2" className="product-type">
				{getProductType()}
			</Typography>
		</div>
	);

	const layoutType = `${isRecurring ? 'Recurring' : 'NonRecurring'}${
		showImage ? 'WithImage' : 'WithoutImage'
	}`;

	switch (layoutType) {
		case 'RecurringWithImage':
			return <RecurringWithImage />;
		case 'RecurringWithoutImage':
			return <RecurringWithoutImage />;
		case 'NonRecurringWithImage':
			return <NonRecurringWithImage />;
		case 'NonRecurringWithoutImage':
			return <NonRecurringWithoutImage />;
		default:
			return null;
	}
};

export default BumpLayouts;

BumpLayouts.propTypes = {
	imageUrl: PropTypes.string,
	imageAlt: PropTypes.string,
	remainingPayments: PropTypes.number,
	type: PropTypes.string,
	paymentHeader: PropTypes.string,
	nextBillingDateCopy: PropTypes.string,
	showImage: PropTypes.bool,
	isRecurring: PropTypes.bool,
	frequency: PropTypes.string,
	frequencyType: PropTypes.string,
	subsequentPayments: PropTypes.string,
	isUnlimited: PropTypes.bool,
	initialOffsetValue: PropTypes.number,
	description: PropTypes.string,
};
