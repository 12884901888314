import { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import Checkout from '@/pages/Checkout';
import OrderReceived from '@/pages/OrderReceived';
import PayPalResponse from '@/pages/PayPalResponse';

const basePath = process.env.REACT_APP_BASE_PATH ? process.env.REACT_APP_BASE_PATH : '/';

const RouteManager = () => {
	const location = useLocation();

	// when a user is returning from paypal there is a hash in the url that needs to be removed
	const removeHashFromUrl = () => {
		const url = window.location.href.split('#')[1];
		return <Redirect to={url} />;
	};

	useEffect(() => {
		// add page view for mouseflow
		window._mfq.push(['newPageView', location.pathname]);
	}, [location]);

	return (
		<Switch>
			<Route exact path={[basePath, basePath.concat('index.html')]}>
				{location.hash ? removeHashFromUrl() : <Checkout />}
			</Route>
			<Route path="/order-received">
				<OrderReceived />
			</Route>
			<Route path="/paypal-response">
				<PayPalResponse />
			</Route>
		</Switch>
	);
};

export default RouteManager;
