export const currencies = [
	{ code: 'ARS', name: 'Argentinean Peso' },
	{ code: 'AUD', name: 'Australian Dollar' },
	{ code: 'BRL', name: 'Brazilian Real' },
	{ code: 'CAD', name: 'Canadian Dollar' },
	{ code: 'CHF', name: 'Swiss Franc' },
	{ code: 'CLP', name: 'Chilean Peso' },
	{ code: 'CNY', name: 'Chinese Yuan' },
	{ code: 'COP', name: 'Columbian Peso' },
	{ code: 'CZK', name: 'Czech Koruna' },
	{ code: 'DKK', name: 'Danish Krone' },
	{ code: 'EUR', name: 'Euro' },
	{ code: 'GBP', name: 'British Pound' },
	{ code: 'HKD', name: 'Hong Kong Dollar' },
	{ code: 'HUF', name: 'Hungarian Forint' },
	{ code: 'IDR', name: 'Indonesian Rupiah' },
	{ code: 'INR', name: 'Indian Rupee' },
	{ code: 'JPY', name: 'Japanese Yen' },
	{ code: 'KRW', name: 'South Korean Won' },
	{ code: 'MXN', name: 'Mexican Peso' },
	{ code: 'MYR', name: 'Malaysian Ringgit' },
	{ code: 'NOK', name: 'Norwegian Krone' },
	{ code: 'NZD', name: 'New Zealand Dollar' },
	{ code: 'PHP', name: 'Philippines Peso' },
	{ code: 'PLN', name: 'Polish Zloty' },
	{ code: 'RUB', name: 'Russian Ruble' },
	{ code: 'SEK', name: 'Swedish Krona' },
	{ code: 'SGD', name: 'Singapore Dollar' },
	{ code: 'THB', name: 'Thai Baht' },
	{ code: 'TRY', name: 'Turkish Lira' },
	{ code: 'USD', name: 'US Dollar' },
	{ code: 'ZAR', name: 'South African Rand' },
];
