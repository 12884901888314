import { css } from '@emotion/react';
import { Button, Container, Grid, Skeleton, Typography } from '@mui/material';
import { Fragment, useEffect } from 'react';

import { Card, Header, SubFooter, UpdateOverlay } from '..';
import { useStore } from '@/state/stores';
import { checkoutTheme } from '@/theme';

// This component is what the user sees while the paypal API api calls are resolved before routing to the receipt page or back to the form

const mainContent = css`
	.container {
		${checkoutTheme.breakpoints.down('md')} {
			padding-left: 12px;
			padding-right: 12px;
		}

		${checkoutTheme.breakpoints.up('sm')} {
			padding-top: 2.5em;
			padding-bottom: 2.5em;
		}
	}

	.grid-container {
		margin-top: 0;
		margin-bottom: 0;
	}

	.column {
		${checkoutTheme.breakpoints.down('md')} {
			padding: 0;
		}

		${checkoutTheme.breakpoints.up('sm')} {
			max-width: 80%;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.order-number {
		margin-bottom: 1em;

		${checkoutTheme.breakpoints.up('lg')} {
			margin-bottom: 0;
		}
	}

	.date-badge {
		padding: 8px;
		border-radius: 4px;
		width: 200px;
		display: flex;
		align-items: center;
		justify-content: center;

		.order-date {
			font-weight: 600;
			font-size: 0.875rem;
		}
	}

	.product-details {
		padding-bottom: 2em;
		flex-direction: row;
	}

	.product-image {
		display: block;
		float: left;
		width: 100px;
		height: 100px;
		margin-bottom: 1.5rem;
	}

	.title-type-price {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: center;
		/* align-content: center; */
		margin-bottom: 1.5rem;

		${checkoutTheme.breakpoints.up('md')} {
			/* flex-direction: column; */
			justify-content: flex-start;
			margin-bottom: 0;

			> * {
				flex-grow: 1;
			}
		}
	}

	.product-title {
		align-self: flex-start;
		flex-shrink: 0;
		width: 100%;
	}

	.product-type {
		align-self: flex-start;
		margin-bottom: 0.5rem;
	}

	.price-quantity-container {
		${checkoutTheme.breakpoints.up('xs')} {
			margin-top: -2rem;
			display: flex;
			align-items: flex-end;
			flex-direction: column;
		}
	}

	.product-price {
		font-weight: bold;
		padding-left: 7.2rem;
		${checkoutTheme.breakpoints.up('md')} {
			text-align: right;
			margin-bottom: 0.5rem;
		}
	}

	.product-quantity {
		padding-left: 7.2rem;
		font-weight: 500;
		margin-bottom: 1rem;
		${checkoutTheme.breakpoints.up('md')} {
			text-align: right;
			margin-bottom: 1rem;
		}
	}

	.product-buttons {
		${checkoutTheme.breakpoints.up('sm')} {
			display: table;
		}
	}

	.product-button {
		width: 100%;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		margin-bottom: 0.5rem;

		${checkoutTheme.breakpoints.up('sm')} {
			display: block;
			width: 100%;
			text-align: center;
		}
	}
`;

const ReceiptPageSkeleton = () => {
	const setSubmitOverlayVisible = useStore((state) => state.setSubmitOverlayVisible);

	useEffect(() => {
		setSubmitOverlayVisible(true);
	}, [setSubmitOverlayVisible]);

	return (
		<Fragment>
			<Header />
			<div css={mainContent}>
				<Container disablegutters="true" className="container">
					<Grid container spacing={3} className="grid-container">
						<Grid item xs={12} md={7} className="column">
							<Card>
								<Skeleton>
									<Typography variant="h3">thankyou for your purchase</Typography>
								</Skeleton>

								<div className="reciept-message">
									<Skeleton>
										<Typography variant="h5" gutterBottom>
											This transaction will appear on your statement as
											CLKBANK*COM. Your order number is .
										</Typography>
									</Skeleton>
									<Skeleton>
										<Typography variant="body1" gutterBottom>
											A copy of this receipt has been emailed to . If this
											email address is incorrect, please update your contact
											information at our customer support website CLKBank.com.
										</Typography>
									</Skeleton>
								</div>
								<Grid container spacing={3}>
									<Grid item xs={12} sm={6}>
										<div className="personal-info-block">
											<Skeleton>
												<Typography variant="h5" gutterBottom>
													Billing Information
												</Typography>
											</Skeleton>
											<Skeleton>
												<Typography variant="body1">Full Name</Typography>
											</Skeleton>
											<Skeleton>
												<Typography variant="body1">
													fullname@email.com
												</Typography>
											</Skeleton>
										</div>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Skeleton>
											<Typography variant="h5" gutterBottom>
												Shipping Information
											</Typography>
										</Skeleton>
										<Skeleton>
											<Typography variant="body1">fullName</Typography>
										</Skeleton>
										<Skeleton>
											<Typography variant="body1">address</Typography>
										</Skeleton>
									</Grid>
								</Grid>
							</Card>
							<Card>
								<Skeleton>
									<Typography variant="h3">Your Order Details</Typography>
								</Skeleton>

								<div className="reciept-message">
									<Skeleton>
										<Typography className="order-number" variant="h4">
											Order Number: XKFSKFVK7
										</Typography>
									</Skeleton>
									<Skeleton>
										<div className="date-badge">
											<Typography className="order-date">
												Order Date: 7/15/2020
											</Typography>
										</div>
									</Skeleton>
								</div>
								<div className="product-details">
									<Skeleton>
										<div className="product-image"></div>
									</Skeleton>
									<div className="title-type-price">
										<Skeleton>
											<Typography
												variant="h6"
												gutterBottom
												className="product-title"
											>
												product title
											</Typography>
										</Skeleton>
										<Skeleton>
											<Typography variant="body2" className="product-type">
												Digital Product
											</Typography>
										</Skeleton>
									</div>
									<div className="price-quantity-container">
										<Skeleton>
											<Typography variant="body1" className="product-price">
												$19.95
											</Typography>
										</Skeleton>
										<Skeleton>
											<Typography
												variant="body1"
												className="product-quantity"
											>
												Quantity
											</Typography>
										</Skeleton>
									</div>
									<div className="product-buttons">
										<Skeleton>
											<div className="product-button">
												Download or access digital product
											</div>
										</Skeleton>
									</div>
								</div>
							</Card>
						</Grid>
						<Grid item xs={12} md={5} className="column">
							<Card>
								<Skeleton>
									<Typography variant="h3">Customer Service</Typography>
								</Skeleton>
								<Skeleton>
									<Typography>
										Do you have questions about your order or need additional
										help? Please visit our customer support website.
									</Typography>
								</Skeleton>
								<Skeleton>
									<Button
										variant="contained"
										color="primary"
										className="button"
										fullWidth
										target="_blank"
										rel="noopener noreferrer"
									>
										Customer Support Website
									</Button>
								</Skeleton>
								<Skeleton>
									<Typography gutterBottom>
										At our customer support website, you can
									</Typography>
								</Skeleton>

								<Typography component="ul" gutterBottom>
									<Skeleton>
										<Typography variant="body1" component="li">
											Download Digital Products
										</Typography>
									</Skeleton>
									<Skeleton>
										<Typography variant="body1" component="li">
											See Delivery Information
										</Typography>
									</Skeleton>
									<Skeleton>
										<Typography variant="body1" component="li">
											Request a Refund
										</Typography>
									</Skeleton>
									<Skeleton>
										<Typography variant="body1" component="li">
											Open a Customer Support Ticket Using Your Order Number
											and Email Address
										</Typography>
									</Skeleton>
									<Skeleton>
										<Typography variant="body1" component="li">
											Manage Your Subscriptions
										</Typography>
									</Skeleton>
								</Typography>
								<Skeleton>
									<Typography variant="h4">Order Number</Typography>
								</Skeleton>
								<Skeleton>
									<Typography gutterBottom>auashdfk</Typography>
								</Skeleton>
								<Skeleton>
									<Typography variant="h4">Order Email Address</Typography>
								</Skeleton>
								<Skeleton>
									<Typography>fullname@email.com</Typography>
								</Skeleton>
								<Skeleton>
									<Typography className="create-account" variant="h4">
										Create an Account for a More Streamlined Experience
									</Typography>
								</Skeleton>
								<Skeleton>
									<Typography className="account">
										With a ClickBank account you can easily manage account
										information, view your purchase history, and initiate
										customer service requests
									</Typography>
								</Skeleton>
								<Skeleton>
									<Button
										variant="outlined"
										className="button create-account-button"
										fullWidth
										href="https://www.clkbank.com/#!/CreateAccount/"
										target="_blank"
										rel="noopener noreferrer"
									>
										Create a Clickbank customer account
									</Button>
								</Skeleton>
							</Card>
						</Grid>
					</Grid>
				</Container>
			</div>
			<SubFooter />
			<UpdateOverlay visible={true} />
		</Fragment>
	);
};

export default ReceiptPageSkeleton;
