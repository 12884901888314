import CountdownTimerWidget from './countdownTimer/CountdownTimerWidget';
import DividerWidget from './DividerWidget';
import ImageCaptionWidget from './ImageCaptionWidget';
import ImageWidget from './ImageWidget';
import TextWidget from './TextWidget';
import Cart from '@/components/cart/Cart';

export default {
	Cart,
	ImageWidget,
	TextWidget,
	DividerWidget,
	ImageCaptionWidget,
	CountdownTimerWidget,
};
