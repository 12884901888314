import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { useContext } from 'react';

import { SecurityLogosContext } from '@/state/SecurityLogosContext';
import { isDevEnv } from '@/utils/helpers';

const securityLogos = (showBBB) => css`
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	margin: 0;
	text-align: center;
	width: 100%;
	justify-content: ${showBBB ? 'center' : 'space-around'};

	/* margin on this bbb img only makes sense if there is 3 security logos and it remains in the middle */
	img {
		margin: 8px 8px 0px 8px;
		vertical-align: bottom;
	}

	.digicert-container {
		img {
			height: 50px;
		}
	}

	.bbb-anchor {
		display: ${showBBB ? 'inline' : 'none'};
	}

	// note there is old css that was unused and deleted deleted from this file.
	// There is a small chance it will come in handy later. It is in the git history.
`;

const showSecurityLogos = !isDevEnv ? process.env.REACT_APP_TRUSTED_SITE_ENABLED === 'true' : true;

//note: the trustedSite logo does not load locally no matter what the value of trustedSiteEnabled is.
const SecurityLogos = () => {
	const { trustedSiteMarkup, digicertMarkup, showBBB, setShowBBB, loadBBB } =
		useContext(SecurityLogosContext);
	return (
		<div css={securityLogos(showBBB)}>
			{/* Digicert Logo */}
			{showSecurityLogos ? (
				isDevEnv ? (
					<div
						style={{
							height: '63px',
							width: '110px',
							border: '1px solid black',
						}}
					>
						norton
					</div>
				) : (
					<div
						className="digicert-container"
						dangerouslySetInnerHTML={{ __html: digicertMarkup }}
					/>
				)
			) : null}
			{/* BBB logo */}
			{loadBBB ? (
				<a
					href="https://www.bbb.org/us/id/boise/profile/online-shopping/clickbank-1296-5004291/#sealclick"
					target="_blank"
					rel="noreferrer"
					className="bbb-anchor"
				>
					<img
						src="https://seal-alaskaoregonwesternwashington.bbb.org/seals/blue-seal-160-82-bbb-5004291.png"
						alt="ClickBank BBB Business Review"
						height="50"
						onLoad={() => {
							setShowBBB(true);
						}}
					/>
				</a>
			) : null}
			{/* Trusted Site logo -  show in payment info and under submit button for now */}
			{showSecurityLogos ? (
				isDevEnv ? (
					<div style={{ height: '45px', width: '90px', border: '1px solid black' }}>
						trusted site
					</div>
				) : (
					<div dangerouslySetInnerHTML={{ __html: trustedSiteMarkup }} />
				)
			) : null}
		</div>
	);
};

SecurityLogos.propTypes = {
	className: PropTypes.string,
	showUnderSubmitButton: PropTypes.bool,
};

export default SecurityLogos;
