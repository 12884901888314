import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

const FacebookPixels = (props) => {
	const { pixels, page } = props;
	const [sendPageView, setSendPageView] = useState(false);
	const pagesRendered = useRef({});

	// Load Facebook pixel script once on mount
	useEffect(() => {
		/* eslint-disable */
		!(function (f, b, e, v, n, t, s) {
			if (f.fbq) return;
			n = f.fbq = function () {
				n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
			};
			if (!f._fbq) f._fbq = n;
			n.push = n;
			n.loaded = !0;
			n.version = '2.0';
			n.queue = [];
			t = b.createElement(e);
			t.async = !0;
			t.src = v;
			s = b.getElementsByTagName(e)[0];
			s.parentNode.insertBefore(t, s);
		})(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
		/* eslint-enable */
	}, []);

	// Send a page view event whenever the page changes
	useEffect(() => {
		if (page && !pagesRendered.current[page]) {
			setSendPageView(true);
			Object.assign(pagesRendered.current, { [page]: true });
		}
	}, [page]);

	if (sendPageView && pixels?.length) {
		pixels.forEach((pixel) => {
			const img = document.createElement('img');
			img.title = `${pixel.service.name} for ${page}`;
			img.src = pixel.service.srcUrl;
		});
		setSendPageView(false);
	}

	return null;
};

export default FacebookPixels;

FacebookPixels.propTypes = {
	pixels: PropTypes.array,
	page: PropTypes.string,
};
