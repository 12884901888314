export const inputData = {
	AF: {
		code: 'AF',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	AX: {
		code: 'AX',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	AL: {
		code: 'AL',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	DZ: {
		code: 'DZ',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	AS: {
		code: 'AS',
		zipRegex: '^\\d{5}(-{1}\\d{4,6})$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	AD: {
		code: 'AD',
		zipRegex: '^[Aa][Dd]\\d{3}$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	AO: {
		code: 'AO',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	AI: {
		code: 'AI',
		zipRegex: '^[Aa][I][-][2][6][4][0]$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	AG: {
		code: 'AG',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	AR: {
		code: 'AR',
		zipRegex: '^\\d{4}|[A-Za-z]\\d{4}[a-zA-Z]{3}$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	AM: {
		code: 'AM',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	AW: {
		code: 'AW',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	AC: {
		code: 'AC',
		zipRegex: '^[Aa][Ss][Cc][Nn]\\s{0,1}[1][Zz][Zz]$',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	AQ: {
		code: 'AQ',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'always',
			},
			state: {},
		},
	},
	AU: {
		code: 'AU',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'always',
			},
			state: {},
		},
	},
	AT: {
		code: 'AT',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	AZ: {
		code: 'AZ',
		zipRegex: '^[Aa][Zz]\\d{4}$',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	BS: {
		code: 'BS',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	BH: {
		code: 'BH',
		zipRegex: '^\\d{3,4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	BD: {
		code: 'BD',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	BB: {
		code: 'BB',
		zipRegex: '^[Aa][Zz]\\d{5}$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	BY: {
		code: 'BY',
		zipRegex: '^\\d{6}$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	BE: {
		code: 'BE',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	BZ: {
		code: 'BZ',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	BJ: {
		code: 'BJ',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	BM: {
		code: 'BM',
		zipRegex: '^[A-Za-z]{2}\\s([A-Za-z]{2}|\\d{2})$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	BT: {
		code: 'BT',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	BO: {
		code: 'BO',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	BQ: {
		code: 'BQ',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	BA: {
		code: 'BA',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	BW: {
		code: 'BW',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	BR: {
		code: 'BR',
		zipRegex: '^\\d{5}-\\d{3}$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	'': {
		code: '',
		zipRegex: '^[Bb][Ii][Qq]{2}\\s{0,1}[1][Zz]{2}$',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	IO: {
		code: 'IO',
		zipRegex: '^[Bb]{2}[Nn][Dd]\\s{0,1}[1][Zz]{2}$',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	VG: {
		code: 'VG',
		zipRegex: '^[Vv][Gg]\\d{4}$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	BN: {
		code: 'BN',
		zipRegex: '^[A-Za-z]{2}\\d{4}$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	BG: {
		code: 'BG',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	BF: {
		code: 'BF',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	BI: {
		code: 'BI',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	KH: {
		code: 'KH',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	CM: {
		code: 'CM',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	CA: {
		code: 'CA',
		zipRegex:
			'^(?=[^DdFfIiOoQqUu\\d\\s])[A-Za-z]\\d(?=[^DdFfIiOoQqUu\\d\\s])[A-Za-z]\\s{0,1}\\d(?=[^DdFfIiOoQqUu\\d\\s])[A-Za-z]\\d$',
		// allow for CALCULATE_CART to be called when the first three digits are entered
		calculateCartZipRegex:
			'^(?=[^DdFfIiOoQqUu\\d\\s])[A-Za-z]\\d(?=[^DdFfIiOoQqUu\\d\\s])[A-Za-z]\\s{0,1}\\d{0,1}(?=[^DdFfIiOoQqUu\\d\\s]){0,1}[A-Za-z]{0,1}\\d{0,1}$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'always',
			},
			state: {
				required: 'always',
			},
		},
	},
	CV: {
		code: 'CV',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	KY: {
		code: 'KY',
		zipRegex: '^[Kk][Yy]\\d[-\\s]{0,1}\\d{4}$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	CF: {
		code: 'CF',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	TD: {
		code: 'TD',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	CL: {
		code: 'CL',
		zipRegex: '^\\d{3}(-|\\s)?\\d{4}$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	CN: {
		code: 'CN',
		zipRegex: '^\\d{6}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	CX: {
		code: 'CX',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	CC: {
		code: 'CC',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	CO: {
		code: 'CO',
		zipRegex: '^\\d{6}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	KM: {
		code: 'KM',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	CG: {
		code: 'CG',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	CD: {
		code: 'CD',
		zipRegex: '^[Cc][Dd]$',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	CK: {
		code: 'CK',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	CR: {
		code: 'CR',
		zipRegex: '^\\d{4,5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	CI: {
		code: 'CI',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	HR: {
		code: 'HR',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	CU: {
		code: 'CU',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	CW: {
		code: 'CW',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	CY: {
		code: 'CY',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	CZ: {
		code: 'CZ',
		zipRegex: '^([Cc][Zz]-)?\\d{3}(\\s)?\\d{2}$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	DK: {
		code: 'DK',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	DJ: {
		code: 'DJ',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	DM: {
		code: 'DM',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	DO: {
		code: 'DO',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	TL: {
		code: 'TL',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	EC: {
		code: 'EC',
		zipRegex: '^\\d{6}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	SV: {
		code: 'SV',
		zipRegex: '^1101$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	EG: {
		code: 'EG',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	GQ: {
		code: 'GQ',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	ER: {
		code: 'ER',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	EE: {
		code: 'EE',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	ET: {
		code: 'ET',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	FK: {
		code: 'FK',
		zipRegex: '^[Ff][Ii][Qq]{2}\\s{0,1}[1][Zz]{2}$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	FO: {
		code: 'FO',
		zipRegex: '^\\d{3}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	FJ: {
		code: 'FJ',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	FI: {
		code: 'FI',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	FR: {
		code: 'FR',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	GF: {
		code: 'GF',
		zipRegex: '^973\\d{2}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	PF: {
		code: 'PF',
		zipRegex: '^987\\d{2}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	TF: {
		code: 'TF',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	GA: {
		code: 'GA',
		zipRegex: '^\\d{2}\\s[a-zA-Z-_ ]\\s\\d{2}$',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	GM: {
		code: 'GM',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	GE: {
		code: 'GE',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	DE: {
		code: 'DE',
		zipRegex: '^\\d{4,5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	GH: {
		code: 'GH',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	GI: {
		code: 'GI',
		zipRegex: '^[Gg][Xx][1]{2}\\s{0,1}[1][Aa]{2}$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	GR: {
		code: 'GR',
		zipRegex: '^\\d{3}\\s{0,1}\\d{2}$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	GL: {
		code: 'GL',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	GD: {
		code: 'GD',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	GP: {
		code: 'GP',
		zipRegex: '^971\\d{2}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	GU: {
		code: 'GU',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	GT: {
		code: 'GT',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	GG: {
		code: 'GG',
		zipRegex: '^[A-Za-z]{2}\\d\\s{0,1}\\d[A-Za-z]{2}$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	GN: {
		code: 'GN',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	GW: {
		code: 'GW',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	GY: {
		code: 'GY',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	HT: {
		code: 'HT',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	HM: {
		code: 'HM',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	HN: {
		code: 'HN',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	HK: {
		code: 'HK',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	HU: {
		code: 'HU',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	IS: {
		code: 'IS',
		zipRegex: '^\\d{3}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	IC: {
		code: 'IC',
		zipRegex: '^\\d{3}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	IN: {
		code: 'IN',
		zipRegex: '^\\d{6}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	ID: {
		code: 'ID',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	IR: {
		code: 'IR',
		zipRegex: '^\\d{5}-\\d{5}$',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	IQ: {
		code: 'IQ',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	IE: {
		code: 'IE',
		zipRegex: '^[A-Z0-9]{3} [A-Z0-9]{4}$',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	IM: {
		code: 'IM',
		zipRegex: '^[Ii[Mm]\\d{1,2}\\s\\d\\[A-Z]{2}$',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	IL: {
		code: 'IL',
		zipRegex: '^\\b\\d{5}(\\d{2})?$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	IT: {
		code: 'IT',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	JM: {
		code: 'JM',
		zipRegex: '^\\d{2}$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	JP: {
		code: 'JP',
		zipRegex: '^\\d{3}(-)?\\d{4}$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	JE: {
		code: 'JE',
		zipRegex: '^[Jj][Ee]\\d\\s{0,1}\\d[A-Za-z]{2}$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	JO: {
		code: 'JO',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	KZ: {
		code: 'KZ',
		zipRegex: '^\\d{6}$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	KE: {
		code: 'KE',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	KI: {
		code: 'KI',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	KP: {
		code: 'KP',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	KR: {
		code: 'KR',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	XK: {
		code: 'XK',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	KW: {
		code: 'KW',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	KG: {
		code: 'KG',
		zipRegex: '^\\d{6}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	LV: {
		code: 'LV',
		zipRegex: '^[Ll][Vv][- ]{0,1}\\d{4}$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	LA: {
		code: 'LA',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	LB: {
		code: 'LB',
		zipRegex: '^\\d{4}\\s{0,1}\\d{4}$',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	LS: {
		code: 'LS',
		zipRegex: '^\\d{3}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	LR: {
		code: 'LR',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	LY: {
		code: 'LY',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	LI: {
		code: 'LI',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	LT: {
		code: 'LT',
		zipRegex: '^[Ll][Tt][- ]{0,1}\\d{5}$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	LU: {
		code: 'LU',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	MO: {
		code: 'MO',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	MK: {
		code: 'MK',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	MG: {
		code: 'MG',
		zipRegex: '^\\d{3}$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	MW: {
		code: 'MW',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	MV: {
		code: 'MV',
		zipRegex: '^\\d{4,5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	MY: {
		code: 'MY',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	ML: {
		code: 'ML',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	MT: {
		code: 'MT',
		zipRegex: '^[A-Za-z]{3}\\s{0,1}\\d{4}$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	MH: {
		code: 'MH',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	MR: {
		code: 'MR',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	MU: {
		code: 'MU',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	MQ: {
		code: 'MQ',
		zipRegex: '^972\\d{2}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	MX: {
		code: 'MX',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	YT: {
		code: 'YT',
		zipRegex: '^976\\d{2}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	FM: {
		code: 'FM',
		zipRegex: '^\\d{5}(-{1}\\d{4})?$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	MD: {
		code: 'MD',
		zipRegex: '^[Mm][Dd][- ]{0,1}\\d{4}$',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	MC: {
		code: 'MC',
		zipRegex: '^980\\d{2}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	MN: {
		code: 'MN',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	ME: {
		code: 'ME',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	MS: {
		code: 'MS',
		zipRegex: '^[Mm][Ss][Rr]\\s{0,1}\\d{4}$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	MA: {
		code: 'MA',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	MZ: {
		code: 'MZ',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	MM: {
		code: 'MM',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	NA: {
		code: 'NA',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	NR: {
		code: 'NR',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	NP: {
		code: 'NP',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	NL: {
		code: 'NL',
		zipRegex: '^\\d{4}\\s{0,1}[A-Za-z]{2}$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	NC: {
		code: 'NC',
		zipRegex: '^988\\d{2}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	NZ: {
		code: 'NZ',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	NI: {
		code: 'NI',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	NE: {
		code: 'NE',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	NG: {
		code: 'NG',
		zipRegex: '^\\d{6}$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	NU: {
		code: 'NU',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	NF: {
		code: 'NF',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	MP: {
		code: 'MP',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	NO: {
		code: 'NO',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	OM: {
		code: 'OM',
		zipRegex: '^\\d{3}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	PK: {
		code: 'PK',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	PW: {
		code: 'PW',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	PA: {
		code: 'PA',
		zipRegex: '^\\d{6}$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	PG: {
		code: 'PG',
		zipRegex: '^\\d{3}$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	PY: {
		code: 'PY',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	PE: {
		code: 'PE',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	PH: {
		code: 'PH',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	PN: {
		code: 'PN',
		zipRegex: '^[Pp][Cc][Rr][Nn]\\s{0,1}[1][Zz]{2}$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	PL: {
		code: 'PL',
		zipRegex: '^\\d{2}[- ]{0,1}\\d{3}$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	PT: {
		code: 'PT',
		zipRegex: '^\\d{4}([- ]{0,1}\\d{3})?$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	PR: {
		code: 'PR',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	QA: {
		code: 'QA',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	RE: {
		code: 'RE',
		zipRegex: '^974\\d{2}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	RO: {
		code: 'RO',
		zipRegex: '^\\d{6}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	RU: {
		code: 'RU',
		zipRegex: '^\\d{6}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	BL: {
		code: 'BL',
		zipRegex: '^97133$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	SH: {
		code: 'SH',
		zipRegex: '^[Ss][Tt][Hh][Ll]\\s{0,1}[1][Zz]{2}$',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	KN: {
		code: 'KN',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	LC: {
		code: 'LC',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	MF: {
		code: 'MF',
		zipRegex: '^97150$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	PM: {
		code: 'PM',
		zipRegex: '^97500$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	VC: {
		code: 'VC',
		zipRegex: '^[Vv][Cc]\\d{4}$',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	SM: {
		code: 'SM',
		zipRegex: '^4789\\d$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	ST: {
		code: 'ST',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	SA: {
		code: 'SA',
		zipRegex: '^\\d{5}(-{1}\\d{4})?$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	SN: {
		code: 'SN',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	RS: {
		code: 'RS',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	SC: {
		code: 'SC',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	SX: {
		code: 'SX',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	SL: {
		code: 'SL',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	SG: {
		code: 'SG',
		zipRegex: '^\\d{2,6}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	SK: {
		code: 'SK',
		zipRegex: '^([Ss][Kk]-)?\\d{3}(\\s)?\\d{2}$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	SI: {
		code: 'SI',
		zipRegex: '^([Ss][Ii][- ]{0,1}){0,1}\\d{4}$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	SB: {
		code: 'SB',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	SO: {
		code: 'SO',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	ZA: {
		code: 'ZA',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	GS: {
		code: 'GS',
		zipRegex: '^[Ss][Ii][Qq]{2}\\s{0,1}[1][Zz]{2}$',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	ES: {
		code: 'ES',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	LK: {
		code: 'LK',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	SD: {
		code: 'SD',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	SR: {
		code: 'SR',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	SZ: {
		code: 'SZ',
		zipRegex: '^[A-Za-z]\\d{3}$',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	SE: {
		code: 'SE',
		zipRegex: '^\\d{3}\\s*\\d{2}$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	CH: {
		code: 'CH',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	SJ: {
		code: 'SJ',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	SY: {
		code: 'SY',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	TW: {
		code: 'TW',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
			},
			state: {},
		},
	},
	TJ: {
		code: 'TJ',
		zipRegex: '^\\d{6}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	TZ: {
		code: 'TZ',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	TH: {
		code: 'TH',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	TG: {
		code: 'TG',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	TK: {
		code: 'TK',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	TO: {
		code: 'TO',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	TT: {
		code: 'TT',
		zipRegex: '^\\d{6}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	TN: {
		code: 'TN',
		zipRegex: '^\\d{4}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	TR: {
		code: 'TR',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	TM: {
		code: 'TM',
		zipRegex: '^\\d{6}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	TC: {
		code: 'TC',
		zipRegex: '^[Tt][Kk][Cc][Aa]\\s{0,1}[1][Zz]{2}$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	TV: {
		code: 'TV',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	UG: {
		code: 'UG',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	UA: {
		code: 'UA',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	AE: {
		code: 'AE',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	GB: {
		code: 'GB',
		zipRegex: '^[A-Z]{1,2}[0-9R][0-9A-Z]?\\s*[0-9][ABDEFGHJLNPQRSTUWXYZ]{2}',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'always',
			},
			state: {},
		},
	},
	US: {
		code: 'US',
		zipRegex: '^\\b\\d{5}\\b(?:[- ]{1}\\d{4})?$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'always',
			},
			state: {
				required: 'always',
			},
		},
	},
	UY: {
		code: 'UY',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	VI: {
		code: 'VI',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	UZ: {
		code: 'UZ',
		zipRegex: '^\\d{3} \\d{3}$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	VU: {
		code: 'VU',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	VA: {
		code: 'VA',
		zipRegex: '^120$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	VE: {
		code: 'VE',
		zipRegex: '^\\d{4}(\\s[a-zA-Z]{1})?$',
		fields: {
			zip: {
				inputMode: 'text',
				required: 'isShippable',
			},
			state: {},
		},
	},
	VN: {
		code: 'VN',
		zipRegex: '^\\d{6}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	WF: {
		code: 'WF',
		zipRegex: '^986\\d{2}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	YE: {
		code: 'YE',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
	ZM: {
		code: 'ZM',
		zipRegex: '^\\d{5}$',
		fields: {
			zip: {
				inputMode: 'numeric',
				required: 'isShippable',
			},
			state: {},
		},
	},
	ZW: {
		code: 'ZW',
		zipRegex: '',
		fields: {
			zip: {
				inputMode: 'text',
			},
			state: {},
		},
	},
};
