import { css } from '@emotion/react';
import { Checkbox, FormControlLabel, FormHelperText } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getZoneStyles } from '@/components/mainContent/contentStyle';
import { useFormStore, useStore } from '@/state/stores';
import { defaultInputBorderColor, defaultInputFocusBorderColor } from '@/theme';
import { deviceType } from '@/utils/deviceType';

const termsCheckboxStyles = (cartStyles) => {
	const { body } = cartStyles || {};
	return css`
		display: flex;
		flex-direction: column;
		margin-bottom: 0.75rem;

		.MuiFormControlLabel-root {
			display: flex;
			align-items: flex-start;
			margin: 4px 0 8px 0;
		}

		.label {
			${body?.fontWeight ? `font-weight: ${body.fontWeight};` : ''}
			${body?.fontColor ? `color: ${body.fontColor};` : ''}
			${body?.fontFamily ? `font-family: ${body.fontFamily};` : ''}
			position: relative;
			top: -3px;
		}

		.checkbox {
			margin-right: 8px;
			margin-top: 2px;
			border-radius: 0px;
			height: 12px;
			width: 12px;
			padding: 0px;
			background-color: ${body?.inputBackgroundColor || 'white'};
			margin-right: 8px;
			color: ${body?.inputBorderColor || defaultInputBorderColor};

			&.checked {
				color: ${body?.inputBorderSelectedColor || defaultInputFocusBorderColor};
			}
		}

		.Mui-error {
			margin-top: 0;
		}
	`;
};

const SubscriptionTermsCheckbox = () => {
	const { t } = useTranslation(['checkout']);
	const { value, toggleRecurringChargeAgreement, formFieldIsValid, formError } = useFormStore(
		(state) => ({
			value: state.formData.subscriptionTerms || false,
			toggleRecurringChargeAgreement: state.toggleRecurringChargeAgreement,
			formFieldIsValid: state.formFieldIsValid,
			formError: state.formErrors?.subscriptionTerms || false,
		}),
	);
	const { template, urlVars } = useStore((state) => ({
		template: state.template,
		urlVars: state.urlVars,
	}));
	const cartStyles = getZoneStyles(template, 'cart');
	const isMobile = deviceType();

	const handleCheck = (ev) => {
		const checked = ev.target.checked;
		toggleRecurringChargeAgreement(checked);
		formFieldIsValid('subscriptionTerms', checked, true);
	};

	useEffect(() => {
		if (urlVars.cbrblaccpt === 'true') {
			toggleRecurringChargeAgreement(true);
		}
	}, [urlVars, toggleRecurringChargeAgreement]);

	const errorObject = formError || {};
	const errorMessage = errorObject.message;
	return (
		<div css={termsCheckboxStyles(cartStyles)}>
			<div className="terms-checkbox-label-container">
				<FormControlLabel
					control={
						<Checkbox
							tabIndex={0}
							id="subscriptionTerms"
							name="subscriptionTerms"
							color="primary"
							disableRipple
							checked={value}
							onChange={handleCheck}
						/>
					}
					htmlFor="subscriptionTerms"
					label={
						isMobile
							? t('form.checkbox.subscription-terms-mobile')
							: t('form.checkbox.subscription-terms')
					}
				/>
			</div>
			{!!errorMessage && (
				<FormHelperText className="Mui-error">{t(errorMessage)}</FormHelperText>
			)}
		</div>
	);
};

export default SubscriptionTermsCheckbox;
