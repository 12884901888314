import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

export const i18nInstance = i18n
	// load translation using xhr -> see /public/locales
	// learn more: https://github.com/i18next/i18next-xhr-backend
	.use(Backend)
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		fallbackLng: 'en',
		debug: false,
		backend: { loadPath: process.env.REACT_APP_LOCALES_PATH },
		whitelist: ['en', 'de', 'es', 'fr', 'it', 'pt'],
		ns: ['checkout', 'common', 'countries', 'order-received'],
		defaultNS: 'common',
		interpolation: {
			escapeValue: false,
		},
		detection: { lookupQuerystring: 'locale' },
	});

export default i18n;
