export const PaymentMethods = {
	PAYPAL: 'payPal',
	PAZE: 'paze',
	APPLE_PAY: 'applePay',
	CREDIT: 'credit',
	TEST: 'test',
};

export const paymentMethodCurrencyDataMap = {
	[PaymentMethods.CREDIT]: 'CREDIT_CARD',
	[PaymentMethods.PAYPAL]: 'PAYPAL',
	[PaymentMethods.APPLE_PAY]: 'APPLE_PAY',
	[PaymentMethods.PAZE]: 'PAZE',
};

/**
 * Enum for the different layouts.
 * @readonly
 * @enum {string}
 */
export const Layouts = {
	STANDARD: 'STANDARD', //Individual Sections
	STANDARD_INPUT_LEFT_BUTTON_LEFT: 'STANDARD_INPUT_LEFT_BUTTON_LEFT', // Two Columns 1
	STANDARD_INPUT_RIGHT_BUTTON_RIGHT: 'STANDARD_INPUT_RIGHT_BUTTON_RIGHT', // Two Columns 2
	JOINED: 'JOINED', // One Form 1
	JOINED_INPUT_LEFT_BUTTON_LEFT: 'JOINED_INPUT_LEFT_BUTTON_LEFT', // One Form 2
	JOINED_INPUT_RIGHT_BUTTON_RIGHT: 'JOINED_INPUT_RIGHT_BUTTON_RIGHT', // One Form 3
};

/**
 * Enum for the different token providers that we use for the receipt page.
 * @readonly
 * @enum {string}
 */
export const TokenProviders = {
	PAYPAL_COMMERCE: 'paypal_commerce',
	PAYPAL_EXPRESS: 'paypal_express',
	APPLE_PAY: 'apple',
	PAZE: 'paze',
};

// Make sure to leave this as an empty object if the feature flags are removed. Shape should be - { [key: string]: string }
export const FeatureFlags = {};

export const WidgetVisibility = {
	MOBILE: 'MOBILE',
	TABLET: 'TABLET',
	DESKTOP: 'DESKTOP',
	MOBILE_2: 'MOBILE_2',
	TABLET_2: 'TABLET_2',
	DESKTOP_2: 'DESKTOP_2',
};

export const CountdownTimerActionType = {
	PAYLINK: 'PAYLINK',
	HIDE_TIMER: 'HIDE_TIMER',
	NOT_AVAILABLE: 'NOT_AVAILABLE',
};
