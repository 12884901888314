import { useManualQuery } from 'graphql-hooks';
import PropTypes from 'prop-types';
import { createContext, useCallback, useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { ADDRESS_SEARCH_QUERY } from '@/graphql/queries';
import { useFormStore, useStore } from '@/state/stores';
import { inputData } from '@/utils/regexAndRequiredInputsByCountry';

export const FindLocationContext = createContext({});

export const FindLocationContextProvider = ({ children }) => {
	const [fetchCities] = useManualQuery(ADDRESS_SEARCH_QUERY);

	const { setCities, setLocationLoading, setIsCartUpdating, kountSessionId } = useStore(
		useShallow((state) => ({
			setCities: state.setCities,
			setLocationLoading: state.setLocationLoading,
			setIsCartUpdating: state.setIsCartUpdating,
			kountSessionId: state.kountSessionId,
		})),
	);

	const { billingCountry, shippingCountry, clearFieldError, setFormValue } = useFormStore(
		useShallow((state) => ({
			billingCountry: state.formData.billing.countryCode,
			shippingCountry: state.formData.shipping.countryCode,
			clearFieldError: state.clearFieldError,
			setFormValue: state.setFormValue,
		})),
	);

	/**
	 *
	 * @param {'billing' | 'shipping'} locationAccessor - billing or shipping
	 * @description Uses the zip/postal code to fetch cities (US) and states/provinces (US and CA)
	 */
	const fetchAndLoadLocationData = useCallback(
		(locationAccessor) => async (ev) => {
			if (!locationAccessor) {
				throw new Error('locationAccessor is required in fetchAndLoadLocationData');
			}
			const zip = ev.target.value;
			const country = locationAccessor === 'billing' ? billingCountry : shippingCountry;
			if (
				!['US', 'CA'].includes(country) ||
				!new RegExp(inputData[country].zipRegex).test(zip)
			) {
				return;
			}

			setLocationLoading(true, locationAccessor);
			setIsCartUpdating(true);

			const { data } = await fetchCities({
				variables: {
					addressSearchRequest: {
						orderIdentifier: kountSessionId,
						searchZipCode: zip,
						searchCountry: billingCountry,
					},
				},
			});

			const { addressSearch } = data || {};
			const cities = addressSearch?.map(({ city }) => city);
			const states = Array.from(new Set(addressSearch?.map(({ state }) => state)));

			// populate city input
			if (cities?.length === 1) {
				setFormValue(`${locationAccessor}.city`, cities[0]);
				// clear city options in case they existed before
				setCities(
					[
						{
							billing: null,
							shipping: null,
						},
					],
					locationAccessor,
				);
				clearFieldError(`${locationAccessor}.city`);
			} else if (cities?.length > 1) {
				// clear city input if there are multiple cities
				setFormValue(`${locationAccessor}.city`, '');
				// populate city options
				setCities(
					addressSearch.map(({ city }) => ({
						label: city,
						value: city,
					})),
					locationAccessor,
				);
			}

			// populate state input
			if (states?.length === 1) {
				setFormValue(`${locationAccessor}.state`, states[0]);
				clearFieldError(`${locationAccessor}.state`);
			}

			setLocationLoading(false, locationAccessor);
			setIsCartUpdating(false);
		},
		[
			billingCountry,
			clearFieldError,
			fetchCities,
			kountSessionId,
			setCities,
			setFormValue,
			setIsCartUpdating,
			setLocationLoading,
			shippingCountry,
		],
	);

	const ctx = useMemo(() => ({ fetchAndLoadLocationData }), [fetchAndLoadLocationData]);

	return <FindLocationContext.Provider value={ctx}>{children}</FindLocationContext.Provider>;
};

FindLocationContextProvider.propTypes = {
	children: PropTypes.node,
};
