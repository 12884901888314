import { WidgetVisibility } from '@/utils/enums';

export const breakpoints = {
	values: {
		xs: 0,
		xsm: 415,
		sm: 700, //this one is custom.  default is 600
		md: 900,
		lmd: 1015, //another custom, large medium
		lg: 1200,
		xl: 1536,
	},
};

// Note: these breakpoint maps are only used for showing/hiding widgets for custom templates
// original breakpoints left in place for backward compatibility
export const breakpointMap = {
	[WidgetVisibility.MOBILE]: '@media (min-width: 0px) and (max-width: 699px)',
	[WidgetVisibility.TABLET]: '@media (min-width: 700px) and (max-width: 1199px)',
	[WidgetVisibility.DESKTOP]: '@media (min-width: 1200px)',
};

// Version 2 breakpoints
export const v2BreakPointMap = {
	[WidgetVisibility.MOBILE_2]: '@media (max-width: 414px)',
	[WidgetVisibility.TABLET_2]: '@media (min-width: 415px) and (max-width: 699px)',
	[WidgetVisibility.DESKTOP_2]: '@media (min-width: 700px)',
};
