import { css } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useStore } from '@/state/stores';
import { cbNeutral, checkoutTheme } from '@/theme';

const styles = css`
	h2,
	p {
		text-align: center;
	}

	.MuiDialogActions-root {
		justify-content: center;
		padding-bottom: 1rem;
		${checkoutTheme.breakpoints.down('sm')} {
			flex-direction: column-reverse;
		}
	}

	.MuiDialog-paper {
		max-width: 590px;
	}

	.buy-it-again-button {
		margin-left: 40px;
		${checkoutTheme.breakpoints.down('sm')} {
			margin: 0 0 16px 0;
		}
	}

	.close-icon {
		position: absolute;
		top: 10px;
		right: 10px;
		font-size: 1.25rem;
		color: ${cbNeutral[400]};
	}
`;

const DuplicateOrderModal = ({ isOpen, setIsOpen, handleSubmit, submitterId }) => {
	const { t } = useTranslation('checkout');

	const setUserHasAcceptedDuplicate = useStore((state) => state.setUserHasAcceptedDuplicate);

	const handleContinue = (e) => {
		setUserHasAcceptedDuplicate(true);
		handleSubmit(e, submitterId);
		setIsOpen(false);
	};

	return (
		<Dialog css={styles} aria-labelledby="customized-dialog-title" open={isOpen}>
			<DialogTitle className="title" variant="h4">
				{t('duplicate-order.buy-again')}
				<IconButton
					onClick={() => setIsOpen(false)}
					aria-label="close"
					className="close-icon"
					size="large"
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Typography>{t('duplicate-order.already-purchased')}</Typography>
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					onClick={() => setIsOpen(false)}
					// TODO add receiptNo param
					href="https://clkbank.com"
				>
					{t('duplicate-order.find-previous-order')}
				</Button>
				<Button className="buy-it-again-button" onClick={handleContinue}>
					{t('duplicate-order.buy-it-again-button')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DuplicateOrderModal;

DuplicateOrderModal.propTypes = {
	submitterId: PropTypes.string,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
};
