import { v4 as uuidv4 } from 'uuid';

import {
	fetchUrl,
	fetchWithRetry,
	getApplePayDataFromGeoCurrencies,
	getPazeDataFromGeoCurrencies,
} from './fetchHelpers';
import { ADDRESS_SEARCH_QUERY, GEO_CURRENCIES } from '@/graphql/queries';
import { i18nInstance } from '@/i18n';
import { defaultPazeData } from '@/state/stores/globalStore';
import { paymentMethodCurrencyDataMap, PaymentMethods } from '@/utils/enums';
import { checkProductComboErrors } from '@/utils/errorHandler';
import { isPreview } from '@/utils/helpers';

const getDefaultCountry = (countries) => {
	const explicitDefault = countries.find((country) => country.default);
	if (explicitDefault) {
		return explicitDefault;
	}
	const unitedStates = countries.find((country) => country.countryCode === 'US');
	if (unitedStates) {
		return unitedStates;
	}
	return countries[0];
};

const getGeoCurrenciesQuery =
	({ urlVars, fetchHeaders, rebuildFormData }, addressSearchId) =>
	async (prevData) => {
		try {
			const { graphql, state: prevState } = prevData;
			const affiliate = graphql.AFFILIATE?.affiliate?.name || null;
			const { allSKUs } = prevState;
			const { countryCode: formRebuildCountry, state: formRebuildState } =
				rebuildFormData || {};
			let state = { ...prevState };
			let body = await fetchWithRetry('GEO_CURRENCIES', fetchUrl, {
				method: 'POST',
				headers: fetchHeaders,
				body: JSON.stringify({
					query: GEO_CURRENCIES,
					variables: {
						vendorId: urlVars.vvvv,
						skus: allSKUs,
						affiliate,
						urlParams: window.location.search,
					},
				}),
			});
			if (body.error) {
				// Query may error because of a bad product combo. If this is the case,
				// remove the bad products and attempt request again
				const badProducts = checkProductComboErrors(body.error);
				if (badProducts) {
					const { state: prevState } = prevData;
					const goodProducts = prevState.allProducts.filter((product) => {
						return !badProducts[product.id];
					});
					const goodCartStatus = prevState.cartStatus.filter(
						(status) => !badProducts[`${urlVars.vvvv}-${status.sku}`],
					);
					const goodAvailableItems = prevState.availableItems.filter(
						(item) => !badProducts[item.productId],
					);
					const goodSkus = prevState.allSKUs.filter(
						(sku) => !badProducts[`${urlVars.vvvv}-${sku}`],
					);
					const goodCartLineItems = prevState.calculateCartLineItems.filter(
						(item) => !badProducts[item.productId],
					);
					state.allProducts = goodProducts;
					state.cartStatus = goodCartStatus;
					state.availableItems = goodAvailableItems;
					state.allSKUs = goodSkus;
					state.calculateCartLineItems = goodCartLineItems;
					body = await fetchWithRetry('GEO_CURRENCIES', fetchUrl, {
						method: 'POST',
						headers: fetchHeaders,
						body: JSON.stringify({
							query: GEO_CURRENCIES,
							variables: {
								vendorId: urlVars.vvvv,
								skus: goodProducts.map((product) => product.sku),
								affiliate,
								urlParams: window.location.search,
							},
						}),
					});
				}
			}
			if (body.error) {
				// Check again, after any potential combo errors have been handled
				throw body;
			}
			const t = await i18nInstance;
			const { data } = body;
			const { country, ctry } = urlVars;
			const geoCurrenciesData = data.geoCurrencies;
			// if a valid country is in the urlVars, or we were given data to rebuild cart,
			// that will override the default returned from geoCurrencies
			const initialCountryString = formRebuildCountry || country || ctry;
			const initialDefaultCountry =
				initialCountryString &&
				geoCurrenciesData.countries.find(
					(item) => item.countryCode === initialCountryString.toUpperCase(),
				);

			const defaultCountry =
				initialDefaultCountry || getDefaultCountry(geoCurrenciesData.countries);

			const isPayPalDirectEnabled = defaultCountry?.currencies.some(
				(currency) =>
					currency.key === 'PAYPAL' &&
					currency.value.length > 0 &&
					currency.value.some((value) => value.accepted.includes('payPal')),
			);

			const isPayPalCommerceEnabled = defaultCountry?.currencies.some(
				(currency) =>
					currency.key === 'PAYPAL' &&
					currency.value.length > 0 &&
					currency.value.some((value) => value.accepted.includes('payPalCommerce')),
			);

			const payPalEnabled = isPayPalDirectEnabled || isPayPalCommerceEnabled;
			const payPalCommerceEnabled = isPayPalCommerceEnabled;

			let paymentMethod = 'credit';
			if (rebuildFormData || (urlVars.paymentMethod === 'pypl' && payPalEnabled)) {
				paymentMethod = 'payPal';
			}

			const isShippable = geoCurrenciesData.forceShipping;
			const showPhone = data.geoCurrencies?.phoneNumberRequired || false;
			let selectedCurrency = 'USD';
			const defaultCountryCurrencies = defaultCountry?.currencies;

			const creditData = defaultCountryCurrencies.find(
				(currency) => currency.key === paymentMethodCurrencyDataMap[PaymentMethods.CREDIT],
			);

			const cardsAccepted = (() => {
				if (!creditData) {
					return [];
				}
				return creditData.value.find((currency) => currency.default).accepted || [];
			})();

			const defaultCurrencyData =
				paymentMethod === PaymentMethods.CREDIT
					? creditData
					: defaultCountryCurrencies.find(
							(currency) =>
								currency.key === paymentMethodCurrencyDataMap[paymentMethod],
						);

			const currencyCodes = defaultCurrencyData.value.map((currency) => {
				if (currency.default) {
					selectedCurrency = currency.code;
				}
				return currency.code;
			});

			const countries = geoCurrenciesData.countries;

			const addressSearchQueryData = await (async () => {
				// Initialize as bare minimum value, without any modification
				try {
					const calculateCartAddressVars = {
						countryCode: defaultCountry.countryCode,
						zip: null,
						city: null,
						state: null,
					};

					// If we have have a zip, and country is US or CA, double-check that the zip is
					// valid and, if so, send the query and fill in applicable state keys
					const zip = urlVars.zipcode || urlVars.zipc || urlVars.st_zip;
					let zipIsValid = false;
					const { countryCode } = defaultCountry;
					if (['US', 'CA'].includes(countryCode) && zip) {
						const zipRegex =
							countryCode === 'US'
								? new RegExp('^\\b\\d{5}\\b(?:[- ]{1}\\d{4})?$')
								: new RegExp('^[A-Za-z]\\d[A-Za-z][ -]?\\d[A-Za-z]\\d$');
						if (zipRegex.test(zip)) {
							zipIsValid = true;
						}
					}
					const shouldGetTaxAndLocationByZip = zipIsValid;
					if (shouldGetTaxAndLocationByZip) {
						const response = await fetchWithRetry('ADDRESS_SEARCH_QUERY', fetchUrl, {
							method: 'POST',
							headers: fetchHeaders,
							body: JSON.stringify({
								query: ADDRESS_SEARCH_QUERY,
								variables: {
									addressSearchRequest: {
										orderIdentifier: addressSearchId,
										searchZipCode: zip,
										searchCountry: defaultCountry.countryCode,
									},
								},
							}),
						});
						if (response.error) {
							return {
								graphql: {
									FIND_LOCATION_BILLING: response,
								},
								state: {
									calculateCartAddressVars,
									stateOptions: {
										billing: null,
										shipping: null,
									},
									cityOptions: {
										billing: null,
										shipping: null,
									},
								},
								errors: {
									FIND_LOCATION_BILLING: response.error,
								},
							};
						}
						const { addressSearch } = response.data;
						calculateCartAddressVars.zip = zip;
						const state = {
							calculateCartAddressVars,
						};

						const cities = addressSearch.map((address) => address.city);
						if (cities) {
							state.cityOptions = {
								billing: cities.map((city) => ({
									value: city,
									label: city,
								})),
								shipping: null,
							};
							const initialCityString = urlVars.st_city;
							const matchingCity =
								initialCityString &&
								cities.find(
									(item) =>
										item.toLowerCase() === initialCityString.toLowerCase(),
								);
							if (matchingCity) {
								calculateCartAddressVars.city = matchingCity;
							} else if (cities.length === 1) {
								calculateCartAddressVars.city = cities[0];
							}
						} else {
							state.cityOptions = {
								billing: null,
								shipping: null,
							};
						}
						const states = Array.from(
							new Set(addressSearch.map((address) => address.state)),
						);
						if (states) {
							state.stateOptions = {
								billing: states.map((state) => ({
									value: state,
									label: state,
								})),
								shipping: null,
							};
							const initialStateString = formRebuildState;
							const matchingState =
								initialStateString &&
								states.find(
									(item) =>
										item.toLowerCase() === initialStateString.toLowerCase(),
								);
							if (matchingState) {
								calculateCartAddressVars.state = matchingState;
							} else if (states.length === 1) {
								calculateCartAddressVars.state = states[0];
							}
						} else {
							state.stateOptions = {
								billing: null,
								shipping: null,
							};
						}
						return {
							graphql: {
								FIND_LOCATION_BILLING: data,
							},
							state,
						};
					}
					return {
						graphql: {},
						state: {
							calculateCartAddressVars,
							stateOptions: {
								billing: null,
								shipping: null,
							},
							cityOptions: {
								billing: null,
								shipping: null,
							},
						},
					};
				} catch (err) {
					console.error('Error fetching ADDRESS_SEARCH_QUERY data:', err);
				}
			})();

			const forceGdpr =
				geoCurrenciesData.forceGdpr ||
				(geoCurrenciesData.countries &&
					geoCurrenciesData.countries.every((country) => country.gdprMember));

			const regulations = (() => {
				const activeRegulations = [];
				// Check if geoCurrenciesData indicates a regulation should be added
				const { regulation: incomingRegulation } = geoCurrenciesData;
				if (incomingRegulation === 'CCPA') {
					activeRegulations.push({
						key: 'CCPA',
						value: 'CCPA',
						checked: false,
					});
				} else if (incomingRegulation === 'GDPR' || forceGdpr) {
					activeRegulations.push({
						key: 'GDPR',
						value: 'GDPR',
						checked: false,
					});
				}
				if (activeRegulations.length > 0) {
					// If we already have a regulation, we can return, as we know
					// there can only be a max of one on initial form load
					return activeRegulations;
				}
				// Construct regulations array from country data if geoCurrenciesData
				// does not tell us what regulation to start with
				const { regulations: incomingRegulations } = defaultCountry;
				if (!Array.isArray(incomingRegulations)) {
					return [];
				}
				incomingRegulations.forEach((item) => {
					if (!activeRegulations.find((reg) => reg.value === item.value)) {
						const newItem = {
							key: item.key,
							value: item.value,
							checked: false,
						};
						// Initial US state can only be set during form rebuild
						const meetsCCPACriteria =
							item.value === 'CCPA' &&
							defaultCountry.countryCode === 'US' &&
							formRebuildState?.toUpperCase() === 'CA';
						const meetsGDPRCriteria = item.value === 'GDPR';
						if (meetsCCPACriteria || meetsGDPRCriteria) {
							activeRegulations.push(newItem);
						}
					}
				});
				return activeRegulations;
			})();

			const newPazeData = getPazeDataFromGeoCurrencies(defaultCountry, geoCurrenciesData, {
				...defaultPazeData,
				uuid: uuidv4(),
			});
			const newApplePayData = await getApplePayDataFromGeoCurrencies(
				defaultCountry,
				geoCurrenciesData,
				selectedCurrency,
				isPreview,
			);

			if (paymentMethod === PaymentMethods.CREDIT && newApplePayData.defaultToApplePay) {
				// PayPal takes precedence as it is only set for rebuild or url var.
				paymentMethod = PaymentMethods.APPLE_PAY;
			}

			const countryOptions = countries.map((country) => ({
				value: country.countryCode,
				label: t(`countries:countries.${country.countryCode}`),
			}));

			state = {
				...state,
				defaultCountry,
				calculateCartAddressVars: {
					countryCode: defaultCountry.countryCode,
				},
				selectedCountryData: {
					billing: defaultCountry,
					shipping: defaultCountry,
				},
				paymentMethod,
				payPalEnabled,
				payPalCommerceEnabled,
				isPayPalDirectEnabled,
				isShippable,
				forceGdpr,
				regulations,
				countryOptions,
				cardsAccepted,
				currencyCodes,
				currencyData: defaultCurrencyData,
				selectedCurrency,
				displayCurrency: selectedCurrency,
				showPhone,
				applePayData: newApplePayData,
				pazeData: newPazeData,
			};
			return {
				...prevData,
				graphql: {
					...graphql,
					GEO_CURRENCIES: data,
				},
				state: {
					...prevState,
					...addressSearchQueryData.state,
					...state,
				},
			};
		} catch (body) {
			return Promise.reject({ errors: { GEO_CURRENCIES: body } });
		}
	};

export default getGeoCurrenciesQuery;
