import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { QuantityInput } from '..';
import ProductType from '@/components/common/ProductType';

const Product = (props) => {
	const {
		className,
		productImage,
		selectedLanguage,
		title,
		type,
		formattedUnitPrice,
		subscriptionBlock,
		showQuantity,
		productQuantity,
		sku,
		maxQuantity,
		quantityEditable,
		description,
		isLargeProductImage,
		commonStyles,
	} = props;

	return (
		<div className={className} css={commonStyles}>
			<div className="CB-product-details" id={`CB-product-details-${sku}`}>
				<div
					className={
						isLargeProductImage
							? 'product-summary product-summary-large-image'
							: 'product-summary'
					}
				>
					{productImage}
					<div
						className={isLargeProductImage ? 'details details-large-image' : 'details'}
					>
						<div className="product-title-container">
							<Typography
								variant="h5"
								className={`title ${selectedLanguage === 'de' ? 'wrap-text' : ''}`}
							>
								{title}
							</Typography>
							<Typography variant="body2" className="type">
								<ProductType type={type} />
							</Typography>
						</div>

						<div className="price-and-quantity-container">
							<Typography variant="body3" className="price">
								{formattedUnitPrice}
							</Typography>
							{showQuantity && (
								<div className="quantity-remove">
									{/*
								if the quantity is greater than 1, show the quantity as read only
								if quantityEditable is true, show quantity as editable regardless of the pre-set quantity
								this is only applicable to cart items, not bump items
							*/}
									<QuantityInput
										quantity={productQuantity}
										maxQuantity={maxQuantity}
										quantityEditable={quantityEditable}
										sku={sku}
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			{description && (
				<Box sx={{ marginBottom: '1rem' }}>
					<Typography variant="body2" className="product-description">
						{description}
					</Typography>
				</Box>
			)}
			{subscriptionBlock}
		</div>
	);
};

Product.propTypes = {
	className: PropTypes.string,
	productImage: PropTypes.node,
	selectedLanguage: PropTypes.string,
	title: PropTypes.string,
	type: PropTypes.string,
	formattedUnitPrice: PropTypes.string,
	subscriptionBlock: PropTypes.node,
	showQuantity: PropTypes.bool,
	productQuantity: PropTypes.number,
	sku: PropTypes.string,
	maxQuantity: PropTypes.number,
	quantityEditable: PropTypes.bool,
	commonStyles: PropTypes.object,
	description: PropTypes.string,
	isLargeProductImage: PropTypes.bool,
};

export default Product;
