import { css } from '@emotion/react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { getZoneStyles } from '@/components/mainContent/contentStyle';
import { useStore } from '@/state/stores';
import { cbNeutral, cbPrimary, checkoutTheme } from '@/theme';
import { getNextBillingDate } from '@/utils/helpers';
import { useCurrency, useFrequency } from '@/utils/hooks';

const container = (paymentStyles, receiptPageData, inBumpList) => {
	const isReceiptPage = !!receiptPageData[0]?.fullName;
	const { borderColor, bump } = paymentStyles || {};

	return css`
		color: ${inBumpList ? bump?.fontColor : 'inherit'};
		&.gutter-top {
			margin-top: 2rem;
		}

		&.gutter-bottom {
			margin-bottom: 2rem;
		}

		.details {
			display: flex;
			flex-direction: column;
			align-content: center;

			.subscription-overview {
				border: 2px solid ${borderColor || cbPrimary[500]};
				border-radius: 4px;
				text-align: center;
				padding: 20px 20px;
				margin-bottom: 12px;
			}

			.subscription-details {
				display: flex;
				flex-direction: column;
				justify-content: center;
			}

			.future-payment {
				display: flex;
				align-items: center;
				margin: 0 0 0;
				p {
					font-size: ${isReceiptPage ? '1.125rem' : '0.875rem'};
				}
				svg {
					margin-right: 9px;
					font-size: ${isReceiptPage ? '1.3rem' : '1.1rem'};
				}
			}

			${checkoutTheme.breakpoints.up('sm')} {
				flex-direction: row;

				.subscription-overview {
					flex-basis: 350px;
					margin-right: 1rem;
					margin-bottom: 0;
					min-width: 170px;
				}
			}
		}

		.heading {
			margin-bottom: 1rem;
		}

		.trial-spacing {
			margin-bottom: 1rem;
		}

		.disclaimer {
			font-size: 0.75rem;
			margin: 8px 0 1rem;
		}
		.payment {
			font-weight: 600;
			font-size: ${isReceiptPage ? '1.125rem' : '0.875rem'};
			margin-top: 7px;
		}
	`;
};

export const SubscriptionDetails = (props) => {
	const {
		classNames,
		frequency,
		frequencyType,
		subsequentPayments,
		isTrialProduct,
		title,
		productType,
		isUnlimited,
		remainingPayments,
		initialOffsetValue,
		nextBillingDate: parentNextBillingDate,
		inBumpList,
	} = props;
	const { t } = useTranslation('common');
	const { lastFour, template, receiptPageData, selectedLanguage } = useStore(
		useShallow((state) => ({
			lastFour: state.lastFour,
			template: state.template,
			receiptPageData: state.receiptPageData,
			selectedLanguage: state.selectedLanguage,
		})),
	);

	const nextBillingDate = useMemo(() => {
		if (parentNextBillingDate) {
			return parentNextBillingDate;
		}
		return getNextBillingDate(initialOffsetValue, selectedLanguage, frequency, frequencyType);
	}, [selectedLanguage, frequency, frequencyType, initialOffsetValue, parentNextBillingDate]);

	const payments = useCurrency(subsequentPayments);
	const decodeFrequencyForTranslations = useFrequency(frequency, frequencyType);
	const paymentStyles = getZoneStyles(template, 'payment');

	const decodedFrequency =
		decodeFrequencyForTranslations && remainingPayments > 1
			? decodeFrequencyForTranslations
			: 'generic';

	//returns translated string for rebill frequency
	const rebillFrequency = () => {
		const paymentTypeSingular = t(`subscription.payment.type.${frequencyType}`);
		const paymentTypePlural = t(`subscription.payment.type.plural.${frequencyType}`);

		return frequency > 1 ? `${frequency} ${paymentTypePlural}` : paymentTypeSingular;
	};

	const translatedPaymentHeader = () => {
		const args = {
			numberOfPayments: remainingPayments,
			paymentAmount: payments,
		};
		if (isUnlimited) {
			return t(`subscription.payment.unlimited.${decodedFrequency}`, args);
		} else {
			return Number(remainingPayments) > 1
				? t(`subscription.payment.plural.${decodedFrequency}`, args)
				: t(`subscription.payment.singular.${decodedFrequency}`, args);
		}
	};

	// Trial language in the disclaimer will only be displayed on the receipt page
	const disclaimer = isTrialProduct ? (
		<Fragment>
			<Typography variant="body2" className="trial-spacing">
				{t(`trial-subscription.${productType}-disclaimer`, {
					title: title,
					remainingDaysInTrial: initialOffsetValue,
					beginningDate: nextBillingDate,
					lastFour: lastFour,
					subsequentPayments: payments,
				})}
			</Typography>
			<Typography variant="body2" className="trial-spacing">
				{t('trial-subscription.wish-to-cancel')}
				<a href="https://www.clkbank.com/" target="_blank" rel="noopener noreferrer">
					{t('trial-subscription.link')}
				</a>
				{t('trial-subscription.cancel-order', { rebillFrequency: rebillFrequency() })}
			</Typography>
			<Typography variant="body2">{t('trial-subscription.subject-to-change')}</Typography>
		</Fragment>
	) : (
		<Typography variant="body2" className="disclaimer">
			{t(`subscription.${productType}-next-bill-frequency-dof`, {
				nextBillingDate: nextBillingDate,
				rebillFrequency: rebillFrequency(),
			})}
		</Typography>
	);

	return (
		<div css={container(paymentStyles, receiptPageData, inBumpList)} className={classNames}>
			<div className="details">
				<div className="subscription-details">
					<div className="future-payment">
						<AccessTimeIcon fontSize="small" color={cbNeutral[400]} />
						<Typography variant="body2">
							{remainingPayments > 1
								? t('subscription.future-payment-plural-dof')
								: t('subscription.future-payment-dof')}
						</Typography>
					</div>
					<Typography className="payment" variant="body1">
						{translatedPaymentHeader()}
						{isTrialProduct ? '**' : '*'}
					</Typography>
					{disclaimer}
				</div>
			</div>
		</div>
	);
};

export default SubscriptionDetails;

SubscriptionDetails.propTypes = {
	classNames: PropTypes.string,
	frequency: PropTypes.string,
	frequencyType: PropTypes.string,
	subsequentPayments: PropTypes.string,
	nextBillingDate: PropTypes.string,
	isTrialProduct: PropTypes.bool,
	title: PropTypes.string,
	type: PropTypes.string,
	isUnlimited: PropTypes.bool,
	productType: PropTypes.string,
	remainingPayments: PropTypes.number,
	initialOffsetValue: PropTypes.number,
	inBumpList: PropTypes.bool,
};
