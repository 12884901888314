import { css } from '@emotion/react';
import Grid from '@mui/material/Grid';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import CityAndState from './CityAndState';
import CBTextField from '@/components/common/CBTextField';
import SelectField from '@/components/common/SelectField';
import { FindLocationContext } from '@/state/FindLocationContext';
import { useFormStore, useStore } from '@/state/stores';
import { checkoutTheme } from '@/theme';
import { listCommonCountriesFirst } from '@/utils/helpers';
import { useLocationUpdateSideEffects } from '@/utils/hooks';
import { isInputRequired } from '@/utils/requiredInputs';

const styles = css`
	margin: 0px 0px 0 -16px;

	${checkoutTheme.breakpoints.down('sm')} {
		margin-bottom: 0;
	}
`;

const DigitalAddress = () => {
	const accessor = 'billing';
	const { t } = useTranslation('checkout');
	const {
		cartInitialized,
		isPayPalDirectEnabled,
		paymentMethod,
		countryOptions,
		isShippable,
		cityOptions,
		stateOptions,
	} = useStore(
		useShallow((state) => ({
			cartInitialized: state.cartInitialized,
			isPayPalDirectEnabled: state.isPayPalDirectEnabled,
			paymentMethod: state.paymentMethod,
			isCartUpdating: state.isCartUpdating,
			countryOptions: state.countryOptions,
			isShippable: state.isShippable,
			stateOptions: state.stateOptions[accessor],
			cityOptions: state.cityOptions[accessor],
		})),
	);
	const { countryCode } = useFormStore(
		useShallow((state) => ({
			countryCode: state.formData[accessor].countryCode,
		})),
	);

	const { fetchAndLoadLocationData } = useContext(FindLocationContext);

	useLocationUpdateSideEffects(accessor);
	const showErrorOnZipInput =
		(countryCode === 'US' && cityOptions?.length === 0) ||
		(countryCode === 'CA' && stateOptions?.length === 0);
	const multiOptionCountryDefault = {
		value: '',
		label: t('checkout:field.common.placeholder.select-country'),
	};
	const forceNameForPayPal = isPayPalDirectEnabled && paymentMethod === 'payPal';

	const countriesToCapitalizeZip = ['GB', 'IE'];

	const { options: orderedCountryOptions, subheaders: countrySubheaders } = countryOptions
		? listCommonCountriesFirst(countryOptions, t)
		: { options: countryOptions };

	return (
		<Grid container spacing={2} css={styles}>
			{forceNameForPayPal && (
				<Grid item xs={12}>
					<CBTextField
						name={`${accessor}.fullName`}
						label={t('field.full-name.label')}
						required={true}
						addressAccessor={accessor}
						showPlaceholder={!cartInitialized}
					/>
				</Grid>
			)}
			<Grid item xs={12} lg={6}>
				<SelectField
					data={orderedCountryOptions}
					subheaders={countrySubheaders}
					name={`${accessor}.countryCode`}
					label={t('field.country.label')}
					required={true}
					disabled={!cartInitialized}
					defaultValue={multiOptionCountryDefault}
					showPlaceholder={!cartInitialized}
					includeDefaultInList={!countrySubheaders}
				/>
			</Grid>
			<Grid item xs={12} lg={6}>
				<CBTextField
					name={`${accessor}.zip`}
					onChange={fetchAndLoadLocationData(accessor)}
					label={
						countryCode === 'US'
							? t('field.zip-code.label')
							: t('field.postal-code.label')
					}
					required={isInputRequired(
						'zip',
						countryCode,
						isShippable ? 'isShippable' : null,
					)}
					updateOn="change"
					defaultValue=""
					disabled={!cartInitialized}
					convertCase={countriesToCapitalizeZip.includes(countryCode) ? 'upper' : null}
					helperTextOverride={
						showErrorOnZipInput
							? t('field.common.error.invalid', {
									inputName: t('field.zip-code.label'),
								})
							: null
					}
					addressAccessor={accessor}
					showPlaceholder={!cartInitialized}
				/>
			</Grid>
			<CityAndState accessor={accessor} renderInBilling />
		</Grid>
	);
};

export default DigitalAddress;
