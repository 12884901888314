import { fetchUrl, fetchWithRetry } from './fetchHelpers';
import { ORDER_BUMP } from '@/graphql/queries';
import { getProductImage, preloadImageSrc } from '@/utils/helpers';

const getBumpsQuery =
	({ urlVars, urlProductsArray, fetchHeaders }) =>
	async () => {
		try {
			const productSKUs = urlProductsArray.map((product) => product.sku);
			const body = await fetchWithRetry('ORDER_BUMP', fetchUrl, {
				method: 'POST',
				headers: fetchHeaders,
				body: JSON.stringify({
					query: ORDER_BUMP,
					variables: {
						vendorId: urlVars.vvvv,
						skus: productSKUs,
					},
				}),
			});
			if (body.error) {
				throw body;
			}
			const { data } = body;

			// Preload bump images if there is a template and order bumps
			if (urlVars.template && data.orderBump?.orderBumpProducts?.length) {
				data.orderBump.orderBumpProducts.forEach(({ product }) => {
					if (!product.image) {
						return;
					}
					const src = getProductImage(product.image);
					preloadImageSrc(src);
				});
			}

			const state = {};
			return {
				graphql: {
					ORDER_BUMP: data,
				},
				state,
			};
		} catch (body) {
			return Promise.reject({
				state: {},
				graphql: {},
				errors: { ORDER_BUMP: body },
			});
		}
	};

export default getBumpsQuery;
