import { css } from '@emotion/react';
import { FormControlLabel } from '@mui/material';
import { default as MuiCheckbox } from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getZoneStyles } from '@/components/mainContent/contentStyle';
import { useStore } from '@/state/stores';
import {
	defaultDividerColor,
	defaultInputBorderColor,
	defaultInputFocusBorderColor,
} from '@/theme';

const checkboxCSS = (cartStyles) => {
	const { body } = cartStyles || {};

	return css`
		border-bottom: 1px solid ${body?.dividerColor || defaultDividerColor};

		margin-bottom: 16px;
		padding-bottom: 8px;

		.MuiFormControlLabel-root {
			display: flex;
			align-items: flex-start;
			margin: 4px 0 8px 0;
		}

		.checkbox-input {
			align-items: start;
			margin-right: 0;
		}

		.label {
			font-size: 1rem;
			position: relative;
			top: -3px;
			${body?.fontWeight ? `font-weight: ${body.fontWeight};` : ''}
			${body?.fontColor ? `color: ${body.fontColor};` : ''}
			${body?.fontFamily ? `font-family: ${body.fontFamily};` : ''}
		}

		.checkbox {
			color: ${body?.inputBorderColor || defaultInputBorderColor};
			border-radius: 0px;
			height: 12px;
			width: 12px;
			padding: 0px;
			background-color: ${body?.inputBackgroundColor || 'white'};
			margin-right: 8px;
			margin-top: 2px;

			&.checked {
				color: ${body?.inputBorderSelectedColor || defaultInputFocusBorderColor};
			}
		}
	`;
};

const ConsentCheckbox = (props) => {
	const { regulation, setRegulations } = props;
	const template = useStore((state) => state.template);
	const name = `consentChecked-${regulation.value}`;
	const { t } = useTranslation('checkout');
	const cartStyles = getZoneStyles(template, 'cart');

	const label =
		regulation.value === 'CCPA'
			? t('form.checkbox.ccpa-consent')
			: t('form.checkbox.gdpr-consent');

	const handleToggle = (newValue) => {
		setRegulations((prev) =>
			prev.map((item) => {
				if (item.value === regulation.value) {
					return {
						...item,
						checked: newValue,
					};
				}
				return item;
			}),
		);
	};

	return regulation ? (
		<div css={checkboxCSS(cartStyles)} className="CB-consent-checkbox">
			<FormControlLabel
				control={
					<MuiCheckbox
						id={name}
						name={name}
						checked={regulation.checked}
						disableRipple
						onChange={(e) => {
							handleToggle(e.target.checked);
						}}
					/>
				}
				label={label}
				htmlFor={name}
			/>
		</div>
	) : null;
};

ConsentCheckbox.propTypes = {
	regulation: PropTypes.shape({
		key: PropTypes.string,
		checked: PropTypes.bool,
		value: PropTypes.string,
	}).isRequired,
	setRegulations: PropTypes.func.isRequired,
};

export default ConsentCheckbox;
