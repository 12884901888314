export const taxName = {
	// countries that use VAT
	vat: [
		'AT',
		'BE',
		'CY',
		'CZ',
		'DK',
		'EE',
		'ES',
		'FI',
		'FR',
		'GB',
		'GR',
		'HU',
		'IE',
		'IT',
		'LT',
		'LU',
		'LV',
		'MT',
		'NL',
		'PL',
		'PT',
		'SE',
		'SK',
		'DE',
		'CH',
		'NO',
		'IS',
		'HR',
		'SI',
		'RO',
		'BG',
		'ZA',
		'KR',
		'JP',
		'RU',
		'TW',
		'SA',
	],
	//countries that use GST
	gst: ['AU', 'NZ', 'CA', 'SG'],
};
