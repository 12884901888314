import { css } from '@emotion/react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getHeaderStyles, getZoneStyles } from '@/components/mainContent/contentStyle';
import { useStore } from '@/state/stores';
import { cbNeutral, defaultDividerColor } from '@/theme';
import { defaultBumpBackgroundColor, defaultBumpBorderColor, palette } from '@/theme/palette';

const bumpCardStyles = (template, hideTopBorder) => {
	const cartStyles = getZoneStyles(template, 'cart');
	const { body, header, bump } = cartStyles || {};
	const headerStyles = getHeaderStyles(header);

	return css`
		position: relative;

		.MuiSvgIcon-root,
		.MuiTypography-root {
			color: ${bump?.fontColor || palette.text.secondary};
		}

		&::before {
			content: '';
			display: block;
			margin-bottom: 16px;
			border: none;
			border-bottom: ${hideTopBorder
				? 'none'
				: `1px solid ${body?.dividerColor || defaultDividerColor}`};
		}

		&::after {
			content: '';
			display: block;
			margin: 16px 0 0;
			border: none;
			border-bottom: 1px solid ${body?.dividerColor || defaultDividerColor};
		}

		.bumps {
			.bump-item {
				background: ${bump?.backgroundColor || defaultBumpBackgroundColor};
				border-radius: ${bump?.borderRadius || 0};
				padding: 0 8px 8px 0;
				margin-bottom: 8px;
				border: ${bump?.borderSize || '1px'} ${bump?.borderStyle || 'solid'}
					${bump?.borderColor || defaultBumpBorderColor};
				.disclaimer {
					margin: 8px 0 0;
				}
			}
			.bump-item:last-of-type {
				margin-bottom: 0;
				.product {
					> :last-child {
						margin-bottom: 0;
					}
				}
			}
		}

		.add-to-cart-link {
			cursor: pointer;
			display: block;
			font-size: 1.125rem;
			text-align: right;
			width: 100%;
			padding-bottom: 1.5rem;
			margin-bottom: 1.5rem;
			border-bottom: 1px solid ${cbNeutral[700]};
		}

		.all-bumps-added {
			font-style: italic;
		}

		${headerStyles}
		.heading {
			margin-left: 0;
		}

		.bumps-inner-container {
			border-radius: 0;
			position: relative;
		}
	`;
};

const Bumps = ({ bumpList, hideTopBorder }) => {
	const template = useStore((state) => state.template);
	const { t } = useTranslation('checkout');

	return (
		<div css={bumpCardStyles(template, hideTopBorder)}>
			<Typography variant="h4" className="heading CB-font-title">
				{t('card-title.recommended')}
			</Typography>
			<div className="bumps-inner-container">
				<div className="bumps">{bumpList}</div>
			</div>
		</div>
	);
};

Bumps.propTypes = {
	bumpList: PropTypes.array,
	hideTopBorder: PropTypes.bool,
};

export default Bumps;
