import { css } from '@emotion/react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { CBTextField } from '..';
import { getInputStyles, getZoneStyles } from '@/components/mainContent/contentStyle';
import { MediaQueryContext } from '@/state/MediaQueryContext';
import { useFormStore, useStore } from '@/state/stores';
import {
	defaultCouponBorderAndFontColor,
	defaultDisabledCouponBorderAndFontColor,
	palette,
	primaryFont,
} from '@/theme';
import { focusNextTabbableNode } from '@/utils/helpers';

const container = (template, bottomBorderColor, isTwoColumnLayout) => {
	const customStyles = getZoneStyles(template, 'cart');
	const { body, button } = customStyles || {};
	const inputStyles = getInputStyles(body);
	const summary = template?.style?.attributes?.summary || {};
	const { bodyFontColor: twoColumnBodyFontColor, bodyFontFamily: twoColumnBodyFontFamily } =
		summary || {};

	return css`
		padding-top: 16px;
		padding-bottom: 16px;
		border-bottom: 1px solid ${bottomBorderColor};
		display: flex;
		flex-direction: row;

		.button {
			margin-left: 16px;
			background-color: transparent;
			border: 1px solid ${button?.backgroundColor || defaultCouponBorderAndFontColor};
			${button?.backgroundColor ? `color: ${button.backgroundColor};` : ''}
			${button?.fontFamily ? `font-family: ${button.fontFamily};` : ''}
			${button?.borderRadius ? `border-radius: ${button.borderRadius};` : ''}
			box-shadow: none;

			&:hover:not(:disabled) {
				background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
				background-color: transparent;
				border: 1px solid ${button?.backgroundColor || defaultCouponBorderAndFontColor};
				box-shadow: none;
			}

			&.MuiButton-root:disabled {
				color: ${defaultDisabledCouponBorderAndFontColor};
				border: 1px solid ${defaultDisabledCouponBorderAndFontColor};
				background-color: transparent;
			}

			&:active {
				box-shadow: none;
			}
		}

		${inputStyles}

		.MuiFormLabel-root,
		.MuiOutlinedInput-root .MuiInputBase-input {
			${isTwoColumnLayout
				? `
				color: ${twoColumnBodyFontColor || palette.text.secondary};
				font-family: ${twoColumnBodyFontFamily || primaryFont};
			`
				: ''}
		}
	`;
};

const CouponFields = ({ bottomBorderColor }) => {
	const {
		setCouponCode,
		couponErrorMessageOverride,
		setCouponErrorMessageOverride,
		isCartUpdating,
		submitOverlayVisible,
		orderTotals,
		template,
	} = useStore(
		useShallow((state) => ({
			setCouponCode: state.setCouponCode,
			couponErrorMessageOverride: state.couponErrorMessageOverride,
			setCouponErrorMessageOverride: state.setCouponErrorMessageOverride,
			isCartUpdating: state.isCartUpdating,
			submitOverlayVisible: state.submitOverlayVisible,
			orderTotals: state.orderTotals,
			template: state.template,
		})),
	);

	const { value, formError, formFieldIsValid } = useFormStore(
		useShallow((state) => ({
			value: state.formData.couponCode,
			formError: state.formErrors.couponCode,
			formFieldIsValid: state.formFieldIsValid,
		})),
	);

	const { isSmallScreen, isTwoColumnLayout } = useContext(MediaQueryContext);

	const { t } = useTranslation(['checkout']);
	const inputEmpty = value === '';
	const inputValid = !formError && !inputEmpty;

	const resetServerError = () => {
		couponErrorMessageOverride && setCouponErrorMessageOverride(null);
	};

	const applyCouponCode = async () => {
		const isValid = await formFieldIsValid('couponCode');
		isValid && setCouponCode(value);
	};

	const disableButton = Boolean(
		isCartUpdating || orderTotals === null || submitOverlayVisible || !inputValid,
	);

	return (
		<div css={container(template, bottomBorderColor, isTwoColumnLayout)}>
			<CBTextField
				name="couponCode"
				label={t('field.coupon-code.label')}
				helperTextOverride={couponErrorMessageOverride}
				validateOnChange={true}
				convertCase={'upper'}
				onChange={resetServerError}
				onKeyPress={(ev) => {
					if (ev.key === 'Enter') {
						ev.preventDefault();
						applyCouponCode();
					}
				}}
				inputProps={{
					tabIndex: isSmallScreen ? 0 : 1,
				}}
			/>
			<Button
				variant="secondary"
				type="button"
				className="button CB-button-coupon"
				disabled={disableButton}
				onClick={applyCouponCode}
				onKeyPress={(ev) => {
					const { target, key } = ev;
					if (key === 'Enter') {
						focusNextTabbableNode(target);
					}
				}}
				tabIndex={isSmallScreen ? 0 : 1}
			>
				{t('form.button.apply')}
			</Button>
		</div>
	);
};
CouponFields.propTypes = {
	couponErrorMessageOverride: PropTypes.string,
	couponStatus: PropTypes.string,
	submitCouponCode: PropTypes.func,
	couponValue: PropTypes.string,
	customStyles: PropTypes.object,
	bottomBorderColor: PropTypes.string,
};
export default CouponFields;
