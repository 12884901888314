import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { amexLogo, dinersLogo, discoverLogo, masterCardLogo, visaLogo } from '@/assets';
import { useStore } from '@/state/stores';

const container = css`
	display: flex;
	align-items: center;
	margin-left: auto;

	img:first-of-type {
		margin-left: 0;
	}

	img {
		margin-left: 8px;
		background: rgba(255, 255, 255, 0.8);

		@media (max-width: 816px) {
			width: 24px;
			height: 16px;
		}
	}
`;

const cardOrder = ['visa', 'masterCard', 'americanExpress', 'discover', 'diners'];

const CreditCardIcons = () => {
	const cardsAccepted = useStore((state) => state.cardsAccepted);
	const { t } = useTranslation('checkout');

	// DOF icons wanted for split test
	const cardIconDataDOF = {
		masterCard: {
			color: masterCardLogo,
			alt: t('alt-tags.mastercard'),
		},
		visa: {
			color: visaLogo,
			alt: t('alt-tags.visa'),
		},
		americanExpress: {
			color: amexLogo,
			alt: t('alt-tags.american-express'),
		},
		discover: {
			color: dinersLogo,
			alt: t('alt-tags.discover'),
		},
		diners: {
			color: discoverLogo,
			alt: t('alt-tags.diners'),
		},
	};

	const cardIcon = (targetCard, index) => {
		const image = cardIconDataDOF[targetCard].color;

		const isDinersIcon = image.includes('cc-diners');

		return (
			<img
				key={index}
				src={image}
				alt={cardIconDataDOF[targetCard].alt}
				width={isDinersIcon ? '24' : '37.5'}
				height="24"
			/>
		);
	};

	const acceptedCardsIcons = [...cardsAccepted]
		.sort((a, b) => {
			const aIndex = cardOrder.indexOf(a);
			const bIndex = cardOrder.indexOf(b);
			if ([aIndex, bIndex].every((i) => i === -1)) {
				return 0;
			} else if (aIndex === -1) {
				return 1;
			} else if (bIndex === -1) {
				return -1;
			}
			return aIndex - bIndex;
		})
		.map((targetCard, index) => {
			return ['payPal', 'payPalCommerce'].indexOf(targetCard) === -1
				? cardIcon(targetCard, index)
				: null;
		});

	return <div css={container}>{acceptedCardsIcons}</div>;
};

export default CreditCardIcons;
