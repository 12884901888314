import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useStore } from '@/state/stores';

const TokenExInputPlaceHolder = () => {
	const { t } = useTranslation(['checkout']);

	const {
		lastFour,
		firstSix,
		token,
		setToken,
		setTokenHash,
		tokenExIframe,
		setShowTokenExPlaceholder,
	} = useStore(
		useShallow((state) => ({
			lastFour: state.lastFour,
			firstSix: state.firstSix,
			token: state.token,
			setToken: state.setToken,
			setTokenHash: state.setTokenHash,
			tokenExIframe: state.tokenExIframe,
			setShowTokenExPlaceholder: state.setShowTokenExPlaceholder,
		})),
	);

	const handleFocus = () => {
		setShowTokenExPlaceholder(false);
		setToken(null);
		setTokenHash(null);
		setTimeout(() => {
			if (tokenExIframe) {
				tokenExIframe.focus();
			}
		});
	};

	const tokenMask = Array(token.length - 10)
		.fill('*')
		.join('');
	const value = `${firstSix}${tokenMask}${lastFour}`.match(/.{1,4}/g).join(' ');

	return (
		<TextField
			id="tokenEx-placeholder"
			label={t('field.card-number.label')}
			required={true}
			value={value}
			onFocus={handleFocus}
			fullWidth
			inputProps={{
				tabIndex: 0,
			}}
		/>
	);
};
export default TokenExInputPlaceHolder;
