import LockIcon from '@mui/icons-material/Lock';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SecureCheckout = () => {
	const { t } = useTranslation('checkout');
	return (
		<section className="secure-checkout-wrapper CB-background" id="CB-main-background">
			<section className="secure-checkout-padding-container">
				<section
					className="secure-checkout-container"
					aria-label="secure checkout"
					id="CB-secure-checkout"
				>
					<LockIcon />
					<Typography variant="body2">{t('card-title.secure-checkout')}</Typography>
				</section>
			</section>
		</section>
	);
};

export default SecureCheckout;
