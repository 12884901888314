import { Container } from '@mui/material';
import PropTypes from 'prop-types';

import { contentStyle } from './contentStyle';
import { useStore } from '@/state/stores';

const MainContent = ({ children, containerStyle = contentStyle }) => {
	const template = useStore((state) => state.template);
	return (
		<div css={containerStyle(template)} id="CB-main-content-container">
			<Container disablegutters="true" className="container">
				{children}
			</Container>
		</div>
	);
};

MainContent.propTypes = {
	containerStyle: PropTypes.object,
	children: PropTypes.node,
};

export default MainContent;
