import { css } from '@emotion/react';
import { Container, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomerService, Header, OrderDetails, SubFooter, ThankYou } from '@/components';
import { useStore } from '@/state/stores';
import { cbNegative, cbNeutral, checkoutTheme } from '@/theme';

// Main page styling
const mainContent = css`
	flex-grow: 1;
	.container {
		box-sizing: border-box;
		padding-left: 16px;
		padding-right: 16px;

		${checkoutTheme.breakpoints.up('sm')} {
			padding-top: 2.5em;
			padding-bottom: 2.5em;
		}
		${checkoutTheme.breakpoints.up('md')} {
			padding-left: 0;
			padding-right: 24px;
		}
	}

	.grid-container {
		margin-top: 0;
		margin-bottom: 0;
		margin-left: 0;
		width: 100%;
	}

	.column {
		${checkoutTheme.breakpoints.down('md')} {
			padding: 0;
		}

		${checkoutTheme.breakpoints.up('sm')} {
			margin-left: auto;
			margin-right: auto;
		}
	}

	.test-purchase {
		display: flex;
		background-color: ${cbNegative[500]};
		justify-content: center;
		height: 60px;
		margin-left: auto;
		margin-right: auto;
		width: 100%;

		${checkoutTheme.breakpoints.up('sm')} {
			margin-bottom: 1.75em;
			max-width: 77%;
		}

		${checkoutTheme.breakpoints.up('md')} {
			max-width: 98%;
		}

		.text {
			font-weight: bold;
			color: ${cbNeutral[1000]};
		}
	}
`;

export default function Confirmation() {
	document.title = 'Order Confirmation and Customer Receipt';
	const receiptPageData = useStore((state) => state.receiptPageData);
	const { t } = useTranslation('order-received');
	const warnUserBeforeLeaving = (e) => {
		e.preventDefault();
		e.returnValue = true;
	};

	useEffect(() => {
		window.addEventListener('beforeunload', warnUserBeforeLeaving);

		return () => {
			window.removeEventListener('beforeunload', warnUserBeforeLeaving);
		};
	}, []);

	const testPurchase = (
		<Grid item className="test-purchase ">
			<Typography variant="subtitle1" className="text ">
				{t('test-purchase')}
			</Typography>
		</Grid>
	);

	return (
		<>
			<Header />
			<div css={mainContent}>
				<Container disablegutters="true" className="container">
					<Grid container spacing={3} className="grid-container">
						{receiptPageData?.[0]?.isTestPurchase && testPurchase}
						<Grid item xs={12} md={7} className="column">
							<ThankYou />
							<OrderDetails />
						</Grid>
						<Grid item xs={12} md={5} className="column">
							<CustomerService />
						</Grid>
					</Grid>
				</Container>
			</div>
			<SubFooter />
		</>
	);
}
