import { css } from '@emotion/react';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Card } from '..';
import { useStore } from '@/state/stores';
import { cbNeutral, cbPrimary, defaultPanelBorderColor, defaultPanelBoxShadow } from '@/theme';

const container = css`
	border: 1 px ${defaultPanelBorderColor};
	box-shadow: ${defaultPanelBoxShadow};
	.button {
		height: 48px;
		margin: 20px 0 20px 0;
	}

	.create-account {
		font-weight: normal;
		margin-top: 20px;
		color: ${cbPrimary[180]};
	}
	.account {
		margin-top: 12px;
		margin-bottom: 8px;
	}
	.create-account-button {
		border-color: ${cbNeutral[400]};
		color: ${cbNeutral[400]};
		font-weight: 600;
		font-size: 16px;
		padding-left: 6px;
		padding-right: 6px;
	}

	h3 {
		margin-bottom: 20px;
		color: ${cbNeutral[300]};
		font-weight: normal;
		overflow-wrap: break-word;
		word-wrap: break-word;
		word-break: break-word;
		hyphens: auto;
	}
`;

const CustomerService = () => {
	const { t } = useTranslation('order-received');
	const title = t('customer-service.title');
	const receiptPageData = useStore((state) => state.receiptPageData);
	const email = receiptPageData[0]?.email;

	const orderNumbers = receiptPageData.map((receipt) => receipt.orderNumber);

	return (
		<Card css={container}>
			<Typography variant="h3">{title}</Typography>
			<Typography>{t('customer-service.questions')}</Typography>
			<Button
				className="button"
				fullWidth
				href="https://www.clkbank.com/"
				target="_blank"
				rel="noopener noreferrer"
			>
				{t('button.support')}
			</Button>

			<Typography gutterBottom>{t('customer-service.list-title')}</Typography>
			<Typography component="ul" gutterBottom>
				<Typography variant="body1" component="li">
					{t('customer-service.list-1')}
				</Typography>
				<Typography variant="body1" component="li">
					{t('customer-service.list-2')}
				</Typography>
				<Typography variant="body1" component="li">
					{t('customer-service.list-3')}
				</Typography>
				<Typography variant="body1" component="li">
					{t('customer-service.list-4')}
				</Typography>
				<Typography variant="body1" component="li">
					{t('customer-service.list-5')}
				</Typography>
				<Typography variant="body1" component="li">
					{t('customer-service.list-6')}
				</Typography>
			</Typography>
			{orderNumbers.length === 1 ? (
				<Typography variant="h4">{t('common.order-number')}</Typography>
			) : (
				<Typography variant="h4">{t('common.order-numbers')}</Typography>
			)}
			{orderNumbers.map((orderNumber, index) => {
				return (
					<Typography key={index} gutterBottom>
						{orderNumber}
					</Typography>
				);
			})}
			<Typography variant="h4">{t('customer-service.email')}</Typography>
			<Typography>{email}</Typography>
			<Typography className="create-account" variant="h4">
				{t('customer-service.create-account')}
			</Typography>
			<Typography className="account">{t('customer-service.with-account')}</Typography>
			<Button
				variant="outlined"
				className="button create-account-button"
				fullWidth
				href="https://www.clkbank.com/#!/CreateAccount/"
				target="_blank"
				rel="noopener noreferrer"
			>
				{t('button.create-account')}
			</Button>
		</Card>
	);
};

export default CustomerService;
