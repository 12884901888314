import { useManualQuery } from 'graphql-hooks';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { PRODUCTS, UPSELL_FLOW_RECEIPTS } from '@/graphql/queries';
import { useStore } from '@/state/stores';
import { buildReceiptPageProducts } from '@/utils/products';

export const UpsellLoader = ({ allQueriesData, queryFetcher }) => {
	const {
		setReceiptPageData,
		selectedLanguage,
		setSelectedCurrency,
		setDisplayCurrency,
		setSessionToken,
		setReceiptPageProducts,
		setOrderTotals,
		setIsUpsellLoading,
		urlVars,
	} = useStore(
		useShallow((state) => ({
			setReceiptPageData: state.setReceiptPageData,
			selectedLanguage: state.selectedLanguage,
			setSelectedCurrency: state.setSelectedCurrency,
			setDisplayCurrency: state.setDisplayCurrency,
			setSessionToken: state.setSessionToken,
			setReceiptPageProducts: state.setReceiptPageProducts,
			setOrderTotals: state.setOrderTotals,
			setIsUpsellLoading: state.setIsUpsellLoading,
			urlVars: state.urlVars,
		})),
	);
	const history = useHistory();
	const [products, setProducts] = useState([]);
	const [fetchProducts] = useManualQuery(PRODUCTS);
	const upsellFlowReceipts = allQueriesData?.UPSELL_FLOW_RECEIPTS?.upsellFlowReceipts;
	const upsellKey = urlVars['upsell-key'];

	// get all products, save them in state, then route to the receipt page
	useEffect(() => {
		if (upsellKey && upsellFlowReceipts?.length) {
			const getProducts = async (purchasedProducts, vendorId) => {
				try {
					const skus = purchasedProducts.map((sku) => {
						// removes the vendorId from the product id
						return sku.productId.substring(sku.productId.indexOf('-') + 1);
					});
					const products = await fetchProducts({
						variables: {
							vendorId,
							skus,
						},
					});
					return products;
				} catch (error) {
					// we are not handling GQL errors for the PRODUCTS query in the UI for the receipt page,
					// but if we get any they will be sent to RUM so we know if they are happening
					console.error(
						'Error fetching products for one of the receipts in an upsell flow',
						JSON.stringify(error),
					);
				}
			};

			const productsPromises = upsellFlowReceipts.map(({ purchasedProducts, vendorId }) =>
				getProducts(purchasedProducts, vendorId),
			);

			const saveProductsAndRouteToReceiptPage = async () => {
				const products = await Promise.all(productsPromises);
				const productsArr = products.map((product) => product.data.products);
				setProducts(productsArr);
			};

			saveProductsAndRouteToReceiptPage();
		}
	}, [upsellFlowReceipts, fetchProducts, upsellKey]);

	// after we have the receipts and the products save the data for the receipt page in store
	useEffect(() => {
		const receiptPageDataArray = [];
		const receiptPageProductsArray = [];
		const orderTotalsArray = [];
		const lastIndex = upsellFlowReceipts?.length - 1;

		if (upsellFlowReceipts && products.length) {
			upsellFlowReceipts.forEach(
				(
					{
						receipt,
						purchasedProducts,
						timestamp,
						softDescriptor,
						calculatedCart,
						paymentMethod,
						email,
						shippingAddress,
						editUrl,
						last4cc,
						fullName,
						cardHolderName,
						tokenProvider,
					},
					index,
				) => {
					const hasPhysicalProduct = Boolean(
						products[index].find((item) => item.type.includes('PHYSICAL')),
					);
					const receiptPageData = {
						fullName: fullName,
						cardHolderName,
						email: email,
						address: shippingAddress,
						softDescriptor: softDescriptor,
						timestamp: timestamp,
						editUrl: editUrl,
						last4cc: last4cc,
						paymentMethod: paymentMethod,
						orderNumber: receipt,
						hasPhysicalProduct: hasPhysicalProduct,
						tokenProvider: tokenProvider,
					};
					receiptPageDataArray.push(receiptPageData);

					const receiptPageProducts = buildReceiptPageProducts(
						products[index],
						purchasedProducts,
						calculatedCart.lineItems,
						selectedLanguage,
					);

					receiptPageProductsArray.push(receiptPageProducts);
					orderTotalsArray.push(calculatedCart);
				},
			);
			setReceiptPageData(receiptPageDataArray);
			setReceiptPageProducts(receiptPageProductsArray);
			setOrderTotals(orderTotalsArray);
			const currencyUsed = upsellFlowReceipts[lastIndex].currencyId;
			setSelectedCurrency(currencyUsed);
			setDisplayCurrency(currencyUsed);
			setIsUpsellLoading(false);
			history.push(`/order-received?upsell-key=${upsellKey}`);
			// this will save the token from the last upsell receipt to use for TrackingPixels which is what we want for now.  This might be changed in the future.
			setSessionToken(upsellFlowReceipts[lastIndex].sessionToken);
		}
	}, [
		upsellFlowReceipts,
		selectedLanguage,
		setOrderTotals,
		setReceiptPageData,
		setSelectedCurrency,
		setDisplayCurrency,
		setReceiptPageProducts,
		setIsUpsellLoading,
		products,
		setSessionToken,
		history,
		upsellKey,
	]);

	const [fetchUpsells] = useManualQuery(UPSELL_FLOW_RECEIPTS, {
		variables: {
			upsellFlowSessionId: upsellKey,
		},
	});

	const fetchReceipts = useCallback(() => {
		queryFetcher({
			targetQuery: 'UPSELL_FLOW_RECEIPTS',
			required: true,
			loadFunction: fetchUpsells,
		});
	}, [fetchUpsells, queryFetcher]);

	useEffect(() => {
		setIsUpsellLoading(true);
		upsellKey && fetchReceipts();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [upsellKey, setIsUpsellLoading]);

	return null;
};
