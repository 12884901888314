import { useManualQuery } from 'graphql-hooks';
import { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { TRACKING_PIXELS } from './queries';
import { useStore } from '@/state/stores';

export const TrackingPixelsLoader = ({ allQueriesData }) => {
	const { urlVars, setTrackingPixels, affiliate, sessionToken, cartInitialized } = useStore(
		useShallow((state) => ({
			urlVars: state.urlVars,
			setTrackingPixels: state.setTrackingPixels,
			affiliate: state.affiliate,
			sessionToken: state.sessionToken,
			cartInitialized: state.cartInitialized,
		})),
	);

	const fetchedPixels = useRef({});

	const location = useLocation();
	const page = location.pathname.match(/\/(order-received|paypal-response)/g)
		? 'RECEIPT_PAGE_VIEW'
		: 'ORDER_FORM_VIEW';

	const [fetchPixels] = useManualQuery(TRACKING_PIXELS);

	const fetchPixelsThenAssign = useCallback(async () => {
		const pixels = await fetchPixels({
			variables: {
				vendorId: urlVars.vvvv,
				eventName: page,
				sessionToken: sessionToken || null,
				urlParams: window.location.search,
				affiliate: affiliate?.name,
				affiliateTrackingCode: affiliate?.trackingCode,
			},
		});
		if (pixels?.error) {
			// RUM logging handled in `logErrors`
			return;
		}
		return setTrackingPixels(pixels.data.trackingPixels);
	}, [affiliate, page, fetchPixels, sessionToken, setTrackingPixels, urlVars.vvvv]);

	useEffect(() => {
		let fetchReady = false;

		// for both, make sure affiliate query has run
		if (page === 'ORDER_FORM_VIEW') {
			// need urlParams and vendorId
			fetchReady =
				cartInitialized &&
				allQueriesData.AFFILIATE?.affiliate &&
				allQueriesData.CALCULATE_CART?.calculateCart &&
				urlVars.vvvv &&
				!fetchedPixels.current[page];
		} else if (page === 'RECEIPT_PAGE_VIEW') {
			// make sure we have sessionToken
			fetchReady = sessionToken && !fetchedPixels.current[page];

			// clear the pixels so they can be replaced with the receipt pixels
			fetchReady && setTrackingPixels(null);
		}
		// set this flag to true so we don't re-trigger the pixels
		if (fetchReady) {
			fetchedPixels.current[page] = true;
			// fetch tracking pixels separately from the rest of GraphQLLoader
			// because we do not want to trigger cart loading
			setTimeout(fetchPixelsThenAssign, 3000);
		}
	}, [
		page,
		sessionToken,
		cartInitialized,
		allQueriesData,
		fetchPixelsThenAssign,
		setTrackingPixels,
		urlVars,
	]);

	return null;
};
