import { css } from '@emotion/react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import PayMethodAccordions from './PayMethodAccordions';
import Bumps from '@/components/cart/Bumps';
import Card from '@/components/common/Card';
import SubmitButton from '@/components/common/SubmitButton';
import ZoneWrapper from '@/components/layoutFactory/ZoneWrapper';
import {
	getHeaderStyles,
	getInputStyles,
	getZoneStyles,
} from '@/components/mainContent/contentStyle';
import { MediaQueryContext } from '@/state/MediaQueryContext';
import { useStore } from '@/state/stores';
import {
	checkoutTheme,
	defaultPanelBackgroundColor,
	defaultPanelBorderColor,
	defaultPanelBoxShadow,
} from '@/theme';
import { Layouts } from '@/utils/enums';
import { isTemplateJoined } from '@/utils/helpers';

const cardStyles = (template) => {
	const paymentStyles = getZoneStyles(template, 'payment');
	const { body, header } = paymentStyles || {};
	const inputStyles = getInputStyles(body);
	const headerStyles = getHeaderStyles(header);

	return css`
		background: transparent;
		box-shadow: none;
		${checkoutTheme.breakpoints.down('sm')} {
			box-shadow: none;
		}

		.MuiCardContent-root {
			padding: 0;
		}

		.payment-card-header {
			padding: 16px 24px 0 24px;
			color: ${body?.fontColor || 'inherit'};
		}

		.toggle-label {
			font-weight: ${body?.fontWeight || '400'};
			color: ${body?.fontColor || 'inherit'};
			font-family: ${body?.fontFamily || 'inherit'};
			margin-bottom: 16px;
			font-size: ${14 / 16}rem;
		}

		.button-container {
			padding: 0 1.5rem 1rem 1.5rem;
		}

		.payment-bump-container {
			padding: 16px 24px 0;
			padding-top: 0;
		}

		${headerStyles}
		${inputStyles}
	`;
};

const styles = (template, zonesAreConnected, isTwoColumnLayout) => {
	const paymentStyles = getZoneStyles(template, 'payment');
	const {
		backgroundColor,
		boxShadow: customBoxShadow,
		borderSize,
		borderColor,
		borderRadius,
	} = paymentStyles || {};
	const isJoined = isTwoColumnLayout || zonesAreConnected;

	const isBorderTransparent = borderColor?.length > 7 && borderColor?.slice(-2) === '00';
	const hideBorder = !borderColor || isBorderTransparent || isJoined;
	const borderWidth = borderSize || '1px';
	const borderColorValue = borderColor || defaultPanelBorderColor;
	const border = hideBorder ? 'none' : `${borderWidth} solid ${borderColorValue}`;

	const boxShadow = zonesAreConnected ? 'none' : customBoxShadow;

	return css`
		background: ${backgroundColor || defaultPanelBackgroundColor};
		box-shadow: ${isJoined ? 'none' : boxShadow || defaultPanelBoxShadow};
		overflow: hidden;

		${checkoutTheme.breakpoints.up('sm')} {
			border: ${border};
			border-radius: ${borderRadius && !isJoined ? borderRadius : '0'};
		}

		${checkoutTheme.breakpoints.down('sm')} {
			box-shadow: none;
		}
	`;
};

const Payment = ({ bumpList, showBumpsInPaymentSection }) => {
	const { isSmallScreen, isTwoColumnLayout } = useContext(MediaQueryContext);
	const { template, isShippable, cartInitialized } = useStore(
		useShallow((state) => ({
			template: state.template,
			isShippable: state.isShippable,
			cartInitialized: state.cartInitialized,
		})),
	);

	const { t } = useTranslation('checkout');

	const shouldShowSubmitButtonInPaymentSection =
		!isSmallScreen &&
		!isShippable &&
		cartInitialized &&
		[
			Layouts.STANDARD_INPUT_LEFT_BUTTON_LEFT,
			Layouts.STANDARD_INPUT_RIGHT_BUTTON_RIGHT,
			Layouts.JOINED_INPUT_RIGHT_BUTTON_RIGHT,
			Layouts.JOINED_INPUT_LEFT_BUTTON_LEFT,
		].includes(template.layout);

	const zonesAreConnected = isTemplateJoined(template);

	return (
		<div
			css={styles(template, zonesAreConnected, isTwoColumnLayout)}
			className="CB-form"
			id="CB-payment-container"
		>
			<Card css={cardStyles(template)}>
				<div className="payment-card-header">
					<Typography
						variant="h4"
						className="heading CB-font-title"
						style={{ marginBottom: 0 }}
					>
						{t('card-title.payment')}
					</Typography>

					<>
						<Typography variant="body2" className="toggle-label">
							{t('field.payment-method.label')}
						</Typography>
					</>
				</div>

				<PayMethodAccordions />

				{showBumpsInPaymentSection && (
					<div className="payment-bump-container">
						<Bumps bumpList={bumpList} hideTopBorder />
					</div>
				)}

				{shouldShowSubmitButtonInPaymentSection && (
					<div className="button-container">
						<SubmitButton />
					</div>
				)}
			</Card>
			{!isShippable && isSmallScreen && <ZoneWrapper zoneId="rightColumnBottom" />}
			{!isShippable && <ZoneWrapper zoneId="leftColumnBottom" />}
		</div>
	);
};

Payment.propTypes = {
	bumpList: PropTypes.array,
	showBumpsInPaymentSection: PropTypes.bool,
};

export default Payment;
