import { CardContent } from '@mui/material';
// We want to export this component as Card in our project
// So we rename Card from material-ui to MuiCard
import { default as MuiCard } from '@mui/material/Card';
import PropTypes from 'prop-types';

const Card = (props) => {
	const { cardStyle, className, children } = props;

	return (
		<MuiCard css={cardStyle} className={`${className || ''}`}>
			<CardContent>{children}</CardContent>
		</MuiCard>
	);
};

Card.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	cardStyle: PropTypes.object,
};

Card.defaultProps = {
	className: null,
	cardStyle: null,
};

export default Card;
